import React, { useEffect, useState } from 'react'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import EditPetroPocketStatement from '../AccountDetailsBasicDialogs/EditPetroPocketStatement'
import EditPetroAccStatus from './EditPetroAccStatus'

const EditPetroPocket = ({ rowData, accountStatusLov, formikValuees, type, petroStatusId }) => {

    const [displayDialog, setDisplayDialog] = useState(false)
    const [displayStatusDialog, setDisplayStatusDialog] = useState(false)
    const [petroStatusName, setPetroStatusName] = useState('')

    const handleDialog = () => {
        setDisplayDialog(true)
    }

    const handlePetroAccStatus = () => {
        setDisplayStatusDialog(true)
    }

    function getCurrentURL() {
        return window.location.href
    }

    const url = getCurrentURL();

    const isChecker = url.includes("editcheckeraccountdetails") || url.includes("viewcheckeraccountdetails");

    const getAccountStatusId = () => {
        const result = petroStatusId && accountStatusLov?.find((item) => item?.accountStatusId == petroStatusId);
        const statusName = result?.accountStatusName;
        setPetroStatusName(statusName)
    }

    useEffect(() => {
        if (type === "Checker") {
            getAccountStatusId()
        }
    }, [accountStatusLov, petroStatusId]);

    return (
        <div>

            <Dialog header="Close Account Disown CNIC" blockScroll draggable={false} visible={displayDialog} style={{ width: '30vw' }} onHide={() => setDisplayDialog(false)}>
                <EditPetroPocketStatement rowData={rowData} onHide={setDisplayDialog} />
            </Dialog>
            <Dialog header="Petro Account Status" blockScroll draggable={false} visible={displayStatusDialog} style={{ width: '30vw' }} onHide={() => setDisplayStatusDialog(false)}>
                <EditPetroAccStatus rowData={rowData} onHide={setDisplayStatusDialog} formikValuees={formikValuees} accountStatusLov={accountStatusLov} />
            </Dialog>

            <div className="hra_head">
                <h5 className="secondary-color">
                    <b>PETRO POCKET</b>
                </h5>
            </div>

            <div className="p-fluid p-formgrid grid mb-3">
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Type</label>
                        <div className="flex align-items-center">
                            <InputText
                                id="accountTypeId"
                                value={rowData?.petroAccountType}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Opening Mode</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.channelName}
                                className='Input__Round'
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Account Status</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={type === "Checker" ? petroStatusName ?? "" : rowData?.petroAccountStatus}
                                className="Input__Round mr-2"
                                disabled
                            />
                            {
                                type !== "Checker" &&
                                <i
                                    className="field-edit-icon pi pi-pencil"
                                    onClick={handlePetroAccStatus}
                                />
                            }
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Terms & Condition Accepted Date</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.petroTncDate && rowData?.petroTncDate.split(" ")[0]}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Current Balance</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.petroBalance}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
                <div className="p-field col-12 md:col-4">
                    <div className="p-field">
                        <label className="Label__Text">Location</label>
                        <div className="flex align-items-center">
                            <InputText
                                value={rowData?.latitude && rowData?.latitude ? rowData?.latitude + ", " + rowData?.longitude : ""}
                                className="Input__Round"
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-content-center'>
                <div className='Down__Btn' style={{ width: "300px", marginBottom: "25px" }}>
                    <Button label='Petro Pocket Statement' className='Btn__Dark w-100' onClick={handleDialog} disabled={isChecker} />
                </div>
            </div>

        </div>
    )
}

export default EditPetroPocket