import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import TypeOfAccountOldNewValues from './TypeOfAccountOldNewValues';
import { Sidebar } from 'primereact/sidebar';

function EditTypeOfAccountChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accountClassificationData, setAccountClassificationData] = useState([]);
    const [kycSetsData, setKycSetsData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [accountLevelIdData, setAccountLevelIdData] = useState();
    const [accountNameData, setAccountNameData] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [typeOfAccountRowData, setTypeOfAccountRowData] = useState();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getAccountTypeById = async () => {
        const resp = await handleGetRequest(`/account/v1/accountype/getAccountTypeById/${refTableId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            formik.setFieldValue('kycSetHeadId', keyData?.tblKycSetHead?.kycSetHeadId?.toString());
            formik.setFieldValue('lkpAccountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            setAccountLevelIdData(keyData?.accountLevelId);
        }
    };

    const getAccountTypeMcRequestDataById = async () => {
        const resp = await handleGetRequest(`/account/v1/accountype/getAccountTypesUpdateCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;
        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });

            formik.setFieldValue('kycSetHeadId', keyData?.tblKycSetHead?.kycSetHeadId?.toString());
            formik.setFieldValue('lkpAccountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            setAccountLevelIdData(keyData?.accountLevelId);
        }
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getAccountNameData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccountlevelcode');
        setAccountNameData(res?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getAccountTypeById();
        } else if (requestType === 'U') {
            getAccountTypeMcRequestDataById();
        }
        getStatusLov();
        getAccountNameData();
    }, []); // eslint-disable-line

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAccountClassificationData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassificationData(res?.payLoad);
    };

    useEffect(() => {
        getAccountClassificationData();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            lkpAccountClassificationId: '',
            accountLevelName: '',
            kycSetHeadId: '',
            dailyTransLimitDr: '',
            dailyAmtLimitDr: '',
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: '',
            action: '',
            checkerComments: '',
            maxAmtLimit: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        lkpAccountLevelRequest: {
                            lkpAccountClassificationId: data['lkpAccountClassificationId'],
                            accountLevelName: data['accountLevelName'],
                            kycSetHeadId: data['kycSetHeadId'],
                            dailyTransLimitDr: data['dailyTransLimitDr'],
                            dailyAmtLimitDr: data['dailyAmtLimitDr'],
                            monthlyTransLimitDr: data['monthlyTransLimitDr'],
                            monthlyAmtLimitDr: data['monthlyAmtLimitDr'],
                            yearlyTransLimitDr: data['yearlyTransLimitDr'],
                            yearlyAmtLimitDr: data['yearlyAmtLimitDr'],
                            dailyTransLimitCr: data['dailyTransLimitCr'],
                            dailyAmtLimitCr: data['dailyAmtLimitCr'],
                            monthlyTransLimitCr: data['monthlyTransLimitCr'],
                            monthlyAmtLimitCr: data['monthlyAmtLimitCr'],
                            yearlyTransLimitCr: data['yearlyTransLimitCr'],
                            yearlyAmtLimitCr: data['yearlyAmtLimitCr'],
                            accountLevelId: accountLevelIdData,
                            isActive: data['isActive'],
                            maxAmtLimit: data['maxAmtLimit']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/accountype/accountTypeCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getUserLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkycsets/TBL_KYC_SET_HEAD:*/${formik.values.lkpAccountClassificationId}`);
        setKycSetsData(resp?.payLoad);
    };
    useEffect(() => {
        if (formik.values.lkpAccountClassificationId !== '') {
            getUserLov();
        }
    }, [formik.values.lkpAccountClassificationId]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]); // eslint-disable-line

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/account/v1/accountype/getAccountTypeById/${refTableId}`);
        setTypeOfAccountRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Channel" position="right" style={{ width: '70vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <TypeOfAccountOldNewValues typeOfAccountRowData={typeOfAccountRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <div className="Form__Header Full__Width">
                    <h1>EDIT DETAILS</h1>
                    {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Select Type Of Account
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="lkpAccountClassificationId"
                                    placeholder="Select Type Of Account"
                                    options={accountClassificationData}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                    className={
                                        highlightedKeys?.includes('lkpAccountClassificationId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountLevelName" className={classNames({ 'p-error': isFormFieldValid('accountLevelName') }, 'Label__Text')}>
                                    Name Of Account
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountLevelName"
                                    placeholder="Enter Name Of Account"
                                    name="accountLevelName"
                                    options={accountNameData}
                                    optionLabel="name"
                                    optionValue="name"
                                    value={formik?.values?.accountLevelName?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Input__Round')}
                                    className={
                                        highlightedKeys?.includes('accountLevelName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('accountLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="kycSetHeadId" className={classNames({ 'p-error': isFormFieldValid('kycSetHeadId') }, 'Label__Text')}>
                                    Select KYC Set
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="kycSetHeadId"
                                    placeholder="Select KYC Set"
                                    options={kycSetsData}
                                    optionLabel="name"
                                    name="kycSetHeadId"
                                    optionValue="lovId"
                                    value={formik.values.kycSetHeadId || ''}
                                    onChange={formik.handleChange}
                                    // className={classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round')}
                                    className={
                                        highlightedKeys?.includes('kycSetHeadId')
                                            ? classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round')
                                    }
                                />

                                {getFormErrorMessage('kycSetHeadId')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Debit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="dailyTransLimitDr"
                                        name="dailyTransLimitDr"
                                        value={formik?.values?.dailyTransLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('dailyTransLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="dailyAmtLimitDr"
                                        name="dailyAmtLimitDr"
                                        value={formik?.values?.dailyAmtLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('dailyAmtLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="monthlyTransLimitDr"
                                        name="monthlyTransLimitDr"
                                        value={formik?.values?.monthlyTransLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('monthlyTransLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="monthlyAmtLimitDr"
                                        name="monthlyAmtLimitDr"
                                        value={formik?.values?.monthlyAmtLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('monthlyAmtLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="yearlyTransLimitDr"
                                        name="yearlyTransLimitDr"
                                        value={formik?.values?.yearlyTransLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('yearlyTransLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="yearlyAmtLimitDr"
                                        name="yearlyAmtLimitDr"
                                        value={formik?.values?.yearlyAmtLimitDr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('yearlyAmtLimitDr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Credit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="dailyTransLimitCr"
                                        name="dailyTransLimitCr"
                                        value={formik?.values?.dailyTransLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('dailyTransLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="dailyAmtLimitCr"
                                        name="dailyAmtLimitCr"
                                        value={formik?.values?.dailyAmtLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('dailyAmtLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="monthlyTransLimitCr"
                                        name="monthlyTransLimitCr"
                                        value={formik?.values?.monthlyTransLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-error': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('monthlyTransLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="monthlyAmtLimitCr"
                                        name="monthlyAmtLimitCr"
                                        value={formik?.values?.monthlyAmtLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('monthlyAmtLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="yearlyTransLimitCr"
                                        name="yearlyTransLimitCr"
                                        value={formik?.values?.yearlyTransLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('yearlyTransLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                                <div>
                                    <InputText
                                        disabled={true}
                                        id="yearlyAmtLimitCr"
                                        name="yearlyAmtLimitCr"
                                        value={formik?.values?.yearlyAmtLimitCr || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round__DC')}
                                        className={
                                            highlightedKeys?.includes('yearlyAmtLimitCr')
                                                ? classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Round')
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        {(formik.values.lkpAccountClassificationId === '2' || formik.values.lkpAccountClassificationId === '3') && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="maxAmtLimit" className={classNames({ 'p-error': isFormFieldValid('maxAmtLimit') }, 'Label__Text__MaxBal')}>
                                        Maximum Balance
                                    </label>
                                    <InputText
                                        id="maxAmtLimit"
                                        placeholder="Enter Maximum Balance"
                                        name="maxAmtLimit"
                                        maxLength={9}
                                        disabled={true}
                                        value={formik?.values?.maxAmtLimit || ''}
                                        onChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round')}
                                        className={
                                            highlightedKeys?.includes('maxAmtLimit')
                                                ? classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round__Highlighted')
                                                : classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round')
                                        }
                                    />
                                    {getFormErrorMessage('maxAmtLimit')}
                                </div>
                            </div>
                        )}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="requestTypeDescr" className={classNames({ 'p-error': isFormFieldValid('requestTypeDescr') }, 'Label__Text')}>
                                    Request Type Description
                                </label>
                                <InputText
                                    disabled={true}
                                    id="requestTypeDescr"
                                    placeholder="Enter Name Of Account"
                                    name="requestTypeDescr"
                                    value={requestTypeDescr}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('requestTypeDescr') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('requestTypeDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    isActive
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox disabled={true} inputId="isActive" name="isActive" value={formik?.values?.isActive} checked={formik?.values?.isActive} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize="false"
                                    rows={5}
                                    cols={30}
                                    maxLength={500}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ')}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default EditTypeOfAccountChecker;
