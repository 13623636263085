import React, { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import TierDialog from './TierDialog';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../services/GetTemplate';
import { useEffect } from 'react';
import { handlePostRequest } from '../../../services/PostTemplate';
import * as Yup from 'yup';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';
import { Paginator } from 'primereact/paginator';
import { InputSwitch } from 'primereact/inputswitch';
import TierDialogView from './TierDialogView';
import ActiveInActiveDialog from '../../../components/ActiveInActiveDialog/ActiveInActiveDialog';

function Tier() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [globalFilter, setGlobalFilter] = useState('');
    const [tierLovData, setTierLovData] = useState();
    const [tierData, setTierData] = useState([]);
    const [showTierDialog, setShowTierDialog] = useState(false);
    const [showTierDialogView, setShowTierDialogView] = useState(false);
    const [dialogData, setDialogData] = useState();
    const [editable, setEditable] = useState(false);
    const [statusLovData, setStatusLovData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [tierRowData, setTierRowData] = useState({});

    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const [tierIdData, setTierIdData] = useState();
    const [isActiveData, setIsActiveData] = useState();

    const [showModal, setShowModal] = useState(false);

    const dataTableRef = useRef(null);

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            tier: '',
            maxTierRange: '',
            multiplier: '',
            dateFrom: '',
            dateTo: '',
            status: '2',
            createdBy: '',
            updatedBy: '',
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/tierSearch`, true, true));

            if (res?.responseCode === '330000') {
                formik.resetForm();
                setTierData(res?.payLoad);
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const header = (
        <div className="Global__Header">
            <h5 className="m-0">Tier List</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const handleTierEdit = (rowData) => {
        setDialogData(rowData);
        setShowTierDialog(true);
        setEditable(true);
    };

    const handleTierView = (rowData) => {
        setDialogData(rowData);
        setShowTierDialogView(true);
    };

    const handleSwitchChange = async () => {
        const updatedData = tierData?.map((item) => {
            if (item.zmilesTierId === tierIdData) {
                const isActive = item.isActive === 'Y' ? 'N' : 'Y';
                return { ...item, isActive };
            }
            return item;
        });
        setTierData(updatedData);
        let isactive = '';
        if (isActiveData === 'Y') {
            isactive = 'N';
        } else if (isActiveData === 'N') {
            isactive = 'Y';
        }
        const previousValue = isActiveData;

        const data = {
            isActive: isactive,
            tier: tierRowData?.tier,
            maxTierRange: tierRowData?.maxTierRange,
            multiplier: tierRowData?.multiplier,
            statusId: 0,
            zmilesTierId: tierIdData
        };
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/zconfig/v1/zmiles/inactiveTier', true, false));
        if (res?.responseCode !== '330000') {
            const revertedData = tierData?.map((item) => {
                if (item.zmilesTierId === tierIdData) {
                    const isActive = previousValue;
                    return { ...item, isActive };
                }
                return item;
            });
            setTierData(revertedData);
        }
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <InputSwitch
                        checked={rowData.isActive === 'Y'}
                        disabled={['Pending', 'Rejected'].includes(rowData?.lkpStatus?.statusName) ? true : false}
                        onChange={() => {
                            setShowModal(true);
                            setTierIdData(rowData?.zmilesTierId);
                            setIsActiveData(rowData?.isActive);
                            setTierRowData(rowData);
                        }}
                    />
                    <Button
                        icon="pi pi-pencil"
                        tooltip="Edit"
                        tooltipOptions={{ position: 'top' }}
                        className="p-button-rounded mr-2"
                        onClick={() => handleTierEdit(rowData)}
                        disabled={['Pending', 'Rejected'].includes(rowData?.status) || (rowData?.status === 'Approved' && rowData?.isActive === 'N') ? true : false}
                    />
                    <Button tooltip="View Details" tooltipOptions={{ position: 'top' }} icon="pi pi-eye" onClick={() => handleTierView(rowData)} className="p-button-rounded" style={{ backgroundColor: '#F2AC57', border: 'none' }} />
                </div>
            </>
        );
    };

    const handledTierClicked = async (e) => {
        e.preventDefault();
        setShowTierDialog(true);
        setEditable(false);
    };
    const onHideTier = () => {
        setShowTierDialog(false);
    };
    const onHideTierView = () => {
        setShowTierDialogView(false);
    };
    const handleReset = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const getTierData = async () => {
        const res = await handleGetRequest('/configuration/v1/zmiles/getAllZmilesTier');
        setTierLovData(res?.payLoad);
    };

    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        setStatusLovData(res?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        getTierData();
        getStatusListData();
        getUserLov();
    }, []); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        tier: formik.values.tier,
        maxTierRange: formik.values.maxTierRange,
        multiplier: formik.values.multiplier,
        dateFrom: formik.values.dateFrom,
        dateTo: formik.values.dateTo,
        status: formik.values.status,
        createdBy: formik.values.createdBy,
        updatedBy: formik.values.updatedBy,
        rowStart: customFirst1,
        rowEnd: customRows1 + customFirst1
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createDate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const handleShowModal = () => {
        setShowModal(false);
    };

    const props = {
        showModal,
        handleShowModal,
        handleSwitchChange
    };

    return (
        <>
            <ActiveInActiveDialog {...props} />

            <Dialog visible={showTierDialog} header={editable ? 'Update Tier Form' : 'Add Tier Form'} style={{ width: '450px' }} onHide={() => setShowTierDialog(false)} resizable={false} draggable={false}>
                <TierDialog onHideTier={onHideTier} dialogData={dialogData} editable={editable} />
            </Dialog>

            <Dialog visible={showTierDialogView} header="View Details" style={{ width: '450px' }} onHide={() => setShowTierDialogView(false)} resizable={false} draggable={false}>
                <TierDialogView onHideTierView={onHideTierView} dialogData={dialogData} />
            </Dialog>

            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setTierData}
                RequestData={RequestData}
                url={'/zconfig/v1/zmiles/tierSearch'}
                responseCode={'330000'}
            />

            {/* Base Factor Form */}
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add Tier" onClick={handledTierClicked} className="Btn__Add__" icon="pi pi-plus" iconPos="left" style={{ width: 'auto' }} />
                </div>
            </div>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Tier</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="tier" className={classNames({ 'p-error': isFormFieldValid('tier') }, 'Label__Text')}>
                                    Tier
                                </label>
                                <Dropdown
                                    id="tier"
                                    placeholder="Select Tier"
                                    options={tierLovData}
                                    optionLabel="tier"
                                    name="tier"
                                    optionValue="tier"
                                    value={formik.values.tier}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tier') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('tier')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="maxTierRange" className={classNames({ 'p-error': isFormFieldValid('maxTierRange') }, 'Label__Text')}>
                                    Max Tier Range
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="maxTierRange"
                                    placeholder=""
                                    name="maxTierRange"
                                    value={formik?.values?.maxTierRange?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('maxTierRange') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('maxTierRange')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="multiplier" className={classNames({ 'p-error': isFormFieldValid('multiplier') }, 'Label__Text')}>
                                    Multiplier
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="multiplier"
                                    placeholder=""
                                    name="multiplier"
                                    value={formik?.values?.multiplier?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('multiplier') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('multiplier')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Date From
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateFrom"
                                    placeholder=""
                                    name="dateFrom"
                                    type="date"
                                    value={formik?.values?.dateFrom?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Date To
                                </label>
                                <InputText
                                    max={previousDate}
                                    id="dateTo"
                                    placeholder=""
                                    name="dateTo"
                                    type="date"
                                    value={formik?.values?.dateTo?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="status"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="status"
                                    optionValue="lovId"
                                    value={formik.values.status || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('status') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="updatedBy" className={classNames({ 'p-error': isFormFieldValid('updatedBy') }, 'Label__Text')}>
                                    Updated By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Updated By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="updatedBy"
                                    optionValue="lovId"
                                    value={formik.values.updatedBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('updatedBy') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('updatedBy')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button label="Search" className="Btn__Dark" />
                        <Button disabled={loading} onClick={(e) => handleReset(e)} iconPos="right" label="Reset" className=" Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Base Factor DataTable */}

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable ref={dataTableRef} className="datatable-responsive" globalFilter={globalFilter} emptyMessage="No List found." header={header} responsiveLayout="scroll" value={tierData}>
                            <Column field="tier" header="Tier" />
                            <Column field="maxTierRange" header="Max Tier Range" />
                            <Column field="multiplier" header="Multiplier" />
                            <Column field="createdBy" header="Created By" />
                            <Column field="updatedBy" header="Updated By" />
                            <Column body={createdDateAndTimeBodyTemplate} header="Created Date & Time" />
                            <Column field="status" header="Status" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={tierData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Tier;
