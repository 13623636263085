import React, { useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { useState } from 'react';
import { Button } from 'primereact/button';

const ViewSegment = ({ rowData, onHide, userTabsFieldData, type, handleSegmentNameChange }) => {

    const [segmentData, setSegmentData] = useState([])
    const [segmentLov, setSegmentLov] = useState([])
    const [segmentName, setSegmentName] = useState(null)

    const getSegmentById = async () => {
        const response = await handleGetRequest(`/account/v1/segment/getSegmentById/${rowData?.segmentId || rowData?.[0]?.segmentId}`);
        if (response?.responseCode === "010000") {
            setSegmentName(response?.payLoad?.segmentId.toString())
        }
    }

    const getSegmentLov = async () => {
        const response = await handleGetRequest(`/configuration/v1/lookups/getsegments/LKP_SEGMENT:*`);
        if (response?.responseCode === "050000") {
            setSegmentLov(response?.payLoad)
        }
    }

    const checkFieldPermissions = (fieldName) => {
        const field = type !== "fromChecker" && userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const segmentNameFieldPermissions = checkFieldPermissions("segmentName");

    const onSubmit = () => {
        handleSegmentNameChange(segmentName || null)
    }

    useEffect(() => {
        getSegmentById()
        getSegmentLov()
    }, []);

    return (
        <>
            <div className='Card__Round'>

                {type !== "fromChecker" ?
                    segmentNameFieldPermissions.viewAllowed && (

                        <div className='mb-2'>
                            <label className='Label__Text'>
                                Segment
                            </label>
                            <Dropdown
                                options={segmentLov}
                                optionLabel="name"
                                optionValue='lovId'
                                value={segmentName ?? ""}
                                onChange={(e) => setSegmentName(e?.target?.value)}
                                className='Dropdown__Round mr-2'
                                placeholder="--Select--"
                            />
                        </div>
                    )
                    :
                    <div className='mb-2'>
                        <label className='Label__Text'>
                            Segment
                        </label>
                        <Dropdown
                            options={segmentLov}
                            optionLabel="name"
                            optionValue='lovId'
                            value={segmentName ?? ""}
                            // onChange={(e) => setSegmentName(e?.target?.value)}
                            className='Dropdown__Round mr-2'
                            placeholder="--Select--"
                        />
                    </div>
                }
                {/* <div className='mb-2'>
                    <label className='Label__Text'>
                        Card Charges
                    </label>
                    <InputText
                        value={segmentName ?? ""}
                        className='Input__Round'
                        disabled
                    />
                </div>

                <div className='mb-2'>
                    <label className='Label__Text'>
                        IBFT Charges
                    </label>
                    <InputText
                        value={segmentName ?? ""}
                        className='Input__Round'
                        disabled
                    />
                </div>

                <div>
                    <label className='Label__Text'>
                        FED
                    </label>
                    <InputText
                        value="-"
                        className='Input__Round'
                        disabled
                    />
                </div> */}

                <div className='Down__Btn mt-4'>
                    {
                        type !== "fromChecker" &&
                        <Button label="Update" iconPos="right" className="Btn__Dark" onClick={onSubmit} />
                    }
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                </div>

            </div>
        </>
    )
}

export default ViewSegment