import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditAgentMateCompanyChecker() {
    const [maincategoryLov, setMainCategoryLov] = useState([]);
    const [subCategoryLov, setSubCategoryLov] = useState([]);
    const [companyIcon, setCompanyIcon] = useState();
    const [companyType, setCompanyType] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .required('This field is required')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Bank name must be alphanumeric')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsCompanyId: '',
            cmsMainCategoryId: '',
            cmsSubCategoryId: '',
            nameEnglish: '',
            nameUrdu: '',
            priority: '',
            icon: '',
            productId: '',
            prefix: '',
            cmsCompanyTypeId: '',
            partialPaymentAllowed: '',
            addTag: true,
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagBgColorId: '',
            expiryDate: '',
            description: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            const addPayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsCompanyId: data?.cmsCompanyId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsCompanyId: data?.cmsCompanyId,
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            cmsSubCategoryId: data?.cmsSubCategoryId,
                            nameEnglish: data?.nameEnglish,
                            nameUrdu: data?.nameUrdu,
                            priority: data?.priority,
                            icon: data?.icon,
                            productId: data?.productId,
                            prefix: data?.prefix,
                            cmsCompanyTypeId: data?.cmsCompanyTypeId,
                            partialPaymentAllowed: data?.partialPaymentAllowed,
                            cmsTagLabelId: data?.cmsTagLabelId,
                            cmsTagTextColorId: data?.cmsTagTextColorId,
                            cmsTagBgColorId: data?.cmsTagBgColorId,
                            expiryDate: data?.expiryDate,
                            description: data?.description,
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const formData = new FormData();

            if (requestType === 'I') {
                formData.append('request', JSON.stringify(addPayload));
            } else if (requestType === 'U') {
                formData.append('request', JSON.stringify(updatePayload));
            }
            await dispatch(handlePostRequest(formData, '/agentmatecms/v1/agentmatecms/companyvalidatorcheckeraction', true, false, 'requestchecker'));
        }
    });

    const getCompanyType = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getcmsdatalov/CMS_COMPANY_TYPE');
        setCompanyType(res?.payLoad);
    };

    const getMainCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getagentmatecategorydatalov');
        setMainCategoryLov(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentmatesubcategorydatalov/${formik?.values?.cmsMainCategoryId}`);
        setSubCategoryLov(res?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getCompanyDataById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getcompanybyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsCompanyId', keyData?.cmsCompanyId?.toString() || '');
        formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
        formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString() || '');
        formik.setFieldValue('nameEnglish', keyData?.nameEnglish || '');
        formik.setFieldValue('nameUrdu', keyData?.nameUrdu || '');
        formik.setFieldValue('priority', keyData?.priority || '');
        formik.setFieldValue('productId', keyData?.productId || '');
        formik.setFieldValue('prefix', keyData?.prefix || '');
        formik.setFieldValue('cmsCompanyTypeId', keyData?.cmsCompanyTypeId?.toString() || '');
        formik.setFieldValue('partialPaymentAllowed', keyData?.partialPaymentAllowed || '');
        formik.setFieldValue('addTag', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagBgColorId ? true : false);
        formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
        formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
        formik.setFieldValue('cmsTagBgColorId', keyData?.cmsTagBgColorId || '');
        const correctedDate = formattedDate(keyData?.expiryDate || '');
        formik.setFieldValue('expiryDate', correctedDate || '');
        formik.setFieldValue('description', keyData?.description || '');
        const companyPathParts = (keyData?.icon && keyData?.icon?.split('/')) || '';
        const company = (companyPathParts && companyPathParts[companyPathParts?.length - 1]) || '';
        setCompanyIcon(company);
    };
    const getCompanyDataByMcId = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getcompanymcrequest/${mcRequestId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('cmsCompanyId', keyData?.cmsCompanyId || '');
        formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId?.toString() || '');
        formik.setFieldValue('cmsSubCategoryId', keyData?.cmsSubCategoryId?.toString() || '');
        formik.setFieldValue('cmsSecondSubCategoryId', keyData?.cmsSecondSubCategoryId?.toString() || '');
        formik.setFieldValue('nameEnglish', keyData?.nameEnglish || '');
        formik.setFieldValue('nameUrdu', keyData?.nameUrdu || '');
        formik.setFieldValue('priority', keyData?.priority || '');
        formik.setFieldValue('productId', keyData?.productId || '');
        formik.setFieldValue('prefix', keyData?.prefix || '');
        formik.setFieldValue('cmsCompanyTypeId', keyData?.cmsCompanyTypeId?.toString() || '');
        formik.setFieldValue('partialPaymentAllowed', keyData?.partialPaymentAllowed || '');
        formik.setFieldValue('addTag', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagBgColorId ? true : false);
        formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
        formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
        formik.setFieldValue('cmsTagBgColorId', keyData?.cmsTagBgColorId || '');
        const correctedDate = formattedDate(keyData?.expiryDate || '');
        formik.setFieldValue('expiryDate', correctedDate || '');
        formik.setFieldValue('description', keyData?.description || '');
        formik.setFieldValue('isActive', keyData?.isActive || '');

        formik.setFieldValue('icon', keyData?.icon || '');

        const companyPathParts = (keyData?.icon && keyData?.icon?.split('/')) || '';
        const company = (companyPathParts && companyPathParts[companyPathParts?.length - 1]) || '';
        setCompanyIcon(company);
    };

    useEffect(() => {
        if (formik?.values?.cmsMainCategoryId != '') {
            getSubCategoryDataLov();
        }
    }, [formik?.values?.cmsMainCategoryId]); // eslint-disable-line

    useEffect(() => {
        getMainCategoryDataLov();
        getCompanyType();
        getStatusLov();
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestType === 'I') {
            getCompanyDataById();
        } else if (requestType === 'U') {
            getCompanyDataByMcId();
        }
    }, [requestType]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="Form__Header Full__Width">
                        <h2>EDIT DETAIL</h2>
                    </div>
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-fluid p-formgrid grid mb-5 col-12 md:col-12 ">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsMainCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsMainCategoryId') }, 'Label__Text')}>
                                        Main Category<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsMainCategoryId"
                                        placeholder="Select cmsMainCategoryId"
                                        options={maincategoryLov}
                                        optionLabel="name"
                                        name="cmsMainCategoryId"
                                        optionValue="lovId"
                                        value={formik?.values?.cmsMainCategoryId || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsMainCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsMainCategoryId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('cmsSubCategoryId') }, 'Label__Text')}>
                                        Sub Category
                                    </label>
                                    <Dropdown
                                        id="cmsSubCategoryId"
                                        placeholder="Select Sub Category"
                                        options={subCategoryLov}
                                        optionLabel="name"
                                        name="cmsSubCategoryId"
                                        optionValue="lovId"
                                        value={formik.values.cmsSubCategoryId || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('cmsSubCategoryId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('cmsSubCategoryId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="nameEnglish" className={classNames({ 'p-error': isFormFieldValid('nameEnglish') }, 'Label__Text')}>
                                        Display Name (English) <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="nameEnglish"
                                        placeholder="Enter Display Name (English)"
                                        name="nameEnglish"
                                        value={formik?.values?.nameEnglish || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameEnglish') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameEnglish')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                        Display Name (Urdu)<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="nameUrdu"
                                        placeholder="Enter Display Name (Urdu) "
                                        name="nameUrdu"
                                        value={formik?.values?.nameUrdu || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('nameUrdu')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                        Priority <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="priority"
                                        placeholder="Enter Priority"
                                        name="priority"
                                        value={formik?.values?.priority || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('priority')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <div className="mb-2">
                                        <label className="Label__Text">
                                            Category Icon<span className="Label__Required">*</span>
                                        </label>
                                        <div className="file-input-container w-100">
                                            <input type="file" style={{ display: 'none' }} accept="image/*" />
                                            <input type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={companyIcon} readOnly disabled />
                                            <span className="file-upload-icon">
                                                <i className="pi pi-paperclip" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                        Product ID <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="productId"
                                        placeholder="Enter Product ID "
                                        name="productId"
                                        value={formik?.values?.productId || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('productId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="prefix" className={classNames({ 'p-error': isFormFieldValid('prefix') }, 'Label__Text')}>
                                        Prefix <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="prefix"
                                        placeholder="Enter Prefix "
                                        name="prefix"
                                        value={formik?.values?.prefix || ''}
                                        onChange={formik.handleChange}
                                        disabled
                                        className={classNames({ 'p-invalid': isFormFieldValid('prefix') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('prefix')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="cmsCompanyTypeId" className={classNames({ 'p-error': isFormFieldValid('cmsCompanyTypeId') }, 'Label__Text')}>
                                        Company Type<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="cmsCompanyTypeId"
                                        placeholder="Select Company Type"
                                        options={companyType}
                                        optionLabel="name"
                                        name="cmsCompanyTypeId"
                                        optionValue="lovId"
                                        value={formik?.values?.cmsCompanyTypeId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames(
                                            {
                                                'p-invalid': isFormFieldValid('cmsCompanyTypeId')
                                            },
                                            'Dropdown__Round'
                                        )}
                                        disabled
                                    />
                                    {getFormErrorMessage('cmsCompanyTypeId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="partialPayment" className={classNames({ 'p-error': isFormFieldValid('channelName') }, 'Label__Text')}>
                                        Allow Partial Payment <span className="Label__Required">*</span>
                                    </label>

                                    <div className="flex pl-1">
                                        <div className="mt-2 mr-8">
                                            <RadioButton inputId="Yes" name="partialPaymentAllowed" value="Y" onChange={formik.handleChange} checked={formik.values.partialPaymentAllowed === 'Y'} disabled />

                                            <label htmlFor="yes" className={classNames({ 'p-error': isFormFieldValid('Yes') }, 'Label__Text')}>
                                                Yes
                                            </label>
                                        </div>

                                        <div className="mt-2">
                                            <RadioButton inputId="no" name="partialPaymentAllowed" value="N" onChange={formik.handleChange} checked={formik.values.partialPaymentAllowed === 'N'} disabled />

                                            <label htmlFor="no" className={classNames({ 'p-error': isFormFieldValid('no') }, 'Label__Text')}>
                                                No
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="card Card__Bg__Change col-12 md:col-12">
                                <h6 className="secondary-color">
                                    <b>TAG</b>
                                </h6>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3 flex align-items-end justify-content-start">
                                    <div className="field-checkbox flex mb-2 p-field-addbanner-width ">
                                        <div className="mr-5">
                                            <Checkbox inputId="addTag" name="addTag" checked={formik?.values?.addTag} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />
                                            <label htmlFor="addTag" className={classNames({ 'p-error': isFormFieldValid('addTag') }, 'Label__Text')} style={{ marginTop: '10px' }}>
                                                Add Tag<span className="Label__Required">*</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                                Tag Label<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="cmsTagLabelId"
                                                placeholder="Enter Tag Label"
                                                name="cmsTagLabelId"
                                                disabled
                                                value={formik?.values?.cmsTagLabelId || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('cmsTagLabelId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                                Tag Text Color<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                                }}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                                }}
                                            />

                                            {getFormErrorMessage('cmsTagTextColorId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="cmsTagBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColorId') }, 'Label__Text')}>
                                                Tag Background Color<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                value={formik?.values?.cmsTagBgColorId || '#FFFFFF'}
                                                onChange={(e) => {
                                                    formik.setFieldValue('cmsTagBgColorId', e.target.value);
                                                }}
                                                disabled
                                                readOnly
                                                className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColorId') }, 'Input__Round')}
                                                style={{
                                                    backgroundColor: formik?.values?.cmsTagBgColorId || '#FFFFFF',
                                                    color: getContrastColor(formik?.values?.cmsTagBgColorId || '#FFFFFF')
                                                }}
                                            />

                                            {getFormErrorMessage('cmsTagBgColorId')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                        <div className="p-field">
                                            <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                                Tag Expiry Date<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                id="expiryDate"
                                                type="date"
                                                name="expiryDate"
                                                value={formik?.values?.expiryDate}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                                disabled
                                            />

                                            {getFormErrorMessage('expiryDate')}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-12 w-full pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                        Description
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="description"
                                        name="description"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Input__Round')}
                                        value={formik?.values?.description}
                                        disabled
                                    />
                                    {getFormErrorMessage('description')}
                                </div>
                            </div>
                            <div className="card Card__Bg__Change col-12 md:col-12 flex">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Select Action<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik.values.action || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                    <div className="desc_Text_Area">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Checker Comments<span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            placeholder="-"
                                            inputid="checkerComments"
                                            name="checkerComments"
                                            rows={4}
                                            cols={30}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                            value={formik?.values?.checkerComments}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditAgentMateCompanyChecker;
