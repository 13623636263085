import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import { baseURL } from '../../../../Config';

function DialogEmailDocToCus({ onHideEmailDoc, rowData, customerId, customerEmail, customerMobile, cnicNumber }) {
    const [isLoading, setIsLoading] = useState(false);
    const [docData, setDocData] = useState([]);
    // const [emailDocData, setEmailDocData] = useState();
    const [isShowFields, setIsShowFields] = useState(false);
    const [handledDropdownSelectedValue, setHandledDropdownSelectedValue] = useState();
    const [isButtonDisable, setIsButtonDisable] = useState(false);
    const [docIds, setDocIds] = useState([]);

    const dispatch = useDispatch();

    const currentDate = new Date();

    currentDate.setDate(currentDate.getDate() - 90); // Subtract 1 day
    // const previousDate = currentDate.toISOString().split('T')[0];

    const nowDate = new Date()?.toISOString()?.split('T')[0];

    const getDocData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getDicrepentDocuments/LKP_DOCUMENT_TYPE:*');
        const data = resp?.payLoad?.filter((item) => ["21", "16", "23", "22"].includes(item?.accountStatusId))
        setDocData(data);
    };

    useEffect(() => {
        getDocData();
        formik.setFieldValue('email', customerEmail);
    }, []);

    const validationSchema = Yup.object().shape({
        selectDoc: Yup.mixed().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            selectDoc: '',
            attachment: '',
            email: '',
            fromDate: '',
            toDate: ''
        },

        onSubmit: async (data, e) => {
            setIsLoading(true);

            ////////////////////////newDataForAccountStatement////////////////////////
            const newDataForAccountStatement = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        email: customerEmail,
                        cnic: cnicNumber,
                        accountNumber: rowData?.accountNo,
                        // email: customerEmail,
                        // to: data?.email,
                        //for now email is hardcoded because of encrypted email
                        // mobileNumber: customerMobile,
                        mobileNumber: customerMobile,
                        fromDate: data?.fromDate,
                        toDate: data?.toDate,
                        /////for now these below keys are set manually
                        text: 'Please find attachment',
                        subject: 'Account Statement Documents',
                        name: rowData?.fullName,

                        // this customerId is from AccountDetailsEditpage and pass as props todialEmailDocToCus Dialog
                        customerId: customerId
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            ///////////////////////////////////////newDataForSendDocsToCust////////////
            const newDataForSendDocsToCust = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        to: customerEmail,
                        // to: data?.email,
                        //for now email is hardcoded because of encrypted email
                        path: data?.attachment,
                        /////for now these below keys are set manually
                        text: 'Please find attachment',
                        subject: 'Documents',
                        fileName: formik.values.attachment,

                        // this customerId is from AccountDetailsEditpage and pass as props todialEmailDocToCus Dialog
                        customerId: customerId
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const dynamicData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        text: 'Please find attachment',
                        subject: formik.values.accountStatusName === 'NOC' ? 'NOC' : formik.values.accountStatusName === 'ACCOUNT MAINTAINANCE CERTIFICATE' ? 'AMC' : 'WHT Certificate',
                        email: rowData?.email,
                        mobileNumber: rowData?.mobileNo,
                        customerId: rowData?.customerId,
                        name: rowData?.fullName,
                        accountNumber: rowData?.accountNo,
                        fileName: formik.values.accountStatusName === 'NOC' ? 'NOC' : formik.values.accountStatusName === 'ACCOUNT MAINTAINANCE CERTIFICATE' ? 'AMC' : 'WHT',
                        fromDate: data?.fromDate,
                        toDate: data?.toDate
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (formik.values.accountStatusName === 'Account Statement') {
                // if (isShowFields) {
                const res = await dispatch(handlePostRequest(newDataForAccountStatement, '/account/v1/customeraccounts/generateaccountstatement', false, false, 'accountsmanagement'));
                if (res?.responseCode !== '070000') {
                    setIsButtonDisable(true);
                }
            } else if (docIds.includes(formik.values.selectDoc)) {
                if (formik.values.accountStatusName === 'NOC' || formik.values.accountStatusName === 'ACCOUNT MAINTAINANCE CERTIFICATE' || formik.values.accountStatusName === 'WHT CERTIFICATE') {
                    // if (formik.values.accountStatusName === "NOC") {
                    dynamicData.data.payLoad['cnic'] = rowData?.cnic;
                    dynamicData.data.payLoad['loanReference'] = '123FF';
                    // }
                    await dispatch(handlePostRequest(dynamicData, '/account/v1/customeraccount/generatecustomerdocs', false, false, 'accountsmanagement'));
                }
            } else {
                await dispatch(handlePostRequest(newDataForSendDocsToCust, '/account/v1/customeraccounts/sendcustomerdocs', false, false, 'accountsmanagement'));
            }

            onHideEmailDoc();
            setIsLoading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledDropdown = async (e) => {
        formik.setFieldValue('accountStatusName', docData && docData.filter((item) => item?.accountStatusId === e?.value)[0]?.accountStatusName);

        e.preventDefault();
        setHandledDropdownSelectedValue(e.value);

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    // this customerId is from AccountDetailsEditpage and pass as props todialEmailDocToCus Dialog
                    customerId: customerId,
                    documentType: e?.value
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        let documentIds = [];

        docData
            .filter((value) => value.accountStatusName === 'Account Statement' || value.accountStatusName === 'ACCOUNT MAINTAINANCE CERTIFICATE' || value.accountStatusName === 'NOC' || value.accountStatusName === 'WHT CERTIFICATE')
            .forEach((value) => {
                documentIds.push(value.accountStatusId);
            });

        if (documentIds.includes(e?.value)) {
            setDocIds(documentIds);
            setIsButtonDisable(false);
            return;
        } else {
            setDocIds('');
            setIsLoading(true);
            const res = await dispatch(handlePostRequest(newData, '/documents/v1/documents/getcustomeraccountdocs', false, true));
            if (res?.responseCode === '070000') {
                // setEmailDocData(res?.payLoad);
                const splitDocPath = res?.payLoad?.documentPath;

                formik.setFieldValue('attachment', splitDocPath ? splitDocPath : '');
                setIsButtonDisable(false);
            } else if (res?.responseCode !== '070000') {
                formik.setFieldValue('attachment', '');
                setIsButtonDisable(true);
            }
            setIsLoading(false);
        }
    };

    // const hideFields = (e) => {
    //     e.preventDefault();
    //     let documentId = '';
    //     docData.filter((value) => (value.accountStatusName === 'Account Statement' || value.accountStatusName === 'WHT CERTIFICATE' ? (documentId = value.accountStatusId) : ''));
    //     if (e?.value === documentId) {
    //         setIsShowFields(true);
    //     } else {
    //         setIsShowFields(false);
    //     }
    // };

    // const hideFields = (e) => {
    //     e.preventDefault();
    //     let documentId = '';

    //     const document = docData.find(value => value.accountStatusName === "Account Statement" || value.accountStatusName === "WHT CERTIFICATE");

    //     if (document) {
    //         documentId = document.accountStatusId;
    //     }

    //     if (e?.value === documentId) {
    //         setIsShowFields(true);
    //     } else {
    //         setIsShowFields(false);
    //     }
    // };

    useEffect(() => {
        if (formik.values.accountStatusName === 'Account Statement') {
            setIsButtonDisable(true);
            if (formik.values.fromDate && formik.values.toDate) {
                setIsButtonDisable(false);
            }
        }
    }, [formik.values.fromDate, formik.values.toDate, formik.values.selectDoc]);

    const handleAccStatementDownload = async () => {
        setIsLoading(true);
        const downloadStatementData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    email: customerEmail,
                    cnic: cnicNumber,
                    accountNumber: rowData?.accountNo,
                    // email: customerEmail,
                    // to: data?.email,
                    //for now email is hardcoded because of encrypted email
                    // mobileNumber: customerMobile,
                    mobileNumber: customerMobile,
                    fromDate: formik.values.fromDate,
                    toDate: formik.values.toDate,
                    /////for now these below keys are set manually
                    text: 'Please find attachment',
                    subject: 'Account Statement Documents',
                    download: true,

                    // this customerId is from AccountDetailsEditpage and pass as props todialEmailDocToCus Dialog
                    customerId: customerId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(downloadStatementData, '/account/v1/customeraccounts/generateaccountstatement', false, true, 'accountsmanagement'));
        if (res?.responseCode === '010000') {
            setIsLoading(false);
            onHideEmailDoc();

            // Redirect to the specified path
            const path = res?.payLoad?.path.split('/opt/wildfly/standalone/documents/accountStatement/')[1];
            window.open(`${baseURL}/document/accountStatement/${path}`, '_blank');
        }
    };

    return (
        <>
            <LoadingOverlay active={isLoading} spinner text="Please Wait ...">
                <div className="card Card__Round">
                    <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="selectDoc" className={classNames({ 'p-error': isFormFieldValid('selectDoc') }, 'Label__Text')}>
                                        Select Document
                                    </label>
                                    <Dropdown
                                        id="selectDoc"
                                        placeholder="Select Document"
                                        options={docData}
                                        optionLabel="accountStatusName"
                                        name="selectDoc"
                                        optionValue="accountStatusId"
                                        value={formik.values.selectDoc || ''}
                                        onChange={(e) => {
                                            formik.handleChange(e);
                                            handledDropdown(e);
                                            // hideFields(e);
                                        }}
                                        className={classNames({ 'p-invalid': isFormFieldValid('selectDoc') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('selectDoc')}
                                </div>
                            </div>
                            {handledDropdownSelectedValue !== '16' && (
                                <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="attachment" className={classNames({ 'p-error': isFormFieldValid('attachment') }, 'Label__Text')}>
                                            Attachment
                                        </label>
                                        <InputText
                                            id="attachment"
                                            placeholder=""
                                            name="attachment"
                                            disabled
                                            value={
                                                formik.values.accountStatusName === 'NOC'
                                                    ? 'NOC.pdf'
                                                    : formik.values.accountStatusName === 'ACCOUNT MAINTAINANCE CERTIFICATE'
                                                        ? 'AMC.pdf'
                                                        : formik.values.accountStatusName === 'WHT CERTIFICATE'
                                                            ? 'WHT.pdf'
                                                            : formik.values.attachment
                                            }
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('attachment') }, 'Input__Round')}
                                        />
                                        {getFormErrorMessage('attachment')}
                                    </div>
                                </div>
                            )}
                            <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="email" className={classNames({ 'p-error': isFormFieldValid('email') }, 'Label__Text')}>
                                        Email
                                    </label>
                                    <InputText
                                        // type="email"
                                        id="email"
                                        placeholder="Enter Email Address"
                                        name="email"
                                        disabled
                                        value={customerEmail || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('email') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('email')}
                                </div>
                            </div>
                            {(formik.values.accountStatusName === 'Account Statement' || formik.values.accountStatusName === 'WHT CERTIFICATE') && (
                                <>
                                    <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="fromDate" className={classNames({ 'p-error': isFormFieldValid('fromDate') }, 'Label__Text')}>
                                                From
                                            </label>
                                            <InputText
                                                type="date"
                                                // min={previousDate}
                                                max={nowDate}
                                                id="fromDate"
                                                name="fromDate"
                                                value={formik?.values?.fromDate?.trim()?.toString() || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('fromDate') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('fromDate')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-12 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="toDate" className={classNames({ 'p-error': isFormFieldValid('toDate') }, 'Label__Text')}>
                                                To
                                            </label>
                                            <InputText
                                                type="date"
                                                id="toDate"
                                                // min={previousDate}
                                                max={nowDate}
                                                name="toDate"
                                                value={formik?.values?.toDate?.trim()?.toString() || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('toDate') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('toDate')}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="Down__Btn">
                            <Button label="Send" type="submit" disabled={isButtonDisable ? true : false} className="Btn__Dark" />
                            {formik.values.accountStatusName === 'Account Statement' && (
                                <Button label="Download" className="Btn__Dark" disabled={isButtonDisable ? true : false} onClick={handleAccStatementDownload} type="button" loading={isLoading} />
                            )}
                            <Button
                                label="Cancel"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onHideEmailDoc();
                                }}
                                // disabled={loading}
                                className="Btn__Transparent"
                            />
                        </div>
                    </form>
                </div>
            </LoadingOverlay>
        </>
    );
}

export default DialogEmailDocToCus;
