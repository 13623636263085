import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { useLocation, useNavigate } from 'react-router-dom';
import { handleGetRequest } from '../../../services/GetTemplate';
import { handlePostRequest } from '../../../services/PostTemplate';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function EditGeneralLedgerChecker({ editable, onHideGeneralLedger }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();

    const query = new URLSearchParams(search);
    const [currencyData, setCurrencyData] = useState([]);
    const [channelData, setChannelData] = useState([]);
    const [accountTypeData, setAccountTypeData] = useState([]);
    const [glTypeData, setGlTypeData] = useState([]);
    const [daoTypeData, setDaoTypeData] = useState([]);
    const [chartTypeData, setChartTypeAccount] = useState([]);
    // const [accountId, setAccountData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const requestTypeDescr = query.get('requestTypeDescr');
    const mcRequestId = query.get('mcRequestId');

    const mcPeindingRequestId = query.get('mcPendingRequest');

    //get data by ref table id

    const getDatabyId = async () => {
        const res = await handleGetRequest(`/account/v1/getallaccountsbyid/${refTableId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('glTitle', keyData?.accountTitle?.toString());
        formik.setFieldValue('glTypeId', keyData?.lkpGlType?.glTypeId?.toString());
        formik.setFieldValue('daoCodeId', keyData?.lkpDaocode?.daocodeId?.toString());
        formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('finCode', keyData?.cbsAccountCode?.toString());
        formik.setFieldValue('oracleFinCode', keyData?.finAccountCode?.toString());
        formik.setFieldValue('accountTypeId', keyData?.lkpAccountType?.accountTypeId?.toString());
        formik.setFieldValue('currencyId', keyData?.lkpCurrency?.currencyId?.toString());
        formik.setFieldValue('ntnNumber', keyData?.ntnNumber?.toString());
        formik.setFieldValue('whtApplicability', keyData?.whtApplicability === 'Y' ? true : false);
        formik.setFieldValue('chartOfAccount', keyData?.tblGlCodeCombination?.glCodeCombinationId?.toString());

        formik.setFieldValue('overDrawnAllowed', keyData?.overDrawnAllowed);
        formik.setFieldValue('overDrawnAmountThreshold', keyData?.overDrawnAmountThreshold?.toString());
    };

    //get data by mc request id
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/account/v1/getGlAccountUpdateCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;
        formik.setFieldValue('glTitle', keyData?.glTitle?.toString());
        formik.setFieldValue('glTypeId', keyData?.glTypeId?.toString());
        formik.setFieldValue('daoCodeId', keyData?.daoCodeId?.toString());
        formik.setFieldValue('channelId', keyData?.channelId?.toString());
        formik.setFieldValue('finCode', keyData?.finCode?.toString());
        formik.setFieldValue('oracleFinCode', keyData?.oracleFinCode?.toString());
        formik.setFieldValue('accountTypeId', keyData?.accountTypeId?.toString());
        formik.setFieldValue('currencyId', keyData?.currencyId?.toString());
        formik.setFieldValue('ntnNumber', keyData?.ntnNumber?.toString());
        formik.setFieldValue('whtApplicability', keyData?.whtApplicability === 'Y' ? true : false);
        formik.setFieldValue('chartOfAccount', keyData?.chartOfAccount?.toString());
        formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);

        formik.setFieldValue('overDrawnAllowed', keyData?.overDrawnAllowed);
        formik.setFieldValue('overDrawnAmountThreshold', keyData?.overDrawnAmountThreshold?.toString());
    };
    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]);

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.mixed().required('This Field is required.'),
        statusId: Yup.mixed().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            currencyId: '',
            accountTypeId: '',
            glTitle: '',
            glTypeId: '',
            daoCodeId: '',
            channelId: '',
            oracleFinCode: '',
            chartOfAccount: '',
            whtApplicability: '',
            checkerComments: '',
            accountId: '',
            finCode: '',
            statusId: '',
            isActive: '',
            overDrawnAllowed: '',
            overDrawnAmountThreshold: ''
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['accountId'] = parseInt(refTableId);
            data['whtApplicability'] = data?.whtApplicability ? 'Y' : 'N';
            data['statusId'] = data.statusId;
            data['isActive'] = data?.isActive ? 'Y' : 'N';
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        glCreationRequest: {
                            isActive: data['isActive'],
                            currencyId: formik.values.currencyId,
                            accountTypeId: formik.values.accountTypeId,
                            glTitle: formik.values.glTitle,
                            glTypeId: formik.values.glTypeId,
                            daoCodeId: formik.values.daoCodeId,
                            channelId: formik.values.channelId,
                            oracleFinCode: formik.values.oracleFinCode,
                            chartOfAccount: formik.values.chartOfAccount,
                            whtApplicability: formik.values.whtApplicability,
                            accountId: formik.values.accountId,
                            finCode: formik.values.finCode,
                            ntnNumber: formik.values.ntnNumber,
                            statusId: data['statusId'],
                            overDrawnAllowed: formik.values.overDrawnAllowed,
                            overDrawnAmountThreshold: formik.values.overDrawnAmountThreshold
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: formik.values.checkerComments,
                            action: formik.values.statusId,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/glAccountCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '') {
                formik.resetForm();

                onHideGeneralLedger();
            }

            //
        }
    });

    const getCurrencyTypeLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*`);
        setCurrencyData(res?.payLoad);
    };

    const getChannelType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getchannels/LKP_CHANNEL:*`);
        setChannelData(res?.payLoad);
    };
    const getAccountType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallaccounttype/LKP_ACCOUNT_TYPE.:*`);
        setAccountTypeData(res?.payLoad);
    };

    const getGlType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallgltypes/LKP_GL_TYPE:*`);
        setGlTypeData(res?.payLoad);
    };
    const getDaoType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getalldaocodes/LKP_DAOCODE:*`);
        setDaoTypeData(res?.payLoad);
    };
    const getChartTypeAccount = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallglcodecombinations/TBL_GL_CODE_COMBINATION:*`);
        setChartTypeAccount(res?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getCurrencyTypeLov();
        getChannelType();
        getAccountType();
        getChartTypeAccount();
        getGlType();
        getDaoType();
        getStatusLov();
    }, []); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid('currency') }, 'Label__Text')}>
                                    <span className="Label__Required"></span>
                                    Currency
                                </label>
                                <Dropdown
                                    id="currencyId"
                                    placeholder="Select"
                                    options={currencyData}
                                    optionLabel="name"
                                    name="currencyId"
                                    optionValue="lovId"
                                    appendTo="self"
                                    disabled={true}
                                    value={formik.values.currencyId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('currency')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountTypeId" className={classNames({ 'p-error': isFormFieldValid('accountTypeId') }, 'Label__Text')}>
                                    Ledger Type<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="accountTypeId"
                                    placeholder="Select"
                                    options={accountTypeData}
                                    optionLabel="name"
                                    name="accountTypeId"
                                    appendTo="self"
                                    disabled={true}
                                    optionValue="lovId"
                                    value={formik.values.accountTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountTypeId')}
                            </div>
                        </div>
                        {formik.values.accountTypeId == 14 ? (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="ntnNumber" className={classNames({ 'p-error': isFormFieldValid('ntnNumber') }, 'Label__Text')}>
                                            NTN Number
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="ntnNumber"
                                            placeholder="-"
                                            name="ntnNumber"
                                            disabled={true}
                                            value={formik?.values?.ntnNumber}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ntnNumber') }, 'Input__Round')}
                                        />

                                        {getFormErrorMessage('ntnNumber')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                            WHT Applicability
                                        </label>
                                        <div className="field-checkbox ml-3">
                                            <Checkbox inputId="whtApplicability" name="whtApplicability" disabled={true} value={formik?.values?.whtApplicability} checked={formik?.values?.whtApplicability} onChange={formik.handleChange} />
                                        </div>
                                        {getFormErrorMessage('wht')}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="GLtitle" className={classNames({ 'p-error': isFormFieldValid('GLtitle') }, 'Label__Text')}>
                                    <span className="Label__Required"></span>
                                    GL Title
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="glTitle"
                                    placeholder="-"
                                    name="glTitle"
                                    value={formik?.values?.glTitle}
                                    disabled={true}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('glTitle') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('glTitle')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="GLtype" className={classNames({ 'p-error': isFormFieldValid('GLtype') }, 'Label__Text')}>
                                    GL Type<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="glTypeId"
                                    placeholder="Select"
                                    options={glTypeData}
                                    optionLabel="name"
                                    name="glTypeId"
                                    disabled={true}
                                    appendTo="self"
                                    optionValue="lovId"
                                    value={formik.values.glTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('glTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('glTypeId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="daoCode" className={classNames({ 'p-error': isFormFieldValid('daoCode') }, 'Label__Text')}>
                                    <span className="Label__Required"></span>
                                    Dao Code
                                </label>
                                <Dropdown
                                    id="daoCodeId"
                                    placeholder="Select"
                                    options={daoTypeData}
                                    optionLabel="name"
                                    name="daoCodeId"
                                    disabled={true}
                                    appendTo="self"
                                    optionValue="lovId"
                                    value={formik.values.daoCodeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('daoCodeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('daoCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="channelId" className={classNames({ 'p-error': isFormFieldValid('channelId') }, 'Label__Text')}>
                                    Channel ID<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="channelId"
                                    placeholder="Select"
                                    options={channelData}
                                    optionLabel="name"
                                    name="channelId"
                                    optionValue="lovId"
                                    appendTo="self"
                                    disabled={true}
                                    value={formik.values.channelId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid('currency') }, 'Label__Text')}>
                                    <span className="Label__Required"></span>
                                    Oracle Financial Code
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="oracleFinCode"
                                    disabled={true}
                                    placeholder="Enter"
                                    name="oracleFinCode"
                                    value={formik?.values?.oracleFinCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('oracleFinCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('oracleFinCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="chartOfAccount" className={classNames({ 'p-error': isFormFieldValid('chartOfAccount') }, 'Label__Text')}>
                                    Chart of Account<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="chartOfAccount"
                                    placeholder="Select"
                                    optionLabel="name"
                                    disabled={true}
                                    appendTo="self"
                                    options={chartTypeData}
                                    name="chartOfAccount"
                                    optionValue="lovId"
                                    value={formik.values.chartOfAccount}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chartOfAccount') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('chartOfAccount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="finAccountCode" className={classNames({ 'p-error': isFormFieldValid('finAccountCode') }, 'Label__Text')}>
                                    <span className="Label__Required"></span>
                                    Fin Code
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="finCode"
                                    placeholder="Enter"
                                    name="finCode"
                                    disabled={true}
                                    value={formik?.values?.finCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('finCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('finCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="overDrawnAllowed" className={classNames({ 'p-error': isFormFieldValid('overDrawnAllowed') }, 'Label__Text')}>
                                    Over Drawn Allowed
                                </label>
                                <div className="field-checkbox ml-3">
                                    <Checkbox
                                        inputId="overDrawnAllowed"
                                        name="overDrawnAllowed"
                                        disabled
                                        value={formik?.values?.overDrawnAllowed}
                                        checked={formik?.values?.overDrawnAllowed === 'Y'}
                                        onChange={(e) => {
                                            formik.setFieldValue('overDrawnAllowed', e.checked ? 'Y' : 'N');
                                        }}
                                    />
                                </div>
                                {/* {getFormErrorMessage('wht')} */}
                            </div>
                        </div>
                        {formik?.values?.overDrawnAllowed === 'Y' && (
                            <>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="overDrawnAmountThreshold" className={classNames({ 'p-error': isFormFieldValid('overDrawnAmountThreshold') }, 'Label__Text')}>
                                            Over Drawn Amount Threshold
                                        </label>
                                        <InputText
                                            maxLength={8}
                                            disabled={true}
                                            id="overDrawnAmountThreshold"
                                            placeholder="Enter Over Drawn Amount Threshold"
                                            name="overDrawnAmountThreshold"
                                            value={formik?.values?.overDrawnAmountThreshold}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('overDrawnAmountThreshold') }, 'Input__Round')}
                                        />

                                        {/* {getFormErrorMessage('overDrawnAmountThreshold')} */}
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Select Action<span className="Label__Required"></span>
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select Status"
                                    options={statusLovData}
                                    appendTo="self"
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('statusId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8"></div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                    </label>
                                    <InputTextarea
                                        rows={5}
                                        cols={80}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>

                        <div div className="Down__Btn">
                            <Button disabled={loading} label="Update" className="Btn__Dark" />
                            <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                        </div>
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditGeneralLedgerChecker;
