import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';

const EditPetroAccStatus = ({ rowData, formikValuees, onHide, accountStatusLov }) => {

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        accountStatusId: Yup.string().required('This Field is required.'),
        reason: Yup.string().min(10).max(200).required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountStatusId: formikValuees?.accountStatusId ? formikValuees?.accountStatusId : '',
            reason: ''
        },

        onSubmit: async () => {

            const data = {
                "data": {
                    "security": {
                        "userName": "string",
                        "password": "string",
                        "securityToken": "string"
                    },
                    "account": {
                        "msidn": "",
                        "iban": "",
                        "bban": "",
                        "pan": "",
                        "currency": ""
                    },
                    "channel": "",
                    "terminal": "",
                    "reterivalReferenceNumber": "",
                    "payLoad": {
                        "accountId": rowData?.petroAccountId,
                        "accountStatusId": formik.values.accountStatusId,
                        "reason": formik.values.reason,
                        "customerId": rowData?.customerId
                    },
                    "additionalInformation": [],
                    "checkSum": ""
                }
            }

            const res = await dispatch(handlePostRequest(data, '/account/v1/petroaccount/updatepetroaccountstatus', true, false));

            console.log('res', res)

        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getAccountStatusId = () => {
        const result = accountStatusLov?.find((item) => item?.accountStatusName === rowData?.petroAccountStatus)
        const statusId = result?.accountStatusId
        formik.setFieldValue("accountStatusId", statusId)
    }

    useEffect(() => {
        getAccountStatusId()
    }, [accountStatusLov]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="Card__Round">
                <div className="p-fluid p-formgrid grid">
                    <div className="p-field col-12">
                        <label className="Label__Text">Name</label>
                        <span className="steric">*</span>
                        <Dropdown
                            id="accountStatusId"
                            name="accountStatusId"
                            placeholder="--Select--"
                            options={accountStatusLov}
                            optionLabel="accountStatusName"
                            optionValue="accountStatusId"
                            value={formik.values.accountStatusId}
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('accountStatusId') }, 'Dropdown__Round')}
                            appendTo="self"
                        />
                        {getFormErrorMessage('accountStatusId')}
                    </div>
                    <div className="p-field col-12">
                        <label className="Label__Text">Remarks</label>
                        <span className="steric">*</span>
                        <InputTextarea
                            id="reason"
                            name="reason"
                            value={formik.values.reason}
                            onChange={formik.handleChange}
                            className={classNames({ 'p-invalid': isFormFieldValid('reason') }, 'TextArea__Round')}
                            rows={5}
                            cols={30}
                            autoResize
                            min={10}
                        />
                        {getFormErrorMessage('reason')}
                    </div>
                </div>

                <div className="Down__Btn mt-4">
                    <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" disabled={!formik.values.reason && true} />
                    <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={() => onHide(false)} />
                </div>
            </div>
        </form>
    )
}

export default EditPetroAccStatus