import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { PaginationCall, PaginationTemplate } from '../../../../components/Pagination';

function ViewFileDialog({ fileheadid }) {
    const [deviceFinancingData, setDeviceFinancingData] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customFirst2, setCustomFirst2] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);

    const dataTableRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const lmsfileheadid = fileheadid;

    const getLmsFileHeadId = async () => {
        const data = {
            fileHeadId: lmsfileheadid,
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        };

        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: data,
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        dataTableRef.current.reset();

        const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/getbulkfiledetailsbyid', true, true));

        if (res?.responseCode === process.env.REACT_APP_LENDING_SUCCESS_CODE) {
            setDeviceFinancingData(res?.payLoad);
        } else {
            setDeviceFinancingData([]);
        }
    };

    const formik = useFormik({
        initialValues: {
            fileHeadId: lmsfileheadid,
            rowStart: customFirst1,
            rowEnd: customRows1 + customFirst1
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {},
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            // apiCall
        }
    });

    useEffect(() => {
        getLmsFileHeadId();
    }, [lmsfileheadid]); // eslint-disable-line

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomFirst2(event.first + 1);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        fileHeadId: lmsfileheadid,
        rowStart: customFirst2,
        rowEnd: customRows1 + customFirst1
    };

    return (
        <>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setDeviceFinancingData}
                RequestData={RequestData}
                url={'/lending/v1/lending/getbulkfiledetailsbyid'}
                responseCode={process.env.REACT_APP_LENDING_SUCCESS_CODE}
            />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>View Device Financing Details</h2>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable ref={dataTableRef} className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={deviceFinancingData}>
                                    <Column header="Name" field="name" />
                                    <Column header="Mobile Number" field="mobileNumber" />
                                    <Column header="Loan Amount" field="loanAmount" />
                                    {/* <Column header="Create Date" field="createDate" /> */}
                                    {/* <Column header="Status" field="status" /> */}
                                    <Column header="Status Reason" field="statusReason" />
                                </DataTable>
                                <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={deviceFinancingData?.[0]?.totalCount} onPageChange={onCustomPageChange1}></Paginator>
                            </div>
                        </div>
                    </div>
                    {/* <div className="Down__Btn">
                        <Button onClick={() => navigate('/devicefinancing')} label="Okay" className="Btn__Dark" />
                    </div> */}
                </form>
            </div>
        </>
    );
}

export default ViewFileDialog;
