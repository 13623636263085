import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import * as Yup from 'yup';
import { Dialog } from 'primereact/dialog';
import ViewFileDialog from './ViewFileDialog';

function EditLendingDeviceFinancingChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('');
    const [statusLovData, setStatusLovData] = useState([]);
    const [productLov, setProductLov] = useState([]);
    const [fileSizeError, setFileSizeError] = useState('');
    const [mcURequest, setMcURequest] = useState();
    const [displayModalViewFile, setDisplayModalViewFile] = useState(false);

    const [tncFile, setTncFile] = useState('');
    const [tncFilePathData, setTncFilePathData] = useState('');

    const tncFileRef = useRef(null);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPendingRequest = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getAllProducts = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/lending/TBL_LMS_PRODUCT');
        setProductLov(res?.payLoad);
    };

    useEffect(() => {
        getAllProducts();
        getStatusLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        manageBulkFile:
                            requestType === 'U' && requestTypeDescr === 'Enable/Disable Request'
                                ? mcURequest
                                : {
                                      fileHeadId: refTableId,
                                      isActive: 'Y'
                                  },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPendingRequest,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/lending/v1/lending/lmsbulkfilecheckeraction', true, false, 'requestchecker'));

            setloading(false);
            setloadingIcon('');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleTncFileInput = () => {
        tncFileRef.current.click();
    };

    const tncFileChange = (event) => {
        const file = event.target.files[0];
        if (file?.size > 2 * 1024 * 1024) {
            setFileSizeError('File size should be less than 2mb');
        } else {
            setFileSizeError('');
        }
        formik.setFieldValue('file', file?.name);
        setTncFile(file);
    };

    const disbursementTypeLov = [
        { lovId: 'S', name: 'Schedule' },
        { lovId: 'N', name: 'Normal' }
    ];

    const getDatabyId = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getbulkfilebyid/${refTableId}`);
        const { payLoad } = resp;
        formik.setFieldValue('productId', payLoad.productId?.toString());
        formik.setFieldValue('description', payLoad.description);
        formik.setFieldValue('disbursementType', payLoad.disbursementType);
        formik.setFieldValue('isLoanDisburse', payLoad.loanDisburse);
        formik.setFieldValue('disbursementDateTime', new Date(payLoad?.disbursementDateTime).toISOString().slice(0, 16));
    };

    const getDatabyIdU = async () => {
        const resp = await handleGetRequest(`/lending/v1/lending/getmcrequest/${mcRequestId}`);
        setMcURequest(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getDatabyIdU();
        }
    }, []); // eslint-disable-line

    const viewFile = () => {
        setDisplayModalViewFile(true);
    };

    return (
        <>
            <Dialog resizable={false} visible={displayModalViewFile} blockScroll style={{ width: '70vw' }} onHide={() => setDisplayModalViewFile(false)} draggable={false}>
                <ViewFileDialog fileheadid={refTableId} />
            </Dialog>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DEVICE LENDING CHECKER</h2>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Loan Product
                                </label>
                                <Dropdown
                                    id="productId"
                                    placeholder="Select Loan Product"
                                    options={productLov}
                                    disabled={true}
                                    optionLabel="name"
                                    name="productId"
                                    optionValue="lovId"
                                    value={formik.values.productId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label htmlFor="file" className={'Label__Text'}>
                                        Source File
                                    </label>
                                    <div className="file-input-container w-100" onClick={handleTncFileInput}>
                                        <input type="file" disabled accept=".xls,.xlsx,.csv" style={{ display: 'none' }} ref={tncFileRef} onChange={tncFileChange} />
                                        <input type="text" disabled className="file-input-field Input__Round" placeholder="DeviceSample.csv" value={formik?.values?.file || ''} name="file" readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                    {fileSizeError ? <small className="p-error">{fileSizeError}</small> : getFormErrorMessage('file')}
                                </div>
                                <Button type="button" label="View File" className="Btn__Dark" target="_blank" onClick={() => viewFile()} />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isLoanDisburse" className={classNames({ 'p-error': isFormFieldValid('isLoanDisburse') }, 'Label__Text')}>
                                    Disburse Loan Amount
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isLoanDisburse" disabled name="isLoanDisburse" checked={formik?.values?.isLoanDisburse} onChange={formik.handleChange} style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('isLoanDisburse')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="description" className={classNames({ 'p-error': isFormFieldValid('description') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    id="description"
                                    placeholder="Enter Description"
                                    name="description"
                                    rows={10}
                                    disabled={true}
                                    value={formik.values.description || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('description') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('description')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="disbursementType" className={classNames({ 'p-error': isFormFieldValid('disbursementType') }, 'Label__Text')}>
                                    Disbursement Type
                                </label>
                                <Dropdown
                                    id="disbursementType"
                                    placeholder="Select Disbursement Type"
                                    options={disbursementTypeLov}
                                    optionLabel="name"
                                    name="disbursementType"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik.values.disbursementType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('disbursementType')}
                            </div>
                        </div>
                    </div>

                    {formik?.values?.disbursementType === 'S' && (
                        <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="disbursementDateTime" className={classNames({ 'p-error': isFormFieldValid('disbursementDateTime') }, 'Label__Text')}>
                                            Date Time
                                        </label>
                                        <InputText
                                            id="disbursementDateTime"
                                            type="datetime-local"
                                            name="disbursementDateTime"
                                            disabled={true}
                                            value={formik.values.disbursementDateTime || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('disbursementDateTime') }, 'Dropdown__Round')}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="col-12">
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Action
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        optionDisabled={(option) => option?.name === 'Pending'}
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        maxLength={200}
                                        autoResize={false}
                                        rows={5}
                                        cols={30}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" loadingIcon={loadingIcon || ''} />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditLendingDeviceFinancingChecker;
