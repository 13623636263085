import React, { useEffect, useState } from 'react'
import { Divider } from 'primereact/divider'
import { InputText } from 'primereact/inputtext'
import { Button } from 'primereact/button'
import { handleGetRequest } from '../../../../services/GetTemplate'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Dropdown } from 'primereact/dropdown'
import classNames from 'classnames'
import { handlePostRequest } from '../../../../services/PostTemplate'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Lien = (props) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [productLovData, setProductLovData] = useState([])

    const validationSchema = Yup.object().shape({
        productId: Yup.number().required('This field is required.'),
        lienAmount: Yup.number().required('This field is required.'),
        remarks: Yup.mixed().required('This field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productId: props?.customerData?.productId?.toString() || "",
            lienAmount: props?.customerData?.lienAmount || "",
            fieldName: "lien",
            remarks: props?.customerData?.remarks || ""
        },
        onSubmit: async (data) => {

            const processedData = {
                ...data,
                productId: parseInt(data.productId, 10),
                lienAmount: parseInt(data.lienAmount, 10)
            };

            const customerKyc = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        updateCustomerKyc: {
                            ...processedData,
                            "fullName": "",
                            "accountStatusId": null,
                            "fullAddress": null,
                            "email": null,
                            "cnicIssuanceDate": null,
                            "cnicFront": null,
                            "cnicBack": null,
                            "proofOfProfession": null,
                            "sourceOfIncome": null,
                            "parentCnicFront": null,
                            "parentCnicBack": null,
                            "bForm": null,
                            "customerId": props?.customerData?.customerId,
                            "mobileNumber": null,
                            "accountId": props?.customerData?.accountId,
                            "addressId": props?.customerData?.addressId,
                            "accountTypeId": 1,
                            "discrepentDocumentId": props?.customerData?.channelId,
                            "pinGenerationId": null,
                            "statusId": null,
                            "isActive": null,
                            "customerNumber": null,
                            "hraNokName": null,
                            "hraNokCnic": null,
                            "hraNokMobileNo": null,
                            "hraNokRelationshipName": null,
                            "countryName": null,
                            "hraOriginatorRelationshipName": null,
                            "fieldName": "lien",
                            "whitelistedDevice": null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const response = await dispatch(handlePostRequest(customerKyc, '/account/v1/customeraccounts/updatecustomerkyc', true, false));
            console.log('response', response)
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    useEffect(() => {
        const getProductLov = async () => {
            const resp = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
            setProductLovData(resp?.payLoad);
        };
        getProductLov()
    }, []);

    return (
        <>
            <div className="pt-2">
                <h6 className="secondary-color">
                    <b>LIEN DETAILS</b>
                </h6>
            </div>

            <Divider />

            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Account No.</label>
                            <InputText placeholder={props?.customerData?.accountNo} className="Input__Round" disabled />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Product</label>
                            <Dropdown
                                id="productId"
                                name="productId"
                                placeholder="Select Product"
                                options={productLovData}
                                optionLabel="name"
                                optionValue="lovId"
                                value={formik.values.productId || ''}
                                onChange={formik.handleChange}
                                className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Dropdown__Round')}
                            />
                            {getFormErrorMessage('productId')}
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Amount</label>
                            <InputText
                                id='lienAmount'
                                name='lienAmount'
                                value={formik.values.lienAmount}
                                onChange={formik.handleChange}
                                className="Input__Round"
                                keyfilter="int"
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4">
                        <div className="p-field">
                            <label className="Label__Text">Comments</label>
                            <InputText
                                id='remarks'
                                name='remarks'
                                value={formik.values.remarks}
                                onChange={formik.handleChange}
                                className="Input__Round"
                            />
                        </div>
                    </div>
                </div>

                <div className="p-field col-12 flex justify-content-center">
                    <div className="Down__Btn">
                        <Button label="Update" className="Btn__Dark mr-2" type='submit' />
                        <Button label="Back" iconPos="right" className="Btn__Transparent" onClick={() => navigate(-1)} />
                    </div>
                </div>
            </form>

        </>
    )
}

export default Lien