import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';

import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';

import BulkDiscardDialog from '../../../components/BulkDialog/BulkDiscardDialog';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';
import { Paginator } from 'primereact/paginator';

function ScheduledBulkPayment() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
   
    const [scheduleBulkPaymentData, setScheduleBulkPaymentData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apiHit, setApiHit] = useState([]);
    const [ProductLovData, setProductLovData] = useState([]);
    const [rowDataProp, setRowDataProp] = useState(null);
    const [accountStatus, setAccountStatus] = useState([]);

    const dispatch = useDispatch();


    const validationSchema = Yup.object().shape({
    
    });
    const dataTableRef = useRef();

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            bulkOperationName: 'BSP',
            bulkScheduledPaymentSearchDTO: '',
            service: '',
            productName: '',
            accountStatusID: '',
            batchID: '',
            registrationNo: '',
            name: '',
            chequeNo: '',
            cnic: '',
            mobileNo: '',
            statusID: '',
            createdBy: '',
            updatedBy: '',
            fileUploadDateFrom: '',
            fileUploadDateTo: '',
            paymentDateTo: '',
            paymentDateFrom: '',
            transactionDateTo: '',
            transactionDateFrom: '',
            transactionCode: '',
            postedFrom: '',
            postedTo: '',
            setteledFrom: '',
            setteledTo: '',
            page: '1',
            size: customRows1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }

            if (data.fileUploadDateTo && !data.fileUploadDateFrom) {
                errors.fileUploadDateFrom = 'File Upload Date To From is required when Date To is provided';
            }

            if (data.fileUploadDateFrom && data.fileUploadDateTo && data.fileUploadDateFrom > data.fileUploadDateTo) {
                errors.fileUploadDateTo = 'File Upload Date To To must be greater than or equal to Date From';
            }
            if (data.paymentDateTo && !data.paymentDateFrom) {
                errors.paymentDateFrom = 'Date From is required when Date To is provided';
            }

            if (data.paymentDateFrom && data.paymentDateTo && data.paymentDateFrom > data.paymentDateTo) {
                errors.paymentDateTo = 'Date To must be greater than or equal to Date From';
            }
            if (data.paymentDateTo && !data.paymentDateFrom) {
                errors.paymentDateFrom = 'Date From is required when Date To is provided';
            }

            if (data.paymentDateFrom && data.paymentDateTo && data.paymentDateFrom > data.paymentDateTo) {
                errors.paymentDateTo = 'Date To must be greater than or equal to Date From';
            }
            if (data.postedTo && !data.postedFrom) {
                errors.postedFrom = 'Date From is required when Date To is provided';
            }

            if (data.postedFrom && data.postedTo && data.postedFrom > data.postedTo) {
                errors.postedTo = 'Date To must be greater than or equal to Date From';
            }
            if (data.setteledTo && !data.setteledFrom) {
                errors.setteledFrom = 'Date From is required when Date To is provided';
            }

            if (data.setteledFrom && data.setteledTo && data.setteledFrom > data.setteledTo) {
                errors.setteledTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
      

            const payloadDataNew = {
                bulkOperationName: data.bulkOperationName,
                page:data.page,
                size:data.size,
                bulkScheduledPaymentSearchDTO: {
                    transactionCode: data.transactionCode,
                    service: data.service,
                    productID: data.productName,
                    accountStatusID: data.accountStatusID,
                    batchID: data.batchID,
                    registrationNo: data.registrationNo,
                    name: data.name,
                    chequeNo: data.chequeNo,
                    cnic: data.cnic,
                    mobileNo: data.mobileNo,
                    statusID: data.statusID,
                    createdBy: data.createdBy,
                    updatedBy: data.updatedBy,
                    fileUploadDateFrom: data.fileUploadDateFrom,
                    fileUploadDateTo: data.fileUploadDateTo,
                    paymentDateTo: data.paymentDateTo,
                    paymentDateFrom: data.paymentDateFrom,
                    transactionDateTo: data.transactionDateTo,
                    transactionDateFrom: data.transactionDateFrom
                }
            };

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadDataNew,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]  // checkSum: hash
                }
            };

            dataTableRef.current.reset();

            setApiHit(newData?.data?.payLoad);

            const res = await dispatch(handlePostRequest(newData, '/batchprocessing/v1/batchprocessing/getallrecords', true, true));
            if (res?.responseCode === '170000') {
           
                setScheduleBulkPaymentData(res?.payLoad);
            } else {
                setScheduleBulkPaymentData('');
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

  
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

  
    const viewNewUseCaseManagement = (rowData) => {

        setRowDataProp(rowData);
        setShowModal(true);
    };
 
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" disabled={rowData.isActive === 'N'} className="p-button-rounded p-button-danger" onClick={() => viewNewUseCaseManagement(rowData)} />
                </div>
            </>
        );
    };
    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const handleShowModal = () => {
        setShowModal(false);
    };
    const esbProps = {
        showModal,
        handleShowModal
       
    };

    const getProductLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProductLovData(resp?.payLoad);
    };

    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getAccountStatus = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
     
        setAccountStatus(res.payLoad);
    };

    useEffect(() => {
    
        getUserLov();
        getProductLov();
        getAccountStatus();
    }, []);// eslint-disable-line

    const serviceData = [
        { label: 'ACCOUNT HOLDER ', value: 'A' },
        { label: 'NON ACCOUNT HOLDER ', value: 'N' }
    ];
    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.scheduleDateTime;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        ...apiHit,
        page: currentPage,
        size: customRows1
    };

    return (
        <>
            <BulkDiscardDialog {...esbProps} rowDataProp={rowDataProp} schedule={true} />

            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Schedule Bulk Payment</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transactionCode" className={classNames({ 'p-error': isFormFieldValid('transactionCode') }, 'Label__Text')}>
                                    Transaction Id
                                </label>
                                <InputText
                                    id="transactionCode"
                                    placeholder="Enter Transaction Id"
                                    name="transactionCode"
                                    value={formik?.values?.transactionCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transactionCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('transactionCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="batchID" className={classNames({ 'p-error': isFormFieldValid('batchID') }, 'Label__Text')}>
                                    Batch Number
                                </label>
                                <InputText
                                    id="batchID"
                                    placeholder="Enter Batch Number"
                                    name="batchID"
                                    value={formik?.values?.batchID || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('batchID') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('batchID')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="service" className={classNames({ 'p-error': isFormFieldValid('service') }, 'Label__Text')}>
                                    Service
                                </label>
                                <Dropdown
                                    id="service"
                                    placeholder="Select service"
                                    options={serviceData}
                                    optionLabel="label"
                                    name="service"
                                    optionValue="value"
                                    value={formik.values.service || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('service') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('service')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productID" className={classNames({ 'p-error': isFormFieldValid('productID') }, 'Label__Text')}>
                                    Product
                                </label>
                                <Dropdown
                                    id="productID"
                                    placeholder="Select productID"
                                    options={ProductLovData}
                                    optionLabel="name"
                                    name="productID"
                                    optionValue="lovId"
                                    value={formik.values.productID || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productID') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('productID')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('acCreationStatus') }, 'Label__Text')}>
                                    A/C Creation Status
                                </label>
                                <Dropdown
                                    id="accountStatusID"
                                    placeholder="Select A/C Creation Status"
                                    options={accountStatus}
                                    optionLabel="accountStatusName"
                                    name="accountStatusID"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatusID || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatusID') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountStatusID')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="registrationNo" className={classNames({ 'p-error': isFormFieldValid('registrationNo') }, 'Label__Text')}>
                                    EMP/Reg No.
                                </label>
                                <InputText
                                    id="registrationNo"
                                    placeholder="Enter  EMP/Reg No."
                                    name="registrationNo"
                                    value={formik?.values?.registrationNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('registrationNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('registrationNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="name" className={classNames({ 'p-error': isFormFieldValid('name') }, 'Label__Text')}>
                                    Name
                                </label>
                                <InputText id="name" placeholder="Enter Namet" name="name" value={formik?.values?.name || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('name') }, 'Input__Round')} />

                                {getFormErrorMessage('name')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chequeNo" className={classNames({ 'p-error': isFormFieldValid('chequeNo') }, 'Label__Text')}>
                                    Cheque Number
                                </label>
                                <InputText
                                    id="chequeNo"
                                    placeholder="Enter Caheque Number"
                                    name="chequeNo"
                                    value={formik?.values?.chequeNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chequeNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('chequeNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC
                                </label>
                                <InputText id="cnic" placeholder="Enter CNIC" name="cnic" value={formik?.values?.cnic || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')} />

                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    id="mobileNo"
                                    placeholder="Enter Mobile Number"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Uploaded By
                                </label>
                                <Dropdown
                                    id="updatedBy"
                                    placeholder="Select Uploaded By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="paymentDateFrom" className={classNames({ 'p-error': isFormFieldValid('paymentDateFrom') }, 'Label__Text')}>
                                    Payment From Date
                                </label>
                                <InputText
                                    id="paymentDateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="paymentDateFrom"
                                    value={formik?.values?.paymentDateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('paymentDateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('paymentDateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="paymentDateTo" className={classNames({ 'p-error': isFormFieldValid('paymentDateTo') }, 'Label__Text')}>
                                    Payment To Date
                                </label>
                                <InputText
                                    id="paymentDateTo"
                                    type="date"
                                    max={previousDate}
                                    name="paymentDateTo"
                                    value={formik?.values?.paymentDateTo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('paymentDateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('paymentDateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fileUploadDateFrom" className={classNames({ 'p-error': isFormFieldValid('fileUploadDateFrom') }, 'Label__Text')}>
                                    File Upload From Date
                                </label>
                                <InputText
                                    id="fileUploadDateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="fileUploadDateFrom"
                                    value={formik?.values?.fileUploadDateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fileUploadDateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('fileUploadDateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fileUploadDateTo" className={classNames({ 'p-error': isFormFieldValid('fileUploadDateTo') }, 'Label__Text')}>
                                    File Upload To Date
                                </label>
                                <InputText
                                    id="fileUploadDateTo"
                                    type="date"
                                    max={previousDate}
                                    name="fileUploadDateTo"
                                    value={formik?.values?.fileUploadDateTo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fileUploadDateTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('fileUploadDateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="postedFrom" className={classNames({ 'p-error': isFormFieldValid('postedFrom') }, 'Label__Text')}>
                                    Posted On From Date
                                </label>
                                <InputText
                                    id="postedFrom"
                                    type="date"
                                    max={previousDate}
                                    name="postedFrom"
                                    value={formik?.values?.postedFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('postedFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('postedFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="postedTo" className={classNames({ 'p-error': isFormFieldValid('postedTo') }, 'Label__Text')}>
                                    Posted On To Date
                                </label>
                                <InputText
                                    id="postedTo"
                                    type="date"
                                    max={previousDate}
                                    name="postedTo"
                                    value={formik?.values?.postedTo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('postedTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('postedTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="setteledFrom" className={classNames({ 'p-error': isFormFieldValid('setteledFrom') }, 'Label__Text')}>
                                    Settled On From Date
                                </label>
                                <InputText
                                    id="setteledFrom"
                                    type="date"
                                    max={previousDate}
                                    name="setteledFrom"
                                    value={formik?.values?.setteledFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('setteledFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('setteledFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="setteledTo" className={classNames({ 'p-error': isFormFieldValid('setteledTo') }, 'Label__Text')}>
                                    Settled On To Date
                                </label>
                                <InputText
                                    id="setteledTo"
                                    type="date"
                                    max={previousDate}
                                    name="setteledTo"
                                    value={formik?.values?.setteledTo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('setteledTo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('setteledTo')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setScheduleBulkPaymentData}
               
                RequestData={RequestData}
                url={'/batchprocessing/v1/batchprocessing/getallrecords'}
                responseCode={'170000'}
            />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            className="datatable-responsive"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={scheduleBulkPaymentData}
                        >
                         
                            <Column field="registrationNo" header="Emp/Reg No" />
                            <Column field="name" header="Name" />
                            <Column field="customerId" header="Customer Id" />

                            <Column field="cnic" header="CNIC" />
                            <Column field={(item) => item?.mobileNo} header="Mobile Number" />
                            <Column field="productName" header="Product" />
                            <Column field="accountStatus" header="A/C Creation Status" />
                            <Column field={(item) => item?.chequeNo} header="Cheque Number" />
                            <Column field="amount" header=" Amount" />
                            <Column field="scheduleDateTime" header="Schedule Date" />
                            <Column header="Scheduled Date" body={createdDateAndTimeBodyTemplate} />

                            <Column field="batchID" header="Batch Number" />
                            <Column field="transactionCode" header="Transaction Id" />
                            <Column field="uploadedBy" header="Uploaded By" />

                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable> 
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={scheduleBulkPaymentData?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>

                    </div>
                </div>
            </div>
        </>
    );
}
export default ScheduledBulkPayment;
