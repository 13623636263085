import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { handleGetRequest } from '../../../src/services/GetTemplate';
import AuthorizationHistoryTable from '../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function AddEditGeneralLedger({ onHideViewGeneralLedger, GeneralLedgerViewRowData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');
    const [currencyData, setCurrencyData] = useState([]);
    const [channelData, setChannelData] = useState([]);
    const [accountTypeData, setAccountTypeData] = useState([]);
    const [glTypeData, setGlTypeData] = useState([]);
    const [daoTypeData, setDaoTypeData] = useState([]);
    const [chartTypeData, setChartTypeAccount] = useState([]);

    const accountId = `${GeneralLedgerViewRowData?.accountId}`;
    const tableName = 'TBL_ACCOUNT';

    const validationSchema = Yup.object().shape({
        currencyId: Yup.string().required('This Field is required.'),
        accountTypeId: Yup.string().required('This Field is required.'),
        glTitle: Yup.mixed().required('This Field is required.'),
        glTypeId: Yup.mixed().required('This Field is required.'),
        daoCodeId: Yup.mixed().required('This Field is required.'),
        channelId: Yup.mixed().required('This Field is required.'),
        oracleFinCode: Yup.mixed().required('This Field is required.'),
        chartOfAccount: Yup.mixed().required('This Field is required.'),
        finCode: Yup.mixed().required('This Field is required.')
        // ntnNumber: Yup.mixed().required('This Field is required.'),
        // whtApplicability: Yup.mixed().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            currencyId: '',
            accountTypeId: '',
            glTitle: '',
            glTypeId: '',
            daoCodeId: '',
            channelId: '',
            oracleFinCode: '',
            chartOfAccount: '',
            finCode: '',
            ntnNumber: '',
            whtApplicability: '',
            overDrawnAllowed: '',
            overDrawnAmountThreshold: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: data,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            //
        }
    });

    const getGeneralLedgerData = async () => {
        const res = await handleGetRequest(`/account/v1/getallaccountsbyid/${accountId}`);
        const keyData = res?.payLoad;

        formik.setFieldValue('glTitle', keyData?.accountTitle?.toString());
        formik.setFieldValue('glTypeId', keyData?.lkpGlType?.glTypeId?.toString());
        formik.setFieldValue('daoCodeId', keyData?.lkpDaocode?.daocodeId?.toString());
        formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('finCode', keyData?.cbsAccountCode?.toString());
        formik.setFieldValue('oracleFinCode', keyData?.finAccountCode?.toString());
        formik.setFieldValue('accountTypeId', keyData?.lkpAccountType?.accountTypeId?.toString());
        formik.setFieldValue('currencyId', keyData?.lkpCurrency?.currencyId?.toString());
        formik.setFieldValue('ntnNumber', keyData?.ntnNumber?.toString());
        formik.setFieldValue('whtApplicability', keyData?.whtApplicability === 'Y' ? true : false);
        formik.setFieldValue('chartOfAccount', keyData?.tblGlCodeCombination?.glCodeCombinationId?.toString());

        formik.setFieldValue('overDrawnAllowed', keyData?.overDrawnAllowed);
        formik.setFieldValue('overDrawnAmountThreshold', keyData?.overDrawnAmountThreshold?.toString());
    };

    useEffect(() => {
        getGeneralLedgerData();
    }, [accountId]); // eslint-disable-line

    const dispatch = useDispatch();

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getCurrencyTypeLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*`);
        setCurrencyData(res?.payLoad);
    };

    const getChannelType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getchannels/LKP_CHANNEL:*`);
        setChannelData(res?.payLoad);
    };
    const getAccountType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallaccounttype/LKP_ACCOUNT_TYPE:*`);
        setAccountTypeData(res?.payLoad);
    };

    const getGlType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallgltypes/LKP_GL_TYPE:*`);
        setGlTypeData(res?.payLoad);
    };
    const getDaoType = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getalldaocodes/LKP_DAOCODE:*`);
        setDaoTypeData(res?.payLoad);
    };
    const getChartTypeAccount = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getallglcodecombinations/TBL_GL_CODE_COMBINATION:*`);
        setChartTypeAccount(res?.payLoad);
    };
    const handleCancel = (e) => {
        e.preventDefault();
        onHideViewGeneralLedger();
        // formik.resetForm();
    };
    useEffect(() => {
        getCurrencyTypeLov();
        getChannelType();
        getAccountType();
        getChartTypeAccount();
        getGlType();
        getDaoType();
    }, []); // eslint-disable-line

    return (
        <>
            <div className="card_GL Card__Round">
                <div className="Form__Header"></div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="currencyId" className={classNames({ 'p-error': isFormFieldValid('currencyId') }, 'Label__Text')}>
                                    Currency
                                </label>
                                <Dropdown
                                    id="currencyId"
                                    placeholder="Select"
                                    options={currencyData}
                                    optionLabel="name"
                                    name="currencyId"
                                    disabled={true}
                                    optionValue="lovId"
                                    value={formik.values.currencyId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('currencyId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="accountTypeId" className={classNames({ 'p-error': isFormFieldValid('accountTypeId') }, 'Label__Text')}>
                                    Ledger Type
                                </label>
                                <Dropdown
                                    id="accountTypeId"
                                    placeholder="Select"
                                    options={accountTypeData}
                                    optionLabel="name"
                                    name="accountTypeId"
                                    disabled={true}
                                    optionValue="lovId"
                                    value={formik.values.accountTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountTypeId')}
                            </div>
                        </div>

                        {formik.values.accountTypeId == 14 ? (
                            <>
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="ntnNumber" className={classNames({ 'p-error': isFormFieldValid('ntnNumber') }, 'Label__Text')}>
                                            NTN Number
                                        </label>
                                        <InputText
                                            maxLength={100}
                                            id="ntnNumber"
                                            placeholder="-"
                                            disabled={true}
                                            name="ntnNumber"
                                            value={formik?.values?.ntnNumber}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ntnNumber') }, 'Input__Round')}
                                        />

                                        {getFormErrorMessage('ntnNumber')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                            WHT Applicability
                                        </label>
                                        <div className="field-checkbox ml-3">
                                            <Checkbox inputId="whtApplicability" name="whtApplicability" value={formik?.values?.whtApplicability} checked={formik?.values?.whtApplicability} onChange={formik.handleChange} disabled={true} />
                                        </div>
                                        {getFormErrorMessage('wht')}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <></>
                        )}

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="glTitle" className={classNames({ 'p-error': isFormFieldValid('glTitle') }, 'Label__Text')}>
                                    GL Tittle
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="glTitle"
                                    placeholder="-"
                                    disabled={true}
                                    name="glTitle"
                                    value={formik?.values?.glTitle}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('glTitle') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('glTitle')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="glTypeId" className={classNames({ 'p-error': isFormFieldValid('glTypeId') }, 'Label__Text')}>
                                    GL Type
                                </label>
                                <Dropdown
                                    id="glTypeId"
                                    placeholder="Select"
                                    options={glTypeData}
                                    optionLabel="name"
                                    name="glTypeId"
                                    disabled={true}
                                    optionValue="lovId"
                                    value={formik.values.glTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('glTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('glTypeId')}
                            </div>
                        </div>

                        <>
                            <div className="p-field col-12 md:col-12">
                                <div className="p-field">
                                    <label htmlFor="daoCodeId" className={classNames({ 'p-error': isFormFieldValid('daoCodeId') }, 'Label__Text')}>
                                        Dao Code
                                    </label>
                                    <Dropdown
                                        id="daoCodeId"
                                        placeholder="Select"
                                        options={daoTypeData}
                                        optionLabel="name"
                                        name="daoCodeId"
                                        disabled={true}
                                        optionValue="lovId"
                                        value={formik.values.daoCodeId}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('daoCodeId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('daoCodeId')}
                                </div>
                            </div>
                        </>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="channelId" className={classNames({ 'p-error': isFormFieldValid('channelId') }, 'Label__Text')}>
                                    Channel ID
                                </label>
                                <Dropdown
                                    id="channelId"
                                    placeholder="Select"
                                    options={channelData}
                                    disabled={true}
                                    optionLabel="name"
                                    name="channelId"
                                    optionValue="lovId"
                                    value={formik.values.channelId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="currency" className={classNames({ 'p-error': isFormFieldValid('currency') }, 'Label__Text')}>
                                    Oracle Financial Code
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="oracleFinCode"
                                    placeholder="Enter"
                                    name="oracleFinCode"
                                    disabled={true}
                                    value={formik?.values?.oracleFinCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('oracleFinCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('oracleFinCode')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="chartOfAccount" className={classNames({ 'p-error': isFormFieldValid('chartOfAccount') }, 'Label__Text')}>
                                    Chart of Account
                                </label>
                                <Dropdown
                                    id="chartOfAccount"
                                    placeholder="Select"
                                    optionLabel="name"
                                    disabled={true}
                                    options={chartTypeData}
                                    name="chartOfAccount"
                                    optionValue="lovId"
                                    value={formik.values.chartOfAccount}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chartOfAccount') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('chartOfAccount')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="finCode" className={classNames({ 'p-error': isFormFieldValid('finCode') }, 'Label__Text')}>
                                    Fin Code
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="finCode"
                                    placeholder="Enter"
                                    name="finCode"
                                    disabled={true}
                                    value={formik?.values?.finCode}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('finCode') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('finCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="overDrawnAllowed" className={classNames({ 'p-error': isFormFieldValid('overDrawnAllowed') }, 'Label__Text')}>
                                    Over Drawn Allowed
                                </label>
                                <div className="field-checkbox ml-3">
                                    <Checkbox
                                        inputId="overDrawnAllowed"
                                        name="overDrawnAllowed"
                                        value={formik?.values?.overDrawnAllowed}
                                        checked={formik?.values?.overDrawnAllowed === 'Y'}
                                        onChange={(e) => {
                                            formik.setFieldValue('overDrawnAllowed', e.checked ? 'Y' : 'N');
                                        }}
                                    />
                                </div>
                                {/* {getFormErrorMessage('wht')} */}
                            </div>
                        </div>
                        {formik?.values?.overDrawnAllowed === 'Y' && (
                            <>
                                <div className="p-field col-12 md:col-12">
                                    <div className="p-field">
                                        <label htmlFor="overDrawnAmountThreshold" className={classNames({ 'p-error': isFormFieldValid('overDrawnAmountThreshold') }, 'Label__Text')}>
                                            Over Drawn Amount Threshold
                                        </label>
                                        <InputText
                                            maxLength={8}
                                            id="overDrawnAmountThreshold"
                                            placeholder="Enter Over Drawn Amount Threshold"
                                            name="overDrawnAmountThreshold"
                                            value={formik?.values?.overDrawnAmountThreshold}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('overDrawnAmountThreshold') }, 'Input__Round')}
                                        />

                                        {/* {getFormErrorMessage('overDrawnAmountThreshold')} */}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" className="Btn__cancel" onClick={(e) => handleCancel(e)} />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={accountId} tableName={tableName} />
        </>
    );
}

export default AddEditGeneralLedger;
