import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { handlePostRequest } from '../../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { InputTextarea } from 'primereact/inputtextarea';

function EditAgentMateMainCategoryChecker() {
    const [mainCategoryIconFileName, setMainCategoryIconFileName] = useState();
    const [filePath, setFilePath] = useState(null);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const tableName = query.get('tableName');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.name !== 'Pending');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string()
            .required('This field is required')
            .matches(/^[^\s]+(\s[^\s]+)*$/, 'Leading and trailing space is not allowed')
            .matches(/^[a-zA-Z0-9\s]*$/, 'Comments must be alphanumeric')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cmsMainCategoryId: '',
            categoryName: '',
            displayNameEnglist: '',
            displayNameUrdu: '',
            categoryNumber: '',
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            cmsTagTextBgColorId: '',
            tagExpiryDate: '',
            isHide: '',
            isActive: '',
            action: '',
            addCheck: false,
            checkerComments: ''
        },

        onSubmit: async (data) => {
            const addpayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsMainCategoryId: formik?.values?.cmsMainCategoryId,
                            isActive: requestType === 'I' ? (formik?.values?.action == '2' ? 'Y' : 'N') : formik?.values?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: ''
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const updatePayload = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        objectBody: {
                            cmsMainCategoryId: data?.cmsMainCategoryId,
                            categoryName: data?.categoryName,
                            displayNameEnglist: data?.displayNameEnglist,
                            displayNameUrdu: data?.displayNameUrdu,
                            categoryNumber: data?.categoryNumber,
                            categoryIcon: filePath,
                            cmsTagLabelId: data?.cmsTagLabelId ? data?.cmsTagLabelId : '',
                            cmsTagTextColorId: data?.cmsTagTextColorId ? data?.cmsTagTextColorId : '',
                            cmsTagTextBgColorId: data?.cmsTagTextBgColorId ? data?.cmsTagTextBgColorId : '',
                            tagExpiryDate: data?.tagExpiryDate ? data?.tagExpiryDate : '',
                            isHide: data?.isHide,
                            isActive: data?.isActive
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '18',
                            checkerComments: data?.checkerComments,
                            action: data?.action,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const formData = new FormData();

            if (requestType === 'I') {
                formData.append('request', JSON.stringify(addpayload));
            } else if (requestType === 'U') {
                formData.append('request', JSON.stringify(updatePayload));
            }
            await dispatch(handlePostRequest(formData, '/agentmatecms/v1/agentmatecms/categoryvalidatorcheckeraction', true, false, 'requestchecker'));
        }
    });
    const getMainCategoryData = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getcategorybyid/${refTableId}`);
        const keyData = res?.payLoad;
        if (keyData) {
            formik.setFieldValue('categoryName', keyData?.categoryName || '');
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId || '');
            formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist || '');
            formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu || '');
            formik.setFieldValue('categoryNumber', keyData?.categoryNumber || '');
            formik.setFieldValue('addCheck', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagTextBgColorId', keyData?.cmsTagTextBgColorId || '');
            const correctEndDate = formattedDate(keyData?.tagExpiryDate || '');
            formik.setFieldValue('tagExpiryDate', correctEndDate || '');
            formik.setFieldValue('isHide', keyData?.isHide || '');
            formik.setFieldValue('isActive', keyData?.isActive || '');
            const pathParts = keyData?.categoryIcon.split('/');
            const categoryIcon = pathParts[pathParts.length - 1];
            setMainCategoryIconFileName(categoryIcon);
        }
    };

    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const keyData = JSON.parse(unParsedkeyData);

        if (keyData) {
            formik.setFieldValue('categoryName', keyData?.categoryName || '');
            formik.setFieldValue('cmsMainCategoryId', keyData?.cmsMainCategoryId || '');
            formik.setFieldValue('displayNameEnglist', keyData?.displayNameEnglist || '');
            formik.setFieldValue('displayNameUrdu', keyData?.displayNameUrdu || '');
            formik.setFieldValue('categoryNumber', keyData?.categoryNumber || '');
            formik.setFieldValue('addCheck', keyData?.cmsTagLabelId || keyData?.cmsTagTextColorId || keyData?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsTagLabelId', keyData?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', keyData?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagTextBgColorId', keyData?.cmsTagTextBgColorId || '');
            const correctEndDate = formattedDate(keyData.tagExpiryDate || '');
            formik.setFieldValue('tagExpiryDate', correctEndDate || '');
            formik.setFieldValue('isHide', keyData?.isHide || '');
            formik.setFieldValue('isActive', keyData?.isActive || '');
            setFilePath(keyData?.categoryIcon);
            const pathParts = keyData?.categoryIcon?.split('/');
            const categoryIcon = pathParts ? pathParts[pathParts?.length - 1] : '';

            setMainCategoryIconFileName(categoryIcon);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getMainCategoryData();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType]); // eslint-disable-line

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAIL</h2>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="categoryName" className={classNames({ 'p-error': isFormFieldValid('categoryName') }, 'Label__Text')}>
                                    Category Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="categoryName"
                                    placeholder="Enter category name"
                                    name="categoryName"
                                    value={formik?.values?.categoryName || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('categoryName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameEnglist" className={classNames({ 'p-error': isFormFieldValid('displayNameEnglist') }, 'Label__Text')}>
                                    Display Name (English)
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameEnglist"
                                    placeholder="Enter display name"
                                    name="displayNameEnglist"
                                    value={formik?.values?.displayNameEnglist || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameEnglist') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameEnglist')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="displayNameUrdu" className={classNames({ 'p-error': isFormFieldValid('displayNameUrdu') }, 'Label__Text')}>
                                    Display Name (Urdu)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="displayNameUrdu"
                                    placeholder="Enter display name"
                                    name="displayNameUrdu"
                                    value={formik?.values?.displayNameUrdu || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('displayNameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('displayNameUrdu')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="categoryNumber" className={classNames({ 'p-error': isFormFieldValid('categoryNumber') }, 'Label__Text')}>
                                    Category Number <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="categoryNumber"
                                    placeholder="Enter product ID"
                                    name="categoryNumber"
                                    value={formik?.values?.categoryNumber || ''}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('categoryNumber') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('categoryNumber')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className="Label__Text">
                                        Category Icon <span className="Label__Required">*</span>
                                    </label>
                                    <div disabled className="file-input-container w-100">
                                        <input disabled type="file" style={{ display: 'none' }} accept="image/*" />
                                        <input disabled type="text" className="file-input-field Input__Round" placeholder="Choose a file..." value={mainCategoryIconFileName} readOnly />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} disabled style={{ marginTop: '10px' }} />{' '}
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                                <InputText
                                    id="cmsTagLabelId"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabelId"
                                    disabled
                                    value={formik?.values?.cmsTagLabelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextColorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextBgColorId') }, 'Label__Text')}>
                                    Tag Back Ground Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextBgColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextBgColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextBgColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextBgColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextBgColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagTextBgColorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="tagExpiryDate" className={classNames({ 'p-error': isFormFieldValid('tagExpiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    type="date"
                                    id="tagExpiryDate"
                                    name="tagExpiryDate"
                                    value={formik?.values?.tagExpiryDate || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('tagExpiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('tagExpiryDate')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-4 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-4">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} disabled style={{ marginTop: '10px' }} />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card Card__Bg__Change col-12 md:col-12 flex">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3 p-field-addbanner-width">
                                <div className="p-field">
                                    <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                        Select Action<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('action')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-6 pt-3 pb-3 p-field-addbanner-width">
                                <div className="desc_Text_Area">
                                    <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                        Checker Comments<span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="checkerComments"
                                        name="checkerComments"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'Input__Round')}
                                        value={formik?.values?.checkerComments}
                                    />

                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditAgentMateMainCategoryChecker;
