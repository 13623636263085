import React, { useEffect, useRef, useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Image } from 'primereact/image';
import eyeIcon from "../../../../../src/assets/images/view_icon_y.png"
import noImgAvailable from "../../../../../src/assets/images/no-image-available.jpg"
import { handleGetRequest } from '../../../../services/GetTemplate';
import { baseURL } from '../../../../Config';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../../services/PostTemplate';

const EditDiscrepantDocs = ({ rowData, onAccountDiscrepantDocsChange, formikValuees, IsMinorAccount, IsMinor1Account, IsUltraSignatureAccount, onHide, userTabsFieldData }) => {

    const [discrepantData, setDiscrepantData] = useState([]);

    // Attachment File Name States
    const [cnicFileName, setCnicFileName] = useState('');
    const [cnicBackFileName, setCnicBackFileName] = useState('');
    const [proofOfProfessionFileName, setProofOfProfessionFileName] = useState('');
    const [sourceOfIncomeFileName, setSourceOfIncomeFileName] = useState('');
    const [parentCnicFrontFileName, setParentCnicFrontFileName] = useState('');
    const [parentCnicBackFileName, setParentCnicBackFileName] = useState('');
    const [bFormFileName, setBFormFileName] = useState('');
    const [utilityBillFileName, setUtilityBillFileName] = useState('');

    // Attachment Base64 States
    const [cnicFrontBase64, setCnicFrontBase64] = useState('');
    const [cnicBackBase64, setCnicBackBase64] = useState('');
    const [proofOfprofessionBase64, setProofOfprofessionBase64] = useState('');
    const [sourceOfIncomeBase64, setSourceOfIncomeBase64] = useState('');
    const [parentCnicFrontBase64, setParentCnicFrontBase64] = useState('');
    const [parentCnicBackBase64, setParentCnicBackBase64] = useState('');
    const [bFormBase64, setBFormBase64] = useState('');
    const [utilityBillBase64, setUtilityBillBase64] = useState('');

    // Images Refrences
    const cnicFrontRef = useRef(null);
    const cnicBackRef = useRef(null);
    const proofOfProfessionRef = useRef(null);
    const sourceOfIncomeRef = useRef(null);
    const parentCnicFrontRef = useRef(null);
    const parentCnicBackRef = useRef(null);
    const bFormRef = useRef(null);
    const utilityBillRef = useRef(null);

    const dispatch = useDispatch()

    const validationSchema = Yup.object().shape({
        // cnicFront: Yup.string().required('This Field is required.'),
        // sourceOfIncome: Yup.string().required('This Field is required.'),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            cnic: formikValuees?.cnic ? formikValuees?.cnic : "",
        },

        onSubmit: async () => {

            // Proceed with submission
            let discrepantDocsValue = {
                cnicFrontBase64: cnicFrontBase64 ? cnicFrontBase64 : "",
                cnicBackBase64: cnicBackBase64 ? cnicBackBase64 : "",
                proofOfprofessionBase64: proofOfprofessionBase64 ? proofOfprofessionBase64 : "",
                sourceOfIncomeBase64: sourceOfIncomeBase64 ? sourceOfIncomeBase64 : "",
                parentCnicFrontBase64: parentCnicFrontBase64 ? parentCnicFrontBase64 : "",
                parentCnicBackBase64: parentCnicBackBase64 ? parentCnicBackBase64 : "",
                bFormBase64: bFormBase64 ? bFormBase64 : "",
            };
            onAccountDiscrepantDocsChange(discrepantDocsValue);
            onHide();
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // Click References
    const handleCnicClick = () => {
        cnicFrontRef.current.click();
    };
    const handleCnicBackClick = () => {
        cnicBackRef.current.click();
    };
    const handleProofOfProfessionClick = () => {
        proofOfProfessionRef.current.click();
    };
    const handleSourceOfIncomeClick = () => {
        sourceOfIncomeRef.current.click();
    };
    const handleParentCnicFrontClick = () => {
        parentCnicFrontRef.current.click();
    };
    const handleParentCnicBackClick = () => {
        parentCnicBackRef.current.click();
    };
    const handleBFormClick = () => {
        bFormRef.current.click();
    };
    const handleUtilityBillClick = () => {
        utilityBillRef.current.click();
    };

    // Change Handlers
    const handleCnicChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setCnicFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setCnicFrontBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleCnicBackChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setCnicBackFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setCnicBackBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleProofOfProfessionChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setProofOfProfessionFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setProofOfprofessionBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleSourceOfIncomeChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setSourceOfIncomeFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setSourceOfIncomeBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleParentCnicFrontChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setParentCnicFrontFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setParentCnicFrontBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleParentCnicBackChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setParentCnicBackFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setParentCnicBackBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleUtilityBillChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setUtilityBillFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setUtilityBillBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const handleBFormChange = (event) => {
        const selectedFile = event.target.files[0];

        if (selectedFile) {
            setBFormFileName(selectedFile.name); // Update the selected file name
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                setBFormBase64(base64Data)
            };
            reader.readAsDataURL(selectedFile);
        }
    };
    const getDiscrepantById = async () => {
        const response = await handleGetRequest(`/account/v1/customeraccounts/getDataByDescrepant/${rowData?.customerId}`);
        if (response?.responseCode === "010000") {
            setDiscrepantData(response?.payLoad)
        }
    }

    const splitCnicFrontFileName = discrepantData?.cnicFrontPic ? discrepantData?.cnicFrontPic.split('/').pop() : '';
    const splitCnicBackFileName = discrepantData?.cnicBankPic ? discrepantData?.cnicBankPic.split('/').pop() : '';
    const splitVideoFileName = discrepantData?.videoPath ? discrepantData?.videoPath.split('/').pop() : '';
    const splitProofOfProfessionFileName = discrepantData?.proofOfProfessionPic ? discrepantData?.proofOfProfessionPic.split('/').pop() : '';
    const splitSourceOfIncomeFileName = discrepantData?.proofOfIncomePic ? discrepantData?.proofOfIncomePic.split('/').pop() : '';
    const splitParentCnicFrontFileName = discrepantData?.parentCnicFrontPic ? discrepantData?.parentCnicFrontPic.split('/').pop() : '';
    const splitParentCnicBackFileName = discrepantData?.parentCnicBackPic ? discrepantData?.parentCnicBackPic.split('/').pop() : '';
    const splitBFormFileName = discrepantData?.bFormPic ? discrepantData?.bFormPic.split('/').pop() : '';
    const splitUtilityBillFileName = discrepantData?.utilityBillPic ? discrepantData?.utilityBillPic.split('/').pop() : '';

    const selfieImage = `${baseURL}/document` + discrepantData?.livePhoto?.substring(33);
    const signatureImage = `${baseURL}/document` + discrepantData?.signaturePic?.substring(33);

    // Handle Submit
    const handleSubmit = async (fieldName) => {

        const customerKyc = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    updateCustomerKyc: {
                        cnicFront: fieldName === "cnicFrontPic" ? cnicFrontBase64 : "",
                        cnicBack: fieldName === "cnicBankPic" ? cnicBackBase64 : "",
                        proofOfProfession: fieldName === "proofOfProfessionPic" ? proofOfprofessionBase64 : "",
                        sourceOfIncome: fieldName === "proofOfIncomePic" ? sourceOfIncomeBase64 : "",
                        parentCnicFront: fieldName === "parentCnicFrontPic" ? parentCnicFrontBase64 : "",
                        parentCnicBack: fieldName === "parentCnicBackPic" ? parentCnicBackBase64 : "",
                        bForm: fieldName === "bFormPic" ? bFormBase64 : "",
                        utilityBill: fieldName === "utilityBill" ? utilityBillBase64 : "",
                        fieldName: fieldName,
                        ///////////////
                        fullName: formikValuees?.fullName,
                        accountStatusId: formikValuees?.accountStatusId,
                        remarks: formikValuees?.remarks,
                        fullAddress: formikValuees?.fullAddress,
                        email: formikValuees?.email,
                        cnicIssuanceDate: formikValuees?.cnicIssuanceDate,
                        //---------------------------------------//
                        customerId: formikValuees?.customerId,
                        mobileNumber: rowData?.mobileNo,
                        accountId: rowData?.accountId,
                        addressId: rowData?.addressId,
                        accountTypeId: formikValuees?.accountTypeId,
                        // cnicExpiryDate: formik.values.cnicExpiryDate,
                        discrepentDocumentId: rowData?.channelId,
                        pinGenerationId: formikValuees?.pinGeneration,
                        statusId: rowData?.statusId,
                        isActive: rowData?.isActive,
                        whitelistedDevice: formikValuees?.whiteListStatus,
                        customerNumber: rowData?.mobileNo,
                        hraNokName: formikValuees?.hraNokName,
                        hraNokCnic: formikValuees?.hraNokCnic,
                        hraNokMobileNo: formikValuees?.hraNokMobileNo,
                        hraNokRelationshipName: formikValuees?.hraNokRelationshipName,
                        // ntn: formik.values.ntn,
                        hraOriginatorRelationshipName: formikValuees?.hraOriginatorRelationshipName,
                    }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        // return
        await dispatch(handlePostRequest(customerKyc, '/account/v1/customeraccounts/updatecustomerkyc', true, false, "accountsmanagement"));
    };

    const checkFieldPermissions = (fieldName) => {
        const field = userTabsFieldData.find(f => f.fieldName === fieldName);
        return {
            viewAllowed: field?.viewAllowed === "Y",
            updateAllowed: field?.updateAllowed === "Y"
        };
    };

    const cnicFrontPicFieldPermissions = checkFieldPermissions("cnicFrontPic");
    const cnicBankPicFieldPermissions = checkFieldPermissions("cnicBankPic");
    const cnicFieldPermissions = checkFieldPermissions("cnic");
    const proofOfIncomePicFieldPermissions = checkFieldPermissions("proofOfIncomePic");
    const proofOfProfessionPicFieldPermissions = checkFieldPermissions("proofOfProfessionPic");
    const livePhotoFieldPermissions = checkFieldPermissions("livePhoto");
    const signaturePicFieldPermissions = checkFieldPermissions("signaturePic");
    const parentCnicFrontPicFieldPermissions = checkFieldPermissions("parentCnicFrontPic");
    const parentCnicBackPicFieldPermissions = checkFieldPermissions("parentCnicBackPic");
    const bFormPicFieldPermissions = checkFieldPermissions("bFormPic");
    const utilityBillFieldPermissions = checkFieldPermissions("utilityBill");
    const customerVideoFieldPermissions = checkFieldPermissions('customerVideo');

    useEffect(() => {
        getDiscrepantById()
    }, []);

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='Card__Round'>

                    <div className="p-fluid p-formgrid grid">
                        <div className="p-field col-12">

                            {/* Cnic Front */}
                            {cnicFrontPicFieldPermissions.viewAllowed &&
                                discrepantData?.cnicScanCopyDiscrepant === "Y" ?
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            CNIC Front
                                        </label>
                                        <div className='flex'>
                                            <div className="file-input-container w-100" onClick={handleCnicClick}>
                                                <input
                                                    id='cnicFront'
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={cnicFrontRef}
                                                    onChange={(e) => {
                                                        handleCnicChange(e)
                                                        formik.handleChange(e)
                                                    }}
                                                    accept="image/*"
                                                />
                                                <input
                                                    type="text"
                                                    className="file-input-field Input__Round"
                                                    placeholder="Choose a file..."
                                                    value={cnicFileName ? cnicFileName : splitCnicFrontFileName}
                                                    readOnly
                                                    disabled={cnicFrontPicFieldPermissions.updateAllowed}
                                                />
                                                {cnicFrontPicFieldPermissions.updateAllowed && (
                                                    <span className="file-upload-icon">
                                                        <i className='pi pi-paperclip' />
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("cnicFrontPic")} disabled={!cnicFrontBase64 && true} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                ""
                            }
                            {/* Cnic Back */}
                            {cnicBankPicFieldPermissions.viewAllowed &&
                                discrepantData?.cnicBackPicDiscrepant === "Y" ?
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            CNIC Back
                                        </label>
                                        <div className='flex'>
                                            <div className="file-input-container w-100" onClick={handleCnicBackClick}>
                                                <input
                                                    id='cnicBack'
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={cnicBackRef}
                                                    onChange={(e) => {
                                                        handleCnicBackChange(e)
                                                        formik.handleChange(e)
                                                    }}
                                                    accept="image/*"
                                                />
                                                <input
                                                    type="text"
                                                    className="file-input-field Input__Round"
                                                    placeholder="Choose a file..."
                                                    value={cnicBackFileName ? cnicBackFileName : splitCnicBackFileName}
                                                    readOnly
                                                    disabled={cnicBankPicFieldPermissions.updateAllowed}
                                                />
                                                {cnicBankPicFieldPermissions.updateAllowed && (
                                                    <span className="file-upload-icon">
                                                        <i className='pi pi-paperclip' />
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("cnicBankPic")} disabled={!cnicBackBase64 && true} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                ""
                            }
                            {/* Video */}
                            {cnicFrontPicFieldPermissions.viewAllowed &&
                                discrepantData?.videoDescrepant === "Y" ?
                                (
                                    <>
                                        <label className="Label__Text">Video</label>
                                        <div className="flex align-items-center">
                                            <InputText id="customerVideo" value={splitVideoFileName ?? ''} className="Input__Round mr-2" disabled />
                                        </div>
                                    </>
                                )
                                :
                                ""
                            }
                            {/* Proof Of Profession */}
                            {proofOfProfessionPicFieldPermissions.viewAllowed &&
                                discrepantData?.proofOfProfessionPicDiscrepant === "Y" ?
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            Proof of Profession
                                        </label>
                                        <div className='flex'>
                                            <div className="file-input-container w-100" onClick={handleProofOfProfessionClick}>
                                                <input
                                                    id=''
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={proofOfProfessionRef}
                                                    onChange={(e) => {
                                                        handleProofOfProfessionChange(e)
                                                        formik.handleChange(e)
                                                    }}
                                                    accept="image/*"
                                                />
                                                <input
                                                    type="text"
                                                    className="file-input-field Input__Round"
                                                    placeholder="Choose a file..."
                                                    value={proofOfProfessionFileName ? proofOfProfessionFileName : splitProofOfProfessionFileName}
                                                    readOnly
                                                    disabled={proofOfProfessionPicFieldPermissions.updateAllowed}
                                                />
                                                {proofOfProfessionPicFieldPermissions.updateAllowed && (
                                                    <span className="file-upload-icon">
                                                        <i className='pi pi-paperclip' />
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("proofOfProfessionPic")} disabled={!proofOfprofessionBase64 && true} />
                                            </div>
                                        </div>
                                        {getFormErrorMessage("cnicFront")}
                                    </div>
                                )
                                :
                                ""
                            }
                            {/* Source Of Income */}
                            {proofOfIncomePicFieldPermissions.viewAllowed &&
                                discrepantData?.proofOfIncomePicDiscrepant === "Y" ?
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            Source of Income
                                        </label>
                                        <div className='flex'>
                                            <div className="file-input-container w-100" onClick={handleSourceOfIncomeClick}>
                                                <input
                                                    id='sourceOfIncome'
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={sourceOfIncomeRef}
                                                    onChange={(e) => {
                                                        handleSourceOfIncomeChange(e)
                                                        formik.handleChange(e)
                                                    }}
                                                    accept="image/*"
                                                />
                                                <input
                                                    type="text"
                                                    className="file-input-field Input__Round"
                                                    placeholder="Choose a file..."
                                                    value={sourceOfIncomeFileName ? sourceOfIncomeFileName : splitSourceOfIncomeFileName}
                                                    readOnly
                                                    disabled={proofOfIncomePicFieldPermissions.updateAllowed}
                                                />
                                                {proofOfIncomePicFieldPermissions.updateAllowed && (
                                                    <span className="file-upload-icon">
                                                        <i className='pi pi-paperclip' />
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("proofOfIncomePic")} disabled={!sourceOfIncomeBase64 && true} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                ""
                            }
                            {IsUltraSignatureAccount &&
                                utilityBillFieldPermissions.viewAllowed &&
                                discrepantData?.utilityBillDiscrepant === "Y" ?
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            Utility Bill
                                        </label>
                                        <div className='flex'>
                                            <div className="file-input-container w-100" onClick={handleUtilityBillClick}>
                                                <input
                                                    id='utilityBill'
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    ref={utilityBillRef}
                                                    onChange={(e) => {
                                                        handleUtilityBillChange(e)
                                                        formik.handleChange(e)
                                                    }}
                                                    accept="image/*"
                                                />
                                                <input
                                                    type="text"
                                                    className="file-input-field Input__Round"
                                                    placeholder="Choose a file..."
                                                    value={utilityBillFileName ? utilityBillFileName : splitUtilityBillFileName}
                                                    readOnly
                                                    disabled={utilityBillFieldPermissions.updateAllowed}
                                                />
                                                {utilityBillFieldPermissions.updateAllowed && (
                                                    <span className="file-upload-icon">
                                                        <i className='pi pi-paperclip' />
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("utilityBill")} disabled={!utilityBillBase64 && true} />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                ""
                            }

                            {
                                (IsMinorAccount || IsMinor1Account) &&
                                (
                                    <>
                                        {/* Parent/Guardian CNIC Front */}
                                        {parentCnicFrontPicFieldPermissions.viewAllowed &&
                                            discrepantData?.parentCnicFrontDiscrepant === "Y" ?
                                            (
                                                <div className='pb-4'>
                                                    <label className='Label__Text'>
                                                        Parent/Guardian CNIC Front
                                                    </label>
                                                    <div className='flex'>
                                                        <div className="file-input-container w-100" onClick={handleParentCnicFrontClick}>
                                                            <input
                                                                id='parentCnicFront'
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={parentCnicFrontRef}
                                                                onChange={(e) => {
                                                                    handleParentCnicFrontChange(e)
                                                                    formik.handleChange(e)
                                                                }}
                                                                accept="image/*"
                                                            />
                                                            <input
                                                                type="text"
                                                                className="file-input-field Input__Round"
                                                                placeholder="Choose a file..."
                                                                value={parentCnicFrontFileName ? parentCnicFrontFileName : splitParentCnicFrontFileName}
                                                                readOnly
                                                                disabled={parentCnicFrontPicFieldPermissions.updateAllowed}
                                                            />
                                                            {parentCnicFrontPicFieldPermissions.updateAllowed && (
                                                                <span className="file-upload-icon">
                                                                    <i className='pi pi-paperclip' />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("parentCnicFrontPic")} disabled={!parentCnicFrontBase64 && true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : ""}
                                        {/* Parent/Guardian CNIC Back */}
                                        {parentCnicBackPicFieldPermissions.viewAllowed &&
                                            discrepantData?.parentCnicBackDiscrepant === "Y" ?
                                            (
                                                <div className='pb-4'>
                                                    <label className='Label__Text'>
                                                        Parent/Guardian CNIC Back
                                                    </label>
                                                    <div className='flex'>
                                                        <div className="file-input-container w-100" onClick={handleParentCnicBackClick}>
                                                            <input
                                                                id='parentCnicBack'
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={parentCnicBackRef}
                                                                onChange={(e) => {
                                                                    handleParentCnicBackChange(e)
                                                                    formik.handleChange(e)
                                                                }}
                                                                accept="image/*"
                                                            />
                                                            <input
                                                                type="text"
                                                                className="file-input-field Input__Round"
                                                                placeholder="Choose a file..."
                                                                value={parentCnicBackFileName ? parentCnicBackFileName : splitParentCnicBackFileName}
                                                                readOnly
                                                                disabled={parentCnicBackPicFieldPermissions.updateAllowed}
                                                            />
                                                            {parentCnicBackPicFieldPermissions.updateAllowed && (
                                                                <span className="file-upload-icon">
                                                                    <i className='pi pi-paperclip' />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("parentCnicBackPic")} disabled={!parentCnicBackBase64 && true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : ""
                                        }
                                        {/* B-Form */}
                                        {bFormPicFieldPermissions.viewAllowed &&
                                            discrepantData?.bformDiscrepant === "Y" ?
                                            (
                                                <div className='pb-4'>
                                                    <label className='Label__Text'>
                                                        B-Form
                                                    </label>
                                                    <div className='flex'>
                                                        <div className="file-input-container w-100" onClick={handleBFormClick}>
                                                            <input
                                                                id='bForm'
                                                                type="file"
                                                                style={{ display: 'none' }}
                                                                ref={bFormRef}
                                                                onChange={(e) => {
                                                                    handleBFormChange(e)
                                                                    formik.handleChange(e)
                                                                }}
                                                                accept="image/*"
                                                            />
                                                            <input
                                                                type="text"
                                                                className="file-input-field Input__Round"
                                                                placeholder="Choose a file..."
                                                                value={bFormFileName ? bFormFileName : splitBFormFileName}
                                                                readOnly
                                                                disabled={bFormPicFieldPermissions.updateAllowed}
                                                            />
                                                            {bFormPicFieldPermissions.updateAllowed && (
                                                                <span className="file-upload-icon">
                                                                    <i className='pi pi-paperclip' />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div>
                                                            <Button icon="pi pi-upload" iconPos="right" className="ml-2" type="submit" onClick={() => handleSubmit("bFormPic")} disabled={!bFormBase64 && true} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            : ""
                                        }
                                    </>
                                )}


                            {/* Selfie */}
                            {
                                discrepantData?.livePhotoDiscrepant === "Y" ?
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            Selfie
                                        </label>
                                        <div className='selfie-styling w-100'>
                                            <InputText
                                                id='livePhoto'
                                                name='livePhoto'
                                                // value={formik.values.livePhoto}
                                                className='Input__Round'
                                                disabled
                                            />
                                            {livePhotoFieldPermissions.viewAllowed && (
                                                <Image className='img-viewer' src={eyeIcon} zoomSrc={selfieImage ? selfieImage : noImgAvailable} alt="Image" width="80" height="60" preview />
                                            )}
                                        </div>
                                    </div>
                                    :
                                    ""
                            }


                            {/* Signature Mismatch */}

                            {
                                discrepantData?.signatureDiscrepant === "Y" &&
                                (
                                    <div className='pb-4'>
                                        <label className='Label__Text'>
                                            Signature Mismatch
                                        </label>
                                        <div className='selfie-styling w-100'>
                                            <InputText
                                                id='signaturePic'
                                                name='signaturePic'
                                                value={rowData?.fullName}
                                                className='Input__Round'
                                                disabled
                                            />
                                            {signaturePicFieldPermissions.viewAllowed && (
                                                <Image className='img-viewer' src={eyeIcon} zoomSrc={signatureImage ? signatureImage : noImgAvailable} alt="Image" width="80" height="60" preview />
                                            )}
                                        </div>
                                    </div>
                                )
                            }



                        </div>

                    </div>

                    <div className='Down__Btn mt-4'>
                        {/* <Button label="Confirm" iconPos="right" className="Btn__Dark" type="submit" /> */}
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" type="button" onClick={onHide} />
                    </div>

                </div>
            </form>
        </>
    )
}

export default EditDiscrepantDocs