import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';

import BulkDiscardDialog from '../../../../components/BulkDialog/BulkDiscardDialog';
import { Paginator } from 'primereact/paginator';
import { PaginationCall, PaginationTemplate } from '../../../../components/Pagination';

function SegmentUpdate({}) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [rowDataProp, setRowDataProp] = useState(null);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apiHit, setApiHit] = useState([]);
    const [segmentUpdateData, setSegmentUpdateData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [batchStatus, setBatchStatus] = useState([]);

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const dataTableRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,

        initialValues: {
            bulkOperationName: 'BSU',
            tblBulkOprFileDTO: '',
            blackListMarkUnmark: '',
            batchId: '',
            createuser: '',
            statusId: '2',
            dateTo: '',
            dateFrom: '',
            lastupdateuser: '',
            fileStatusId: '',
            page: '1',
            size: customRows1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const payloadDataNew = {
                bulkOperationName: data.bulkOperationName,
                page: data.page,
                size: data.size,
                tblBulkOprFileDTO: {
                    batchId: data.batchId,
                    createuser: data.createuser,
                    statusId: data.statusId,
                    dateTo: data.dateTo,
                    dateFrom: data.dateFrom,
                    lastupdateuser: data.lastupdateuser,
                    fileStatusId: data.fileStatusId
                }
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadDataNew,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]
                }
            };

            dataTableRef.current.reset();
            setApiHit(newData?.data?.payLoad);

            const resetHandle = (e) => {
                e.preventDefault();
                formik.resetForm();
            };
            const res = await dispatch(handlePostRequest(newData, '/batchprocessing/v1/batchprocessing/getallrecords', true, true));
            if (res?.responseCode === '170000') {
                setSegmentUpdateData(res?.payLoad);
            } else {
                setSegmentUpdateData('');
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        let data = resp?.payLoad;
        data = data.filter((item) => item.lovId !== '4');

        setStatusLovData(data);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getBatchStatusData = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getLkpFileStatus/LKP_FILE_STATUS');
        setBatchStatus(resp?.payLoad);
    };
    useEffect(() => {
        getStatusLov();
        getBatchStatusData();
        getUserLov();
    }, []); // eslint-disable-line

    const handleBatchNumberClick = (rowData) => {
        navigate('/viewValidateSegment', { state: { rowData } });
    };

    const getBatchDetais = (rowData) => {
        setRowDataProp(rowData);
    };

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Bulk Segment Update</h5>
            </div>
        );
    };

    const header = renderHeader();

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        label="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        disabled={rowData?.fileStatusId?.fileStatusName === 'Discarded'}
                        tooltip="Withdraw"
                        onClick={() => handleBatchNumberClick(rowData)}
                        className="Btn__Withdraw"
                        iconPos="left"
                    />
                    <Button
                        tooltip="View Details"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-trash"
                        disabled={
                            rowData.fileStatusId.fileStatusName === 'Executed' ||
                            rowData.fileStatusId.fileStatusName === 'Batch in Progress' ||
                            rowData.fileStatusId.fileStatusName === 'Approved' ||
                            rowData.fileStatusId.fileStatusName === 'Completed' ||
                            rowData?.fileStatusId?.fileStatusName === 'Discarded'
                        }
                        //  onClick={() => viewNewUseCaseManagement(rowData)}
                        className="p-button-rounded p-button-danger"
                        onClick={() => {
                            setShowModal(true);
                            getBatchDetais(rowData);
                        }}
                    />
                </div>
            </>
        );
    };
    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const handleShowModal = () => {
        setShowModal(false);
    };
    const esbProps = {
        showModal,
        handleShowModal
    };
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusId.statusName === 'Approved' ? '#14A38B' : rowData?.statusId.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusId.statusName}</b>
            </p>
        );
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        ...apiHit,
        page: currentPage,
        size: customRows1
    };
    return (
        <>
            <BulkDiscardDialog {...esbProps} rowDataProp={rowDataProp} />
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Upload" onClick={() => navigate('/addUploadSegmentUpdate')} icon="pi pi-plus" className="Btn__Add__" iconPos="left" />
                </div>
            </div>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="batchId" className={classNames({ 'p-error': isFormFieldValid('batchId') }, 'Label__Text')}>
                                    Batch ID
                                </label>
                                <InputText id="batchId" placeholder="" name="batchId" value={formik?.values?.batchId} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('batchId') }, 'Input__Round')} />

                                {getFormErrorMessage('batchId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Uploaded From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Uploaded To
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createuser" className={classNames({ 'p-error': isFormFieldValid('createuser') }, 'Label__Text')}>
                                    Uploaded By
                                </label>
                                <Dropdown
                                    id="createuser"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createuser"
                                    optionValue="lovId"
                                    value={formik.values.createuser || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createuser') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createuser')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="fileStatusId" className={classNames({ 'p-error': isFormFieldValid('fileStatusId') }, 'Label__Text')}>
                                    Batch Status
                                </label>
                                <Dropdown
                                    id="fileStatusId"
                                    placeholder="Select Batch Status"
                                    options={batchStatus}
                                    optionLabel="name"
                                    name="fileStatusId"
                                    optionValue="lovId"
                                    value={formik.values.fileStatusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('fileStatusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('fileStatusId')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setSegmentUpdateData}
                RequestData={RequestData}
                url={'/batchprocessing/v1/batchprocessing/getallrecords'}
                responseCode={'170000'}
            />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable ref={dataTableRef} className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={segmentUpdateData}>
                            <Column field="batchId" header="Batch ID" />

                            <Column field="totalRecords" header="Total Records" />
                            <Column field="validRecords" header="Valid Records" />
                            <Column field="invalidRecords" header="Invalid Records" />
                            <Column field="createdBy" header="Uploaded By" />
                            <Column header="Uploaded Date & Time" body={createdDateAndTimeBodyTemplate} />

                            <Column
                                field="fileStatusId.fileStatusName"
                                header=" Batch Status"
                                body={(rowData) => (
                                    <span
                                        style={{
                                            color:
                                                (rowData.fileStatusId.fileStatusName === rowData.fileStatusId.fileStatusName) === 'Executed' ||
                                                rowData.fileStatusId.fileStatusName === 'Batch in Progress' ||
                                                rowData.fileStatusId.fileStatusName === 'Approved' ||
                                                rowData.fileStatusId.fileStatusName === 'Completed'
                                                    ? 'red'
                                                    : 'green'
                                        }}
                                    >
                                        {rowData.fileStatusId.fileStatusName}
                                    </span>
                                )}
                            />
                            <Column header="Status" body={statusBodyTemplate} />

                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={segmentUpdateData?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SegmentUpdate;
