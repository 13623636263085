import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handleGetRequest } from '../../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewPricingSlabWiseHybridChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [sharingAccount, setSharingAccount] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const requestTypeDescr = query.get('requestTypeDescr');
    const checkerComments = query.get('checkerComments');

    //const dispatch = useDispatch();
    const navigate = useNavigate();

    const getPricingFixedbyId = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${refTableId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = keyData?.incomeSharingRequests?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });
        const segmentValues = keyData?.tblPricingSegments?.map((values) => {
            return values?.segmentId?.toString();
        });
        formik.setFieldValue('segmentIds', segmentValues || '');


        const channelValues = keyData?.tblPricingChannels?.map((values) => {
            return values?.channelId?.toString();
        });
        formik.setFieldValue('channelIds', channelValues || '');
        formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        // formik.setFieldValue('segmentId', keyData?.lkpSegment?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        // formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.tblAccount2?.accountId?.toString());
        // formik.setFieldValue('pricingIncomeSharingId', keyData?.incomeSharingRequests[0]?.pricingIncomeSharingId);
        formik.setFieldValue('startSlab', keyData?.tblPricingSlabs[0].startSlab);
        formik.setFieldValue('endSlab', keyData?.tblPricingSlabs[0].endSlab);
        formik.setFieldValue('feeAmount', keyData?.tblPricingSlabs[0].feeAmount);
        formik.setFieldValue('feePercentage', keyData?.tblPricingSlabs[0]?.feePercentage);
        formik.setFieldValue('pricingSlabId', keyData?.tblPricingSlabs[0].pricingSlabId);
        formik.setFieldValue('slabFeeTypeCode', keyData?.tblPricingSlabs[0]?.lkpFeeType?.feeTypeCode);
        formik.setFieldValue('feeCondition', keyData?.tblPricingSlabs[0]?.feeCondition);
        // formik.setFieldValue('pricingSlabId', keyData?.tblPricingSlabs[0]?.lkpFeeType?.pricingSlabId)
        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    const getPricingFixedbyIdU = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getPricingProfileCheckerById/${mcRequestId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblProduct?.map((values) => {
            return values?.productId?.toString();
        });

        const partnerData = keyData?.tblPricingIncomeSharings?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });
        const tblPricingSlabs = keyData?.tblPricingSlabs?.map((pricingData) => {
            return {
                pricingSlabId: pricingData?.pricingSlabId.toString() || '',
                startSlab: pricingData?.startSlab?.toString() || '',
                endSlab: pricingData?.endSlab?.toString() || '',
                slabFeeTypeCode: pricingData?.slabFeeTypeCode || '',
                feePercentage: pricingData?.feePercentage?.toString() || '',
                feeCondition: pricingData?.feeCondition?.toString() || '',
                feeAmount: pricingData?.feeAmount?.toString() || ''
            };
        });
        const segmentValues = keyData?.segmentIds?.map(id => id.toString());

        formik.setFieldValue('segmentIds', segmentValues || '');
        const channelValues = keyData?.channelIds?.map(id => id.toString());

        formik.setFieldValue('channelIds', channelValues || '');
        formik.setFieldValue('accountClassificationId', keyData?.accountClassificationId?.toString());
        // formik.setFieldValue('segmentId', keyData?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        // formik.setFieldValue('channelId', keyData?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.chargingParty);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.incomeGlAccountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.payeeGlAccountId?.toString());

        if (keyData?.tblPricingIncomeSharings !== null) {
            formik.setFieldValue('startSlab', keyData?.tblPricingSlabs[0].startSlab);
            formik.setFieldValue('endSlab', keyData?.tblPricingSlabs[0].endSlab);
            formik.setFieldValue('feeAmount', keyData?.tblPricingSlabs[0].feeAmount);
            formik.setFieldValue('feePercentage', keyData?.tblPricingIncomeSharings[0]?.feePercentage);
            formik.setFieldValue('pricingSlabId', keyData?.tblPricingIncomeSharings[0].pricingSlabId);
            formik.setFieldValue('slabFeeTypeCode', keyData?.tblPricingSlabs[0]?.slabFeeTypeCode);
            formik.setFieldValue('feeCondition', keyData?.tblPricingIncomeSharings[0]?.feeCondition);
            formik.setFieldValue('pricingIncomeSharingId', keyData?.tblPricingIncomeSharings[0]?.pricingIncomeSharingId);
        }
        if (tblPricingSlabs && tblPricingSlabs.length > 0) {
            formik.setFieldValue('tblPricingSlabs', tblPricingSlabs || []);
        }

        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    const getMcRequestDataByIdU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getPricingProfileCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedPricingSlabWiseHybridData = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${requestedDataById?.pricingProfileId}`);
        const keyData = response?.payLoad;

        const productsValues = keyData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = keyData?.incomeSharingRequests?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });
        const tblPricingSlabs = keyData?.tblPricingSlabs?.map((pricingData) => {
            return {
                pricingSlabId: pricingData?.pricingSlabId.toString() || '',
                startSlab: pricingData?.startSlab?.toString() || '',
                endSlab: pricingData?.endSlab?.toString() || '',
                slabFeeTypeCode: pricingData?.lkpFeeType.feeTypeCode || '',
                feePercentage: pricingData?.feePercentage?.toString() || '',
                feeCondition: pricingData?.feeCondition?.toString() || '',
                feeAmount: pricingData?.feeAmount?.toString() || ''
            };
        });

        formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('segmentId', keyData?.lkpSegment?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.tblAccount2?.accountId?.toString());
        // formik.setFieldValue('pricingIncomeSharingId', keyData?.incomeSharingRequests[0]?.pricingIncomeSharingId);

        if (tblPricingSlabs && tblPricingSlabs.length > 0) {
            formik.setFieldValue('tblPricingSlabs', tblPricingSlabs || []);
        }
        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getPricingFixedbyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getPricingFixedbyIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdU();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.pricingProfileId !== undefined) {
            getRequestedPricingSlabWiseHybridData();
        }
    }, [requestedDataById?.pricingProfileId]); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    //const previousDate = currentDate.toISOString().split('T')[0];

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountClassificationId: '',
            segmentIds: [],
            pricingProfileName: '',
            channelIds: [],
            chargingParty: '',
            taxCalculationType: '',
            feeCalculationType: '',
            startDate: '',
            endDate: '',
            feeTypeCode: 'SH',
            incomeGlAccountId: '',
            velocity: 'N',
            incomeSharing: 'N',
            tblProduct: [],
            payeeGlAccountId: '',
            feeAmount: '',
            slabFeeTypeCode: '',
            feePercentage: '',
            tblPricingSlabs: [
                {
                    pricingSlabId: '',
                    startSlab: '',
                    endSlab: '',
                    feeAmount: '',
                    slabFeeTypeCode: '',
                    feePercentage: '',
                    feeCondition: ''
                }
            ],
            feeCondition: '',
            pricingProfileId: refTableId,
            pricingSlabId: '',
            tblPricingVelocities: [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: 'No'
                }
            ],
            tblPricingIncomeSharings: [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: ''
                }
            ],
            action: '',
            checkerComments: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            console.log('slab id', data?.pricingSlabId);
            if (data.velocity === 'N') {
                delete data['tblPricingVelocities'];
            }
            if (data.incomeSharing === 'N') {
                delete data['tblPricingIncomeSharings'];
            }
            data['tblPricingSlabs'] = formik.values.tblPricingSlabs?.map((item) => {
                let transformedItem = {
                    pricingSlabId: item?.pricingSlabId,
                    startSlab: item?.startSlab,
                    endSlab: item?.endSlab,
                    feeAmount: item?.feeAmount,
                    feePercentage: item?.feePercentage,
                    slabFeeTypeCode: item?.slabFeeTypeCode,
                    feeCondition: item?.feeCondition
                };

                if (item?.slabFeeTypeCode === 'FF') {
                    transformedItem = {
                        startSlab: item?.startSlab,
                        endSlab: item?.endSlab,
                        feeAmount: item?.feeAmount,
                        slabFeeTypeCode: item?.slabFeeTypeCode,
                        pricingSlabId: item?.pricingSlabId
                    };
                } else if (item?.slabFeeTypeCode === 'PF') {
                    transformedItem = {
                        startSlab: item?.startSlab,
                        endSlab: item?.endSlab,
                        feePercentage: item?.feePercentage,
                        pricingSlabId: item?.pricingSlabId,
                        slabFeeTypeCode: item?.slabFeeTypeCode
                    };
                } else if (item?.slabFeeTypeCode === 'CF') {
                    transformedItem = {
                        startSlab: item?.startSlab,
                        endSlab: item?.endSlab,
                        feeAmount: item?.feeAmount,
                        feePercentage: item?.feePercentage,
                        feeCondition: item?.feeCondition,
                        slabFeeTypeCode: item?.slabFeeTypeCode,
                        pricingSlabId: item?.pricingSlabId
                    };
                }

                return transformedItem;
            });
            // if (data?.slabFeeTypeCode === 'FF') {
            //     data['tblPricingSlabs'] = [
            //         {
            //             pricingSlabId: data?.pricingSlabId || '',
            //             startSlab: data?.startSlab,
            //             endSlab: data?.endSlab,
            //             feeAmount: data?.feeAmount,
            //             slabFeeTypeCode: data?.slabFeeTypeCode
            //         }
            //     ];
            // }
            // if (data?.slabFeeTypeCode === 'PF') {
            //     data['tblPricingSlabs'] = [
            //         {
            //             startSlab: data?.startSlab,
            //             endSlab: data?.endSlab,
            //             feePercentage: data?.feePercentage,
            //             pricingSlabId: data?.pricingSlabId || '',
            //             slabFeeTypeCode: data?.slabFeeTypeCode
            //         }
            //     ];
            // }
            // if (data?.slabFeeTypeCode === 'CF') {
            //     data['tblPricingSlabs'] = [
            //         {
            //             pricingSlabId: data?.pricingSlabId || '',
            //             startSlab: data?.startSlab,
            //             endSlab: data?.endSlab,
            //             feeAmount: data?.feeAmount,
            //             feePercentage: data?.feePercentage,
            //             feeCondition: data?.feeCondition,
            //             slabFeeTypeCode: data?.slabFeeTypeCode
            //         }
            //     ];
            // }
            if (data?.velocity === 'Y') {
                data?.tblPricingVelocities?.forEach((item) => {
                    delete item.lastupdatedate;
                    delete item.lastupdateuser;
                    delete item.updateindex;
                    delete item.createdate;
                    delete item.createuser;
                    delete item.isActive;
                });
            }

            const productArray = data?.tblProduct.map((prdctData) => {
                return {
                    productId: prdctData
                };
            });

            data['tblProduct'] = productArray;
            delete data['startSlab'];
            delete data['endSlab'];
            delete data['feeAmount'];
            delete data['feePercentage'];
            delete data['slabFeeTypeCode'];
            delete data['feeCondition'];
            delete data['pricingSlabId'];
            delete data['pricingIncomeSharingId'];

            data['isActive'] = requestedDataById?.isActive;
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const slabFeeType = [
        { name: 'Fixed', value: 'FF' },
        { name: 'Percentage', value: 'PF' },
        { name: 'Conditional', value: 'CF' }
    ];
    const addVelocity = () => {
        formik.setFieldValue('tblPricingVelocities', [
            ...formik.values.tblPricingVelocities,
            {
                noOfFreeTrxn: '',
                frequency: '',
                velocityRule: '',
                velocityAmount: '',
                chargeOnDifferential: 'No'
            }
        ]);
    };

    const removeCard = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingVelocities];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
        if (index === 0) {
            formik.setFieldValue('velocity', 'N');
            const updatedData = [...formik.values.tblPricingVelocities];
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
    };

    const addPartner = () => {
        formik.setFieldValue('tblPricingIncomeSharings', [
            ...formik.values.tblPricingIncomeSharings,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };
    const removePartnerCard = (index) => {
        if (index > 1) {
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            updatedData.splice(index - 1, 1);
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
        if (index === 1) {
            formik.setFieldValue('incomeSharing', 'N');
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
    };

    const getAllLovsForPricing = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getfeeprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForPricing();
    }, []);
    const accountTypeLov = allLovs?.accountype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const segmentLov = allLovs?.segment?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const channelLov = allLovs?.channel?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const transactionsLov = allLovs?.transactions?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const chargingPartyLov = allLovs?.chargingParty?.map((data) => {
        return {
            lovId: data?.code,
            name: data?.name
        };
    });

    const glJsonLov = allLovs?.glAccounts?.map((data) => {
        return {
            lovId: data?.accountId,
            name: `${data?.accountNo}-${data?.accountTitle}`,
            accountTitle: data?.accountTitle
        };
    });

    const feeTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];
    const taxTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];

    const frequencyJson = [
        {
            value: 'D',
            label: 'Daily'
        },
        {
            value: 'M',
            label: 'Monthly'
        },
        {
            value: 'Y',
            label: 'Yearly'
        }
    ];

    const sharingTypeJson = [
        {
            value: 'F',
            label: 'Fixed'
        },
        {
            value: 'P',
            label: 'Percentage'
        }
    ];
    const taxStatusJson = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non-Filer'
        }
    ];
    const conditionLov = [
        {
            value: 'H',
            label: 'Higher'
        },
        {
            value: 'L',
            label: 'Lower'
        }
    ];
    const addSlabHybird = () => {
        formik.setFieldValue('tblPricingSlabs', [
            ...formik.values.tblPricingSlabs,
            {
                startSlab: '',
                endSlab: '',
                feeAmount: '',
                slabFeeTypeCode: '',
                feePercentage: '',
                feeCondition: ''
            }
        ]);
    };

    const removeSlabHybird = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>VIEW DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentIds" className={classNames({ 'p-error': isFormFieldValid('segmentIds') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <MultiSelect
                                    disabled={true}
                                    id="segmentIds"
                                    placeholder="Select User Type"
                                    options={segmentLov}
                                    optionLabel="name"
                                    name="segmentIds"
                                    optionValue="lovId"
                                    value={formik.values.segmentIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentIds') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('segmentIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileName" className={classNames({ 'p-error': isFormFieldValid('pricingProfileName') }, 'Label__Text')}>
                                    Pricing Profile Name
                                </label>
                                <InputText
                                    disabled={true}
                                    id="pricingProfileName"
                                    placeholder="Enter Pricing Profile Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('pricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIds" className={classNames({ 'p-error': isFormFieldValid('channelIds') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <MultiSelect
                                    disabled={true}
                                    id="channelIds"
                                    placeholder="Select Partner Group"
                                    options={channelLov}
                                    optionLabel="name"
                                    name="channelIds"
                                    optionValue="lovId"
                                    value={formik.values.channelIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channelIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transaction" className={classNames({ 'p-error': isFormFieldValid('transaction') }, 'Label__Text')}>
                                    Transaction
                                </label>
                                <MultiSelect
                                    // disabled={true}
                                    id="tblProduct"
                                    placeholder="Select Transaction"
                                    options={transactionsLov}
                                    optionLabel="name"
                                    name="tblProduct"
                                    optionValue="lovId"
                                    value={formik.values.tblProduct || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblProduct') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transaction')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingParty" className={classNames({ 'p-error': isFormFieldValid('chargingParty') }, 'Label__Text')}>
                                    Charging Party
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="chargingParty"
                                    placeholder="Select Charging Party"
                                    options={chargingPartyLov}
                                    optionLabel="name"
                                    name="chargingParty"
                                    optionValue="lovId"
                                    value={formik.values.chargingParty || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingParty') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('chargingParty')}
                            </div>
                        </div>
                        {formik.values.chargingParty === 'P' ? (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="payeeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('payeeGlAccountId') }, 'Label__Text')}>
                                        Payee GL Account
                                    </label>
                                    <Dropdown
                                        disabled={true}
                                        id="payeeGlAccountId"
                                        placeholder="Select Charging Party"
                                        options={glJsonLov}
                                        optionLabel="name"
                                        filter
                                        name="payeeGlAccountId"
                                        optionValue="lovId"
                                        value={formik.values.payeeGlAccountId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('payeeGlAccountId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('payeeGlAccountId')}
                                </div>
                            </div>
                        ) : null}
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="startDate"
                                    placeholder="Enter startDate"
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="endDate"
                                    placeholder="Enter endDate"
                                    name="endDate"
                                    type="date"
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeCalculationType" className={classNames({ 'p-error': isFormFieldValid('feeCalculationType') }, 'Label__Text')}>
                                    Type of Fee Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="feeCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={feeTypeJson}
                                    optionLabel="label"
                                    name="feeCalculationType"
                                    optionValue="value"
                                    value={formik.values.feeCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('feeCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Type of Tax Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="taxCalculationType"
                                    placeholder="Select Type of Tax Calculation"
                                    options={taxTypeJson}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>Slab Wise Hybrid</h1>
                                </div>
                                <div className="grid">
                                    {formik?.values?.tblPricingSlabs?.map((id, index) => (
                                        <div key={`id-${index}`} style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className=" col-12 card">
                                            <div className="grid">
                                                <div className="grid col-11">
                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                        <div className="p-field">
                                                            <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].startSlab`) }, 'Label__Text')}>
                                                                Start Slab <span className="Label__Required">*</span>
                                                            </label>
                                                            <InputNumber
                                                                disabled={true}
                                                                min={0}
                                                                maxLength={15}
                                                                maxFractionDigits={15}
                                                                inputId={`startSlab${index}`}
                                                                placeholder="Enter Start Slab"
                                                                name={`tblPricingSlabs[${index}].startSlab`}
                                                                value={id.startSlab || ''}
                                                                onValueChange={formik.handleChange}
                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].startSlab`) }, 'Input_Number')}
                                                            />

                                                            <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.startSlab && formik.errors.tblPricingSlabs?.[index]?.startSlab}</small>
                                                        </div>
                                                    </div>

                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                        <div className="p-field">
                                                            <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].endSlab`) }, 'Label__Text')}>
                                                                End Slab <span className="Label__Required">*</span>
                                                            </label>
                                                            <InputNumber
                                                                disabled={true}
                                                                min={0}
                                                                maxLength={15}
                                                                maxFractionDigits={15}
                                                                inputId={`endSlab${index}`}
                                                                placeholder="Enter End Slab"
                                                                name={`tblPricingSlabs[${index}].endSlab`}
                                                                value={id.endSlab || ''}
                                                                onValueChange={formik.handleChange}
                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].endSlab`) }, 'Input_Number')}
                                                            />
                                                            <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.endSlab && formik.errors.tblPricingSlabs?.[index]?.endSlab}</small>
                                                        </div>
                                                    </div>

                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                        <div className="p-field">
                                                            <label htmlFor={`slabFeeTypeCode${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].slabFeeTypeCode`) }, 'Label__Text')}>
                                                                Slab Fee Type
                                                                <span className="Label__Required">*</span>
                                                            </label>
                                                            <Dropdown
                                                                disabled={true}
                                                                id={`slabFeeTypeCode${index}`}
                                                                placeholder="Select Slab Commission Type"
                                                                options={slabFeeType}
                                                                optionLabel="name"
                                                                name={`tblPricingSlabs[${index}].slabFeeTypeCode`}
                                                                optionValue="value"
                                                                value={formik.values.tblPricingSlabs[index].slabFeeTypeCode || ''}
                                                                onChange={formik.handleChange}
                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].slabFeeTypeCode`) }, 'Dropdown__Round')}
                                                            />
                                                            <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.slabFeeTypeCode && formik.errors.tblPricingSlabs?.[index]?.slabFeeTypeCode}</small>
                                                        </div>
                                                    </div>

                                                    {formik.values.tblPricingSlabs[index].slabFeeTypeCode === 'FF' && (
                                                        <>
                                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                <div className="p-field">
                                                                    <label htmlFor={`feeAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].feeAmount`) }, 'Label__Text')}>
                                                                        Enter Fee <span className="Label__Required">*</span>
                                                                    </label>
                                                                    <InputNumber
                                                                        disabled={true}
                                                                        min={0}
                                                                        maxLength={15}
                                                                        maxFractionDigits={15}
                                                                        inputId={`feeAmount${index}`}
                                                                        placeholder="Enter Commission Amount"
                                                                        name={`tblPricingSlabs[${index}].feeAmount`}
                                                                        value={id.feeAmount || ''}
                                                                        onValueChange={formik.handleChange}
                                                                        className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feeAmount`) }, 'Input_Number')}
                                                                    />
                                                                    <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feeAmount && formik.errors.tblPricingSlabs?.[index]?.feeAmount}</small>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {formik.values.tblPricingSlabs[index].slabFeeTypeCode === 'PF' && (
                                                        <>
                                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                <div className="p-field">
                                                                    <label htmlFor={`feePercentage${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].feePercentage`) }, 'Label__Text')}>
                                                                        Enter Percentage <span className="Label__Required">*</span>
                                                                    </label>
                                                                    <InputNumber
                                                                        disabled={true}
                                                                        min={0}
                                                                        maxLength={15}
                                                                        maxFractionDigits={15}
                                                                        inputId={`feePercentage${index}`}
                                                                        placeholder="Enter Commission Amount"
                                                                        name={`tblPricingSlabs[${index}].feePercentage`}
                                                                        value={id.feePercentage || ''}
                                                                        onValueChange={formik.handleChange}
                                                                        className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feePercentage`) }, 'Input_Number')}
                                                                    />
                                                                    <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feePercentage && formik.errors.tblPricingSlabs?.[index]?.feePercentage}</small>
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                    {formik.values.tblPricingSlabs[index].slabFeeTypeCode === 'CF' && (
                                                        <>
                                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                <div className="p-field">
                                                                    <label htmlFor={`feePercentage${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].feePercentage`) }, 'Label__Text')}>
                                                                        Enter Percentage <span className="Label__Required">*</span>
                                                                    </label>
                                                                    <InputNumber
                                                                        disabled={true}
                                                                        min={0}
                                                                        maxLength={15}
                                                                        maxFractionDigits={15}
                                                                        inputId={`feePercentage${index}`}
                                                                        placeholder="Enter Commission Amount"
                                                                        name={`tblPricingSlabs[${index}].feePercentage`}
                                                                        value={id.feePercentage || ''}
                                                                        onValueChange={formik.handleChange}
                                                                        className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feePercentage`) }, 'Input_Number')}
                                                                    />
                                                                    <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feePercentage && formik.errors.tblPricingSlabs?.[index]?.feePercentage}</small>
                                                                </div>
                                                            </div>
                                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                <div className="p-field">
                                                                    <label htmlFor={`feeAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].feeAmount`) }, 'Label__Text')}>
                                                                        Enter Fee <span className="Label__Required">*</span>
                                                                    </label>
                                                                    <InputNumber
                                                                        disabled={true}
                                                                        min={0}
                                                                        maxLength={15}
                                                                        maxFractionDigits={15}
                                                                        inputId={`feeAmount${index}`}
                                                                        placeholder="Enter Commission Amount"
                                                                        name={`tblPricingSlabs[${index}].feeAmount`}
                                                                        value={id.feeAmount || ''}
                                                                        onValueChange={formik.handleChange}
                                                                        className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feeAmount`) }, 'Input_Number')}
                                                                    />
                                                                    <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feeAmount && formik.errors.tblPricingSlabs?.[index]?.feeAmount}</small>
                                                                </div>
                                                            </div>
                                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                                <div className="p-field">
                                                                    <label htmlFor={`feeCondition${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].feeCondition`) }, 'Label__Text')}>
                                                                        Add Condition<span className="Label__Required">*</span>
                                                                    </label>
                                                                    <Dropdown
                                                                        disabled={true}
                                                                        id={`feeCondition${index}`}
                                                                        placeholder="Add Condition"
                                                                        options={conditionLov}
                                                                        optionLabel="label"
                                                                        name={`tblPricingSlabs[${index}].feeCondition`}
                                                                        optionValue="value"
                                                                        value={formik.values.tblPricingSlabs[index].feeCondition || ''}
                                                                        onChange={formik.handleChange}
                                                                        className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feeCondition`) }, 'Dropdown__Round')}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="col-1">
                                                    <div className="flex flex-column align-items-center justify-content-center">
                                                        <Button
                                                            disabled={true}
                                                            icon="pi pi-plus"
                                                            className="p-button-rounded"
                                                            aria-label="Filter"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                addSlabHybird();
                                                            }}
                                                        />
                                                        <Button
                                                            disabled={true}
                                                            //disabled={formik.values.tblPricingSlabs.length === 1 ? true : false}
                                                            icon="pi pi-minus"
                                                            className="p-button-rounded mt-2 p-button-danger"
                                                            aria-label="Filter"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeSlabHybird(index);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                    {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="startSlab" className={classNames({ 'p-error': isFormFieldValid('startSlab') }, 'Label__Text')}>
                                                Start Slab
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="startSlab"
                                                placeholder="Enter Start Slab "
                                                name="startSlab"
                                                value={formik.values.startSlab || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('startSlab') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('startSlab')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="endSlab" className={classNames({ 'p-error': isFormFieldValid('endSlab') }, 'Label__Text')}>
                                                End Slab
                                            </label>
                                            <InputText
                                                disabled={true}
                                                id="endSlab"
                                                placeholder="Enter End Slab "
                                                name="endSlab"
                                                value={formik.values.endSlab || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('endSlab') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('endSlab')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="slabFeeTypeCode" className={classNames({ 'p-error': isFormFieldValid('slabFeeTypeCode') }, 'Label__Text')}>
                                                Slab Fee Type
                                            </label>
                                            <Dropdown
                                                disabled={true}
                                                id="slabFeeTypeCode"
                                                placeholder="Select End Slab "
                                                options={slabFeeType}
                                                optionLabel="name"
                                                name="slabFeeTypeCode"
                                                optionValue="value"
                                                value={formik.values.slabFeeTypeCode || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('slabFeeTypeCode') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('slabFeeTypeCode')}
                                        </div>
                                    </div>
                                    {formik.values.slabFeeTypeCode === 'FF' && (
                                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="feeAmount" className={classNames({ 'p-error': isFormFieldValid('feeAmount') }, 'Label__Text')}>
                                                    Enter fee
                                                </label>
                                                <InputText
                                                    disabled={true}
                                                    id="feeAmount"
                                                    placeholder="Enter fee"
                                                    name="feeAmount"
                                                    value={formik?.values?.feeAmount || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('feeAmount') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('feeAmount')}
                                            </div>
                                        </div>
                                    )}
                                    {formik.values.slabFeeTypeCode === 'PF' && (
                                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="feePercentage" className={classNames({ 'p-error': isFormFieldValid('feePercentage') }, 'Label__Text')}>
                                                    Enter Percentage
                                                </label>
                                                <InputText
                                                    disabled={true}
                                                    id="feePercentage"
                                                    placeholder="Enter Percentage"
                                                    name="feePercentage"
                                                    value={formik?.values?.feePercentage || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('feePercentage') }, 'Input__Round')}
                                                />
                                                {getFormErrorMessage('feePercentage')}
                                            </div>
                                        </div>
                                    )}
                                    {formik.values.slabFeeTypeCode === 'CF' && (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="feePercentage" className={classNames({ 'p-error': isFormFieldValid('feePercentage') }, 'Label__Text')}>
                                                        Enter Percentage
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id="feePercentage"
                                                        placeholder="Enter feePercentage"
                                                        name="feePercentage"
                                                        value={formik?.values?.feePercentage || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('feePercentage') }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage('feePercentage')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="feeAmount" className={classNames({ 'p-error': isFormFieldValid('feeAmount') }, 'Label__Text')}>
                                                        Enter Fixed Amount
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id="feeAmount"
                                                        placeholder="Enter Fixed Amount"
                                                        name="feeAmount"
                                                        value={formik?.values?.feeAmount || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('feeAmount') }, 'Input__Round')}
                                                    />
                                                    {getFormErrorMessage('feeAmount')}
                                                </div>
                                            </div>
                                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="feeCondition" className={classNames({ 'p-error': isFormFieldValid('feeCondition') }, 'Label__Text')}>
                                                        Add Condition
                                                    </label>
                                                    <Dropdown
                                                        disabled={true}
                                                        id="feeCondition"
                                                        placeholder="Add Condition"
                                                        options={conditionLov}
                                                        optionLabel="label"
                                                        name="feeCondition"
                                                        optionValue="value"
                                                        value={formik.values.feeCondition || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('feeCondition') }, 'Dropdown__Round')}
                                                    />

                                                    {getFormErrorMessage('feeCondition')}
                                                </div>
                                            </div>
                                        </>
                                    )} */}

                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div className="">
                                                    <label htmlFor="velocity" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                        Add Velocity
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="N" onChange={formik.handleChange} checked={formik.values.velocity === 'N'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="Y" onChange={formik.handleChange} checked={formik.values.velocity === 'Y'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik?.values?.velocity === 'Y' && (
                                        <>
                                            <>
                                                {formik?.values?.tblPricingVelocities?.map((velocityData, index) => {
                                                    return (
                                                        <div key={`id-${index}`} className="col-12 card">
                                                            <div className="grid">
                                                                <div className=" grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityRule${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Label__Text')}>
                                                                                Add Velocity Rule
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityRule${index}`}
                                                                                placeholder="Enter Velocity Rule"
                                                                                name={`tblPricingVelocities[${index}].velocityRule`}
                                                                                value={velocityData.velocityRule || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityRule`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`frequency${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Label__Text')}>
                                                                                Enter Frequency
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`frequency${index}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={frequencyJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingVelocities[${index}].frequency`}
                                                                                optionValue="value"
                                                                                value={velocityData.frequency || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].frequency`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Label__Text')}>
                                                                                Velocity Amount
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityAmount${index}`}
                                                                                placeholder="Enter velocity amount"
                                                                                name={`tblPricingVelocities[${index}].velocityAmount`}
                                                                                value={velocityData.velocityAmount || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Input__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityAmount`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`noOfFreeTrxn${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Label__Text')}>
                                                                                Enter Number of Free Trx
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`noOfFreeTrxn${index}`}
                                                                                placeholder="Enter Number of Free Trx"
                                                                                name={`tblPricingVelocities[${index}].noOfFreeTrxn`}
                                                                                value={velocityData.noOfFreeTrxn || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].noOfFreeTrxn`)}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3 mt-5">
                                                                        <div className="flex flex-column gap-2">
                                                                            <div className="flex align-items-center">
                                                                                <label htmlFor="chargeOnDifferential" className={classNames({ 'p-error': isFormFieldValid('chargeOnDifferential') }, 'Label__Text')}>
                                                                                    Charge on Differential
                                                                                </label>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities${index}_n`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="N"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'N'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities[${index}]_y`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="Y"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'Y'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>

                                                                                {getFormErrorMessage('chargeOnDifferential')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addVelocity();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removeCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        </>
                                    )}
                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div>
                                                    <label htmlFor="incomeSharing" className={classNames({ 'p-error': isFormFieldValid('incomeSharing') }, 'Label__Text')}>
                                                        Income Sharing
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="N" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'N'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="Y" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'Y'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>

                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik.values.incomeSharing === 'Y' && (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="noOfPartners" className={classNames({ 'p-error': isFormFieldValid('noOfPartners') }, 'Label__Text')}>
                                                        Number of Partners
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id="noOfPartners"
                                                        placeholder="Select Partners "
                                                        name="noOfPartners"
                                                        value={formik.values.noOfPartners || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfPartners') }, 'Input__Round')}
                                                    />

                                                    {getFormErrorMessage('noOfPartners')}
                                                </div>
                                            </div>
                                            {formik?.values?.tblPricingIncomeSharings?.map((partnerData, index) => {
                                                index = index + 1;
                                                return (
                                                    <div key={`id-${index - 1}`} className="col-12">
                                                        <div style={{ padding: '2rem' }} className="col-12 card">
                                                            <div className="Form__Header">
                                                                <h1>Sharing {index}</h1>
                                                            </div>

                                                            <div className="grid">
                                                                <div className="grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingType${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Label__Text')}>
                                                                                Sharing Type
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`sharingType${index - 1}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={sharingTypeJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingType`}
                                                                                optionValue="value"
                                                                                value={partnerData.sharingType || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingType`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`incomeGlAccountId${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Label__Text')}
                                                                            >
                                                                                Income GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`incomeGlAccountId${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.incomeGlAccountId || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    const selectedValue = e.value;
                                                                                    const selectedAccount = glJsonLov.find((item) => item.lovId === selectedValue);
                                                                                    const updatedSharingAccount = [...sharingAccount];
                                                                                    updatedSharingAccount[index - 1] = selectedAccount ? selectedAccount.accountTitle : '';
                                                                                    setSharingAccount(updatedSharingAccount);
                                                                                }}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].incomegl`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingDetails${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Label__Text')}>
                                                                                Sharing Details
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingDetails${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingDetails`}
                                                                                value={(partnerData.sharingDetails = sharingAccount[index - 1] || partnerData.sharingDetails || '')}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingDetails`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`sharingPercentage${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Label__Text')}
                                                                            >
                                                                                Fee
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingPercentage${index - 1}`}
                                                                                placeholder="Enter Fee"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingPercentage`}
                                                                                value={partnerData.sharingPercentage || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`taxStatus${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Label__Text')}>
                                                                                Tax Status
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`taxStatus${index - 1}`}
                                                                                placeholder="Select Type of Tax Status "
                                                                                options={taxStatusJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].taxStatus`}
                                                                                optionValue="value"
                                                                                value={partnerData.taxStatus || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].taxStatus`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`whtGlAccountId${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Label__Text')}>
                                                                                WHT GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`whtGlAccountId${index - 1}`}
                                                                                placeholder="Select Type of WHT GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.whtGlAccountId || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addPartner();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removePartnerCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}

                                    {formik.values.incomeSharing === 'Y' ? null : (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="incomeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('incomeGlAccountId') }, 'Label__Text')}>
                                                        Income GL
                                                    </label>
                                                    <Dropdown
                                                        disabled={true}
                                                        id="incomeGlAccountId"
                                                        placeholder="Select Income GL"
                                                        options={glJsonLov}
                                                        optionLabel="name"
                                                        filter
                                                        name="incomeGlAccountId"
                                                        optionValue="lovId"
                                                        value={formik.values.incomeGlAccountId || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('incomeGlAccountId') }, 'Dropdown__Round')}
                                                    />

                                                    {getFormErrorMessage('incomeGlAccountId')}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="attachspecialprofile" className={classNames({ 'p-error': isFormFieldValid('attachspecialprofile') }, 'Label__Text')}>
                                    Attach Special Profile
                                </label>
                                <Dropdown
                                    id="attachspecialprofile"
                                    placeholder="Select Attach Special Profile"
                                    //options={partnerGroupLovData}
                                    optionLabel="name"
                                    name="attachspecialprofile"
                                    optionValue="lovId"
                                    value={formik.values.attachspecialprofile || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('attachspecialprofile') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('attachspecialprofile')}
                            </div>
                        </div> */}
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" disabled={true} name="isActive" value={requestedDataById?.isActive === 'Y' ? true : false} checked={requestedDataById?.isActive === 'Y' ? true : false} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    disabled={true}
                                    rows={5}
                                    cols={30}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={checkerComments}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Okay" disabled={loading} loadingIcon={loadingIcon} onClick={() => navigate('/requestchecker')} className="Btn__Dark" />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default ViewPricingSlabWiseHybridChecker;
