import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { handlePostRequestForSAF } from '../../../services/PostTemplateForSAF';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function AddSwitchConfiguration() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [switchConnectioData, setSwitchConnectioData] = useState([]);
    const [switchMtiData, setSwitchMtiData] = useState([]);
    const [queueConfigTableData, setQueueConfigTableData] = useState([]);
    const [queueConfigData, setQueueConfigData] = useState({
        expiryTime: '',
        size: '',
        mtiId: ''
    });
    const [queueConfigError, setQueueConfigError] = useState({
        expiryTime: '',
        size: '',
        mtiId: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];

    useEffect(() => {
        getSwitchConnectionData();
        getSwitchMtiData();
    }, []); // eslint-disable-line

    const alphanumericWithSpacesRegex = /^[a-zA-Z0-9\/\-_]+(?: [a-zA-Z0-9\/\-_]+)*$/;
    const ipAddressRegex = /^[0-9.]+$/;

    const validationSchema = Yup.object().shape({
        // clientCode: Yup.string().required('Client Code is required').matches(alphanumericWithSpacesRegex, 'Client Code must be alphanumeric and should not contain spaces'),
        socketName: Yup.string().required('Socket Name is required').matches(alphanumericWithSpacesRegex, 'Socket Name must be alphanumeric and should not contain spaces'),
        clientName: Yup.string().required('Client Name is required').matches(alphanumericWithSpacesRegex, 'Client Name must be alphanumeric and should not contain spaces'),
        switchConnectionId: Yup.string().required('Switch Connection is required'),
        socketIp: Yup.string().required('Socket IP is required').matches(ipAddressRegex, 'Please enter a valid IP Address'),
        socketUrl: Yup.string().matches(/^(http:\/\/|https:\/\/).+$/, 'Please enter a valid URL starting with "http://" or "https://"')
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            clientCode: '',
            clientName: '',
            switchConnectionId: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: ''
        },

        onSubmit: async (data) => {
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        client: { clientCode: data?.clientCode, clientName: data?.clientName, switchConnectionId: data?.switchConnectionId },
                        safConfig: [{ safEntries: data?.safRetries, safEntriesTime: data?.safRetriesTime }],
                        socket: [{ name: data?.socketName, ip: data?.socketIp, port: data?.socketPort, type: data?.socketType, url: data?.socketUrl, status: 'Y' }],
                        queue: queueConfigTableData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequestForSAF(newData, '/communication-service/client/create', true, false, 'switchconfiguration'));
        }
    });

    const getSwitchConnectionData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getswitchconnections');
        setSwitchConnectioData(res?.payLoad);
    };

    const getSwitchMtiData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getswitchmti');
        setSwitchMtiData(res?.payLoad);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledQueueConfigAdd = (e) => {
        e.preventDefault();

        const errors = {
            expiryTime: '',
            size: '',
            mtiId: ''
        };

        // Check for duplicate Id
        if (queueConfigTableData?.some((item) => item?.mtiId === queueConfigData?.mtiId)) {
            errors.mtiId = 'This MTI is already in use.';
        }

        setQueueConfigError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newQueueConfigTableData = {
                expiryTime: queueConfigData.expiryTime,
                size: queueConfigData.size,
                mtiId: queueConfigData.mtiId,
                status: 'Y'
            };
            setQueueConfigTableData([...queueConfigTableData, newQueueConfigTableData]);

            setQueueConfigData({ expiryTime: '', size: '', mtiId: '' });
        }
    };

    const handledFieldInputQueueConfigData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setQueueConfigData({ ...queueConfigData, [name]: value });

        // Clear the error message for the field being edited
        setQueueConfigError({
            ...queueConfigError,
            [name]: ''
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeletequeueConfigData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const mtiBodyTemplate = (rowData) => {
        const filteredMti = switchMtiData?.filter((item) => item.lovId == rowData?.mtiId);

        return (
            <p>
                <b>{filteredMti[0]?.name}</b>
            </p>
        );
    };

    const handledDeletequeueConfigData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = queueConfigTableData?.filter((value) => value.mtiId !== rowData.mtiId);
        setQueueConfigTableData(newFilterData);
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className={classNames({ 'p-error': isFormFieldValid('clientCode') }, 'Label__Text')}>
                                    Client Code
                                </label>
                                <InputText
                                    maxLength={10}
                                    keyfilter="int"
                                    id="clientCode"
                                    name="clientCode"
                                    placeholder="Enter Client Code"
                                    value={formik?.values?.clientCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientCode') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('clientCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className={classNames({ 'p-error': isFormFieldValid('clientName') }, 'Label__Text')}>
                                    Client Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={30}
                                    id="clientName"
                                    name="clientName"
                                    placeholder="Enter Client Name"
                                    value={formik?.values?.clientName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientName') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('clientName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="switchConnectionId" className={classNames({ 'p-error': isFormFieldValid('switchConnectionId') }, 'Label__Text')}>
                                    Switch Connection<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="switchConnectionId"
                                    placeholder="Select Switch Connection"
                                    options={switchConnectioData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    name="switchConnectionId"
                                    value={formik.values.switchConnectionId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('switchConnectionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('switchConnectionId')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className={classNames({ 'p-error': isFormFieldValid('safRetries') }, 'Label__Text')}>
                                    SAF Retries
                                </label>
                                <InputText
                                    maxLength={2}
                                    id="safRetries"
                                    name="safRetries"
                                    keyfilter="int"
                                    placeholder="Enter SAF Retries"
                                    value={formik?.values?.safRetries || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetries') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('safRetries')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className={classNames({ 'p-error': isFormFieldValid('safRetriesTime') }, 'Label__Text')}>
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText
                                    maxLength={10}
                                    id="safRetriesTime"
                                    name="safRetriesTime"
                                    keyfilter="int"
                                    placeholder="Enter SAF Retries Time"
                                    value={formik?.values?.safRetriesTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetriesTime') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('safRetriesTime')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className={classNames({ 'p-error': isFormFieldValid('socketName') }, 'Label__Text')}>
                                    Socket Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={10}
                                    id="socketName"
                                    name="socketName"
                                    placeholder="Enter Socket Name"
                                    value={formik?.values?.socketName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketName') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className={classNames({ 'p-error': isFormFieldValid('socketIp') }, 'Label__Text')}>
                                    Socket IP <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={15}
                                    id="socketIp"
                                    name="socketIp"
                                    placeholder="Enter IP Address"
                                    value={formik?.values?.socketIp || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketIp') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketIp')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className={classNames({ 'p-error': isFormFieldValid('socketPort') }, 'Label__Text')}>
                                    Port
                                </label>
                                <InputText
                                    maxLength={4}
                                    id="socketPort"
                                    placeholder="Enter Port Number"
                                    keyfilter="int"
                                    value={formik.values.socketPort}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketPort') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('socketPort')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className={classNames({ 'p-error': isFormFieldValid('socketType') }, 'Label__Text')}>
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketType') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('socketType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className={classNames({ 'p-error': isFormFieldValid('socketUrl') }, 'Label__Text')}>
                                    Socket URL
                                </label>
                                <InputText
                                    maxLength={50}
                                    id="socketUrl"
                                    name="socketUrl"
                                    placeholder="Enter Socket URL"
                                    value={formik?.values?.socketUrl || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketUrl') }, 'Input__Round')}
                                    aria-required
                                />
                                {getFormErrorMessage('socketUrl')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="expiryTime" className={classNames({ 'p-error': isFormFieldValid('expiryTime') }, 'Label__Text')}>
                                        Expiry Time In (in milli's)
                                    </label>
                                    <InputText
                                        maxLength={10}
                                        id="expiryTime"
                                        name="expiryTime"
                                        // keyfilter="int"
                                        placeholder="Enter Expiry Time"
                                        value={queueConfigData?.expiryTime || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryTime') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('expiryTime')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="size" className={classNames({ 'p-error': isFormFieldValid('size') }, 'Label__Text')}>
                                        Queue Size
                                    </label>
                                    <InputText
                                        maxLength={2}
                                        id="size"
                                        name="size"
                                        keyfilter="int"
                                        placeholder="Enter Queue Size"
                                        value={queueConfigData?.size || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('size') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('size')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="mtiId" className={classNames({ 'p-error': isFormFieldValid('mtiId') }, 'Label__Text')}>
                                        MTI
                                    </label>
                                    <Dropdown
                                        id="mtiId"
                                        placeholder="Select MTI"
                                        options={switchMtiData}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        name="mtiId"
                                        value={queueConfigData.mtiId}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('mtiId') }, 'Dropdown__Round')}
                                    />

                                    {/* {getFormErrorMessage('mtiId')} */}
                                    <small className="p-error">{queueConfigError.mtiId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" loadingIcon={loadingIcon} label="Add" onClick={handledQueueConfigAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={queueConfigTableData}>
                                    <Column field="expiryTime" header="Queue Expiry Time" />
                                    <Column field="size" header="Queue Size" />
                                    <Column header="MTI" body={mtiBodyTemplate} />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" label="Submit" className="Btn__Dark" />
                        <Button
                            onClick={() => {
                                navigate('/switchconfiguration');
                            }}
                            label="Cancel"
                            className="Btn__Transparent"
                        />
                    </div>
                </form>
            </div>
        </>
    );
}
export default AddSwitchConfiguration;
