import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { handleGetRequest } from '../../../services/GetTemplate';
import { MultiSelect } from 'primereact/multiselect';

const PointRedemptionDialog = ({ onHidePointRedemption, editable, pointRedemptionRowData }) => {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-save');

    const [transactionTypeData, setTransactionTypeData] = useState([]);

    useEffect(() => {
        formik.setFieldValue('voucherValue', pointRedemptionRowData?.voucherValue?.toString());
        formik.setFieldValue('voucherName', pointRedemptionRowData?.voucherName?.toString());
        formik.setFieldValue('pointValue', pointRedemptionRowData?.pointValue?.toString());

        formik.setFieldValue('dateFrom', pointRedemptionRowData?.dateFrom?.slice(0, 10));
        formik.setFieldValue('dateTo', pointRedemptionRowData?.dateTo?.slice(0, 10));
        formik.setFieldValue('expiry', pointRedemptionRowData?.expiry?.toString());

        const transTypeData = pointRedemptionRowData?.productId?.map((value) => {
            return value?.toString();
        });

        formik.setFieldValue('transType', transTypeData);
    }, [pointRedemptionRowData]); // eslint-disable-line

    useEffect(() => {
        if (!editable) {
            formik.resetForm();
        }
    }, [editable]); // eslint-disable-line

    const getTransactionTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setTransactionTypeData(res?.payLoad);
    };

    useEffect(() => {
        getTransactionTypeData();
    }, []); // eslint-disable-line

    const dispatch = useDispatch();

    const validationSchema = Yup.object().shape({
        voucherName: Yup.string().required('This field is required.').nullable(),
        voucherValue: Yup.number().required('This field is required.').typeError('Please enter numeric data').positive('Please enter a value greater than 0').nullable(),
        pointValue: Yup.number().required('This field is required.').typeError('Please enter numeric data').positive('Please enter a value greater than 0').nullable(),
        expiry: Yup.number().max(365, 'Value cannot exceed 365').nullable()
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            voucherValue: '',
            voucherName: '',
            pointValue: '',
            transType: [],
            expiry: '',
            isActive: false,
            statusId: 0
        },
        // validate: (data) => {
        //     let errors = {};

        //     if (data.transType?.length === 0 || ['', null, undefined].includes(data.transType)) {
        //         errors.transType = 'Transaction Type is required.';
        //     }

        //     return errors;
        // },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['expiry'] = parseInt(data?.expiry);
            data['voucherValue'] = parseInt(data?.voucherValue);

            if (editable) {
                data['zmilesVoucherId'] = pointRedemptionRowData?.zmilesVoucherId;
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (editable) {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/updateVoucher`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHidePointRedemption();
                }
            } else {
                const res = await dispatch(handlePostRequest(newData, `/zconfig/v1/zmiles/saveVoucher`, true, false));
                if (res?.responseCode === '330000') {
                    formik.resetForm();
                    onHidePointRedemption();
                }
            }
            setloading(false);
            setloadingIcon('pi pi-save');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherName" className={classNames({ 'p-error': isFormFieldValid('voucherName') }, 'Label__Text')}>
                                    Voucher Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={25}
                                    id="voucherName"
                                    placeholder="Enter Voucher Name"
                                    name="voucherName"
                                    value={formik?.values?.voucherName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="voucherValue" className={classNames({ 'p-error': isFormFieldValid('voucherValue') }, 'Label__Text')}>
                                    Voucher Value<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="voucherValue"
                                    placeholder="Enter Voucher Value"
                                    name="voucherValue"
                                    value={formik?.values?.voucherValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('voucherValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('voucherValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="pointValue" className={classNames({ 'p-error': isFormFieldValid('pointValue') }, 'Label__Text')}>
                                    Point Value<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="pointValue"
                                    placeholder="Enter Point Value"
                                    name="pointValue"
                                    value={formik?.values?.pointValue}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pointValue') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('pointValue')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="transType" className={classNames({ 'p-error': isFormFieldValid('transType') }, 'Label__Text')}>
                                    Transaction Type
                                </label>
                                <MultiSelect
                                    id="transType"
                                    placeholder="Select Transaction Type"
                                    options={transactionTypeData}
                                    optionLabel="name"
                                    name="transType"
                                    filter
                                    optionValue="lovId"
                                    value={formik.values.transType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('transType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="expiry" className={classNames({ 'p-error': isFormFieldValid('expiry') }, 'Label__Text')}>
                                    Expiry
                                </label>
                                <InputText
                                    maxLength={9}
                                    id="expiry"
                                    placeholder="Enter Expiry"
                                    name="expiry"
                                    value={formik?.values?.expiry}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiry') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('expiry')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button disabled={loading} type="submit" label={editable ? 'Update' : 'Add'} className="Btn__Dark" />
                    </div>
                </form>
            </div>
        </>
    );
};

export default PointRedemptionDialog;
