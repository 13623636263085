import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';

function PricingSlabWiseFixedOldNewValues({ pricingSlabWiseFixedData }) {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [sharingAccount, setSharingAccount] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const query = new URLSearchParams(search);

    const refTableId = query.get('refTableId');
    const tableName = query.get('tableName');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    useEffect(() => {
        getStatusLov();
    }, []); // eslint-disable-line

    const getPricingFixedbyId = async () => {
        const productsValues = pricingSlabWiseFixedData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = pricingSlabWiseFixedData?.incomeSharingRequests?.map((value) => {
            return {
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });
        const channelValues = pricingSlabWiseFixedData?.tblPricingChannels?.map((values) => {
            return values?.channelId.toString();
        });
        formik.setFieldValue('channelIds', channelValues || '');
        const segmentValues = pricingSlabWiseFixedData?.tblPricingSegments?.map((values) => {
            return values?.segmentId?.toString();
        });
        formik.setFieldValue('segmentIds', segmentValues || '');
        formik.setFieldValue('accountClassificationId', pricingSlabWiseFixedData?.lkpAccountClassification?.accountClassificationId?.toString());
        formik.setFieldValue('pricingProfileName', pricingSlabWiseFixedData?.pricingProfileName);
        formik.setFieldValue('chargingParty', pricingSlabWiseFixedData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', pricingSlabWiseFixedData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', pricingSlabWiseFixedData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', pricingSlabWiseFixedData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', pricingSlabWiseFixedData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', pricingSlabWiseFixedData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('tblPricingVelocities', pricingSlabWiseFixedData?.tblPricingVelocities);
        formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        formik.setFieldValue('noOfPartners', pricingSlabWiseFixedData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', pricingSlabWiseFixedData?.tblAccount2?.accountId?.toString());
        formik.setFieldValue('pricingIncomeSharingId', pricingSlabWiseFixedData?.incomeSharingRequests[0]?.pricingIncomeSharingId);
        formik.setFieldValue('tblPricingSlabs', pricingSlabWiseFixedData?.tblPricingSlabs);

        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (pricingSlabWiseFixedData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (pricingSlabWiseFixedData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    useEffect(() => {
        getPricingFixedbyId();
    }, [pricingSlabWiseFixedData]); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1); // Subtract 1 day
    //const previousDate = currentDate.toISOString().split('T')[0];

    const formik = useFormik({
        initialValues: {
            accountClassificationId: '',
            segmentIds: [],
            pricingProfileName: '',
            channelIds: [],
            chargingParty: '',
            taxCalculationType: '',
            feeCalculationType: '',
            startDate: '',
            endDate: '',
            feeTypeCode: 'SF',
            incomeGlAccountId: '',
            velocity: 'N',
            incomeSharing: 'N',
            tblProduct: [],
            payeeGlAccountId: '',
            feeAmount: '',
            tblPricingSlabs: [
                {
                    pricingSlabId: '',
                    startSlab: '',
                    endSlab: '',
                    feeAmount: ''
                }
            ],
            pricingSlabId: '',
            pricingProfileId: refTableId,
            tblPricingVelocities: [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: 'No'
                }
            ],
            tblPricingIncomeSharings: [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: '',
                    pricingIncomeSharingId: ''
                }
            ],
            action: '',
            checkerComments: '',
            isActive: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            formik.setFieldValue('tblProduct', data?.tblProduct);

            if (data.velocity === 'N') {
                delete data['tblPricingVelocities'];
            }
            if (data.incomeSharing === 'N') {
                delete data['tblPricingIncomeSharings'];
            }
            data['payeeGlAccountId'] = data?.payeeGlAccountId;

            data['tblPricingSlabs'] = formik.values.tblPricingSlabs?.map((item) => ({
                pricingSlabId: item?.pricingSlabId,
                startSlab: item?.startSlab,
                endSlab: item?.endSlab,
                feeAmount: item?.feeAmount
            }));

            const productArray = data?.tblProduct.map((prdctData) => {
                return {
                    productId: prdctData
                };
            });

            data['tblProduct'] = productArray;

            if (data?.velocity === 'Y') {
                data?.tblPricingVelocities?.forEach((item) => {
                    delete item.lastupdatedate;
                    delete item.lastupdateuser;
                    delete item.updateindex;
                    delete item.createdate;
                    delete item.createuser;
                    delete item.isActive;
                });
            }
            if (data?.incomeSharing === 'Y') {
                data['tblPricingIncomeSharings'] = data?.tblPricingIncomeSharings?.map((items) => {
                    return {
                        sharingType: items?.sharingType,
                        incomeGlAccountId: items?.incomeGlAccountId,
                        sharingDetails: items?.sharingDetails,
                        sharingPercentage: items?.sharingPercentage,
                        taxStatus: items?.taxStatus,
                        whtGlAccountId: items?.whtGlAccountId,
                        pricingIncomeSharingId: data?.pricingIncomeSharingId
                    };
                });
            }

            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        pricingProfileRequest: {
                            pricingProfileId: refTableId,
                            pricingProfileName: data['pricingProfileName'] || '',
                            accountClassificationId: data['accountClassificationId'] || '',
                            segmentIds: data['segmentIds'] || '',
                            channelIds: data['channelIds'] || '',
                            chargingParty: data['chargingParty'] || '',
                            payeeGlAccountId: data['payeeGlAccountId'] || '',
                            startDate: data['startDate'] || '',
                            endDate: data['endDate'] || '',
                            feeCalculationType: data['feeCalculationType'] || '',
                            taxCalculationType: data['taxCalculationType'] || '',
                            feeTypeCode: data['feeTypeCode'] || 'SF',
                            velocity: data['velocity'] || '',
                            tblPricingVelocities: data['tblPricingVelocities'],
                            incomeSharing: data['incomeSharing'] || '',
                            noOfPartners: data['noOfPartners'] || '',
                            tblPricingIncomeSharings: data['tblPricingIncomeSharings'],
                            tblProduct: data['tblProduct'],
                            incomeGlAccountId: data['incomeGlAccountId'] || '',
                            tblPricingSlabs: data['tblPricingSlabs'],
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/pricingprofileCheckerAction', true, false, 'requestchecker'));
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addVelocity = () => {
        formik.setFieldValue('tblPricingVelocities', [
            ...formik.values.tblPricingVelocities,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };

    const removeCard = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingVelocities];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
        if (index === 0) {
            formik.setFieldValue('velocity', 'N');
            const updatedData = [...formik.values.tblPricingVelocities];
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
    };

    const addPartner = () => {
        formik.setFieldValue('tblPricingIncomeSharings', [
            ...formik.values.tblPricingIncomeSharings,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };
    const removePartnerCard = (index) => {
        if (index > 1) {
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            updatedData.splice(index - 1, 1);
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
        if (index === 1) {
            formik.setFieldValue('incomeSharing', 'N');
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
    };

    const getAllLovsForPricing = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getfeeprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForPricing();
    }, []);
    const accountTypeLov = allLovs?.accountype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const segmentLov = allLovs?.segment?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const channelLov = allLovs?.channel?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const transactionsLov = allLovs?.transactions?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const chargingPartyLov = allLovs?.chargingParty?.map((data) => {
        return {
            lovId: data?.code,
            name: data?.name
        };
    });

    const glJsonLov = allLovs?.glAccounts?.map((data) => {
        return {
            lovId: data?.accountId,
            name: `${data?.accountNo}-${data?.accountTitle}`,
            accountTitle: data?.accountTitle
        };
    });

    const feeTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];
    const taxTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];

    const frequencyJson = [
        {
            value: 'D',
            label: 'Daily'
        },
        {
            value: 'M',
            label: 'Monthly'
        },
        {
            value: 'Y',
            label: 'Yearly'
        }
    ];

    const sharingTypeJson = [
        {
            value: 'F',
            label: 'Fixed'
        },
        {
            value: 'P',
            label: 'Percentage'
        }
    ];
    const taxStatusJson = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non-Filer'
        }
    ];

    const addSlabWiseFixed = () => {
        formik.setFieldValue('tblPricingSlabs', [
            ...formik.values.tblPricingSlabs,
            {
                startSlab: '',
                endSlab: '',
                feeAmount: ''
            }
        ]);
    };

    const removeSlabWiseFixed = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.tblPricingSlabs];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingSlabs', updatedData);
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>OLD DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentIds" className={classNames({ 'p-error': isFormFieldValid('segmentIds') }, 'Label__Text')}>
                                    Segment
                                </label>
                                <MultiSelect
                                    disabled={true}
                                    id="segmentIds"
                                    placeholder="Select User Type"
                                    options={segmentLov}
                                    optionLabel="name"
                                    name="segmentIds"
                                    optionValue="lovId"
                                    value={formik.values.segmentIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentIds') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('segmentIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileName" className={classNames({ 'p-error': isFormFieldValid('pricingProfileName') }, 'Label__Text')}>
                                    Pricing Profile Name
                                </label>
                                <InputText
                                    disabled={true}
                                    id="pricingProfileName"
                                    placeholder="Enter Pricing Profile Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('pricingProfileName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelIds" className={classNames({ 'p-error': isFormFieldValid('channelIds') }, 'Label__Text')}>
                                    Channel
                                </label>
                                <MultiSelect
                                    disabled={true}
                                    id="channelIds"
                                    placeholder="Select Partner Group"
                                    options={channelLov}
                                    optionLabel="name"
                                    name="channelIds"
                                    optionValue="lovId"
                                    value={formik.values.channelIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channelIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transaction" className={classNames({ 'p-error': isFormFieldValid('transaction') }, 'Label__Text')}>
                                    Transaction
                                </label>
                                <MultiSelect
                                    // disabled={true}
                                    id="tblProduct"
                                    placeholder="Select Transaction"
                                    options={transactionsLov}
                                    optionLabel="name"
                                    name="tblProduct"
                                    optionValue="lovId"
                                    value={formik.values.tblProduct || ''}
                                    // onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblProduct') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('transaction')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingParty" className={classNames({ 'p-error': isFormFieldValid('chargingParty') }, 'Label__Text')}>
                                    Charging Party
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="chargingParty"
                                    placeholder="Select Charging Party"
                                    options={chargingPartyLov}
                                    optionLabel="name"
                                    name="chargingParty"
                                    optionValue="lovId"
                                    value={formik.values.chargingParty || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingParty') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('chargingParty')}
                            </div>
                        </div>
                        {formik.values.chargingParty === 'P' ? (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="payeeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('payeeGlAccountId') }, 'Label__Text')}>
                                        Payee GL Account
                                    </label>
                                    <Dropdown
                                        disabled={true}
                                        id="payeeGlAccountId"
                                        placeholder="Select Charging Party"
                                        options={glJsonLov}
                                        optionLabel="name"
                                        filter
                                        name="payeeGlAccountId"
                                        optionValue="lovId"
                                        value={formik.values.payeeGlAccountId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('payeeGlAccountId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('payeeGlAccountId')}
                                </div>
                            </div>
                        ) : null}
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="startDate"
                                    placeholder="Enter startDate"
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date
                                </label>
                                <InputText
                                    disabled={true}
                                    id="endDate"
                                    placeholder="Enter endDate"
                                    name="endDate"
                                    type="date"
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeCalculationType" className={classNames({ 'p-error': isFormFieldValid('feeCalculationType') }, 'Label__Text')}>
                                    Type of Fee Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="feeCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={feeTypeJson}
                                    optionLabel="label"
                                    name="feeCalculationType"
                                    optionValue="value"
                                    value={formik.values.feeCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('feeCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Type of Tax Calculation
                                </label>
                                <Dropdown
                                    disabled={true}
                                    id="taxCalculationType"
                                    placeholder="Select Type of Tax Calculation"
                                    options={taxTypeJson}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>Slab Wise Fixed</h1>
                                </div>
                                <div className="grid">
                                    {formik?.values?.tblPricingSlabs?.map((id, index) => (
                                        <div key={`id-${index}`} style={{ backgroundColor: '#FFFFFF', padding: '3rem' }} className="col-12 card">
                                            <div className="grid">
                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`startSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[tblPricingSlabs${index}].startSlab`) }, 'Label__Text')}>
                                                            Start Slab <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`startSlab${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].startSlab`}
                                                            value={id.startSlab || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].startSlab`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.startSlab && formik.errors.tblPricingSlabs?.[index]?.startSlab}</small>
                                                    </div>
                                                </div>

                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`endSlab${index}`} className={classNames({ 'p-error': isFormFieldValid(`[slabWiseFixed${index}].endSlab`) }, 'Label__Text')}>
                                                            End Slab <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`endSlab${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].endSlab`}
                                                            value={id.endSlab || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].endSlab`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.endSlab && formik.errors.tblPricingSlabs?.[index]?.endSlab}</small>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="p-field pt-3 pb-3">
                                                        <label htmlFor={`feeAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`[slabWiseFixed${index}].feeAmount`) }, 'Label__Text')}>
                                                            Fee <span className="Label__Required">*</span>
                                                        </label>
                                                        <InputText
                                                            disabled
                                                            maxLength={15}
                                                            id={`feeAmount${index}`}
                                                            placeholder="Enter Start Slab "
                                                            name={`tblPricingSlabs[${index}].feeAmount`}
                                                            value={id.feeAmount || ''}
                                                            keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                            onChange={formik.handleChange}
                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingSlabs[${index}].feeAmount`) }, 'Input__Round')}
                                                        />
                                                        <small className="p-error">{formik.touched.tblPricingSlabs?.[index]?.feeAmount && formik.errors.tblPricingSlabs?.[index]?.feeAmount}</small>
                                                    </div>
                                                </div>
                                                <div className="col-3">
                                                    <div className="flex flex-column align-items-center justify-content-center">
                                                        <Button
                                                            icon="pi pi-plus"
                                                            className="p-button-rounded"
                                                            aria-label="Filter"
                                                            disabled
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                addSlabWiseFixed();
                                                            }}
                                                        />
                                                        <Button
                                                            disabled
                                                            icon="pi pi-minus"
                                                            className="p-button-rounded mt-2 p-button-danger"
                                                            aria-label="Filter"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                removeSlabWiseFixed(index);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}

                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div className="">
                                                    <label htmlFor="velocity" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                        Add Velocity
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="N" onChange={formik.handleChange} checked={formik.values.velocity === 'N'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="velocity" disabled={true} name="velocity" value="Y" onChange={formik.handleChange} checked={formik.values.velocity === 'Y'} />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik?.values?.velocity === 'Y' && (
                                        <>
                                            <>
                                                {formik?.values?.tblPricingVelocities?.map((velocityData, index) => {
                                                    return (
                                                        <div key={`id-${index}`} className="col-12 card">
                                                            <div className="grid">
                                                                <div className=" grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityRule${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Label__Text')}>
                                                                                Add Velocity Rule
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityRule${index}`}
                                                                                placeholder="Enter Velocity Rule"
                                                                                name={`tblPricingVelocities[${index}].velocityRule`}
                                                                                value={velocityData.velocityRule || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityRule`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`frequency${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Label__Text')}>
                                                                                Enter Frequency
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`frequency${index}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={frequencyJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingVelocities[${index}].frequency`}
                                                                                optionValue="value"
                                                                                value={velocityData.frequency || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].frequency`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`velocityAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Label__Text')}>
                                                                                Velocity Amount
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`velocityAmount${index}`}
                                                                                placeholder="Enter velocity amount"
                                                                                name={`tblPricingVelocities[${index}].velocityAmount`}
                                                                                value={velocityData.velocityAmount || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Input__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].velocityAmount`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`noOfFreeTrxn${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Label__Text')}>
                                                                                Enter Number of Free Trx
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`noOfFreeTrxn${index}`}
                                                                                placeholder="Enter Number of Free Trx"
                                                                                name={`tblPricingVelocities[${index}].noOfFreeTrxn`}
                                                                                value={velocityData.noOfFreeTrxn || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingVelocities[${index}].noOfFreeTrxn`)}
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3 mt-5">
                                                                        <div className="flex flex-column gap-2">
                                                                            <div className="flex align-items-center">
                                                                                <label htmlFor="chargeOnDifferential" className={classNames({ 'p-error': isFormFieldValid('chargeOnDifferential') }, 'Label__Text')}>
                                                                                    Charge on Differential
                                                                                </label>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities${index}_n`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="N"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'N'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        No
                                                                                    </label>
                                                                                </div>
                                                                                <div className="flex align-items-center mr-3">
                                                                                    <RadioButton
                                                                                        disabled={true}
                                                                                        inputId={`tblPricingVelocities[${index}]_y`}
                                                                                        name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                        value="Y"
                                                                                        onChange={formik.handleChange}
                                                                                        checked={velocityData.chargeOnDifferential === 'Y'}
                                                                                    />
                                                                                    <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                        Yes
                                                                                    </label>
                                                                                </div>

                                                                                {getFormErrorMessage('chargeOnDifferential')}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addVelocity();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removeCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        </>
                                    )}
                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div>
                                                    <label htmlFor="incomeSharing" className={classNames({ 'p-error': isFormFieldValid('incomeSharing') }, 'Label__Text')}>
                                                        Income Sharing
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="N" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'N'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton inputId="incomeSharing" disabled={true} name="incomeSharing" value="Y" onChange={formik.handleChange} checked={formik.values.incomeSharing === 'Y'} />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>

                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik.values.incomeSharing === 'Y' && (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="noOfPartners" className={classNames({ 'p-error': isFormFieldValid('noOfPartners') }, 'Label__Text')}>
                                                        Number of Partners
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        id="noOfPartners"
                                                        placeholder="Select Partners "
                                                        name="noOfPartners"
                                                        value={formik.values.noOfPartners || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfPartners') }, 'Input__Round')}
                                                    />

                                                    {getFormErrorMessage('noOfPartners')}
                                                </div>
                                            </div>
                                            {formik?.values?.tblPricingIncomeSharings?.map((partnerData, index) => {
                                                index = index + 1;
                                                return (
                                                    <div key={`id-${index - 1}`} className="col-12">
                                                        <div style={{ padding: '2rem' }} className="col-12 card">
                                                            <div className="Form__Header">
                                                                <h1>Sharing {index}</h1>
                                                            </div>

                                                            <div className="grid">
                                                                <div className="grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingType${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Label__Text')}>
                                                                                Sharing Type
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`sharingType${index - 1}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={sharingTypeJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingType`}
                                                                                optionValue="value"
                                                                                value={partnerData.sharingType || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingType`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`incomeGlAccountId${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Label__Text')}
                                                                            >
                                                                                Income GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`incomeGlAccountId${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.incomeGlAccountId || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    const selectedValue = e.value;
                                                                                    const selectedAccount = glJsonLov.find((item) => item.lovId === selectedValue);
                                                                                    const updatedSharingAccount = [...sharingAccount];
                                                                                    updatedSharingAccount[index - 1] = selectedAccount ? selectedAccount.accountTitle : '';
                                                                                    setSharingAccount(updatedSharingAccount);
                                                                                }}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].incomegl`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingDetails${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Label__Text')}>
                                                                                Sharing Details
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingDetails${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingDetails`}
                                                                                value={(partnerData.sharingDetails = sharingAccount[index - 1] || partnerData.sharingDetails || '')}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingDetails`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`sharingPercentage${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Label__Text')}
                                                                            >
                                                                                Fee
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingPercentage${index - 1}`}
                                                                                placeholder="Enter Fee"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingPercentage`}
                                                                                value={partnerData.sharingPercentage || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Input__Round')}
                                                                            />
                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`taxStatus${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Label__Text')}>
                                                                                Tax Status
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`taxStatus${index - 1}`}
                                                                                placeholder="Select Type of Tax Status "
                                                                                options={taxStatusJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].taxStatus`}
                                                                                optionValue="value"
                                                                                value={partnerData.taxStatus || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].taxStatus`)}
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`whtGlAccountId${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Label__Text')}>
                                                                                WHT GL
                                                                            </label>
                                                                            <Dropdown
                                                                                disabled={true}
                                                                                id={`whtGlAccountId${index - 1}`}
                                                                                placeholder="Select Type of WHT GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.whtGlAccountId || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            {getFormErrorMessage(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addPartner();
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removePartnerCard(index);
                                                                        }}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}

                                    {formik.values.incomeSharing === 'Y' ? null : (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="incomeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('incomeGlAccountId') }, 'Label__Text')}>
                                                        Income GL
                                                    </label>
                                                    <Dropdown
                                                        disabled={true}
                                                        id="incomeGlAccountId"
                                                        placeholder="Select Income GL"
                                                        options={glJsonLov}
                                                        optionLabel="name"
                                                        filter
                                                        name="incomeGlAccountId"
                                                        optionValue="lovId"
                                                        value={formik.values.incomeGlAccountId || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('incomeGlAccountId') }, 'Dropdown__Round')}
                                                    />

                                                    {getFormErrorMessage('incomeGlAccountId')}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}
export default PricingSlabWiseFixedOldNewValues;
