import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { handlePostRequestForSAF } from '../../../services/PostTemplateForSAF';
import { handleGetRequest } from '../../../services/GetTemplate';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classNames from 'classnames';

function ViewSwitchConfiguration() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [switchConnectioData, setSwitchConnectioData] = useState([]);
    const [switchMtiData, setSwitchMtiData] = useState([]);
    const [queueConfigTableData, setQueueConfigTableData] = useState([]);
    const [queueConfigData, setQueueConfigData] = useState({
        queueExpiryTime: '',
        queueSize: '',
        mtiId: ''
    });
    const [queueConfigError, setQueueConfigError] = useState({
        queueExpiryTime: '',
        queueSize: '',
        mtiId: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const clientId = query.get('switchClientId');
    const tableName = 'TBL_SWITCH_CLIENT';

    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];
    const formik = useFormik({
        initialValues: {
            clientCode: '',
            clientName: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: ''
        }
    });

    const getRecordById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    client: { id: clientId }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        const res = await dispatch(handlePostRequestForSAF(newData, '/communication-service/client/'));
        // setClientData(res?.payLoad);
        formik.setFieldValue('clientCode', res?.payLoad?.switchClientCode);
        formik.setFieldValue('clientName', res?.payLoad?.switchClientName);

        formik.setFieldValue('safRetries', res?.payLoad?.tblSwitchSafConfigs[0]?.safRetries);
        formik.setFieldValue('safRetriesTime', res?.payLoad?.tblSwitchSafConfigs[0]?.safRetriesTime);

        formik.setFieldValue('socketName', res?.payLoad?.tblSwitchSockets[0]?.socketName);
        formik.setFieldValue('socketIp', res?.payLoad?.tblSwitchSockets[0]?.socketIp);
        formik.setFieldValue('socketPort', res?.payLoad?.tblSwitchSockets[0]?.socketPort);
        formik.setFieldValue('socketType', res?.payLoad?.tblSwitchSockets[0]?.socketType);
        formik.setFieldValue('socketUrl', res?.payLoad?.tblSwitchSockets[0]?.socketUrl);
        formik.setFieldValue('socketStatus', res?.payLoad?.tblSwitchSockets[0]?.isActive);

        const queueData = res?.payLoad?.tblSwitchMessageBufferQueues?.map((item) => {
            return {
                expiryTime: item?.queueExpiryTime,
                size: item?.queueLimitSize,
                mtiId: item?.tblSwitchMti?.switchMtiId,
                status: item?.isActive
            };
        });

        setQueueConfigTableData(queueData);

        formik.setFieldValue('switchConnectionId', res?.payLoad?.lkpSwitchConnection?.switchConnectionId?.toString());
    };

    const getSwitchConnectionData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getswitchconnections');
        setSwitchConnectioData(res?.payLoad);
    };

    useEffect(() => {
        getSwitchConnectionData();
        if (clientId) {
            getRecordById();
        }
    }, [clientId]); // eslint-disable-line

    const handledQueueConfigAdd = (e) => {
        e.preventDefault();

        const errors = {
            queueExpiryTime: '',
            queueSize: '',
            mtiId: ''
        };

        // Check for duplicate Id
        if (queueConfigTableData?.some((item) => item?.mtiId === queueConfigData?.mtiId)) {
            errors.mtiId = 'This MTI is already in use.';
        }

        setQueueConfigError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newQueueConfigTableData = {
                queueExpiryTime: queueConfigData.queueExpiryTime,
                queueSize: queueConfigData.queueSize,
                mtiId: queueConfigData.mtiId,
                queueStatus: 'Y'
            };
            setQueueConfigTableData([...queueConfigTableData, newQueueConfigTableData]);

            setQueueConfigData({ queueExpiryTime: '', queueSize: '', mtiId: '' });
        }
    };

    const handledFieldInputQueueConfigData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setQueueConfigData({ ...queueConfigData, [name]: value });

        // Clear the error message for the field being edited
        setQueueConfigError({
            ...queueConfigError,
            [name]: ''
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeletequeueConfigData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const mtiBodyTemplate = (rowData) => {
        const filteredMti = switchMtiData?.filter((item) => item.lovId == rowData?.mtiId);

        return (
            <p>
                <b>{filteredMti[0]?.name}</b>
            </p>
        );
    };

    const handledDeletequeueConfigData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = queueConfigTableData?.filter((value) => value.mtiId !== rowData.mtiId);
        setQueueConfigTableData(newFilterData);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className="Label__Text">
                                    Client Code
                                </label>
                                <InputText id="clientCode" name="clientCode" placeholder="Enter Client Code" value={formik?.values?.clientCode || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className="Label__Text">
                                    Client Name<span className="Label__Required">*</span>
                                </label>
                                <InputText id="clientName" name="clientName" placeholder="Enter Client Name" value={formik?.values?.clientName || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="switchConnectionId" className={'Label__Text'}>
                                    Switch Connection<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="switchConnectionId"
                                    placeholder="Select Switch Connection"
                                    options={switchConnectioData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    disabled
                                    name="switchConnectionId"
                                    value={formik.values.switchConnectionId}
                                    onChange={formik.handleChange}
                                    className={'Dropdown__Round'}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className="Label__Text">
                                    SAF Retries
                                </label>
                                <InputText id="safRetries" name="safRetries" placeholder="Enter SAF Retries" value={formik?.values?.safRetries || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className="Label__Text">
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText id="safRetriesTime" name="safRetriesTime" placeholder="Enter SAF Retries Time" value={formik?.values?.safRetriesTime || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className="Label__Text">
                                    Socket Name <span className="Label__Required">*</span>
                                </label>
                                <InputText id="socketName" name="socketName" placeholder="Enter Socket Name" value={formik?.values?.socketName || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className="Label__Text">
                                    Socket IP <span className="Label__Required">*</span>
                                </label>
                                <InputText id="socketIp" name="socketIp" placeholder="Enter Socket IP" value={formik?.values?.socketIp || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className="Label__Text">
                                    Port
                                </label>
                                <InputText id="socketPort" placeholder="Enter Port Number" name="socketPort" value={formik.values.socketPort} onChange={formik.handleChange} className="Input__Round" disabled />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className="Label__Text">
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className="Dropdown__Round"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className="Label__Text">
                                    Socket URL
                                </label>
                                <InputText id="socketUrl" name="socketUrl" placeholder="Enter Socket URL" value={formik?.values?.socketUrl || ''} onChange={formik.handleChange} className="Input__Round" aria-required disabled />
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="expiryTime" className={classNames({ 'p-error': isFormFieldValid('expiryTime') }, 'Label__Text')}>
                                        Expiry Time In (in milli's)
                                    </label>
                                    <InputText
                                        maxLength={10}
                                        id="expiryTime"
                                        name="expiryTime"
                                        // keyfilter="int"
                                        disabled
                                        placeholder="Enter Expiry Time"
                                        value={queueConfigData?.expiryTime || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryTime') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('expiryTime')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="size" className={classNames({ 'p-error': isFormFieldValid('size') }, 'Label__Text')}>
                                        Queue Size
                                    </label>
                                    <InputText
                                        maxLength={2}
                                        id="size"
                                        name="size"
                                        keyfilter="int"
                                        disabled
                                        placeholder="Enter Queue Size"
                                        value={queueConfigData?.size || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('size') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('size')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="mtiId" className={classNames({ 'p-error': isFormFieldValid('mtiId') }, 'Label__Text')}>
                                        MTI
                                    </label>
                                    <Dropdown
                                        id="mtiId"
                                        placeholder="Select MTI"
                                        options={switchMtiData}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        name="mtiId"
                                        disabled
                                        value={queueConfigData.mtiId}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('mtiId') }, 'Dropdown__Round')}
                                    />

                                    {/* {getFormErrorMessage('mtiId')} */}
                                    <small className="p-error">{queueConfigError.mtiId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" loadingIcon={loadingIcon} disabled label="Add" onClick={handledQueueConfigAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={queueConfigTableData}>
                                    <Column field="expiryTime" header="Queue Expiry Time" />
                                    <Column field="size" header="Queue Size" />
                                    <Column header="MTI" body={mtiBodyTemplate} />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button
                            label="Okay"
                            type="Button"
                            onClick={() => {
                                navigate('/switchconfiguration');
                            }}
                            className="Btn__Dark__Search"
                        />
                    </div>
                </form>
            </div>

            <AuthorizationHistoryTable refTableId={clientId} tableName={tableName} />
        </>
    );
}
export default ViewSwitchConfiguration;
