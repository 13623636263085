import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';
import { Paginator } from 'primereact/paginator';

function BulkBlacklistedCnicExternal() {
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);

    const [userLov, setUserLov] = useState([]);
    const [accountType, setAccountType] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apiHit, setApiHit] = useState([]);
    const [bulkBlacklistedCnicExternal, setBulkBlacklistedCnicExternal] = useState();

    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const dataTableRef = useRef();
    const dispatch = useDispatch();

    const formik = useFormik({
        validationSchema,
        initialValues: {
            bulkOperationName: 'BBLE',
            tblBlackListCnicHistoryDTO: '',
            accountTypeId: '',
            blackListName: '',
            mobileNo: '',
            accountStatusId: '',
            isActive: '',
            cnic: '',
            isBlackListed: '',
            createdBy: '',
            dateFrom: '',
            dateTo: '',
            page: '1',
            size: customRows1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloadingIcon('pi pi-spin pi-spinner');

            const payloadDataNew = {
                bulkOperationName: data.bulkOperationName,
                page:data.page,
                size:data.size,
                tblBlackListCnicHistoryDTO: {
                    accountTypeId: data.accountTypeId,
                    blackListName: data.blackListName,
                    mobileNo: data.mobileNo,
                    accountStatusId: data.accountStatusId,
                    isActive: '',
                    cnic: data.cnic,
                    isBlackListed: data.isBlackListed,
                    createdBy: data.createdBy,
                    dateFrom: data.dateFrom,
                    dateTo: data.dateTo
                }
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadDataNew,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]
                }
            };

            dataTableRef.current.reset();
            setApiHit(newData?.data?.payLoad);

            const res = await dispatch(handlePostRequest(newData, '/batchprocessing/v1/batchprocessing/getallrecords', true, true));
            if (res?.responseCode === '170000') {
                setBulkBlacklistedCnicExternal(res?.payLoad);
            }
        }
    });

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getAccountStatus/LKP_ACCOUNT_STATUS:*');
        setStatusLovData(resp?.payLoad);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };
    const getAccountType = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getallaccounttype/LKP_ACCOUNT_TYPE:*');
        setAccountType(resp?.payLoad);
    };
    useEffect(() => {
        getStatusLov();
        getUserLov();
        getAccountType();
    }, []); // eslint-disable-line

    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Bulk Blacklisted CNIC (External)</h5>
            </div>
        );
    };
    const blacklistedData = [
        { label: 'Yes ', value: 1 },
        { label: 'No ', value: 0 }
    ];

    const header = renderHeader();

    const actionBodyTemplate = (rowData) => {
        const statusColor = rowData?.status === 'Y' ? '#14A38B' : rowData?.status === 'N' ? '#F2AC57' : 'black';
        const statusText = rowData?.status === 'Y' ? 'Active' : rowData?.status === 'N' ? 'Inactive' : '';

        return (
            <p style={{ color: statusColor }}>
                <b>{statusText}</b>
            </p>
        );
    };

    const blacklisted = (rowData) => {
        if (rowData) {
            return <div>{rowData?.isBlackListed === 1 ? 'Yes' : rowData?.isBlackListed === 0 ? 'No' : ''}</div>;
        }
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.markedOn;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        ...apiHit,
        page: currentPage,
        size: customRows1
    };
    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountTypeId" className={classNames({ 'p-error': isFormFieldValid('accountTypeId') }, 'Label__Text')}>
                                    Account Type
                                </label>

                                <Dropdown
                                    id="accountTypeId"
                                    placeholder="Select"
                                    options={accountType}
                                    optionLabel="name"
                                    name="accountTypeId"
                                    optionValue="lovId"
                                    value={formik.values.accountTypeId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountTypeId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountTypeId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC
                                </label>
                                <InputText maxLength={13} id="cnic" placeholder="" name="cnic" value={formik?.values?.cnic} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')} />

                                {getFormErrorMessage('cnic')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="isBlackListed" className={classNames({ 'p-error': isFormFieldValid('isBlackListed') }, 'Label__Text')}>
                                    BlackListed
                                </label>
                                <Dropdown
                                    id="isBlackListed"
                                    placeholder="Select"
                                    options={blacklistedData}
                                    optionLabel="label"
                                    name="isBlackListed"
                                    optionValue="value"
                                    value={formik.values.isBlackListed}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('isBlackListed') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('isBlackListed')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="blackListName" className={classNames({ 'p-error': isFormFieldValid('blackListName') }, 'Label__Text')}>
                                    Blacklisted List Name
                                </label>
                                <InputText
                                    maxLength={13}
                                    id="blackListName"
                                    placeholder=""
                                    name="blackListName"
                                    value={formik?.values?.blackListName}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('blackListName') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('blackListName')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    maxLength={13}
                                    id="mobileNo"
                                    placeholder=""
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('mobileNo')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="accountStatusId" className={classNames({ 'p-error': isFormFieldValid('accountStatusId') }, 'Label__Text')}>
                                    Registration Status
                                </label>
                                <Dropdown
                                    id="accountStatusId"
                                    placeholder="Select"
                                    options={statusLovData}
                                    optionLabel="accountStatusName"
                                    name="accountStatusId"
                                    optionValue="accountStatusId"
                                    value={formik.values.accountStatusId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountStatusId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountStatusId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4  ">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    Uploaded Date From
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 ">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    Uploaded Date To
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createdBy" className={classNames({ 'p-error': isFormFieldValid('createdBy') }, 'Label__Text')}>
                                    Uploaded By
                                </label>
                                <Dropdown
                                    id="createdBy"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createdBy"
                                    optionValue="lovId"
                                    value={formik.values.createdBy || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createdBy')}
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button  loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button  label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                </form>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setBulkBlacklistedCnicExternal}
               
                RequestData={RequestData}
                url={'/batchprocessing/v1/batchprocessing/getallrecords'}
                responseCode={'170000'}
            />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                          
                            className="datatable-responsive"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={bulkBlacklistedCnicExternal}
                        >
                            <Column field="cnic" header="CNIC" />
                            <Column header="Blacklisted" body={blacklisted} />
                            <Column field="mobileNumber" header="Mobile Number" />
                            <Column header="Marked On" body={createdDateAndTimeBodyTemplate} />
                            <Column field="markedBy" header="Marked By" />
                            <Column field="registrationStatus" header="Registration Status" />
                            <Column field="comment" header="Comments" />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={bulkBlacklistedCnicExternal?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>

                    </div>
                </div>
            </div>
        </>
    );
}
export default BulkBlacklistedCnicExternal;
