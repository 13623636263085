import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';

function EditTypeOfAccount() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [accountClassificationData, setAccountClassificationData] = useState([]);
    const [kycSetsData, setKycSetsData] = useState([]);
    const [accountNameData, setAccountNameData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const accLevelId = query.get('accLevelId');

    const getAccountNameData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccountlevelcode');
        setAccountNameData(res?.payLoad);
    };

    useEffect(() => {
        getAccountNameData();
    }, []); // eslint-disable-line

    const getAccountTypeById = async () => {
        const resp = await handleGetRequest(`/account/v1/accountype/getAccountTypeById/${accLevelId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('kycSetHeadId', keyData?.tblKycSetHead?.kycSetHeadId?.toString());
            formik.setFieldValue('lkpAccountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
            // formik.setFieldValue('dailyTransLimitDr', keyData?.dailyTransLimitDr.toString())
        }
    };

    useEffect(() => {
        getAccountTypeById();
    }, []); // eslint-disable-line

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getAccountClassificationData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getclientroles/LKP_ACCOUNT_CLASSIFICATION:*');
        setAccountClassificationData(res?.payLoad);
    };

    useEffect(() => {
        getAccountClassificationData();
    }, []); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        accountLevelName: Yup.string().required('This field is required.'),
        lkpAccountClassificationId: Yup.mixed().required('This field is required.').nullable(),
        kycSetHeadId: Yup.mixed().required('This field is required.').nullable(),
        dailyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyTransLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyTransLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyAmtLimitDr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        dailyTransLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        dailyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyTransLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        monthlyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyTransLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable(),
        yearlyAmtLimitCr: Yup.string()
            .required('This field is required.')
            .matches(/^[1-9][0-9]*$/, 'Please enter a numeric value greater than 0')
            .nullable()
    });

    const formik = useFormik({
        validationSchema,
        initialValues: {
            lkpAccountClassificationId: '',
            accountLevelName: '',
            kycSetHeadId: '',
            dailyTransLimitDr: null,
            dailyAmtLimitDr: '',
            monthlyTransLimitDr: '',
            monthlyAmtLimitDr: '',
            yearlyTransLimitDr: '',
            yearlyAmtLimitDr: '',
            dailyTransLimitCr: '',
            dailyAmtLimitCr: '',
            monthlyTransLimitCr: '',
            monthlyAmtLimitCr: '',
            yearlyTransLimitCr: '',
            yearlyAmtLimitCr: '',
            maxAmtLimit: ''
        },
        // validate: (data) => {
        //     let errors = {};
        //     if (!data?.dailyTransLimitDr || !/^[0-9]*$/.test(data?.dailyTransLimitDr)) {
        //         errors.dailyTransLimitDr = 'This field is mandatory and only numeric data is allowed';
        //     }
        //     if (['2', '3'].includes(formik.values.lkpAccountClassificationId)) {
        //         if (!data?.maxAmtLimit) {
        //             errors.maxAmtLimit = 'This field is required';
        //         }
        //         if (data?.maxAmtLimit <= 0) {
        //             errors.maxAmtLimit = 'Maximum available balance should be greater than 0';
        //         }
        //         if (data?.maxAmtLimit === /^\d+$/.test(data?.maxAmtLimit)) {
        //             errors.maxAmtLimit = 'Special characters are not allowed';
        //         }
        //     }

        //     return errors;
        // },
        validate: (data) => {
            let errors = {};

            if (['2', '3'].includes(formik.values.lkpAccountClassificationId)) {
                if (!data?.maxAmtLimit) {
                    errors.maxAmtLimit = 'This field is required';
                }
                if (data?.maxAmtLimit <= 0) {
                    errors.maxAmtLimit = 'Maximum available balance should be greater than 0';
                }
                if (data?.maxAmtLimit === /^\d+$/.test(data?.maxAmtLimit)) {
                    errors.maxAmtLimit = 'Special characters are not allowed';
                }
                if (parseInt(data?.maxAmtLimit) < parseInt(data?.yearlyAmtLimitDr) || parseInt(data?.maxAmtLimit) < parseInt(data?.yearlyAmtLimitCr)) {
                    errors.maxAmtLimit = 'Maximum Amount Limit should be less than or equal to Yearly Amount Limit';
                }
            }

            if (data?.dailyTransLimitDr === '' || data?.dailyTransLimitDr <= 0) {
                errors.dailyTransLimitDr = 'This field is required and value must be greater than 0';
            }
            if (data?.monthlyTransLimitDr === '' || data?.monthlyTransLimitDr <= 0) {
                errors.monthlyTransLimitDr = 'This field is required and value must be greater than 0';
            }
            if (data.yearlyTransLimitDr === '' || data.yearlyTransLimitDr <= 0) {
                errors.yearlyTransLimitDr = 'This field is required and value must be greater than 0';
            }

            if (data.dailyTransLimitCr === '' || data.dailyTransLimitCr <= 0) {
                errors.dailyTransLimitCr = 'This field is required and value must be greater than 0';
            }

            if (data.monthlyTransLimitCr === '' || data.monthlyTransLimitCr <= 0) {
                errors.monthlyTransLimitCr = 'This field is required and value must be greater than 0';
            }

            if (data.yearlyTransLimitCr === '' || data.yearlyTransLimitCr <= 0) {
                errors.yearlyTransLimitCr = 'This field is required and value must be greater than 0';
            }
            // Debit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitDr) {
                if (parseInt(data?.dailyAmtLimitDr) > parseInt(data?.monthlyAmtLimitDr)) {
                    errors.monthlyAmtLimitDr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitDr) {
                if (parseInt(data?.monthlyAmtLimitDr) > parseInt(data?.yearlyAmtLimitDr)) {
                    errors.yearlyAmtLimitDr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Debit Count less/greater than Count Validation
            if (data?.dailyTransLimitDr) {
                if (parseInt(data?.dailyTransLimitDr) > parseInt(data?.monthlyTransLimitDr)) {
                    errors.monthlyTransLimitDr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitDr) {
                if (parseInt(data?.monthlyTransLimitDr) > parseInt(data?.yearlyTransLimitDr)) {
                    errors.yearlyTransLimitDr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            // Credit Amount less/greater than Amount Validation
            if (data?.dailyAmtLimitCr) {
                if (parseInt(data?.dailyAmtLimitCr) > parseInt(data?.monthlyAmtLimitCr)) {
                    errors.monthlyAmtLimitCr = 'Monthly Amount should be greater than Daily Amount';
                }
            }
            if (data?.monthlyAmtLimitCr) {
                if (parseInt(data?.monthlyAmtLimitCr) > parseInt(data?.yearlyAmtLimitCr)) {
                    errors.yearlyAmtLimitCr = 'Yearly Amount should be greater than Monthly Amount';
                }
            }

            // Credit Count less/greater than Count Validation
            if (data?.dailyTransLimitCr) {
                if (parseInt(data?.dailyTransLimitCr) > parseInt(data?.monthlyTransLimitCr)) {
                    errors.monthlyTransLimitCr = 'Monthly Count should be greater than Daily Count';
                }
            }
            if (data?.monthlyTransLimitCr) {
                if (parseInt(data?.monthlyTransLimitCr) > parseInt(data?.yearlyTransLimitCr)) {
                    errors.yearlyTransLimitCr = 'Yearly Count should be greater than Monthly Count';
                }
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');
            data['accountLevelId'] = accLevelId;

            if (data?.lkpAccountClassificationId === '1') {
                data['maxAmtLimit'] = '';
            }

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            const res = await dispatch(handlePostRequest(newData, '/account/v1/accountype/updateexistingaccounttype', true, false, 'typeofaccount'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const getUserLov = async () => {
        const resp = await handleGetRequest(`/configuration/v1/lookups/getkycsets/TBL_KYC_SET_HEAD:*/${formik.values.lkpAccountClassificationId}`);
        setKycSetsData(resp?.payLoad);
    };

    useEffect(() => {
        if (formik.values.lkpAccountClassificationId !== '') {
            getUserLov();
        }
    }, [formik.values.lkpAccountClassificationId]); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleAccountTypeChange = (event) => {
        // Reset the KYC set value when the account type changes
        formik.setFieldValue('kycSetHeadId', ''); // Set KYC set value to empty string

        // Update the account type value
        formik.handleChange(event);
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpAccountClassificationId" className={classNames({ 'p-error': isFormFieldValid('lkpAccountClassificationId') }, 'Label__Text')}>
                                    Select Type Of Account <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    disabled
                                    id="lkpAccountClassificationId"
                                    placeholder="Select Type Of Account"
                                    options={accountClassificationData}
                                    optionLabel="name"
                                    name="lkpAccountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.lkpAccountClassificationId || ''}
                                    onChange={handleAccountTypeChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpAccountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('lkpAccountClassificationId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountLevelName" className={classNames({ 'p-error': isFormFieldValid('accountLevelName') }, 'Label__Text')}>
                                    Name Of Account <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountLevelName"
                                    placeholder="Select Name Of Account"
                                    name="accountLevelName"
                                    options={accountNameData}
                                    optionLabel="name"
                                    optionValue="name"
                                    value={formik?.values?.accountLevelName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountLevelName') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('accountLevelName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="kycSetHeadId" className={classNames({ 'p-error': isFormFieldValid('kycSetHeadId') }, 'Label__Text')}>
                                    Select KYC Set <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="kycSetHeadId"
                                    placeholder="Select KYC Set"
                                    options={kycSetsData}
                                    optionLabel="name"
                                    name="kycSetHeadId"
                                    optionValue="lovId"
                                    value={formik.values.kycSetHeadId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('kycSetHeadId') }, 'Dropdown__Round')}
                                />
                            </div>
                            {getFormErrorMessage('kycSetHeadId')}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Debit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyTransLimitDr"
                                        name="dailyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.dailyTransLimitDr !== null && formik?.values?.dailyTransLimitDr !== undefined ? String(formik.values.dailyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyAmtLimitDr"
                                        name="dailyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.dailyAmtLimitDr !== null && formik?.values?.dailyAmtLimitDr !== undefined ? String(formik.values.dailyAmtLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyTransLimitDr"
                                        name="monthlyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.monthlyTransLimitDr !== null && formik?.values?.monthlyTransLimitDr !== undefined ? String(formik.values.monthlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyAmtLimitDr"
                                        name="monthlyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.monthlyAmtLimitDr !== null && formik?.values?.monthlyAmtLimitDr !== undefined ? String(formik.values.monthlyAmtLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyTransLimitDr"
                                        name="yearlyTransLimitDr"
                                        maxLength={6}
                                        value={formik?.values?.yearlyTransLimitDr !== null && formik?.values?.yearlyTransLimitDr !== undefined ? String(formik.values.yearlyTransLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyAmtLimitDr"
                                        name="yearlyAmtLimitDr"
                                        maxLength={17}
                                        value={formik?.values?.yearlyAmtLimitDr !== null && formik?.values?.yearlyAmtLimitDr !== undefined ? String(formik.values.yearlyAmtLimitDr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitDr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            {['', '0', null].includes(formik.values.dailyTransLimitDr)
                                ? getFormErrorMessage('dailyTransLimitDr')
                                : ['', '0', null].includes(formik.values.dailyAmtLimitDr)
                                ? getFormErrorMessage('dailyAmtLimitDr')
                                : ['', '0', null].includes(formik.values.monthlyTransLimitDr)
                                ? getFormErrorMessage('monthlyTransLimitDr')
                                : ['', '0', null].includes(formik.values.monthlyAmtLimitDr)
                                ? getFormErrorMessage('monthlyAmtLimitDr')
                                : ['', '0', null].includes(formik.values.yearlyTransLimitDr)
                                ? getFormErrorMessage('yearlyTransLimitDr')
                                : ['', '0', null].includes(formik.values.yearlyAmtLimitDr)
                                ? getFormErrorMessage('yearlyAmtLimitDr')
                                : null}
                        </div>
                        <div className="p-field col-12 md:col-6 pt-3 pb-3">
                            <h3>Credit</h3>
                            <div className="Debit__Heading">
                                <div>
                                    <p>Particular</p>
                                </div>
                                <div>
                                    <p>Count</p>
                                </div>
                                <div>
                                    <p>Amount</p>
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Daily</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyTransLimitCr"
                                        name="dailyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.dailyTransLimitCr !== null && formik?.values?.dailyTransLimitCr !== undefined ? String(formik.values.dailyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="dailyAmtLimitCr"
                                        name="dailyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.dailyAmtLimitCr !== null && formik?.values?.dailyAmtLimitCr !== undefined ? String(formik.values.dailyAmtLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('dailyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Monthly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyTransLimitCr"
                                        name="monthlyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.monthlyTransLimitCr !== null && formik?.values?.monthlyTransLimitCr !== undefined ? String(formik.values.monthlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="monthlyAmtLimitCr"
                                        name="monthlyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.monthlyAmtLimitCr !== null && formik?.values?.monthlyAmtLimitCr !== undefined ? String(formik.values.monthlyAmtLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('monthlyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            <div className="DebitValue__Heading">
                                <div>
                                    <p>Yearly</p>
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyTransLimitCr"
                                        name="yearlyTransLimitCr"
                                        maxLength={6}
                                        value={formik?.values?.yearlyTransLimitCr !== null && formik?.values?.yearlyTransLimitCr !== undefined ? String(formik.values.yearlyTransLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyTransLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                                <div>
                                    <InputNumber
                                        inputId="yearlyAmtLimitCr"
                                        name="yearlyAmtLimitCr"
                                        maxLength={17}
                                        value={formik?.values?.yearlyAmtLimitCr !== null && formik?.values?.yearlyAmtLimitCr !== undefined ? String(formik.values.yearlyAmtLimitCr).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('yearlyAmtLimitCr') }, 'Input__Number')}
                                    />
                                </div>
                            </div>
                            {['', '0', null].includes(formik.values.dailyTransLimitCr)
                                ? getFormErrorMessage('dailyTransLimitCr')
                                : ['', '0', null].includes(formik.values.dailyAmtLimitCr)
                                ? getFormErrorMessage('dailyAmtLimitCr')
                                : ['', '0', null].includes(formik.values.monthlyTransLimitCr)
                                ? getFormErrorMessage('monthlyTransLimitCr')
                                : ['', '0', null].includes(formik.values.monthlyAmtLimitCr)
                                ? getFormErrorMessage('monthlyAmtLimitCr')
                                : ['', '0', null].includes(formik.values.yearlyTransLimitCr)
                                ? getFormErrorMessage('yearlyTransLimitCr')
                                : ['', '0', null].includes(formik.values.yearlyAmtLimitCr)
                                ? getFormErrorMessage('yearlyAmtLimitCr')
                                : null}
                        </div>
                    </div>

                    {(formik.values.lkpAccountClassificationId === '2' || formik.values.lkpAccountClassificationId === '3') && (
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="maxAmtLimit">
                                    <label htmlFor="maxAmtLimit" className={classNames({ 'p-error': isFormFieldValid('maxAmtLimit') }, 'Label__Text__MaxBal')}>
                                        Maximum Balance<span className="Label__Required">*</span>
                                    </label>
                                    <InputNumber
                                        inputId="maxAmtLimit"
                                        placeholder="Enter Maximum Balance"
                                        name="maxAmtLimit"
                                        maxLength={11}
                                        // value={formik?.values?.maxAmtLimit || ''}
                                        value={formik?.values?.maxAmtLimit !== null && formik?.values?.maxAmtLimit !== undefined ? String(formik.values.maxAmtLimit).replace(/[^0-9]/g, '') : ''}
                                        onValueChange={formik.handleChange}
                                        // className={classNames({ 'p-invalid': isFormFieldValid('maxAmtLimit') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('maxAmtLimit')}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/typeofaccount')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditTypeOfAccount;
