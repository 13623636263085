import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';

import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import { handleRequest } from '../../../utils/HandleRequest/handleRequest';
import SecuredFileChange from '../../../components/SecuredFileChange';
import moment from 'moment';

function DisbursementUploadPage() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');

    const [ftpFileData, setFtpFileData] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [dsFileError, setDsFileError] = useState('');

    const [products, setProduct] = useState([]);
    const [service, setService] = useState([
        {
            label: 'Bulk Disbursement to A/c Holder',
            lovId: 'BDA'
        },
        {
            label: 'Bulk Disbursement to N/c Holder',
            lovId: 'BDN'
        }
    ]);

    const [accountType, setAccountType] = useState([
        {
            label: 'BLB',
            lovId: 'B'
        },
        {
            label: 'CORE',
            lovId: 'C'
        },
        {
            label: 'GL',
            lovId: 'G'
        }
    ]);

    const [distrubanceOccurence, setDistrubanceOccurence] = useState([
        {
            label: 'NORMAL',
            lovId: 'N'
        },
        {
            label: 'SCHEDULED',
            lovId: 'S'
        }
    ]);

    const [useCaseError, setUseCaseError] = useState({});

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        service: Yup.mixed().required('This field is required.'),
        product: Yup.mixed().required('This field is required.'),

        accountType: Yup.mixed().required('This field is required.'),
        sourceAccountNick: Yup.mixed().required('This field is required.'),
        sourceAccount: Yup.mixed().required('This field is required.'),

        digitalSigningInput: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.mixed().required('Please choose a file.')
        }),

        isActive: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.boolean().oneOf([true], 'This field is required')
        }),
        disbursementOccurence: Yup.mixed().when('fileSource', {
            is: 'upload',
            then: Yup.mixed().required('This field is required.')
        }),
        disbursementOccurences: Yup.mixed().when('fileSource', {
            is: 'ftp',
            then: Yup.mixed().required('This field is required.')
        }),

        ftpFileName: Yup.mixed().when('fileSource', {
            is: 'ftp',
            then: Yup.mixed().required('This field is required.')
        })
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            digitalSigningInput: '',
            fileSource: 'upload',
            service: '',
            product: '',
            accountType: '',
            sourceAccount: '',
            sourceAccountNick: '',
            disbursementDescr: '',
            disbursementOccurence: '',
            disbursementOccurences: '',
            dateTime: '',
            uploadedBy: '',
            isActive: '',
            ftpFileName: '',
            dateTimeCsv: '',
            isActiveCsv: ''
        },

        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const formData = new FormData();

            if (data?.fileSource === 'ftp') {
                const filePath = data?.ftpFileName;

                let onlyDate = '';
                if (data?.dateTime) {
                    onlyDate = formatDate(data?.dateTime);
                } else {
                    onlyDate = new Date();
                }

                let payload = {
                    ftpFileName: filePath,
                    bulkOperationName: data?.service,
                    uploadMethod: data?.fileSource.toLocaleUpperCase(),
                    description: data?.description,
                    serviceId: data?.service == 'BDA' ? 'A' : 'N',
                    productId: data?.product,
                    accountTypeId: data?.accountType,
                    sourceAccountNo: data?.sourceAccount,
                    sourceAccountNick: data?.sourceAccountNick,
                    disbursementOccurrenceId: data?.disbursementOccurences,
                    disbursementDate: onlyDate,
                    recursive: data?.isActive === true ? 'Y' : 'N'
                };
                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: payload,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };

                // Append request data if uploadMethod is not ftp
                formData.append('request', JSON.stringify(newData));
                const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'disbursementFile'));
            } else {
                let onlyDate = '';
                if (data?.dateTimeCsv) {
                    onlyDate = formatDate(data?.dateTimeCsv);
                } else {
                    onlyDate = new Date();
                }

                let payload = {
                    bulkOperationName: data?.service,
                    uploadMethod: 'CSV',
                    description: data?.description,
                    serviceId: data?.service == 'BDA' ? 'A' : 'N',
                    productId: data?.product,
                    accountTypeId: data?.accountType,
                    sourceAccountNo: data?.sourceAccount,
                    sourceAccountNick: data?.sourceAccountNick,
                    disbursementOccurrenceId: data?.disbursementOccurence,
                    disbursementDate: onlyDate,
                    recursive: data?.isActiveCsv === true ? 'Y' : 'N'
                };
                const newData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: payload,
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };

                formData.append('file', selectedFile);

                // Append request data if uploadMethod is not ftp
                formData.append('request', JSON.stringify(newData));
                const res = await dispatch(handlePostRequest(formData, '/batchprocessing/v1/batchprocessing/uploadbulkfile', true, false, 'disbursementFile'));
            }

            // // Append file data

            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'sourceAccount') {
            name = 'sourceAccount';
            formik.handleChange(e);
        } else if (name === 'accountType') {
            name = 'accountType';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };

    const handleFetch = () => {
        formik.setFieldValue('sourceAccountNick', '');
        const newData = {
            sourceAccount: formik.values.sourceAccount,
            accountType: formik.values.accountType
        };

        const schema = Yup.object().shape({
            sourceAccount: Yup.string().required('This Field is Required.'),
            accountType: Yup.string().required('This Field is Required.')
        });
        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(async () => {
                let requestType = 'POST';
                let apiUrl;
                let payLoad;
                if (formik.values.accountType === 'G') {
                    apiUrl = '/account/v1/fetchglbyaccountno';
                    payLoad = {
                        accountNumber: formik.values.sourceAccount
                    };
                } else if (formik.values.accountType === 'B') {
                    apiUrl = '/account/v1/accounts/verifyaccountdetails';
                    payLoad = {
                        msisdn: formik.values.sourceAccount,
                        iban: '',
                        bban: ''
                    };
                } else if (formik.values.accountType === 'C') {
                    apiUrl = '/batchprocessing/titlefechcoreaccount';
                    payLoad = {
                        coreAccountNo: formik.values.sourceAccount
                    };
                }

                let additionalInformation = [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ];

                let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

                if (res.payLoad.accountTitle) {
                    formik.setFieldValue('sourceAccountNick', res.payLoad.accountTitle);
                } else {
                    formik.setFieldValue('sourceAccountNick', res.payLoad.coreAccountNick);
                }
            })
            .catch((error) => {
                const errors = {};
                error?.inner?.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                setUseCaseError(errors);
            });
    };

    const downloadSample = async () => {
        if (formik.values.service) {
            handleGetRequest(`/batchprocessing/v1/batchprocessing/downloadfile?bulkOperationName=${formik?.values?.service}`).then((response) => {
                // Convert the data to CSV format using PapaParse

                const textData = response;

                const blob = new Blob([textData], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'BTR.csv');

                document.body.appendChild(link);
                link.click();

                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
            });
        }
    };

    // file upload
    const handleDigitalSigningInput = () => {
        digitalSigningRef.current.click();
    };
    const digitalSigningRef = React.useRef(null);

    const digitalSigningChange = (event) => {
        formik.setFieldValue('digitalSigningInput', '');
        formik.setFieldValue('digitalSigningInputBase64', '');
        setDsFileError('');
        const selectedFile = event.target.files[0];
        const fileTypes = ['csv'];
        const { file: newFile, error } = SecuredFileChange(selectedFile, fileTypes);
        if (error) {
            setDsFileError(error);
        } else if (newFile) {
            setSelectedFile(selectedFile);
            formik.setFieldValue('digitalSigningInput', selectedFile?.name);
            const reader = new FileReader();

            reader.onload = () => {
                const base64Data = reader.result;
                formik.setFieldValue('digitalSigningInputBase64', base64Data);
            };

            reader.readAsDataURL(selectedFile);
        }
    };

    const product = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProduct(res?.payLoad);
    };

    const getFtpFile = async (value) => {
        const resp = await handleGetRequest(`/batchprocessing/v1/batchprocessing/getftpfiles/${value}`);
        setFtpFileData(resp?.payLoad);
    };

    const getCurrentDateTime = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0');
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${year}-${month}-${day}T${hours}:${minutes}`;
    };
    useEffect(() => {
        // Check if formik.values.service is defined and not an empty string
        if (formik.values.service !== undefined && formik.values.service !== '') {
            getFtpFile(formik.values.service);
        }
    }, [formik.values.service]);

    useEffect(() => {
        product();
    }, []);

    const formatDate = (inputDate) => {
        const date = new Date(inputDate);

        const day = date.getDate()?.toString().padStart(2, '0');
        const month = (date.getMonth() + 1)?.toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours()?.toString().padStart(2, '0');
        const minutes = date.getMinutes()?.toString().padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';

        // Convert hours from 24-hour to 12-hour format
        const formattedHours = hours % 12 || 12;

        const formattedDate = `${day}-${month}-${year}, ${formattedHours}:${minutes} ${ampm}`;
        return formattedDate;
    };

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>Disbursement Upload Page</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="service" className={classNames({ 'p-error': isFormFieldValid('service') }, 'Label__Text')}>
                                    Service
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="service"
                                    placeholder="Select service"
                                    options={service}
                                    optionLabel="label"
                                    name="service"
                                    optionValue="lovId"
                                    value={formik.values.service || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('service') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('service')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="product"
                                    placeholder="Select product"
                                    options={products}
                                    optionLabel="name"
                                    name="product"
                                    optionValue="lovId"
                                    value={formik.values.product || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountType" className={classNames({ 'p-error': isFormFieldValid('accountType') }, 'Label__Text')}>
                                    Account Type
                                    <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountType"
                                    placeholder="Select Account Type"
                                    options={accountType}
                                    optionLabel="label"
                                    name="accountType"
                                    optionValue="lovId"
                                    value={formik.values.accountType || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountType') }, 'Dropdown__Round')}
                                />

                                {useCaseError.accountType !== undefined ? <small className="p-error">{useCaseError.accountType}</small> : getFormErrorMessage('accountType')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-2 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sourceAccount" className={classNames({ 'p-error': isFormFieldValid('sourceAccount') }, 'Label__Text')}>
                                    Source Account{'Core/BLB'} #<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="sourceAccount"
                                    placeholder="Enter Source Account"
                                    name="sourceAccount"
                                    value={formik?.values?.sourceAccount || ''}
                                    keyfilter="num"
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceAccount') }, 'Input__Round')}
                                />

                                {useCaseError.sourceAccount !== undefined ? <small className="p-error">{useCaseError.sourceAccount}</small> : getFormErrorMessage('sourceAccount')}
                            </div>
                        </div>

                        <div className="p-field  md:col-2  mt-4">
                            <div className="Down__Btn__Configuration">
                                <Button type="button" label="Fetch" onClick={() => handleFetch()} className="Btn__Add" />
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="sourceAccountNick" className={classNames({ 'p-error': isFormFieldValid('sourceAccountNick') }, 'Label__Text')}>
                                    Source Account Nick<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    id="sourceAccountNick"
                                    placeholder="Enter Source Account Nick"
                                    name="sourceAccountNick"
                                    disabled
                                    value={formik?.values?.sourceAccountNick || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('sourceAccountNick') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('sourceAccountNick')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="disbursementDescr" className={classNames({ 'p-error': isFormFieldValid('disbursementDescr') }, 'Label__Text')}>
                                    Description
                                </label>
                                <InputTextarea
                                    id="disbursementDescr"
                                    name="disbursementDescr"
                                    value={formik?.values?.disbursementDescr}
                                    rows={5}
                                    cols={10}
                                    autoResize="false"
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('disbursementDescr') }, 'TextArea__Round')}
                                />
                                {getFormErrorMessage('disbursementDescr')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3 " style={{ marginTop: '-4%' }}>
                            <label htmlFor="useCaseDescr" className={classNames({ 'p-error': isFormFieldValid('useCaseDescr') }, 'Label__Text')}>
                                File Source
                            </label>
                            <div className="flex" style={{ marginLeft: '1%' }}>
                                <div className="flex mr-5">
                                    <RadioButton inputId="fileSourceUpload" name="fileSource" value="upload" onChange={formik.handleChange} checked={formik.values.fileSource === 'upload'} />
                                    <label htmlFor="fileSource" className="ml-2">
                                        CSV
                                    </label>
                                </div>
                                <div style={{ marginLeft: '5rem' }} className="flex mr-3">
                                    <RadioButton inputId="fileSourceFtp" name="fileSource" value="ftp" onChange={formik.handleChange} checked={formik.values.fileSource === 'ftp'} />
                                    <label htmlFor="fileSource" className="ml-2">
                                        FTP
                                    </label>
                                </div>
                            </div>
                            {getFormErrorMessage('isSearchable')}
                        </div>
                        {formik.values.fileSource === 'upload' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <div className="mb-2">
                                            <label className={classNames({ 'p-error': isFormFieldValid('digitalSigningInput') }, 'Label__Text')}>
                                                Disbursement File Location-
                                                <a className="cursor-pointer ml-2" onClick={() => downloadSample()}>
                                                    Download Sample File
                                                </a>
                                                <span className="Label__Required">*</span>
                                            </label>
                                            <div className="file-input-container w-100" onClick={handleDigitalSigningInput}>
                                                <input type="file" accept=".csv" style={{ display: 'none' }} ref={digitalSigningRef} onChange={digitalSigningChange} />
                                                <input
                                                    type="text"
                                                    className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('digitalSigningInput') }, 'file-input-field Input__Round')}
                                                    placeholder="Choose a file..."
                                                    value={formik.values.digitalSigningInput}
                                                    readOnly
                                                />
                                                <span className="file-upload-icon">
                                                    <i className="pi pi-paperclip" />
                                                </span>
                                            </div>
                                            {dsFileError ? <small className="p-error">{dsFileError}</small> : getFormErrorMessage('digitalSigningInput')}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}

                        {formik.values.fileSource === 'upload' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="disbursementOccurence" className={classNames({ 'p-error': isFormFieldValid('disbursementOccurence') }, 'Label__Text')}>
                                            Disbursement Occurence<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="partner"
                                            placeholder="Select Disbursement Occurence"
                                            options={distrubanceOccurence}
                                            optionLabel="label"
                                            name="disbursementOccurence"
                                            optionValue="lovId"
                                            value={formik.values.disbursementOccurence || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('disbursementOccurence') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('disbursementOccurence')}
                                    </div>
                                </div>
                                {formik.values.disbursementOccurence === 'S' && (
                                    <>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="dateTime" className={classNames({ 'p-error': isFormFieldValid('dateTimeCsv') }, 'Label__Text')}>
                                                    Date Time Picker
                                                </label>

                                                <InputText
                                                    id="dateTimeCsv"
                                                    placeholder="Enter  Start Date & Time"
                                                    name="dateTimeCsv"
                                                    type="datetime-local"
                                                    min={getCurrentDateTime()}
                                                    value={formik?.values?.dateTimeCsv || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTimeCsv') }, 'Input__Round')}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isActiveCsv" className={classNames({ 'p-error': isFormFieldValid('isActiveCsv') }, 'Label__Text')}>
                                                    Recursive
                                                </label>
                                                <div className="field-checkbox" style={{ marginLeft: '4px' }}>
                                                    <Checkbox inputId="isActiveCsv" name="isActiveCsv" value={formik.values.isActiveCsv} checked={formik.values.isActiveCsv} onChange={formik.handleChange} />
                                                </div>
                                                {formik.values.isActiveCsv === false ? getFormErrorMessage('isActiveCsv') : ''}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}

                        {formik.values.fileSource === 'ftp' && (
                            <>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="ftpFileName" className={classNames({ 'p-error': isFormFieldValid('ftpFileName') }, 'Label__Text')}>
                                            File<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="ftpFileName"
                                            placeholder="Select Disbursement Occurence"
                                            options={ftpFileData}
                                            optionLabel="ftpFileName"
                                            name="ftpFileName"
                                            optionValue="ftpFilePath"
                                            value={formik.values.ftpFileName || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('ftpFileName') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('ftpFileName')}
                                    </div>
                                </div>
                                <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                    <div className="p-field">
                                        <label htmlFor="disbursementOccurences" className={classNames({ 'p-error': isFormFieldValid('disbursementOccurences') }, 'Label__Text')}>
                                            Disbursement Occurence<span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="partner"
                                            placeholder="Select Disbursement Occurence"
                                            options={distrubanceOccurence}
                                            optionLabel="label"
                                            name="disbursementOccurences"
                                            optionValue="lovId"
                                            value={formik.values.disbursementOccurences || ''}
                                            onChange={formik.handleChange}
                                            className={classNames({ 'p-invalid': isFormFieldValid('disbursementOccurences') }, 'Dropdown__Round')}
                                        />
                                        {getFormErrorMessage('disbursementOccurences')}
                                    </div>
                                </div>
                                {formik.values.disbursementOccurences === 'S' && (
                                    <>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="dateTime" className={classNames({ 'p-error': isFormFieldValid('dateTime') }, 'Label__Text')}>
                                                    Date Time Picker
                                                </label>

                                                <InputText
                                                    id="dateTime"
                                                    placeholder="Enter  Start Date & Time"
                                                    name="dateTime"
                                                    type="datetime-local"
                                                    min={getCurrentDateTime()}
                                                    value={formik?.values?.dateTime || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dateTime') }, 'Input__Round')}
                                                />
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                            <div className="p-field">
                                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                                    Recursive <span className="Label__Required">*</span>
                                                </label>
                                                <div className="field-checkbox" style={{ marginLeft: '4px' }}>
                                                    <Checkbox inputId="isActive" name="isActive" value={formik.values.isActive} checked={formik.values.isActive} onChange={formik.handleChange} />
                                                </div>
                                                {formik.values.isActive === false ? getFormErrorMessage('isActive') : ''}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    <div className="Down__Btn">
                        <Button type="submit" loadingIcon={loadingIcon} label="Submit" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/disbursementFile')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default DisbursementUploadPage;
