import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleGetRequest } from '../../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';

function ViewAgentMateProductChecker() {
    const [mainData, setMainData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [selectedSubCategoryname, setSelectedSubCategoryname] = useState([]);
    const [getByIdData, setGetByIdData] = useState([]);

    let { search } = useLocation();
    const navigate = useNavigate();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const tableName = query.get('tableName');

    const otpTypeLookup = [
        { lovId: '1', name: 'Numeric' },
        { lovId: '2', name: 'Alpha Numeric' }
    ];

    const formik = useFormik({
        initialValues: {
            amCmsProductId: '',
            amCmsCategoryId: '',
            amCmsSubCategoryId: '',
            biometricFlow: '',
            addCheck: false,
            cmsTagBgColorId: '',
            cmsTagLabelId: '',
            cmsTagTextColorId: '',
            dailyCreditLimit: '',
            dailyDebitLimit: '',
            expiryDate: '',
            isActive: '',
            isHide: '',
            monthlyCreditLimit: '',
            monthlyDebitLimit: '',
            nameEnglish: '',
            nameUrdu: '',
            otpFlow: '',
            otpLength: '',
            otpAttempts: '',
            otpTimer: '',
            otpType: '',
            timeHours: '',
            priority: '',
            productCode: '',
            productIcon: '',
            productId: '',
            productName: '',
            agentRecepient: '',
            receiverRecepient: '',
            senderRecepient: '',
            smsResponse: '',
            smsText: '',
            yearlyCreditLimit: '',
            yearlyDebitLimit: '',
            action: '',
            checkerComments: ''
        }
    });

    const getCategoryDataLov = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getagentmatecategorydatalov');
        setMainData(res?.payLoad);
    };

    const getSubCategoryDataLov = async () => {
        const res = await handleGetRequest(`/configuration/v1/lookups/getagentmatesubcategorydatalov/${formik?.values?.amCmsCategoryId}`);
        setSubData(res?.payLoad);
    };

    useEffect(() => {
        const findSubcategory = subData?.filter((item) => item?.lovId == getByIdData)[0]?.name;
        setSelectedSubCategoryname(findSubcategory);
    }, [subData, getByIdData]);

    useEffect(() => {
        if (formik?.values?.amCmsCategoryId != '') {
            getSubCategoryDataLov();
        }
    }, [formik?.values?.amCmsCategoryId]);

    useEffect(() => {
        getCategoryDataLov();
    }, []);

    const getProductDetailsById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getproductbyid/${refTableId}`);
        const productDetails = res?.payLoad;
        setGetByIdData(productDetails?.amCmsSubCategoryId);

        if (productDetails) {
            formik.setFieldValue('amCmsProductId', productDetails?.amCmsProductId || '');
            formik.setFieldValue('productName', productDetails?.productName || '');
            formik.setFieldValue('nameEnglish', productDetails?.nameEnglish || '');
            formik.setFieldValue('nameUrdu', productDetails?.nameUrdu || '');
            formik.setFieldValue('productId', productDetails?.productId || '');
            formik.setFieldValue('priority', productDetails?.priority || '');
            formik.setFieldValue('isHide', productDetails?.isHide || '');
            formik.setFieldValue('amCmsCategoryId', productDetails?.amCmsCategoryId?.toString() || '');
            formik.setFieldValue('amCmsSubCategoryId', productDetails?.amCmsSubCategoryId?.toString() || '');
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabelId || productDetails?.cmsTagTextColorId || productDetails?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsTagLabelId', productDetails?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', productDetails?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagBgColorId', productDetails?.cmsTagBgColorId || '');
            const correctEndDate = formattedDate(productDetails?.expiryDate || '');
            formik.setFieldValue('expiryDate', correctEndDate || '');
            formik.setFieldValue('smsText', productDetails?.smsText || '');
            formik.setFieldValue('dailyCreditLimit', productDetails?.dailyCreditLimit || '');
            formik.setFieldValue('dailyDebitLimit', productDetails?.dailyDebitLimit || '');
            formik.setFieldValue('monthlyCreditLimit', productDetails?.monthlyCreditLimit || '');
            formik.setFieldValue('monthlyDebitLimit', productDetails?.monthlyDebitLimit || '');
            formik.setFieldValue('yearlyCreditLimit', productDetails?.yearlyCreditLimit || '');
            formik.setFieldValue('yearlyDebitLimit', productDetails?.yearlyDebitLimit || '');
            formik.setFieldValue('otpFlow', productDetails?.otpFlow || '');
            formik.setFieldValue('otpLength', productDetails?.otpLength || '');
            formik.setFieldValue('otpType', productDetails?.otpType || '');
            formik.setFieldValue('otpAttempts', productDetails?.otpAttempts || '');
            formik.setFieldValue('otpTimer', productDetails?.otpTimer || '');
            formik.setFieldValue('timeHours', String(productDetails?.timeHours ?? ''));
            formik.setFieldValue('biometricFlow', productDetails?.biometricFlow || '');
            formik.setFieldValue('smsResponse', productDetails?.smsResponse || '');
            formik.setFieldValue('agentRecepient', productDetails?.agentRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('receiverRecepient', productDetails?.receiverRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('senderRecepient', productDetails?.senderRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('isActive', productDetails?.isActive || '');

            const pathParts = productDetails?.productIcon?.split('/');
            const categoryIcon = pathParts[pathParts.length - 1];
            formik.setFieldValue('productIcon', categoryIcon || '');
        }
    };
    const getMcRequestDataById = async () => {
        const res = await handleGetRequest(`/agentmatecms/v1/agentmatecms/getmcrequestbyid/${mcRequestId}`);
        const unParsedkeyData = res?.payLoad?.updateJson;
        const productDetails = JSON.parse(unParsedkeyData);
        setGetByIdData(productDetails?.amCmsSubCategoryId);

        if (productDetails) {
            formik.setFieldValue('amCmsProductId', productDetails?.amCmsProductId || '');
            formik.setFieldValue('productName', productDetails?.productName || '');
            formik.setFieldValue('nameEnglish', productDetails?.nameEnglish || '');
            formik.setFieldValue('nameUrdu', productDetails?.nameUrdu || '');
            formik.setFieldValue('productId', productDetails?.productId || '');
            formik.setFieldValue('priority', productDetails?.priority || '');
            formik.setFieldValue('isHide', productDetails?.isHide || '');
            formik.setFieldValue('amCmsCategoryId', productDetails?.amCmsCategoryId?.toString() || '');
            formik.setFieldValue('amCmsSubCategoryId', productDetails?.amCmsSubCategoryId?.toString() || '');
            formik.setFieldValue('addCheck', productDetails?.cmsTagLabelId || productDetails?.cmsTagTextColorId || productDetails?.cmsTagTextBgColorId ? true : false);
            formik.setFieldValue('cmsTagLabelId', productDetails?.cmsTagLabelId || '');
            formik.setFieldValue('cmsTagTextColorId', productDetails?.cmsTagTextColorId || '');
            formik.setFieldValue('cmsTagBgColorId', productDetails?.cmsTagBgColorId || '');
            const correctEndDate = formattedDate(productDetails?.expiryDate || '');
            formik.setFieldValue('expiryDate', correctEndDate || '');
            formik.setFieldValue('smsText', productDetails?.smsText || '');
            formik.setFieldValue('dailyCreditLimit', productDetails?.dailyCreditLimit || '');
            formik.setFieldValue('dailyDebitLimit', productDetails?.dailyDebitLimit || '');
            formik.setFieldValue('monthlyCreditLimit', productDetails?.monthlyCreditLimit || '');
            formik.setFieldValue('monthlyDebitLimit', productDetails?.monthlyDebitLimit || '');
            formik.setFieldValue('yearlyCreditLimit', productDetails?.yearlyCreditLimit || '');
            formik.setFieldValue('yearlyDebitLimit', productDetails?.yearlyDebitLimit || '');
            formik.setFieldValue('otpFlow', productDetails?.otpFlow || '');
            formik.setFieldValue('otpLength', productDetails?.otpLength || '');
            formik.setFieldValue('otpType', productDetails?.otpType || '');
            formik.setFieldValue('otpAttempts', productDetails?.otpAttempts || '');
            formik.setFieldValue('otpTimer', productDetails?.otpTimer || '');
            formik.setFieldValue('timeHours', String(productDetails?.timeHours ?? ''));
            formik.setFieldValue('biometricFlow', productDetails?.biometricFlow || '');
            formik.setFieldValue('smsResponse', productDetails?.smsResponse || '');
            formik.setFieldValue('agentRecepient', productDetails?.agentRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('receiverRecepient', productDetails?.receiverRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('senderRecepient', productDetails?.senderRecepient === 'Y' ? true : false || '');
            formik.setFieldValue('isActive', productDetails?.isActive || '');

            const pathParts = productDetails?.productIcon?.split('/');
            const categoryIcon = pathParts[pathParts.length - 1];
            formik.setFieldValue('productIcon', categoryIcon || '');
        }
    };

    useEffect(() => {
        if (requestType === 'I' && subData) {
            getProductDetailsById();
        } else if (requestType === 'U') {
            getMcRequestDataById();
        }
    }, [requestType, subData]);

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());

    function formattedDate(originalDate) {
        const createDate = new Date(originalDate);

        const year = createDate.getFullYear();
        const month = (createDate.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const day = createDate.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const getContrastColor = (hexColor) => {
        const r = parseInt(hexColor.substr(1, 2), 16);
        const g = parseInt(hexColor.substr(3, 2), 16);
        const b = parseInt(hexColor.substr(5, 2), 16);
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        return luminance > 0.5 ? '#000000' : '#FFFFFF';
    };
    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header Full__Width">
                    <h2>VIEW DETAIL</h2>
                </div>

                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productId" className={classNames({ 'p-error': isFormFieldValid('productId') }, 'Label__Text')}>
                                    Product ID <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productId"
                                    placeholder="Enter product ID"
                                    name="productId"
                                    value={formik?.values?.productId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('productId') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productId')}
                            </div>
                        </div>
                        <dvi className="p-field col-12 md:col-8 pt-5 pb-3">
                            <div className="Down__Btn justify-content-start mt-2">
                                <Button type="button" label="Get" className="Btn__Dark" />
                            </div>
                        </dvi>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Product Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder="Enter product name"
                                    name="productName"
                                    value={formik?.values?.productName || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="nameEnglish" className={classNames({ 'p-error': isFormFieldValid('nameEnglish') }, 'Label__Text')}>
                                    Display Name (English)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="nameEnglish"
                                    placeholder="Enter display name"
                                    name="nameEnglish"
                                    value={formik?.values?.nameEnglish || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('nameEnglish') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('nameEnglish')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="nameUrdu" className={classNames({ 'p-error': isFormFieldValid('nameUrdu') }, 'Label__Text')}>
                                    Display Name (Urdu)<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="nameUrdu"
                                    placeholder="Enter display name"
                                    name="nameUrdu"
                                    value={formik?.values?.nameUrdu || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('nameUrdu') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('nameUrdu')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="priority" className={classNames({ 'p-error': isFormFieldValid('priority') }, 'Label__Text')}>
                                    Product Priority<span className="Label__Required">*</span>
                                </label>
                                <InputText id="priority" name="priority" value={formik?.values?.priority || ''} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('priority') }, 'Input__Round')} disabled />

                                {getFormErrorMessage('priority')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <div className="mb-2">
                                    <label className={classNames({ 'p-error': isFormFieldValid('productIcon') }, 'Label__Text')}>
                                        Product Icon <span className="Label__Required">*</span>
                                    </label>
                                    <div className="file-input-container w-100">
                                        <input type="file" accept="image/*" style={{ display: 'none' }} />
                                        <input
                                            type="text"
                                            className={classNames({ 'p-inputtext p-component p-invalid': isFormFieldValid('productIcon') }, 'file-input-field Input__Round')}
                                            placeholder="Choose a file..."
                                            value={formik.values.productIcon}
                                            disabled
                                        />
                                        <span className="file-upload-icon">
                                            <i className="pi pi-paperclip" />
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3"></div>
                        <div className="p-field col-12 md:col-1 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="addCheck" className={classNames({ 'p-error': isFormFieldValid('addCheck') }, 'Label__Text')}>
                                    Add Tag
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="addCheck" name="addCheck" checked={formik?.values?.addCheck} onChange={formik.handleChange} style={{ marginTop: '10px' }} disabled />
                                </div>
                                {getFormErrorMessage('addCheck')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-3 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagLabelId" className={classNames({ 'p-error': isFormFieldValid('cmsTagLabelId') }, 'Label__Text')}>
                                    Tag Label
                                </label>
                                <InputText
                                    id="cmsTagLabelId"
                                    placeholder="Enter Tag Label"
                                    name="cmsTagLabelId"
                                    disabled
                                    value={formik?.values?.cmsTagLabelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagLabelId') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('cmsTagLabelId')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagTextColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagTextColorId') }, 'Label__Text')}>
                                    Tag Text Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagTextColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagTextColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagTextColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagTextColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagTextColorId || '#FFFFFF')
                                    }}
                                />
                                {getFormErrorMessage('cmsTagTextColorId')}{' '}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cmsTagBgColorId" className={classNames({ 'p-error': isFormFieldValid('cmsTagBgColorId') }, 'Label__Text')}>
                                    Tag Background Color
                                </label>
                                <InputText
                                    value={formik?.values?.cmsTagBgColorId || '#FFFFFF'}
                                    onChange={(e) => {
                                        formik.setFieldValue('cmsTagBgColorId', e.target.value);
                                    }}
                                    disabled
                                    readOnly
                                    className={classNames({ 'p-invalid': isFormFieldValid('cmsTagBgColorId') }, 'Input__Round')}
                                    style={{
                                        backgroundColor: formik?.values?.cmsTagBgColorId || '#FFFFFF',
                                        color: getContrastColor(formik?.values?.cmsTagBgColorId || '#FFFFFF')
                                    }}
                                />

                                {getFormErrorMessage('cmsTagBgColorId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="expiryDate" className={classNames({ 'p-error': isFormFieldValid('expiryDate') }, 'Label__Text')}>
                                    Tag Expiry Date
                                </label>
                                <InputText
                                    type="date"
                                    id="expiryDate"
                                    name="expiryDate"
                                    value={formik?.values?.expiryDate || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('expiryDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('expiryDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amCmsCategoryId" className={classNames({ 'p-error': isFormFieldValid('amCmsCategoryId') }, 'Label__Text')}>
                                    Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="amCmsCategoryId"
                                    placeholder="Select Category"
                                    options={mainData}
                                    optionLabel="name"
                                    name="amCmsCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.amCmsCategoryId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('amCmsCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('amCmsCategoryId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="amCmsSubCategoryId" className={classNames({ 'p-error': isFormFieldValid('amCmsSubCategoryId') }, 'Label__Text')}>
                                    Sub Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="amCmsSubCategoryId"
                                    placeholder="Select Sub Category"
                                    options={subData}
                                    optionLabel="name"
                                    name="amCmsSubCategoryId"
                                    optionValue="lovId"
                                    value={formik.values.amCmsSubCategoryId || ''}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        const findSubcategory = subData?.filter((item) => item?.lovId === e.target.value)[0]?.name;
                                        setSelectedSubCategoryname(findSubcategory);
                                    }}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('amCmsSubCategoryId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('amCmsSubCategoryId')}
                            </div>
                        </div>
                        {selectedSubCategoryname === 'IBFT' && (
                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>Transaction Limit</b>
                                </h6>
                                <div className="p-fluid p-formgrid grid mb-5">
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                        <h6 className="secondary-color pt-1 text-center">
                                            <b>Debit</b>
                                        </h6>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="dailyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('dailyDebitLimit') }, 'Label__Text')}>
                                                    Daily Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="dailyDebitLimit"
                                                    placeholder="-"
                                                    name="dailyDebitLimit"
                                                    value={formik?.values?.dailyDebitLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyDebitLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('dailyDebitLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="monthlyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('monthlyDebitLimit') }, 'Label__Text')}>
                                                    Monthly Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="monthlyDebitLimit"
                                                    placeholder="-"
                                                    name="monthlyDebitLimit"
                                                    value={formik?.values?.monthlyDebitLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyDebitLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('monthlyDebitLimit')}
                                            </div>
                                        </div>{' '}
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="yearlyDebitLimit" className={classNames({ 'p-error': isFormFieldValid('yearlyDebitLimit') }, 'Label__Text')}>
                                                    Yearly Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="yearlyDebitLimit"
                                                    placeholder="-"
                                                    name="yearlyDebitLimit"
                                                    value={formik?.values?.yearlyDebitLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyDebitLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('yearlyDebitLimit')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                        <h6 className="secondary-color pt-1 text-center">
                                            <b>Credit</b>
                                        </h6>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="dailyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('dailyCreditLimit') }, 'Label__Text')}>
                                                    Daily Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="dailyCreditLimit"
                                                    placeholder="-"
                                                    name="dailyCreditLimit"
                                                    value={formik?.values?.dailyCreditLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('dailyCreditLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('dailyCreditLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="monthlyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('monthlyCreditLimit') }, 'Label__Text')}>
                                                    Monthly Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="monthlyCreditLimit"
                                                    placeholder="-"
                                                    name="monthlyCreditLimit"
                                                    value={formik?.values?.monthlyCreditLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('monthlyCreditLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('monthlyCreditLimit')}
                                            </div>
                                        </div>
                                        <div className="p-field col-12 md:col-8 pt-3 pb-3 mx-auto">
                                            <div className="p-field">
                                                <label htmlFor="yearlyCreditLimit" className={classNames({ 'p-error': isFormFieldValid('yearlyCreditLimit') }, 'Label__Text')}>
                                                    Yearly Limit
                                                </label>
                                                <InputText
                                                    maxLength={100}
                                                    id="yearlyCreditLimit"
                                                    placeholder="-"
                                                    name="yearlyCreditLimit"
                                                    value={formik?.values?.yearlyCreditLimit || ''}
                                                    onChange={formik.handleChange}
                                                    className={classNames({ 'p-invalid': isFormFieldValid('yearlyCreditLimit') }, 'Input__Round')}
                                                    disabled
                                                />

                                                {getFormErrorMessage('yearlyCreditLimit')}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isHide" className={classNames({ 'p-error': isFormFieldValid('isHide') }, 'Label__Text')}>
                                    Status
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="show" name="isHide" value="Y" checked={formik?.values?.isHide === 'Y'} onChange={(e) => formik.setFieldValue('isHide', 'Y')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="show" className={classNames({ 'p-error': isFormFieldValid('show') }, 'Label__Text')}>
                                            Show
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="hide" name="isHide" value="N" checked={formik?.values?.isHide === 'N'} onChange={(e) => formik.setFieldValue('isHide', 'N')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="hide" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                            Hide
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="biometricFlow" className={classNames({ 'p-error': isFormFieldValid('biometricFlow') }, 'Label__Text')}>
                                    Biometric Flow
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="enable" name="biometricFlow" value="Y" checked={formik?.values?.biometricFlow === 'Y'} onChange={(e) => formik.setFieldValue('biometricFlow', 'Y')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="disable" name="biometricFlow" value="N" checked={formik?.values?.biometricFlow === 'N'} onChange={(e) => formik.setFieldValue('biometricFlow', 'N')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="otpFlow" className={classNames({ 'p-error': isFormFieldValid('otpFlow') }, 'Label__Text')}>
                                    OTP Flow
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="enable" name="otpFlow" value="Y" checked={formik?.values?.otpFlow === 'Y'} onChange={(e) => formik.setFieldValue('otpFlow', 'Y')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="disable" name="otpFlow" value="N" checked={formik?.values?.otpFlow === 'N'} onChange={(e) => formik.setFieldValue('otpFlow', 'N')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="smsResponse" className={classNames({ 'p-error': isFormFieldValid('smsResponse') }, 'Label__Text')}>
                                    SMS Response
                                </label>
                                <div className="flex ml-2">
                                    <div className="mt-0 mr-5">
                                        <Checkbox inputId="enable" name="smsResponse" value="Y" checked={formik?.values?.smsResponse === 'Y'} onChange={(e) => formik.setFieldValue('smsResponse', 'Y')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="enable" className={classNames({ 'p-error': isFormFieldValid('enable') }, 'Label__Text')}>
                                            Enable
                                        </label>
                                    </div>
                                    <div className="mt-0">
                                        <Checkbox inputId="disable" name="smsResponse" value="N" checked={formik?.values?.smsResponse === 'N'} onChange={(e) => formik.setFieldValue('smsResponse', 'N')} style={{ marginTop: '10px' }} disabled />
                                        <label htmlFor="disable" className={classNames({ 'p-error': isFormFieldValid('disable') }, 'Label__Text')}>
                                            Disable
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {formik?.values?.smsResponse === 'Y' && (
                            <div className="p-field col-12 md:col-8  pt-3 pb-3">
                                <div className="desc_Text_Area">
                                    <label htmlFor="smsText" className={classNames({ 'p-error': isFormFieldValid('smsText') }, 'Label__Text')}>
                                        SMS Text <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        placeholder="-"
                                        inputid="smsText"
                                        name="smsText"
                                        rows={4}
                                        cols={30}
                                        onChange={formik.handleChange}
                                        value={formik?.values?.smsText}
                                        className={classNames({ 'p-invalid': isFormFieldValid('smsText') }, 'Input__Round')}
                                        disabled
                                    />
                                    {getFormErrorMessage('smsText')}
                                </div>
                            </div>
                        )}

                        <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                            <div className="grid">
                                <div className="p-field col-12 md:col-6 pt-3 pb-3">
                                    <div className="p-field">
                                        <h6 className="secondary-color pt-1">
                                            <b>SMS Recipient</b>
                                        </h6>
                                        <div className="flex ml-2">
                                            <div className="mt-0 mr-5">
                                                <Checkbox
                                                    inputId="agentRecepient"
                                                    name="agentRecepient"
                                                    value={formik?.values?.agentRecepient}
                                                    checked={formik?.values?.agentRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    style={{ marginTop: '10px' }}
                                                />{' '}
                                                <label htmlFor="agentRecepient" className={classNames({ 'p-error': isFormFieldValid('agentRecepient') }, 'Label__Text')}>
                                                    Agent Recipient
                                                </label>
                                            </div>
                                            <div className="mt-0 mr-5">
                                                <Checkbox
                                                    inputId="senderRecepient"
                                                    name="senderRecepient"
                                                    value={formik?.values?.senderRecepient}
                                                    checked={formik?.values?.senderRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    style={{ marginTop: '10px' }}
                                                />

                                                <label htmlFor="senderRecepient" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                    Sender Recipient
                                                </label>
                                            </div>
                                            <div className="mt-0">
                                                <Checkbox
                                                    inputId="receiverRecepient"
                                                    name="receiverRecepient"
                                                    value={formik?.values?.receiverRecepient}
                                                    checked={formik?.values?.receiverRecepient}
                                                    onChange={formik.handleChange}
                                                    disabled
                                                    style={{ marginTop: '10px' }}
                                                />

                                                <label htmlFor="receiverRecepient" className={classNames({ 'p-error': isFormFieldValid('hide') }, 'Label__Text')}>
                                                    Reciever Recipient
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {formik.values.otpFlow === 'Y' && (
                            <div className="card Card__Bg__Change col-12 md:col-12 px-3">
                                <h6 className="secondary-color pt-1">
                                    <b>OTP</b>
                                </h6>
                                <div className="grid">
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpLength" className={classNames({ 'p-error': isFormFieldValid('otpLength') }, 'Label__Text')}>
                                                OTP Length
                                            </label>
                                            <InputText
                                                maxLength={100}
                                                id="otpLength"
                                                placeholder="Enter OTP Length"
                                                name="otpLength"
                                                value={formik?.values?.otpLength || ''}
                                                onChange={formik.handleChange}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpLength') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpLength')}
                                        </div>
                                    </div>{' '}
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpType" className={classNames({ 'p-error': isFormFieldValid('otpType') }, 'Label__Text')}>
                                                OTP Type
                                            </label>
                                            <Dropdown
                                                id="otpType"
                                                placeholder="Select OTP Type"
                                                options={otpTypeLookup}
                                                optionLabel="name"
                                                name="otpType"
                                                optionValue="lovId"
                                                value={formik?.values?.otpType || ''}
                                                onChange={(e) => formik.setFieldValue('otpType', e.value)}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpType') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('otpType')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpAttempts" className={classNames({ 'p-error': isFormFieldValid('otpAttempts') }, 'Label__Text')}>
                                                OTP Attempts
                                            </label>
                                            <InputText
                                                maxLength={100}
                                                id="otpAttempts"
                                                placeholder="Enter OTP Attempts"
                                                name="otpAttempts"
                                                value={formik?.values?.otpAttempts || ''}
                                                onChange={formik.handleChange}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpAttempts') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpAttempts')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="otpTimer" className={classNames({ 'p-error': isFormFieldValid('otpTimer') }, 'Label__Text')}>
                                                OTP Timer
                                            </label>
                                            <InputText
                                                maxLength={100}
                                                id="otpTimer"
                                                placeholder="Enter OTP Timer"
                                                name="otpTimer"
                                                value={formik?.values?.otpTimer || ''}
                                                onChange={formik.handleChange}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('otpTimer') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('otpTimer')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="timeHours" className={classNames({ 'p-error': isFormFieldValid('timeHours') }, 'Label__Text')}>
                                                Time Hours
                                            </label>
                                            <InputText
                                                maxLength={100}
                                                id="timeHours"
                                                placeholder="Enter OTP in Hours"
                                                name="timeHours"
                                                value={formik?.values?.timeHours || ''}
                                                onChange={formik.handleChange}
                                                disabled
                                                className={classNames({ 'p-invalid': isFormFieldValid('timeHours') }, 'Input__Round')}
                                            />

                                            {getFormErrorMessage('timeHours')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                    </div>
                </form>
            </div>
            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default ViewAgentMateProductChecker;
