import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import * as Yup from 'yup';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import TransactionConfigOldNewValuesView from './TransactionsConfigOldNewValuesView';
import { Sidebar } from 'primereact/sidebar';

function EditTransactionConfigChecker() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [transactionClassificationData, setTransactionClassificationData] = useState([]);
    const [currencyData, setCurrencyData] = useState([]);
    const [accountTypeTableData, setAccountTypeTableData] = useState([]);
    const [fieldTableData, setFieldTableData] = useState([]);
    const [statusLovData, setStatusLovData] = useState([]);
    const [accountTypeLovData, setAccountTypeLovData] = useState([]);
    const [requestedDataById, setRequestedDataByID] = useState();
    const [productCategoryLovData, setProductCategoryLovData] = useState([]);

    const [oldNewData, setOldNewData] = useState([]);
    const [highlightedKeys, setHighlightedKeys] = useState([]);
    const [transactionConfigRowData, setTransactionConfigRowData] = useState();
    const [displayDialog, setDisplayDialog] = useState();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const getAllCurrencyData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*');
        setCurrencyData(res?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = resp?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountTypeLovData(res?.payLoad);
    };

    const getProductCategoryData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getproductCategory');
        setProductCategoryLovData(res?.payLoad);
    };

    useEffect(() => {
        getAllCurrencyData();
        getStatusLov();
        getAccountTypeData();
        getProductCategoryData();
    }, []); // eslint-disable-line

    const getOldNewData = async () => {
        const resp = await handleGetRequest(`/workflow/v1/management/compareValues/${mcRequestId}`);
        setOldNewData(resp?.payLoad);
    };

    useEffect(() => {
        if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getOldNewData();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        const keys = oldNewData?.map((item) => item.key);
        setHighlightedKeys(keys);
    }, [oldNewData]);

    const getTransConfigDataById = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionById/${refTableId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik?.setFieldValue('lkpProductCategory', keyData?.lkpProductCategory?.productCategoryId.toString());

            setTransactionClassificationData(keyData?.tblTransPatterns);
            setAccountTypeTableData(keyData?.tblProductLimits);
            setFieldTableData(keyData?.tblProductFields);
        }
    };

    const getTransConfigDataByIdU = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionCheckerById/${mcRequestId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik?.setFieldValue('lkpProductCategory', keyData?.lkpProductCategory?.toString());

            setTransactionClassificationData(keyData?.tblTransPatterns);
            setAccountTypeTableData(keyData?.tblProductLimits);
            setFieldTableData(keyData?.tblProductFields);
        }
    };

    const getMcRequestDataByIdActiveInactiveU = async () => {
        setloading(true);

        const res = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionCheckerById/${mcRequestId}`);
        const keyData = res?.payLoad;

        setloading(false);

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik.setFieldValue(key, keyData[key]);
                }
            });
            formik.setFieldValue('isActive', keyData?.isActive === 'Y' ? true : false);
            // formik?.setFieldValue('lkpProductCategoryId', keyData?.lkpProductCategoryId?.toString());
            formik?.setFieldValue('lkpProductCategory', keyData?.lkpProductCategory?.productCategoryId?.toString());
        }
        setRequestedDataByID(res?.payLoad);
    };

    const getRequestedTransConfigConditionalData = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionById/${requestedDataById?.productId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik?.setFieldValue('lkpProductCategory', keyData?.lkpProductCategory?.productCategoryId?.toString());
            setTransactionClassificationData(keyData?.tblTransPatterns);
            setAccountTypeTableData(keyData?.tblProductLimits);
            setFieldTableData(keyData?.tblProductFields);
        }
    };

    useEffect(() => {
        if (requestType === 'I') {
            getTransConfigDataById();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getTransConfigDataByIdU();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getMcRequestDataByIdActiveInactiveU();
        }
    }, []); // eslint-disable-line

    useEffect(() => {
        if (requestTypeDescr === 'Enable/Disable Request' && requestType === 'U' && requestedDataById?.productId !== undefined) {
            getRequestedTransConfigConditionalData();
        }
    }, [requestedDataById?.productId]); // eslint-disable-line

    const validationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required.'),
        checkerComments: Yup.string().required('This field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            productName: '',
            lkpProductCategory: '',
            isCoolingOffRequired: '',
            productCode: '',
            id: '',
            type: '',
            transClassification: '',
            transSign: '',
            currency: '',
            targetAccountType: '',
            accountNo: '',
            accountLevelId: '',
            minAmount: '',
            maxAmount: '',
            fieldName: '',
            fieldValue: '',
            checkerComments: '',
            action: '',
            isActive: ''
        },

        validate: (data) => {
            let errors = {};

            if (!data.productName) {
                errors.productName = 'This Field is required.';
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            data['isActive'] = requestedDataById?.isActive;
            if (requestTypeDescr !== 'Enable/Disable Request') {
                delete data['isActive'];
            }

            data['productId'] = refTableId;

            delete data['transClassification'];
            delete data['type'];
            delete data['accountNo'];
            delete data['accountLevelId'];
            delete data['currency'];
            delete data['fieldName'];
            delete data['fieldValue'];
            delete data['id'];
            delete data['maxAmount'];
            delete data['minAmount'];
            delete data['targetAccountType'];
            delete data['transSign'];
            delete data['currencyId'];
            delete data['exclude'];

            const fieldTableValues = fieldTableData.map((item) => ({
                fieldName: item.fieldName,
                fieldValue: item.fieldValue,
                productFieldId: item.productFieldId
            }));

            const accountTypeTableValue = accountTypeTableData.map((item) => ({
                accountLevelId: item.accountLevelId || item?.lkpAccountLevel?.accountLevelId,
                minAmount: item.minAmount,
                maxAmount: item.maxAmount,
                exclude: item.exclude,
                productLimitId: item.productLimitId
            }));

            const transactionClassificationTableValue = transactionClassificationData.map((item) => ({
                id: item.id,
                type: item.type,
                transClassification: item.transClassification,
                transSign: item.transSign,
                currencyId: item?.lkpCurrency?.currencyId || item?.currencyId,
                targetAccountType: item.targetAccountType,
                accountNo: item?.tblAccount?.accountNo || item?.accountNo,
                transPatternId: item.transPatternId,
                isMandatory: item.isMandatory
            }));

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        transactionRequest: {
                            productId: refTableId,
                            lkpProductCategory: data['lkpProductCategory'],
                            productName: data['productName'],
                            tblTransPatterns: transactionClassificationTableValue,
                            tblProductLimits: accountTypeTableValue,
                            tblProductFields: fieldTableValues,
                            isActive: data['isActive']
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: data['checkerComments'],
                            action: data['action'],
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/transactions/transactionCheckerAction', true, false, 'requestchecker'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getTypeData = [
        { name: 'Static', value: 'S' },
        { name: 'Dynamic', value: 'D' }
    ];

    const getTransSignData = [
        { name: 'Credit', value: 'C' },
        { name: 'Debit', value: 'D' }
    ];

    const handledDeleteTransClassData = (rowData) => {
        const newFilterData = transactionClassificationData.filter((value) => value.type !== rowData.type);
        setTransactionClassificationData(newFilterData);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button disabled tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={() => handledDeleteTransClassData(rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledTransClassificationAdd = (e) => {
        e.preventDefault();

        const transactionClassificationTableOldData = transactionClassificationData.map((item) => ({
            id: item.id,
            type: item.type,
            transClassification: item.transClassification,
            transSign: item.transSign,
            currencyId: item?.lkpCurrency?.currencyId,
            targetAccountType: item.targetAccountType,
            accountNo: item?.tblAccount?.accountId,
            transPatternId: item.transPatternId
        }));

        const transClassificationTableData = {
            id: formik.values.id,
            type: formik.values.type,
            transClassification: formik.values.transClassification,
            transSign: formik.values.transSign,
            currencyId: formik.values.currencyId,
            targetAccountType: formik.values.targetAccountType,
            accountNo: formik.values.accountNo
        };
        setTransactionClassificationData([...transactionClassificationTableOldData, transClassificationTableData]);

        Object.keys(transClassificationTableData).forEach((keys) => {
            formik.setFieldValue(keys, '');
        });
    };

    const handledAccountTypeAdd = (e) => {
        e.preventDefault();

        const accountTypeTableOldData = accountTypeTableData.map((item) => ({
            accountLevelId: item.accountLevelId,
            minAmount: item.minAmount,
            maxAmount: item.maxAmount,
            exclude: item.exclude,
            productLimitId: item.productLimitId
        }));

        const accountTypeObjData = {
            accountLevelId: formik.values.accountLevelId,
            minAmount: formik.values.minAmount,
            maxAmount: formik.values.maxAmount,
            exclude: formik.values.exclude ? 'Y' : 'N'
        };
        setAccountTypeTableData([...accountTypeTableOldData, accountTypeObjData]);

        formik.setFieldValue('accountLevelId', '');
        formik.setFieldValue('minAmount', '');
        formik.setFieldValue('maxAmount', '');
        formik.setFieldValue('exclude', '');
    };

    const handledDeleteAccountTypeData = (rowData) => {
        const newFilterData = accountTypeTableData.filter((value) => value.minAmount !== rowData.minAmount);
        setAccountTypeTableData(newFilterData);
    };

    const actionAccountTypeBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button disabled tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={() => handledDeleteAccountTypeData(rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledFieldsDataAdd = (e) => {
        e.preventDefault();

        const fieldTableOldData = fieldTableData.map((item) => ({
            fieldName: item.fieldName,
            fieldValue: item.fieldValue,
            productFieldId: item.productFieldId
        }));

        const fieldsObjData = {
            fieldName: formik.values.fieldName,
            fieldValue: formik.values.fieldValue
        };
        setFieldTableData([...fieldTableOldData, fieldsObjData]);

        formik.setFieldValue('fieldName', '');
        formik.setFieldValue('fieldValue', '');
    };

    const handledDeleteFieldsData = (e, rowData) => {
        e.preventDefault();

        const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
        setFieldTableData(newFilterData);
    };

    const actionFieldsBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button disabled tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const getTransClassificationData = [{ name: 'Customer' }, { name: 'Agent' }, { name: 'Merchant' }];

    const getTransAccountTypeData = [{ name: 'Customer' }, { name: 'Agent' }, { name: 'Merchant' }, { name: 'GL' }];

    // const accountLevelBodyTemplate = (rowData) => {
    //     return <p>{rowData?.lkpAccountLevel?.accountLevelId || rowData?.accountLevelId}</p>;
    // };

    const transClassificationBodyTemplate = (rowData) => {
        return <div>{rowData?.transClassification === 'C' ? 'Customer' : rowData?.transClassification === 'A' ? 'Agent' : 'Merchant'}</div>;
    };

    const targetAccountTypeBodyTemplate = (rowData) => {
        return <div>{rowData?.targetAccountType === 'C' ? 'Customer' : rowData?.targetAccountType === 'A' ? 'Agent' : rowData?.targetAccountType === 'M' ? 'Merchant' : rowData?.targetAccountType === 'G' ? 'GL' : '-'}</div>;
    };

    // const currencyTemplateBody = (rowData) => {
    //     return <div>{rowData?.currencyId ? rowData?.currencyId : rowData?.lkpCurrency?.currencyId ? rowData?.lkpCurrency?.currencyId : null}</div>;
    // };

    const accountNoBodyTemplate = (rowData) => {
        return <div>{rowData?.accountNo ? rowData?.accountNo : rowData?.tblAccount?.accountNo ? rowData?.tblAccount?.accountNo : null}</div>;
    };

    const typeBodyTemplate = (rowData) => {
        return <div>{rowData?.type === 'S' ? 'Static' : 'Dynamic'}</div>;
    };

    const transSignBodyTemplate = (rowData) => {
        return <div>{rowData?.transSign === 'C' ? 'Credit' : 'Debit'}</div>;
    };

    const currencyBodyTemplate = (rowData) => {
        if (requestType === 'I' || (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request')) {
            return <div>{rowData?.lkpCurrency?.currencyDescr ? rowData?.lkpCurrency?.currencyDescr : '-'}</div>;
        } else {
            const currency = currencyData?.find((value) => value?.lovId === rowData?.currencyId?.toString() && value?.name);
            return <div>{currency ? currency.name : '-'}</div>;
        }
    };

    const excludeBodyTemplate = (rowData) => {
        return <p>{rowData?.exclude === 'Y' ? 'YES' : 'NO'}</p>;
    };

    const accountTypeBodyTemplate = (rowData) => {
        const rowDataAccountLevelId = rowData?.lkpAccountLevel?.accountLevelId || rowData?.accountLevelId;
        const accountLevelIdData = accountTypeLovData.filter((item) => item.lovId === rowDataAccountLevelId.toString());

        return <p>{accountLevelIdData[0]?.name}</p>;
    };

    const HandledOldValue = async (e) => {
        e.preventDefault();
        setDisplayDialog(true);
        const resp = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionById/${refTableId}`);
        setTransactionConfigRowData(resp?.payLoad);
    };

    return (
        <>
            <Sidebar header="View Channel" position="right" style={{ width: '70vw' }} visible={displayDialog} onHide={() => setDisplayDialog(false)}>
                <TransactionConfigOldNewValuesView transactionConfigRowData={transactionConfigRowData} />
            </Sidebar>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT DETAILS</h2>
                            {requestType === 'U' && <Button label="View Old Details" className="Btn__Dark__OldValue" onClick={HandledOldValue} />}
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Transaction Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled
                                    maxLength={100}
                                    id="productName"
                                    placeholder="Enter transaction name"
                                    name="productName"
                                    value={formik?.values?.productName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('productName')
                                            ? classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')
                                    }
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpProductCategory" className={classNames({ 'p-error': isFormFieldValid('lkpProductCategory') }, 'Label__Text')}>
                                    Product Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpProductCategory"
                                    placeholder="Select Product Category"
                                    options={productCategoryLovData}
                                    optionLabel="name"
                                    name="lkpProductCategory"
                                    optionValue="lovId"
                                    disabled={true}
                                    value={formik?.values?.lkpProductCategory || ''}
                                    onChange={formik.handleChange}
                                    className={
                                        highlightedKeys?.includes('lkpProductCategory')
                                            ? classNames({ 'p-invalid': isFormFieldValid('lkpProductCategory') }, 'Dropdown__Round__Highlighted')
                                            : classNames({ 'p-invalid': isFormFieldValid('lkpProductCategory') }, 'Dropdown__Round')
                                    }
                                />
                                {getFormErrorMessage('lkpProductCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 mt-auto">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isCoolingOffRequired"
                                        name="isCoolingOffRequired"
                                        value={formik?.values?.isCoolingOffRequired}
                                        checked={formik?.values?.isCoolingOffRequired === 'Y'}
                                        onChange={(e) => formik.setFieldValue('isCoolingOffRequired', e.checked ? 'Y' : 'N')}
                                    />
                                    <label htmlFor="isCoolingOffRequired" className={classNames({ 'p-error': isFormFieldValid('isCoolingOffRequired') }, 'Label__Text')}>
                                        Cooling Off
                                    </label>
                                </div>
                                {getFormErrorMessage('isCoolingOffRequired')}
                            </div>
                        </div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="Form__Header Full__Width">
                                <h2>TRANSACTIONS CLASSIFICATION</h2>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="id" className={classNames({ 'p-error': isFormFieldValid('id') }, 'Label__Text')}>
                                        ID <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="id"
                                        placeholder="Enter ID"
                                        name="id"
                                        value={formik?.values?.id?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('id') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('id')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                        Type <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="type"
                                        placeholder="Select Type"
                                        options={getTypeData}
                                        optionLabel="name"
                                        name="type"
                                        optionValue="value"
                                        value={formik.values.type || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('type')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="transClassification" className={classNames({ 'p-error': isFormFieldValid('transClassification') }, 'Label__Text')}>
                                        Transaction Classification <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="transClassification"
                                        placeholder="Select Classification"
                                        options={getTransClassificationData}
                                        optionLabel="name"
                                        name="transClassification"
                                        optionValue="name"
                                        value={formik.values.transClassification || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('transClassification') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('transClassification')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="transSign" className={classNames({ 'p-error': isFormFieldValid('transSign') }, 'Label__Text')}>
                                        Credit/Debit <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="transSign"
                                        placeholder="Select Credit/Debit"
                                        options={getTransSignData}
                                        optionLabel="name"
                                        name="transSign"
                                        optionValue="value"
                                        value={formik.values.transSign || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('transSign') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('transSign')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="currencyId" className={classNames({ 'p-error': isFormFieldValid('currencyId') }, 'Label__Text')}>
                                        Currency <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="currencyId"
                                        placeholder="Select Currency"
                                        options={currencyData}
                                        optionLabel="name"
                                        name="currencyId"
                                        optionValue="lovId"
                                        value={formik.values.currencyId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('currencyId')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="targetAccountType" className={classNames({ 'p-error': isFormFieldValid('targetAccountType') }, 'Label__Text')}>
                                        Target Account Type {formik.values.type === 'S' ? <span className="Label__Required">*</span> : null}
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="targetAccountType"
                                        placeholder="Select Target"
                                        options={getTransAccountTypeData}
                                        optionLabel="name"
                                        name="targetAccountType"
                                        optionValue="name"
                                        value={formik.values.targetAccountType || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('targetAccountType') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('targetAccountType')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountNo" className={classNames({ 'p-error': isFormFieldValid('accountNo') }, 'Label__Text')}>
                                        Account Number {formik.values.type === 'S' ? <span className="Label__Required">*</span> : null}
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="accountNo"
                                        placeholder="Enter Account Number"
                                        name="accountNo"
                                        value={formik?.values?.accountNo?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('accountNo') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('accountNo')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="isMandatory" className={classNames({ 'p-error': isFormFieldValid('isMandatory') }, 'Label__Text')}>
                                        Is Mandatory
                                    </label>
                                    <div className="field-checkbox ml-3">
                                        <Checkbox inputId="isMandatory" name="isMandatory" disabled value={formik?.values?.isMandatory} onChange={formik.handleChange} checked={transactionClassificationData?.isMandatory === 'M'} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button disabled type="submit" loadingIcon={loadingIcon} label="Add" onClick={handledTransClassificationAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className={highlightedKeys?.includes('tblTransPatterns') ? 'Card Card__Highlighted' : 'Card'}>
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={transactionClassificationData}>
                                    <Column field="id" header="Id" />
                                    <Column header="Type" body={typeBodyTemplate} />
                                    {/* <Column field="accountType" header="Account Type" /> */}
                                    <Column header="Transaction Classification" body={transClassificationBodyTemplate} />
                                    {/* <Column field="transSign" header="Credit/Debit" />
                                    <Column body={currencyTemplateBody} header="Currency" /> */}
                                    <Column header="Credit/Debit" body={transSignBodyTemplate} />
                                    <Column header="Currency" body={currencyBodyTemplate} />
                                    {/* <Column field="targetAccountType" header="General Ledger" /> */}
                                    <Column body={accountNoBodyTemplate} header="Account Number" />
                                    <Column header="Target Account Type" body={targetAccountTypeBodyTemplate} />
                                    <Column field="isMandatory" header="Is Mandatory" />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    {/* form and data table account type */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                        Account Type <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        disabled
                                        id="accountLevelId"
                                        placeholder="Select Type"
                                        options={accountTypeLovData}
                                        optionLabel="name"
                                        name="accountLevelId"
                                        optionValue="lovId"
                                        value={formik.values.accountLevelId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('accountLevelId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('accountLevelId')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-8 mt-5">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox inputId="exclude" disabled name="exclude" value={formik?.values?.exclude} checked={formik?.values?.exclude} onChange={formik.handleChange} />
                                        <label htmlFor="exclude" className={classNames({ 'p-error': isFormFieldValid('exclude') }, 'Label__Text')}>
                                            Exclude
                                        </label>
                                    </div>
                                    {getFormErrorMessage('exclude')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="minAmount" className={classNames({ 'p-error': isFormFieldValid('minAmount') }, 'Label__Text')}>
                                        Minimum Amount
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="minAmount"
                                        placeholder="Enter Minimum Amount"
                                        name="minAmount"
                                        value={formik?.values?.minAmount?.trim()?.toString() || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('minAmount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('minAmount')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="maxAmount" className={classNames({ 'p-error': isFormFieldValid('maxAmount') }, 'Label__Text')}>
                                        Maximum Amount
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="maxAmount"
                                        placeholder="Enter Maximum Amount"
                                        name="maxAmount"
                                        value={formik?.values?.maxAmount?.trim()?.toString() || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('maxAmount') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('maxAmount')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button disabled type="submit" label="Add" onClick={handledAccountTypeAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div className={highlightedKeys?.includes('tblProductLimits') ? 'Card Card__Highlighted' : 'Card'}>
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={accountTypeTableData}>
                                        {/* <Column header="Account Type" body={accountLevelBodyTemplate} /> */}
                                        <Column header="Account Type" body={accountTypeBodyTemplate} />
                                        <Column field="minAmount" header="Minimum" />
                                        <Column field="maxAmount" header="Maximum" />
                                        <Column header="Exlcude" body={excludeBodyTemplate} />
                                        <Column header="Actions" body={actionAccountTypeBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Form and data table Fields related */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="fieldName" className={classNames({ 'p-error': isFormFieldValid('fieldName') }, 'Label__Text')}>
                                        Field Name <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="fieldName"
                                        placeholder="Enter Field Name"
                                        name="fieldName"
                                        value={formik?.values?.fieldName || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('fieldName') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('fieldName')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="fieldValue" className={classNames({ 'p-error': isFormFieldValid('fieldValue') }, 'Label__Text')}>
                                        Field Value <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        disabled
                                        maxLength={100}
                                        id="fieldValue"
                                        placeholder="Enter Field Value"
                                        name="fieldValue"
                                        value={formik?.values?.fieldValue?.trim()?.toString() || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('fieldValue') }, 'Input__Round')}
                                    />
                                    {getFormErrorMessage('fieldValue')}
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button disabled type="submit" label="Add" onClick={(e) => handledFieldsDataAdd(e)} className="Btn__Add" />
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div className={highlightedKeys?.includes('tblProductFields') ? 'Card Card__Highlighted' : 'Card'}>
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={fieldTableData}>
                                        <Column field="fieldName" header="Field Name" />
                                        <Column field="fieldValue" header="Field Value" />
                                        <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 mt-2">
                            <div className="p-field">
                                <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                    Action <span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="action"
                                    placeholder="Select Action"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="action"
                                    optionValue="lovId"
                                    value={formik.values.action || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('action')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="isActive" className={classNames({ 'p-error': isFormFieldValid('isActive') }, 'Label__Text')}>
                                    Is Active
                                </label>
                                <div className="field-checkbox">
                                    <Checkbox inputId="isActive" disabled={true} name="isActive" value={requestedDataById?.isActive === 'Y' ? true : false} checked={requestedDataById?.isActive === 'Y' ? true : false} onChange={formik.handleChange} />
                                </div>
                                {getFormErrorMessage('isActive')}
                            </div>
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field">
                                <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                    Comments
                                    <span className="Label__Required">*</span>
                                </label>
                                <InputTextarea
                                    autoResize="false"
                                    rows={5}
                                    cols={30}
                                    maxLength={500}
                                    id="checkerComments"
                                    name="checkerComments"
                                    value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') === 'null' ? '' : formik?.values?.checkerComments}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                />

                                {getFormErrorMessage('checkerComments')}
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>

            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}

export default EditTransactionConfigChecker;
