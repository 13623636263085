import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { toast } from 'react-toastify';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { useNavigate } from 'react-router-dom';
import { handlePostRequest } from '../../../services/PostTemplate';
import React, { useState, useRef, useEffect } from 'react';
import { handleGetRequest } from '../../../services/GetTemplate';
import { Dropdown } from 'primereact/dropdown';
import * as yup from 'yup';
import classNames from 'classnames';
import DynamicOrganizationChartComponent from '../../../components/DynamicComponent/DynamicOrganizationChartComponent';
import DynamicDialog from '../../../components/DynamicComponent/DynamicModal';
import { useDispatch } from 'react-redux';
import { handleRequest } from '../../../utils/HandleRequest/handleRequest';
import * as Yup from 'yup';
import DynamicDialogFiles from '../../../components/DynamicComponent/DynamicDialogFiles';
import { useLocation } from 'react-router-dom';
import { Toast } from 'primereact/toast';

function EditRuleEngine() {
    const location = useLocation();
    const [dropdownOptions, setDropdownOptions] = useState([]);

    const [loading, setloading] = useState(false);
    const [ruleNameValue, setRuleNameValue] = useState('');
    const [ruleString, setRuleString] = useState('');
    const [expandedDialog, setExpandedDialog] = useState(false);
    const [getEditData, setGetEditData] = useState([]);
    const [depthCounter, setDepthCounter] = useState(0);
    const [parentRule, setParentRule] = useState('');
    const [domainValue, setDomainValue] = useState();
    const [expandedValue, setExpandedValue] = useState({});
    const [ruleResult, setRuleResult] = useState({});
    const [isEdit, setIsEditRule] = useState(true);

    const [isAdd, setIsAdd] = useState(false);
    const [isExpanded, setIsExpanded] = useState(true);
    const [userLov, setUserLov] = useState([]);
    const [fieldLov, setFieldLov] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedValues, setSelectedValues] = useState('');
    const [selectedExpandValues, setSelectedExpandValues] = useState('');
    const [useCaseError, setUseCaseError] = useState({});
    const [isAccept, setIsAccept] = useState(false);
    const [dataTree, setDataTree] = useState([]);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);

    const { viewId, domainId } = location.state[0] || {};
    const onNodeClick = (node) => {
        setSelectedNode(node);
        setDialogVisible(true);

        setSelectedValues('');
    };
    const onExpandedClick = (node) => {
        setSelectedExpandValues(node);
        setExpandedDialog(true);
    };
    const acceptDelete = () => {
        setDataTree([]);
        setRuleNameValue('');
        setRuleResult('');
        setRuleString('');
        setDepthCounter(0);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getalldomain/TBL_DOMAIN:*');
        setUserLov(resp?.payLoad);
    };
    const onRemove = (selectedNode) => {
        // Define a function to remove children of a node
        const removeChildren = (node) => {
            if (node.children) {
                node.children.forEach((child) => {
                    child.isAdd = false;
                    removeChildren(child);
                });
            }
        };

        // Remove children of the selectedNode
        removeChildren(selectedNode);

        // Helper function to find the max counter value in the data tree
        const findMaxCounter = (node) => {
            let maxCounter = node.counter || 0;
            if (node.children && node.children.length > 0) {
                node.children.forEach((child) => {
                    maxCounter = Math.max(maxCounter, findMaxCounter(child));
                });
            }
            return maxCounter;
        };

        // Find the maximum counter value in the data tree
        const maxCounterValue = findMaxCounter(dataTree[0]);

        // Update the state with the modified data tree
        const newDataTree = [...dataTree];
        setDataTree(newDataTree);
        if (selectedNode === dataTree[0]) {
            confirmPopup({
                message: 'Are you certain you want to delete the parent rule?',
                // icon: 'pi pi-exclamation-triangle',
                accept: () => acceptDelete(),
                reject
            });
        } else {
            const newLabel = selectedNode.color === 'red' ? 'false' : 'true';

            // Update the selectedNode with the new label and no children
            selectedNode.label = newLabel;
            selectedNode.children = [];
            selectedNode.isAdd = false;
            selectedNode.isRemove = true;
            selectedNode.isExpanded = true;
            // Use the max counter value here if needed

            setDepthCounter(maxCounterValue + 1);
            const jsonString = JSON.stringify(newDataTree);
            setRuleString(jsonString);

            const resultRule = convertTreeToString(newDataTree);
            setRuleResult(resultRule);
        }
    };

    function convertTreeToString(tree) {
        function traverse(node) {
            const childResults = [];
            let hasTrue = false;
            let hasFalse = false;

            if (node?.children) {
                for (const child of node.children) {
                    const childResult = traverse(child);
                    childResults.push(childResult);

                    if (childResult.includes('true')) {
                        hasTrue = true;
                    } else if (childResult.includes('false')) {
                        hasFalse = true;
                    }
                }

                let result = `${node.label}`;
                if (hasTrue && hasFalse) {
                    const reversedArray = childResults.reverse();

                    // Convert the reversed elements back to an array
                    const newArray = reversedArray.map((element) => element);
                    result += `$?$${newArray.join('$:$')}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] == 'true') {
                    result += `$?$${childResults.join('$:$')}$:$false$#_${node.counter}`;
                } else if (hasFalse && childResults[0] == 'false') {
                    result += `$?$true$:$${childResults.join('$:$')}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] == 'true') {
                    result += `$?$true$:$${childResults[0]}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] != 'true') {
                    result += `$?$${childResults[1]}$:$${childResults[0]}$#_${node.counter}`;
                } else if (hasTrue && childResults[0] != 'true' && childResults[1] == 'false') {
                    result += `$?$${childResults[0]}$:$false$#_${node.counter}`;
                }

                if (hasTrue || hasFalse) {
                    return `@_${node.counter}$${result}`;
                } else {
                    return `${result}`;
                }
            }

            return `@_${node.counter}${node.label}`;
        }

        return `${tree.map((node) => traverse(node)).join('')}`;
    }

    // function formatValuesNumber(value) {
    //     if (typeof value === 'string' || typeof value === 'number') {
    //         const isNumber = /^\d+$/.test(value); // Check if value is a number
    //         const isAlphabetic = /^[A-Za-z]+$/.test(value); // Check if value is alphabetic

    //         if (isAlphabetic || isNumber) {
    //             return `[[${value}]]`;
    //         } else {
    //             // Handle other cases here if needed
    //             return `{{${value}}}`; // Return value as is for other types
    //         }
    //     } else {
    //         // Handle other types as needed
    //         return `{{${value}}}`; // Return value as is for other types
    //     }
    // }
    // function formatValues(value) {
    //     if (typeof value === 'string' || typeof value === 'number') {
    //         const isNumber = /^\d+$/.test(value);
    //         const isAlphabetic = /^[A-Za-z]+$/.test(value);

    //         if (isAlphabetic || isNumber) {
    //             return `{{${value}}}`;
    //         } else {
    //             return value; // Return value as is for other types
    //         }
    //     } else {
    //         // Handle other types as needed
    //         return value; // Return value as is for other types
    //     }
    // }

    function formatValuesNumber(value) {
        if (typeof value === 'string' || typeof value === 'number') {
            const isNumber = /^\d+$/.test(value); // Check if value is a number
            const isAlphabetic = /^[A-Za-z]+$/.test(value); // Check if value is alphabetic

            if (isAlphabetic || isNumber) {
                // const modifiedString = value.replace(/\[|\]/g, '');
                return `[[${value}]]`;
            } else {
                // Handle other cases here if needed
                return `[[${value}]]`; // Return value as is for other types
            }
        } else {
            // Handle other types as needed
            return `[[${value}]]`; // Return value as is for other types
        }
    }
    function formatValues(value) {
        // if (typeof value === 'string' || typeof value === 'number') {
        //     const isNumber = /^\d+$/.test(value);
        //     const isAlphabetic = /^[A-Za-z]+$/.test(value);

        //     if (isAlphabetic || isNumber) {
        //         return `{{${value}}}`;
        //     } else {
        //         return `{{${value}}}`; // Return value as is for other types
        //     }
        // } else
        {
            // Handle other types as needed

            return `{{${value}}}`; // Return value as is for other types
        }
    }

    const handleParentChildRelation = (formik, fieldTypes) => {
        let newValue = '';
        let values = '';

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            newValue = formatValuesNumber(formik.values.values);
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            newValue = formatValues(formik.values.values1);
        }

        // if (formik.values.values) {
        //     if (formik.values.selectedValues === 'dynamicValues') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else if (formik.values.values1) {
        //     if (formik.values.selectedValues === 'dynamicValues') {

        //         newValue = formatValues(formik.values.values1);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values1);
        //     }
        // }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            values: newValue,
            selectedValues: formik.values.selectedValues
        };

        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),
            logicalOperator: yup.string().required('This Field is Required.'),
            values: yup.string().required('This Field is Required.'),
            selectedValues: yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                // Validation passed

                let result = fieldLov?.filter((result) => {
                    if (result.lovId == formik.values.fieldType) {
                        return result?.code;
                    }
                })[0];

                // Reset the depth counter

                if (selectedNode.isRoot) {
                    // Update the root node with the form values
                    setIsAdd(false);
                    let perviousNode = selectedNode;
                    selectedNode.label = `${result?.code}${formik.values.logicalOperator}${newValue}`;

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    if (formik.values.values) {
                        const match = newValue.match(/\[\[(.*?)\]\]/);
                        const extractedText = match ? match[1] : null;
                        selectedNode.values = extractedText;
                        selectedNode.values1 = '';
                    } else if (formik.values.values1) {
                        const match = newValue.match(/\{\{(.*?)\}\}/);
                        const extractedText = match ? match[1] : null;
                        selectedNode.values1 = extractedText;
                        selectedNode.values = '';
                    }
                    selectedNode.fieldTypes = fieldTypes;
                    selectedNode.isAdd = true;
                    selectedNode.isExpanded = false;
                    // selectedNode.counter = depthCounter;
                    selectedNode.counter = 0;
                    selectedNode.selectedValues = formik.values.selectedValues;
                    setDepthCounter(parseInt(depthCounter) + 1);
                    setParentRule(selectedNode.label);

                    if (selectedNode.children.length > 0 && selectedNode?.children[0]?.label != 'false' && selectedNode?.children[1]?.label != 'true') {
                        selectedNode.children = selectedNode.children
                            .map((child, index) => {
                                if (index === 0 && child.isLeftChild === true) {
                                    return child;
                                } else if (index === 1 && child.isRightChild === true) {
                                    return child;
                                }
                                // If the conditions are not met, return the original child.
                                return null;
                            })
                            .filter(Boolean);
                        selectedNode.children = [selectedNode.children[0], selectedNode.children[1]];
                    } else {
                        if (selectedNode.children && selectedNode.children.length > 0) {
                            selectedNode.children.map((result) => {
                                if (result?.isLeftChild === false) {
                                    const leftChild = generateRuleNode('false', '', '', true, false);
                                    leftChild.color = 'red';
                                    selectedNode.children = [leftChild, result];
                                } else if (result?.isLeftChild === true) {
                                    const rightChild = generateRuleNode('true', '', '', false, true);
                                    rightChild.color = 'green';
                                    selectedNode.children = [rightChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            selectedNode.children = [leftChild, rightChild];
                        }
                    }

                    setDataTree([selectedNode]);
                } else {
                    setIsAdd(false);

                    // Update the root node's label with the new form value
                    const { fieldType, logicalOperator, values, values1 } = formik.values;
                    let newValue1 = '';

                    if (values) {
                        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
                            newValue1 = formatValuesNumber(values);
                        }
                    } else if (values1) {
                        if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
                            newValue1 = formatValues(values1);
                        }
                    }

                    // if (values) {
                    //     if (formik.values.selectedValues === 'dynamicValue') {
                    //         newValue1 = formatValues(values);
                    //     } else {
                    //         newValue1 = formatValuesNumber(values);
                    //     }
                    // } else if (values1) {
                    //     if (formik.values.selectedValues === 'dynamicValue') {
                    //         newValue1 = formatValues(values1);
                    //     } else {
                    //         newValue1 = formatValuesNumber(values1);
                    //     }
                    // }

                    // Create a new parent node if both "isTrue" and "isFalse" are selected
                    let result = fieldLov?.filter((result) => {
                        if (result.lovId == fieldType) {
                            return result?.code;
                        }
                    })[0];
                    let perviousNode = selectedNode;
                    selectedNode.label = `${result?.code}${logicalOperator}${newValue1}`;

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    if (values) {
                        const match = newValue1.match(/\[\[(.*?)\]\]/);
                        const extractedText = match ? match[1] : null;
                        selectedNode.values = extractedText;
                        selectedNode.values1 = '';
                    } else if (values1) {
                        const match = newValue1.match(/\{\{(.*?)\}\}/);
                        const extractedText = match ? match[1] : null;
                        selectedNode.values1 = extractedText;
                        selectedNode.values = '';
                    }
                    selectedNode.fieldTypes = fieldTypes;

                    selectedNode.isAdd = true;
                    selectedNode.isExpanded = false;
                    selectedNode.isRemove = false;
                    selectedNode.counter = depthCounter;
                    setDepthCounter(parseInt(depthCounter) + 1);
                    selectedNode.selectedValues = formik.values.selectedValues;

                    if (selectedNode?.children[0]?.label != 'false' && selectedNode?.children[1]?.label != 'true' && selectedNode?.children[0]?.isLeftChild == true && selectedNode?.children[1]?.isRightChild == true) {
                        selectedNode.children = [selectedNode.children[0], selectedNode.children[1]];
                    } else {
                        // Create a left child node with "false" label and red color

                        // Check if children already exist, and update them
                        if (selectedNode.children && selectedNode.children.length > 0) {
                            selectedNode.children = selectedNode.children
                                .map((child, index) => {
                                    if (index === 0 && child.isLeftChild === true) {
                                        return child;
                                    } else if (index === 1 && child.isRightChild === true) {
                                        return child;
                                    }
                                    // If the conditions are not met, return the original child.
                                    return null;
                                })
                                .filter(Boolean);
                            selectedNode.children.map((result) => {
                                if (result?.isLeftChild === false) {
                                    const rightChild = generateRuleNode('true', '', '', false, true);
                                    rightChild.color = 'green';
                                    selectedNode.children = [rightChild, result];
                                } else if (result?.isLeftChild === true) {
                                    const leftChild = generateRuleNode('false', '', '', true, false);
                                    leftChild.color = 'red';

                                    selectedNode.children = [leftChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            selectedNode.children = [leftChild, rightChild];
                        }
                    }
                    setDepthCounter(parseInt(depthCounter) + 1);
                    // Simulate form values'

                    //
                    setDataTree([...dataTree, selectedNode]);
                }

                setDataTree([...dataTree]);

                const jsonString = JSON.stringify(dataTree);
                setRuleString(jsonString);

                const resultRule = convertTreeToString(dataTree);

                setRuleResult(resultRule);

                // Clear form values or reset as needed
                formik?.setFieldValue('fieldType', '');
                formik?.setFieldValue('logicalOperator', '');
                formik?.setFieldValue('values', '');

                setUseCaseError({ fieldType: '', logicalOperator: '', values: '' });

                // Reset isTrue and isFalse

                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );

                setDialogVisible(false);
                setSelectedValues('');
                formik.setFieldValue('fieldType', '');
                formik.setFieldValue('logicalOperator', '');
                formik.setFieldValue('values', '');
                formik.setFieldValue('values1', '');
                formik.setFieldValue('selectedValues', '');
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error?.inner?.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                setUseCaseError(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    };

    function updateDataTreeWithSelectedNode(selectedNode, formik, type) {
        let newValue = '';

        // if (formik.values.values) {
        //     if (formik.values.selectedValues === 'dynamicValues') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else if (formik.values.values1) {
        //     if (formik.values.selectedValues === 'dynamicValue') {
        //         newValue = formatValues(formik.values.values1);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values1);
        //     }
        // }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            // values: newValue,
            selectedValues: formik.values.selectedValues
        };

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            newValue = formik.values.values;
            newData.values = formik.values.values;
            newData.values1 = '';
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            newValue = formik.values.values1;
            newData.values1 = formik.values.values1;
            newData.values = '';
        }
        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),
            // conditionOperator:yup.string().required('This Field is Required.'),
            logicalOperator: yup.string().required('This Field is Required.'),
            // values: yup.string().required('This Field is Required.'),
            selectedValues: yup.string().required('This Field is Required')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                selectedNode.selectedValues = formik.values.selectedValues;
                if (selectedNode.label !== 'true' && selectedNode.label !== 'false') {
                    let result = fieldLov?.filter((result) => {
                        if (result.lovId == formik.values.fieldType) {
                            return result?.code;
                        }
                    })[0];

                    if (selectedNode.label.includes('AND')) {
                        const parts = selectedNode.label.split('AND');

                        // Concatenate the new values with the existing label
                        selectedNode.label = `${result?.code}${formik.values.logicalOperator}${newValue}  AND ${parts.slice(1).join('AND')}`;
                    } else {
                        selectedNode.label = `${result?.code}${formik.values.logicalOperator}${newValue}`;
                    }

                    selectedNode.fieldType = formik.values.fieldType;
                    selectedNode.logicalOperator = formik.values.logicalOperator;
                    if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
                        newValue = formik.values.values;
                        selectedNode.values = newValue;
                        selectedNode.values1 = '';
                    } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
                        newValue = formik.values.values1;
                        selectedNode.values1 = newValue;
                        selectedNode.values = '';
                    }
                    // selectedNode.values = newValue;

                    setDataTree([...dataTree, selectedNode]);
                    const jsonString = JSON.stringify(dataTree);
                    setRuleString(jsonString);

                    const resultRule = convertTreeToString(dataTree);

                    setRuleResult(resultRule);

                    setExpandedDialog(false);
                    setDialogVisible(false);
                } else {
                    handleParentChildRelation(formik);
                }
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error?.inner?.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    }

    const ExpandRule = (formik) => {
        let newValue = '';

        // if (formik.values.values) {
        //     if (formik.values.selectedValues === 'dynamicValues') {
        //         newValue = formatValues(formik.values.values);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values);
        //     }
        // } else if (formik.values.values1) {
        //     if (formik.values.selectedValues === 'dynamicValue') {
        //         newValue = formatValues(formik.values.values1);
        //     } else {
        //         newValue = formatValuesNumber(formik.values.values1);
        //     }
        // }

        const newData = {
            fieldType: formik.values.fieldType,
            logicalOperator: formik.values.logicalOperator,
            // values: newValue,
            selectedValues: formik.values.selectedValues
        };

        if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
            // newValue = formatValuesNumber(formik.values.values);
            newValue = formatValuesNumber(formik.values.values);
            newData.values = formatValuesNumber(formik.values.values);
            newData.values1 = '';
        } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
            // newValue = formatValues(formik.values.values1);
            newValue = formatValues(formik.values.values1);
            newData.values1 = formatValues(formik.values.values1);
            newData.values = '';
        }

        const schema = yup.object().shape({
            fieldType: yup.string().required('This Field is Required.'),
            logicalOperator: yup.string().required('This Field is Required.'),
            // values: yup.string().required('This Field is Required.'),
            selectedValues: yup.string().required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                selectedExpandValues.selectedValues = formik.values.selectedValues;
                if (formik.values.selectedValues === 'staticValues' || formik.values.selectedValues === 'staticValue') {
                    setExpandedValue({ logicalOperator: formik.values.logicalOperator, conditon: selectedOption, fieldType: formik.values.fieldType, values: newValue });
                } else if (formik.values.selectedValues === 'dynamicValues' || formik.values.selectedValues === 'dynamicValue') {
                    setExpandedValue({ logicalOperator: formik.values.logicalOperator, conditon: selectedOption, fieldType: formik.values.fieldType, values1: newValue });
                }
                let result = fieldLov?.filter((result) => {
                    if (result.lovId == formik.values.fieldType) {
                        return result?.code;
                    }
                })[0];

                if (selectedExpandValues && selectedExpandValues.isRoot) {
                    selectedExpandValues.label = `${selectedExpandValues.label} AND  ${result?.code} ${formik.values.logicalOperator}  ${newValue}`;

                    setDataTree([selectedExpandValues]);
                } else {
                    selectedExpandValues.label = `${selectedExpandValues.label} AND  ${result?.code} ${formik.values.logicalOperator}  ${newValue}`;

                    if (selectedExpandValues?.children[0]?.label != 'false' && selectedExpandValues?.children[1]?.label != 'true' && selectedExpandValues?.children[0]?.isLeftChild == true && selectedExpandValues?.children[1]?.isRightChild == true) {
                        selectedExpandValues.children = [selectedExpandValues.children[0], selectedExpandValues.children[1]];
                    } else {
                        if (selectedExpandValues.children && selectedExpandValues.children.length > 0) {
                            selectedExpandValues.children = selectedExpandValues.children
                                .map((child, index) => {
                                    if (index === 0 && child.isLeftChild === true) {
                                        return child;
                                    } else if (index === 1 && child.isRightChild === true) {
                                        return child;
                                    }
                                    // If the conditions are not met, return the original child.
                                    return null;
                                })
                                .filter(Boolean);
                            selectedExpandValues.children.map((result) => {
                                if (result.isLeftChild === false) {
                                    const leftChild = generateRuleNode('false', '', '', false, true);
                                    leftChild.color = 'red';
                                    selectedExpandValues.children = [leftChild, result];
                                } else if (result.isLeftChild === true) {
                                    const rightChild = generateRuleNode('true', '', '', true, false);
                                    rightChild.color = 'green';
                                    selectedExpandValues.children = [rightChild, result];
                                }
                            });
                        } else {
                            const leftChild = generateRuleNode('false', '', '', true, false);
                            leftChild.color = 'red';

                            // Create a right child node with "true" label and green color
                            const rightChild = generateRuleNode('true', '', '', false, true);
                            rightChild.color = 'green';

                            // If no children exist, set the children
                            selectedExpandValues.children = [leftChild, rightChild];
                        }
                    }
                    setDataTree([...dataTree]);
                }

                const jsonString = JSON.stringify(dataTree);
                setRuleString(jsonString);

                const resultRule = convertTreeToString(dataTree);

                setRuleResult(resultRule);

                setSelectedOption('');
                setExpandedDialog(false);
                formik.setFieldValue('fieldType', '');
                formik.setFieldValue('logicalOperator', '');
                formik.setFieldValue('values', '');
                formik.setFieldValue('values1', '');
                formik.setFieldValue('selectedValues', '');
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });

                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});
                setUseCaseError(errors);
                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );
            });
    };
    function generateRuleNode(fieldType, logicalOperator, values, isLeftChild, isRightChild) {
        let color = isLeftChild ? 'red' : 'green';

        return {
            label: `${fieldType}${logicalOperator}${values}`,
            expanded: false,
            data: 'ar',
            isParent: true,
            isEdit: false,
            isExpanded: true,
            logicalOperator: logicalOperator,
            fieldType: fieldType,
            counter: parseInt(depthCounter) + 1,
            selectedValues: selectedValues,
            isRoot: false,
            isLeftChild: isLeftChild,
            isRightChild: isRightChild,
            color: color, // Set the color based on isLeftChild
            children: [],
            isRemove: true
        };
    }

    const reject = () => {
        toast.warn('You have rejected to rewrite rule again');
    };

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        domainName: Yup.string().required('This Field is required'),
        ruleName: Yup.string()
            .matches(/^[a-zA-Z\s]+[0-9]*$/, 'only alphanumeric characters are allowed')
            .required('This Field is required')
    });
    const handleInputChange = (e) => {
        let { name, value } = e.target;

        if (name === 'domainName') {
            name = 'domainName';
            formik.handleChange(e);
        } else if (name === 'ruleName') {
            name = 'ruleName';
            formik.handleChange(e);
        }
        // Create a new object with the updated error for the specific field
        const updatedErrors = { ...useCaseError, [name]: '' };
        setUseCaseError(updatedErrors);

        //
    };
    const handleAddRule = (formik) => {
        const newData = {
            ruleName: formik.values.ruleName,
            domainName: formik.values.domainName
        };
        const schema = yup.object().shape({
            ruleName: yup
                .string()
                .matches(/^[a-zA-Z\s]+[0-9]*$/, 'Only characters are allowed')
                .required('This Field is Required.'),
            domainName: yup
                .string()

                .required('This Field is Required.')
        });

        schema
            .validate(newData, { abortEarly: false }) // Abort early ensures that all errors are collected
            .then(() => {
                getFieldData(userLov.filter((result) => result.lovId == formik.values.domainName)[0]?.lovId);
                setDomainValue(formik.values.domainName);
                // setDataTree([]);

                if (dataTree.length == 0) {
                    let array = [
                        {
                            label: formik.values.ruleName,
                            expanded: false,
                            data: 'ar',
                            isParent: true,
                            isRoot: true,
                            isExpanded: true,
                            isAdd: false,
                            children: []
                        }
                    ];
                    setDataTree(array);
                    const jsonString = JSON.stringify(array);
                    setRuleString(jsonString);
                }
                if (formik.values.ruleName === ruleNameValue) {
                    toast.warn('Rule Name already exist');
                } else {
                    setRuleNameValue(formik.values.ruleName);
                    // setRuleString(ruleString);

                    toast.success('Rule Name update Successfully');

                    const newDataFields = Object.keys(newData);
                    const touchedFields = newDataFields.reduce((acc, field) => {
                        acc[field] = true;
                        return acc;
                    }, {});

                    formik.setTouched(
                        {
                            ...formik.touched,
                            ...touchedFields
                        },
                        true
                    );
                }
            })
            .catch((error) => {
                // Validation failed

                const errors = {};
                error.inner.forEach((validationError) => {
                    errors[validationError.path] = validationError.message;
                });
                formik.setErrors(errors);
                const newDataFields = Object.keys(newData);
                const touchedFields = newDataFields.reduce((acc, field) => {
                    acc[field] = true;
                    return acc;
                }, {});

                formik.setTouched(
                    {
                        ...formik.touched,
                        ...touchedFields
                    },
                    true
                );

                //  setUseCaseError(errors);
            });
    };
    const handleReset = () => {
        // e.preventDefault();

        // formik?.resetForm();
        formik?.setFieldValue('ruleName', '');
        setRuleNameValue('');
        formik.resetForm();
    };
    const formik = useFormik({
        initialValues: {
            domainName: '',
            ruleName: '',
            ruleCode: ''
        },
        validationSchema,

        onSubmit: async (data) => {
            setloading(true);
            let requestType = 'POST';
            let apiUrl = '/ruleengine/v1/rule/update';
            let additionalInformation = [{}];

            let payLoad = {
                domainId: domainValue,
                id: viewId,
                ruleName: ruleNameValue,
                rule: ruleResult,
                ruleString: ruleString,
                description: 'This is Description',
                code: data.ruleCode
            };

            if (dataTree[0]?.label == ruleNameValue) {
                toast.warn('Please Add New Rule');
            } else {
                let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, true, false);
                if (res) {
                    navigate('/rulesEngine');
                }
            }

            setloading(false);
        }
    });

    const validationSchema1 = Yup.object({
        fieldType: Yup.string().required('This Field is required'),
        logicalOperator: yup.string().required('This Field is Required.'),
        values: yup.string().required('This Field is Required.')
    });

    const getUpdatedRule = async (id) => {
        let requestType = 'POST';
        let apiUrl = '/ruleengine/v1/rule/';
        let additionalInformation = [{}];

        let payLoad = { id };
        let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);

        setGetEditData(res?.payLoad);
    };

    const getFieldData = async (fieldData) => {
        if (fieldData) {
            const resp = await handleGetRequest(`/configuration/v1/lookups/table/column/view/${fieldData}`);
            setFieldLov(resp?.payLoad);
        }
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    // const getUserLov = async () => {
    //     setloading(true);
    //     let requestType = 'POST';
    //     let apiUrl = '/ruleengine/v1/domain/';
    //     let additionalInformation = [{}];
    //     let payLoad = {
    //         id: domainId.toString()
    //     };

    //     let res = await handleRequest(payLoad, requestType, apiUrl, additionalInformation, dispatch, false, true);
    //     setUserLov(res?.payLoad);

    //     setloading(false);
    //     // const resp = await handleGetRequest('/configuration/v1/lookups/getalldomain/TBL_DOMAIN:*');
    // };

    function setExpandedToTrue(node) {
        if (Array.isArray(node)) {
            return node.map(setExpandedToTrue);
        } else if (typeof node === 'object' && node !== null) {
            const newNode = { ...node }; // Create a shallow copy of the object
            if (newNode.hasOwnProperty('expanded')) {
                newNode.expanded = true;
            }
            for (const key in newNode) {
                if (newNode.hasOwnProperty(key)) {
                    newNode[key] = setExpandedToTrue(newNode[key]);
                }
            }
            return newNode;
        }
        return node;
    }

    useEffect(() => {
        if (getEditData) {
            formik?.setFieldValue('domainName', getEditData?.domainId?.toString());

            formik?.setFieldValue('ruleName', getEditData?.ruleName);
            formik?.setFieldValue('ruleCode', getEditData?.code);
            setDomainValue(getEditData?.domainId);
            let newArray = getEditData?.ruleString;
            const allRules = getEditData?.rule?.split('@_');
            // const allRulesNew = allRules && allRules[allRules?.length - 1].match(/^[^$]+/)[0];
            setDepthCounter(allRules && parseInt(allRules?.length) + 1);
            setRuleResult(getEditData?.rule);

            if (newArray) {
                setRuleString(newArray);

                // setDataTree(JSON?.parse(newArray));
                let newArray2 = JSON.parse(newArray);
                let newTree = setExpandedToTrue(newArray2);

                setDataTree(newTree);
                setSelectedNode(JSON?.parse(newArray));
                setRuleNameValue(getEditData?.ruleName);
            }

            getFieldData(getEditData?.domainId);
        }
    }, [getEditData]);

    // useEffect(() => {
    //     getUserLov();
    // }, []);

    useEffect(() => {
        getUpdatedRule(viewId);
        getUserLov();

        getRuleDropdown();
    }, [viewId]);

    const getRuleDropdown = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {},
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequest(newData, '/ruleengine/v1/rule/rulefield/lookup'));
        setDropdownOptions(res?.payLoad);
    };

    // Function to validate a specific field on change

    return (
        <>
            <Toast ref={toast} />
            <ConfirmPopup style={{ marginTop: '30px' }} />
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="Form__Header Full__Width">
                            <div className="title__header">EDIT DETAIL</div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('domainName') }, 'Label__Text')}>
                                    Domain Name
                                </label>
                                {/* <InputText
                                    maxLength={100}
                                    id="domain"
                                    name="domainName"
                                    disabled
                                    value={formik?.values?.domainName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleName') }, 'Input__Round')}
                                /> */}
                                <Dropdown
                                    id="domain"
                                    placeholder="Select Domain"
                                    options={userLov}
                                    filter
                                    optionLabel="name"
                                    name="domainName"
                                    optionValue="lovId"
                                    value={formik.values.domainName || ''}
                                    onChange={handleInputChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('createdBy') }, 'Dropdown__Round')}
                                />

                                {useCaseError.domainName !== '' ? <small className="p-error">{useCaseError.domainName}</small> : getFormErrorMessage('domainName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="domain" className={classNames({ 'p-error': isFormFieldValid('ruleName') }, 'Label__Text')}>
                                    Rule Name
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="ruleId"
                                    name="ruleName"
                                    value={formik?.values?.ruleName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={handleInputChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleName') }, 'Input__Round')}
                                />
                                {useCaseError.ruleName !== '' ? <small className="p-error">{useCaseError.ruleName}</small> : getFormErrorMessage('ruleName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="ruleCodeId" className={classNames({ 'p-error': isFormFieldValid('ruleCodeId') }, 'Label__Text')}>
                                    Rule Code
                                </label>

                                <InputText
                                    maxLength={100}
                                    id="ruleCode"
                                    name="ruleCode"
                                    disabled
                                    value={formik?.values?.ruleCode?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('ruleCode') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('ruleCodeId')}
                            </div>
                        </div>

                        <div className="Usergroup__SearchBtn p-field col-12 ">
                            <Button type="button" label="Update" onClick={() => handleAddRule(formik)} className="Btn__Dark__Search" />
                        </div>
                    </div>
                </form>
                <>
                    {ruleNameValue && dataTree.length > 0 ? (
                        <>
                            <div>
                                {' '}
                                <div className="title__header mt-2">Rules DESCRIPTION</div>
                                <DynamicOrganizationChartComponent isEdit={isEdit} data={dataTree} isExpanded={isExpanded} isAdd={isAdd} ruleName={ruleNameValue} onNodeClick={onNodeClick} onExpandedClick={onExpandedClick} onRemove={onRemove} />
                                {dialogVisible && (
                                    <DynamicDialogFiles
                                        dropdownOptions={dropdownOptions}
                                        visible={dialogVisible}
                                        header="Edit Rule"
                                        onHide={() => {
                                            setDialogVisible(false);
                                            setExpandedDialog(false);
                                            setSelectedNode(null);
                                            setSelectedExpandValues(null);
                                            setUseCaseError({});
                                        }}
                                        node={selectedNode}
                                        setUseCaseError={setUseCaseError}
                                        fieldLov={fieldLov}
                                        updateDataTreeWithSelectedNode={updateDataTreeWithSelectedNode}
                                        isEdit={true}
                                        isView={false}
                                        expand={false}
                                        errors={useCaseError}
                                        expandButton={false}
                                        userLov={userLov}
                                        handleParentChildRelation={handleParentChildRelation}
                                        validationSchema={validationSchema1}
                                    ></DynamicDialogFiles>
                                )}
                                {expandedDialog && (
                                    <DynamicDialogFiles
                                        dropdownOptions={dropdownOptions}
                                        visible={expandedDialog}
                                        header="Expand Rule"
                                        onHide={() => {
                                            setDialogVisible(false);
                                            setExpandedDialog(false);
                                            setSelectedNode(null);
                                            setSelectedExpandValues(null);
                                            setUseCaseError({});
                                        }}
                                        node={selectedExpandValues}
                                        expandButton={true}
                                        setUseCaseError={setUseCaseError}
                                        fieldLov={fieldLov}
                                        isView={false}
                                        expand={true}
                                        errors={useCaseError}
                                        userLov={userLov}
                                        ExpandRule={ExpandRule}
                                        validationSchema={validationSchema1}
                                    ></DynamicDialogFiles>
                                )}
                            </div>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="Down__Btn my-5">
                                    <Button type="submit" label="Update" className="Btn__Dark" />

                                    <Button disabled={loading} onClick={() => navigate('/rulesEngine')} label="Cancel" className="Btn__Transparent" />
                                </div>
                            </form>
                        </>
                    ) : (
                        <></>
                    )}
                </>
            </div>
        </>
    );
}

export default EditRuleEngine;
