import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { PaginationCall, PaginationTemplate } from '../../../../components/Pagination';
import { Paginator } from 'primereact/paginator';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';

function CxFieldMgmt() {
    const navigate = useNavigate();
    const dataTableRef = useRef(null);
    const [searchData, setSearchData] = useState([]);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apihit, setApiHit] = useState([])

    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roleCode: { value: '', matchMode: FilterMatchMode.STARTS_WITH }
    });
    const dispatch = useDispatch();

    const getSearchData = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    page: '1',
                    size: customRows1
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        setApiHit(newData?.data?.payLoad);

        const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/getAllCxExistingRecords', true, true));
        if (res) {
            setSearchData(res.payLoad);
        }
    };
    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">CX Field Management</h5>
                <span className="block mt-2 md:mt-0 p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search..." />
                </span>
            </div>
        );
    };

    const header = () => renderHeader();

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        // ...apiHit,
        page: currentPage,
        size: customRows1
    };
    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button
                        tooltip="Edit Detail"
                        icon="pi pi-pencil"
                        tooltipOptions={{ position: 'top' }}
                        disabled={['Pending', 'Rejected'].includes(rowData?.statusName) || (rowData?.statusName === 'Approved' && rowData?.isActive === 'N') ? true : false}
                        onClick={() => navigate(`/editcxfieldmanagement`, { state: { rowData } })}
                        className="p-button-rounded p-button-primary"
                    />
                    <Button
                        tooltip="View Detail"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-eye"
                        onClick={() => navigate(`/viewcxfieldmanagement`, { state: { rowData } })}
                        className="p-button-rounded"
                        style={{ backgroundColor: '#F2AC57', border: 'none' }}
                    />
                </div>
            </>
        );
    };

    useEffect(() => {
        getSearchData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="Top__Btn Top__Btn__Border">
                <div>
                    <Button label="Add New" onClick={() => navigate('/addcxfieldmanagement')} className="Btn__Add__" icon="pi pi-plus" iconPos="left" />
                </div>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setSearchData}
                RequestData={RequestData}
                url={'/account/v1/customeraccounts/getAllCxExistingRecords'}
                responseCode={'010000'}
            />

            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable ref={dataTableRef} className="datatable-responsive" globalFilterFields={['roleCode']} emptyMessage="No List found." responsiveLayout="scroll" value={searchData}>
                            <Column field="roleCode" header="Role" />
                            <Column field="userName" header="User" />
                            <Column header="Action" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                        </DataTable>
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={searchData?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
export default CxFieldMgmt;
