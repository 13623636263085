import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../services/PostTemplate';
import { handleGetRequest } from '../../services/GetTemplate';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { toast } from 'react-toastify';
import ScrollMover from '../../components/ScrollMover';

function EditTransactionConfiguration() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [transactionClassificationTableData, setTransactionClassificationTableData] = useState([]);
    const [transactionClassificationData, setTransactionClassificationData] = useState({
        id: '',
        type: '',
        transClassification: '',
        transSign: '',
        currencyId: '',
        targetAccountType: '',
        accountNo: '',
        isMandatory: 'O'
    });
    const [transactionClassificationError, setTransactionClassificationError] = useState({
        id: '',
        type: '',
        transClassification: '',
        transSign: '',
        currencyId: '',
        targetAccountType: '',
        accountNo: ''
    });

    const [currencyData, setCurrencyData] = useState([]);
    const [accountTypeTableData, setAccountTypeTableData] = useState([]);

    const [accountTypeData, setAccountTypeData] = useState({
        accountLevelId: '',
        minAmount: '',
        maxAmount: '',
        exclude: ''
    });
    const [accountTypeError, setAccountTypeError] = useState({
        accountLevelId: '',
        minAmount: '',
        maxAmount: '',
        exclude: ''
    });

    const [fieldTypeData, setFieldTypeData] = useState({ fieldName: '', fieldValue: '' });
    const [fieldTypeError, setFieldTypeError] = useState({ fieldName: '', fieldValue: '' });

    const [fieldTableData, setFieldTableData] = useState([]);
    const [accountTypeLovData, setAccountTypeLovData] = useState([]);

    const [productCategoryLovData, setProductCategoryLovData] = useState([]);

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const productId = query.get('productId');

    const getAllCurrencyData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAllCurrency/LKP_CURRENCY:*');
        setCurrencyData(res?.payLoad);
    };

    const getAccountTypeData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getaccounttypes/LKP_ACCOUNT_LEVEL:*');
        setAccountTypeLovData(res?.payLoad);
    };

    const getProductCategoryData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getproductCategory');
        setProductCategoryLovData(res?.payLoad);
    };

    useEffect(() => {
        getAllCurrencyData();
        getAccountTypeData();
        getProductCategoryData();
    }, []); // eslint-disable-line

    const getTransConfigDataById = async () => {
        const resp = await handleGetRequest(`/productcatalog/v1/transactions/getTransactionById/${productId}`);
        const keyData = resp?.payLoad;

        if (keyData !== null && typeof keyData !== 'undefined') {
            Object.keys(keyData).forEach((key) => {
                if (formik.initialValues.hasOwnProperty(key)) {
                    formik?.setFieldValue(key, keyData[key]);
                }
            });

            formik?.setFieldValue('lkpProductCategory', keyData?.lkpProductCategory?.productCategoryId?.toString() || '');

            setTransactionClassificationTableData(keyData?.tblTransPatterns);
            setAccountTypeTableData(keyData?.tblProductLimits);
            setFieldTableData(keyData?.tblProductFields);
        }
    };

    useEffect(() => {
        getTransConfigDataById();
    }, []); // eslint-disable-line

    const formik = useFormik({
        initialValues: {
            productName: '',
            lkpProductCategory: '',
            isCoolingOffRequired: ''
        },

        validate: (data) => {
            let errors = {};

            if (!data.productName) {
                errors.productName = 'This Field is required.';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.productName)) {
                errors.productName = 'Leading and trailing space is not allowed';
            }
            if (!data.lkpProductCategory) {
                errors.lkpProductCategory = 'This Field is required.';
            }
            // if (!data.productCode) {
            //     errors.productCode = 'This Field is required.';
            //
            // }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            delete data['transClassification'];
            delete data['type'];
            delete data['accountNo'];
            delete data['accountLevelId'];
            delete data['currency'];
            delete data['fieldName'];
            delete data['fieldValue'];
            delete data['id'];
            delete data['maxAmount'];
            delete data['minAmount'];
            delete data['targetAccountType'];
            delete data['transSign'];
            delete data['currencyId'];
            delete data['exclude'];

            data['productId'] = productId;

            const transactionClassificationTableNewData = transactionClassificationTableData?.map((item) => ({
                id: item.id || '',
                type: item.type || '',
                transClassification: item.transClassification || '',
                transSign: item.transSign || '',
                currencyId: item?.lkpCurrency?.currencyId || item?.currencyId || '',
                targetAccountType: item.targetAccountType || '',
                accountNo: item?.tblAccount?.accountNo || item?.accountNo || '',
                transPatternId: item.transPatternId || '',
                isMandatory: item.isMandatory || ''
            }));

            const accountTypeTableNewData = accountTypeTableData.map((item) => ({
                accountLevelId: item?.lkpAccountLevel?.accountLevelId || item?.accountLevelId,
                minAmount: item.minAmount,
                maxAmount: item.maxAmount,
                exclude: item.exclude,
                productLimitId: item.productLimitId
            }));

            const fieldTableNewData = fieldTableData.map((item) => ({
                fieldName: item.fieldName,
                fieldValue: item.fieldValue,
                productFieldId: item.productFieldId
            }));

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data,
                        tblTransPatterns: transactionClassificationTableNewData,
                        tblProductLimits: accountTypeTableNewData,
                        tblProductFields: fieldTableNewData
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };

            if (transactionClassificationTableNewData.length <= 0) {
                toast.warning('Please add Transaction Classification');
            }

            const res = await dispatch(handlePostRequest(newData, '/productcatalog/v1/transactions/updatetransaction', true, false, 'transactions'));
            if (res?.responseCode === '010000') {
                formik.resetForm();
            }
            // }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const getTypeData = [
        { name: 'Static', value: 'S' },
        { name: 'Dynamic', value: 'D' }
    ];

    const getTransSignData = [
        { name: 'Credit', value: 'C' },
        { name: 'Debit', value: 'D' }
    ];

    const handledDeleteTransClassData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = transactionClassificationTableData.filter((value) => value.id !== rowData.id);
        setTransactionClassificationTableData(newFilterData);
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteTransClassData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledFieldInputTransactionClassificationData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? (e.target.checked ? 'M' : 'O') : e.target.value;

        const transClassificationDTypeData = {
            id: transactionClassificationData?.id,
            type: transactionClassificationData?.type,
            transClassification: transactionClassificationData?.transClassification,
            transSign: transactionClassificationData?.transSign,
            currencyId: transactionClassificationData?.currencyId,
            targetAccountType: '',
            accountNo: '',
            isMandatory: transactionClassificationData?.isMandatory
        };

        if (e.target.value === 'D') {
            setTransactionClassificationData({
                ...transClassificationDTypeData,
                [name]: value
            });
        } else {
            setTransactionClassificationData({ ...transactionClassificationData, [name]: value });
        }

        // Clear the error message for the field being edited
        setTransactionClassificationError({
            ...transactionClassificationError,
            [name]: ''
        });
    };

    const handledTransClassificationAdd = (e) => {
        e.preventDefault();

        const errors = {
            id: '',
            type: '',
            transClassification: '',
            transSign: '',
            currencyId: '',
            targetAccountType: '',
            accountNo: ''
        };

        if (transactionClassificationData.id === '') {
            errors.id = 'This field is required.';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(transactionClassificationData?.id)) {
            errors.id = 'Leading and trailing space is not allowed';
        } else if (!/^[0-9]+$/.test(transactionClassificationData.id)) {
            errors.id = 'Field should contain only numeric values';
        }

        if (transactionClassificationData.type === '') {
            errors.type = 'This field is required.';
        }
        if (transactionClassificationData.transClassification === '') {
            errors.transClassification = 'This field is required.';
        }
        if (transactionClassificationData.transSign === '') {
            errors.transSign = 'This field is required.';
        }
        if (transactionClassificationData.currencyId === '') {
            errors.currencyId = 'This field is required.';
        }
        if (transactionClassificationData.type === 'S') {
            if (transactionClassificationData.targetAccountType === '') {
                errors.targetAccountType = 'This field is required.';
            }
            if (transactionClassificationData.accountNo === '') {
                errors.accountNo = 'This field is required.';
            }
        }

        // Check for duplicate Id
        if (transactionClassificationTableData?.some((item) => item?.id == transactionClassificationData?.id)) {
            errors.id = 'This Id is already in use.';
        }

        setTransactionClassificationError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const transactionClassificationTableOldData = transactionClassificationTableData?.map((item) => ({
                id: item.id || '',
                type: item.type || '',
                transClassification: item.transClassification || '',
                transSign: item.transSign || '',
                currencyId: item?.lkpCurrency?.currencyId || item?.currencyId,
                targetAccountType: item.targetAccountType || '',
                accountNo: item?.tblAccount?.accountNo || item?.accountNo,
                transPatternId: item.transPatternId || '',
                isMandatory: item.isMandatory || ''
            }));

            const transClassificationTableData = {
                id: transactionClassificationData.id,
                type: transactionClassificationData.type,
                transClassification: transactionClassificationData.transClassification,
                transSign: transactionClassificationData.transSign,
                currencyId: transactionClassificationData.currencyId,
                targetAccountType: transactionClassificationData.targetAccountType,
                accountNo: transactionClassificationData.accountNo,
                isMandatory: transactionClassificationData.isMandatory
            };

            setTransactionClassificationTableData([...transactionClassificationTableOldData, transClassificationTableData]);

            setTransactionClassificationData({ id: '', type: '', transClassification: '', transSign: '', currency: '', targetAccountType: '', accountNo: '', isMandatory: 'O' });
        }
    };

    const handledAccountTypeAdd = (e) => {
        e.preventDefault();

        const errors = {
            accountLevelId: '',
            minAmount: '',
            maxAmount: ''
        };

        if (accountTypeData.accountLevelId === '') {
            errors.accountLevelId = 'This field is required.';
        }
        if (accountTypeData.minAmount === '') {
            errors.minAmount = 'This field is required.';
        } else if (!/^\d+$/.test(accountTypeData.minAmount)) {
            errors.minAmount = 'Min Amount should contain only numeric values.';
        }
        if (accountTypeData.maxAmount === '') {
            errors.maxAmount = 'This field is required.';
        } else if (!/^\d+$/.test(accountTypeData.maxAmount)) {
            errors.maxAmount = 'Max Amount should contain only numeric values.';
        }

        // Check if minAmount is greater than maxAmount
        if (accountTypeData.minAmount !== '' && accountTypeData.maxAmount !== '') {
            const minAmount = parseFloat(accountTypeData.minAmount);
            const maxAmount = parseFloat(accountTypeData.maxAmount);
            if (minAmount > maxAmount) {
                // errors.minAmount = 'Min Amount should not be greater than Max Amount.';
                errors.maxAmount = 'Max Amount should not be less than Min Amount.';
            }
        }

        // Check for duplicate accountLevelId
        if (accountTypeTableData.some((item) => (item?.lkpAccountLevel?.accountLevelId || item?.accountLevelId) == accountTypeData.accountLevelId)) {
            errors.accountLevelId = 'This account Type is already in use.';
        }

        setAccountTypeError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const accountTypeTableOldData = accountTypeTableData.map((item) => ({
                accountLevelId: item?.lkpAccountLevel?.accountLevelId?.toString() || item?.accountLevelId,
                minAmount: item.minAmount,
                maxAmount: item.maxAmount,
                exclude: item.exclude,
                productLimitId: item.productLimitId
            }));

            const accountTypeObjData = {
                accountLevelId: accountTypeData?.accountLevelId,
                minAmount: accountTypeData.minAmount,
                maxAmount: accountTypeData.maxAmount,
                exclude: accountTypeData.exclude ? 'Y' : 'N',
                productLimitId: ''
            };
            setAccountTypeTableData([...accountTypeTableOldData, accountTypeObjData]);

            setAccountTypeData({ accountLevelId: '', minAmount: '', maxAmount: '', exclude: false });
        }
    };

    const handledFieldInputAccountTypeData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        const checked = e.target.checked;
        const type = e.target.type;

        setAccountTypeData({ ...accountTypeData, [name]: type === 'checkbox' ? checked : value });

        // Clear the error message for the field being edited
        setAccountTypeError({
            ...accountTypeError,
            [name]: ''
        });
    };

    const handledDeleteAccountTypeData = (e, rowData, rowIndex) => {
        e.preventDefault();
        const newFilterData = accountTypeTableData?.filter((value, index) => index !== rowIndex?.rowIndex);
        setAccountTypeTableData(newFilterData);
    };

    const accountTypeBodyTemplate = (rowData) => {
        const rowDataAccountLevelId = rowData?.lkpAccountLevel?.accountLevelId || rowData?.accountLevelId;
        const accountLevelIdData = accountTypeLovData.filter((item) => item.lovId == rowDataAccountLevelId);

        return <p>{accountLevelIdData[0]?.name}</p>;
    };

    const excludeBodyTemplate = (rowData) => {
        return <p>{rowData?.exclude === 'Y' ? 'YES' : 'NO'}</p>;
    };

    const accountNumberBodyTemplate = (rowData) => {
        return <p>{rowData?.tblAccount?.accountNo || rowData?.accountNo}</p>;
    };

    const actionAccountTypeBodyTemplate = (rowData, rowIndex) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteAccountTypeData(e, rowData, rowIndex)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const handledFieldsDataAdd = (e) => {
        e.preventDefault();

        const errors = {
            fieldName: '',
            fieldValue: ''
        };

        if (fieldTypeData.fieldName === '') {
            errors.fieldName = 'This field is required.';
        } else if (!/^[^\s]+(\s[^\s]+)*$/.test(fieldTypeData?.fieldName)) {
            errors.fieldName = 'Leading and trailing space is not allowed';
        } else if (!/^[A-Za-z]+$/.test(fieldTypeData.fieldName)) {
            errors.fieldName = 'Field Name contain only alphabets.';
        }

        // if (fieldTypeData.fieldValue === '') {
        //     errors.fieldValue = 'This field is required.';
        // }

        if (fieldTypeData.fieldName !== '') {
            if (fieldTypeData.fieldValue === '') {
                errors.fieldValue = 'This field is required.';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(fieldTypeData?.fieldValue)) {
                errors.fieldValue = 'Leading and trailing space is not allowed';
            } else if (!/^\d+$/.test(fieldTypeData.fieldValue)) {
                errors.fieldValue = 'Field Name contain only numeric values.';
            }
        }

        // Check for duplicate fieldName
        if (fieldTableData.some((item) => item.fieldName === fieldTypeData.fieldName)) {
            errors.fieldName = 'This field name is already in use.';
        }

        setFieldTypeError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const fieldTableOldData = fieldTableData.map((item) => ({
                fieldName: item.fieldName,
                fieldValue: item.fieldValue,
                productFieldId: item.productFieldId
            }));
            const fieldsObjData = {
                fieldName: fieldTypeData.fieldName,
                fieldValue: fieldTypeData.fieldValue
            };
            setFieldTableData([...fieldTableOldData, fieldsObjData]);

            setFieldTypeData({ fieldName: '', fieldValue: '' });
        }
    };

    const handledFieldInputData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;
        setFieldTypeData({ ...fieldTypeData, [name]: value });

        // Clear the error message for the field being edited
        setFieldTypeError({
            ...fieldTypeError,
            [name]: ''
        });
    };

    const handledDeleteFieldsData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = fieldTableData.filter((value) => value.fieldName !== rowData.fieldName);
        setFieldTableData(newFilterData);
    };

    const actionFieldsBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeleteFieldsData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const getTransClassificationData = [
        { name: 'Customer', value: 'C' },
        { name: 'Agent', value: 'A' },
        { name: 'Merchant', value: 'M' }
    ];

    const getTransAccountTypeData = [
        { name: 'Customer', value: 'C' },
        { name: 'Agent', value: 'A' },
        { name: 'Merchant', value: 'M' },
        { name: 'GL', value: 'G' }
    ];

    // const currencyTemplateBody = (rowData) => {
    //     return <div>{rowData?.currencyId ? rowData?.currencyId : rowData?.lkpCurrency?.currencyId ? rowData?.lkpCurrency?.currencyId : null}</div>;
    // };

    // const accountNoBodyTemplate = (rowData) => {
    //     return <div>{rowData?.accountNo ? rowData?.accountNo : rowData?.tblAccount?.accountNo ? rowData?.tblAccount?.accountNo : null}</div>;
    // };

    // const accountLevelIdBodyTemplate = (rowData) => {
    //     return <div>{rowData?.accountLevelId ? rowData?.accountLevelId : rowData?.lkpAccountLevel?.accountLevelId ? rowData?.lkpAccountLevel?.accountLevelId : null}</div>;
    // };

    const transClassificationBodyTemplate = (rowData) => {
        return <div>{rowData?.transClassification === 'C' ? 'Customer' : rowData?.transClassification === 'A' ? 'Agent' : 'Merchant'}</div>;
    };

    const targetAccountTypeBodyTemplate = (rowData) => {
        return <div>{rowData?.targetAccountType === 'C' ? 'Customer' : rowData?.targetAccountType === 'A' ? 'Agent' : rowData?.targetAccountType === 'M' ? 'Merchant' : rowData?.targetAccountType === 'G' ? 'GL' : '-'}</div>;
    };

    const typeBodyTemplate = (rowData) => {
        return <div>{rowData?.type === 'S' ? 'Static' : 'Dynamic'}</div>;
    };

    const transSignBodyTemplate = (rowData) => {
        return <div>{rowData?.transSign === 'C' ? 'Credit' : 'Debit'}</div>;
    };

    // const currencyBodyTemplate = (rowData) => {
    //     return <div>{(rowData?.lkpCurrency?.currencyId || rowData?.currencyId) == 1 ? 'PAKISTANI RUPEES' : (rowData?.lkpCurrency?.currencyId || rowData?.currencyId) == 2 ? 'Default' : '-'}</div>;
    // };
    const currencyBodyTemplate = (rowData) => {
        // Check if the rowData contains currencyId directly or in lkpCurrency object
        const currencyId = rowData.lkpCurrency?.currencyId || rowData.currencyId;

        // Find the currency object with the matching lovId
        const currency = currencyData.find((currency) => currency.lovId === currencyId?.toString());

        // Check if a matching currency object was found
        if (currency) {
            // Return the currency name
            return <div>{currency.name}</div>;
        } else {
            // If no matching currency object was found, return a default value
            return <div>-</div>;
        }
    };

    return (
        <>
            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="Form__Header Full__Width">
                            <h2>EDIT TRANSACTION CONFIGURATION</h2>
                        </div>

                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="productName" className={classNames({ 'p-error': isFormFieldValid('productName') }, 'Label__Text')}>
                                    Transaction Name <span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    maxLength={100}
                                    id="productName"
                                    placeholder="Enter transaction name"
                                    name="productName"
                                    value={formik?.values?.productName?.replace(/\s\s+/g, ' ') || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('productName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('productName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lkpProductCategory" className={classNames({ 'p-error': isFormFieldValid('lkpProductCategory') }, 'Label__Text')}>
                                    Product Category<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="lkpProductCategory"
                                    placeholder="Select Product Category"
                                    options={productCategoryLovData}
                                    optionLabel="name"
                                    name="lkpProductCategory"
                                    optionValue="lovId"
                                    value={formik?.values?.lkpProductCategory || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('lkpProductCategory') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('lkpProductCategory')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 mt-auto">
                            <div className="p-field">
                                <div className="field-checkbox">
                                    <Checkbox
                                        inputId="isCoolingOffRequired"
                                        name="isCoolingOffRequired"
                                        value={formik?.values?.isCoolingOffRequired}
                                        checked={formik?.values?.isCoolingOffRequired === 'Y'}
                                        onChange={(e) => formik.setFieldValue('isCoolingOffRequired', e.checked ? 'Y' : 'N')}
                                    />
                                    <label htmlFor="isCoolingOffRequired" className={classNames({ 'p-error': isFormFieldValid('isCoolingOffRequired') }, 'Label__Text')}>
                                        Cooling Off
                                    </label>
                                </div>
                                {getFormErrorMessage('isCoolingOffRequired')}
                            </div>
                        </div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="Form__Header Full__Width">
                                <h2>TRANSACTIONS CLASSIFICATION</h2>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="id" className={classNames({ 'p-error': isFormFieldValid('id') }, 'Label__Text')}>
                                        ID <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        id="id"
                                        placeholder="Enter ID"
                                        name="id"
                                        maxLength={5}
                                        value={transactionClassificationData.id?.replace(/\s\s+/g, ' ') || ''}
                                        onChange={handledFieldInputTransactionClassificationData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('id') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{transactionClassificationError.id}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="type" className={classNames({ 'p-error': isFormFieldValid('type') }, 'Label__Text')}>
                                        Type <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="type"
                                        placeholder="Select Type"
                                        options={getTypeData}
                                        optionLabel="name"
                                        name="type"
                                        optionValue="value"
                                        value={transactionClassificationData.type || ''}
                                        onChange={handledFieldInputTransactionClassificationData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('type') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{transactionClassificationError.type}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="transClassification" className={classNames({ 'p-error': isFormFieldValid('transClassification') }, 'Label__Text')}>
                                        Transaction Classification <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="transClassification"
                                        placeholder="Select Classification"
                                        options={getTransClassificationData}
                                        optionLabel="name"
                                        name="transClassification"
                                        optionValue="value"
                                        value={transactionClassificationData.transClassification || ''}
                                        onChange={handledFieldInputTransactionClassificationData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('transClassification') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{transactionClassificationError.transClassification}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="transSign" className={classNames({ 'p-error': isFormFieldValid('transSign') }, 'Label__Text')}>
                                        Credit/Debit <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="transSign"
                                        placeholder="Select Credit/Debit"
                                        options={getTransSignData}
                                        optionLabel="name"
                                        name="transSign"
                                        optionValue="value"
                                        value={transactionClassificationData.transSign || ''}
                                        onChange={handledFieldInputTransactionClassificationData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('transSign') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{transactionClassificationError.transSign}</small>
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="currencyId" className={classNames({ 'p-error': isFormFieldValid('currencyId') }, 'Label__Text')}>
                                        Currency <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="currencyId"
                                        placeholder="Select Currency"
                                        options={currencyData}
                                        optionLabel="name"
                                        name="currencyId"
                                        optionValue="lovId"
                                        value={transactionClassificationData.currencyId || ''}
                                        onChange={handledFieldInputTransactionClassificationData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('currencyId') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{transactionClassificationError.currencyId}</small>
                                </div>
                            </div>
                            {transactionClassificationData.type === 'S' ? (
                                <>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="targetAccountType" className={classNames({ 'p-error': isFormFieldValid('targetAccountType') }, 'Label__Text')}>
                                                Target Account Type {transactionClassificationData.type === 'S' ? <span className="Label__Required">*</span> : null}
                                            </label>
                                            <Dropdown
                                                disabled={transactionClassificationData.type === 'D' ? true : false}
                                                id="targetAccountType"
                                                placeholder="Select Target"
                                                options={getTransAccountTypeData}
                                                optionLabel="name"
                                                name="targetAccountType"
                                                optionValue="value"
                                                value={transactionClassificationData.targetAccountType || ''}
                                                onChange={handledFieldInputTransactionClassificationData}
                                                className={classNames({ 'p-invalid': isFormFieldValid('targetAccountType') }, 'Dropdown__Round')}
                                            />

                                            <small className="p-error">{transactionClassificationError.targetAccountType}</small>
                                        </div>
                                    </div>
                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="accountNo" className={classNames({ 'p-error': isFormFieldValid('accountNo') }, 'Label__Text')}>
                                                Account Number {transactionClassificationData.type === 'S' ? <span className="Label__Required">*</span> : null}
                                            </label>
                                            <InputText
                                                disabled={transactionClassificationData.type === 'D' ? true : false}
                                                maxLength={100}
                                                id="accountNo"
                                                placeholder="Enter Account Number"
                                                name="accountNo"
                                                value={transactionClassificationData?.accountNo?.replace(/\s\s+/g, ' ') || ''}
                                                onChange={handledFieldInputTransactionClassificationData}
                                                className={classNames({ 'p-invalid': isFormFieldValid('accountNo') }, 'Input__Round')}
                                            />
                                            <small className="p-error">{transactionClassificationError.accountNo}</small>
                                        </div>
                                    </div>
                                </>
                            ) : null}

                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="isMandatory" className={classNames({ 'p-error': isFormFieldValid('isMandatory') }, 'Label__Text')}>
                                        Is Mandatory
                                    </label>
                                    <div className="field-checkbox ml-3">
                                        <Checkbox
                                            inputId="isMandatory"
                                            name="isMandatory"
                                            value={transactionClassificationData?.isMandatory}
                                            onChange={handledFieldInputTransactionClassificationData}
                                            checked={transactionClassificationData?.isMandatory === 'M'}
                                        />
                                    </div>
                                    {/* {getFormErrorMessage('wht')} */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" loadingIcon={loadingIcon} label="Add" onClick={handledTransClassificationAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={transactionClassificationTableData}>
                                    {/* <Column field="type" header="Type" />
                                    <Column field="accountType" header="Account Type" />
                                    <Column field="transSign" header="Credit/Debit" />
                                    <Column body={currencyTemplateBody} header="Currency" />
                                    <Column field="targetAccountType" header="General Ledger" />
                                    <Column body={accountNoBodyTemplate} header="Account Number" />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" /> */}

                                    <Column field="id" header="Id" />
                                    <Column header="Type" body={typeBodyTemplate} />
                                    {/* <Column field="accountType" header="Account Type" /> */}
                                    <Column header="Transaction Classification" body={transClassificationBodyTemplate} />
                                    <Column header="Credit/Debit" body={transSignBodyTemplate} />
                                    <Column header="Currency" body={currencyBodyTemplate} />
                                    {/* <Column field="targetAccountType" header="General Ledger" /> */}
                                    <Column header="Account Number" body={accountNumberBodyTemplate} />
                                    <Column header="Target Account Type" body={targetAccountTypeBodyTemplate} />
                                    <Column field="isMandatory" header="Is Mandatory" />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    {/* form and data table account type */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="accountLevelId" className={classNames({ 'p-error': isFormFieldValid('accountLevelId') }, 'Label__Text')}>
                                        Account Type <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="accountLevelId"
                                        placeholder="Select Type"
                                        options={accountTypeLovData}
                                        optionLabel="name"
                                        name="accountLevelId"
                                        optionValue="lovId"
                                        value={accountTypeData.accountLevelId || ''}
                                        onChange={handledFieldInputAccountTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('accountLevelId') }, 'Dropdown__Round')}
                                    />

                                    <small className="p-error">{accountTypeError.accountLevelId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-8 mt-5">
                                <div className="p-field">
                                    <div className="field-checkbox">
                                        <Checkbox inputId="exclude" name="exclude" value={accountTypeData?.exclude} checked={accountTypeData?.exclude} onChange={handledFieldInputAccountTypeData} />
                                        <label htmlFor="exclude" className={classNames({ 'p-error': isFormFieldValid('exclude') }, 'Label__Text')}>
                                            Exclude
                                        </label>
                                    </div>
                                    <small className="p-error">{accountTypeError.exclude}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="minAmount" className={classNames({ 'p-error': isFormFieldValid('minAmount') }, 'Label__Text')}>
                                        Minimum Amount <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="minAmount"
                                        placeholder="Enter Minimum Amount"
                                        name="minAmount"
                                        value={accountTypeData.minAmount?.trim()?.toString() || ''}
                                        onChange={handledFieldInputAccountTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('minAmount') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{accountTypeError.minAmount}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="maxAmount" className={classNames({ 'p-error': isFormFieldValid('maxAmount') }, 'Label__Text')}>
                                        Maximum Amount <span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="maxAmount"
                                        placeholder="Enter Maximum Amount"
                                        name="maxAmount"
                                        value={accountTypeData?.maxAmount?.trim()?.toString() || ''}
                                        onChange={handledFieldInputAccountTypeData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('maxAmount') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{accountTypeError.maxAmount}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" label="Add" onClick={handledAccountTypeAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={accountTypeTableData}>
                                        {/* <Column body={accountLevelIdBodyTemplate} header="Account Type" /> */}
                                        <Column header="Account Type" body={accountTypeBodyTemplate} />
                                        <Column field="minAmount" header="Minimum" />
                                        <Column field="maxAmount" header="Maximum" />
                                        <Column header="Exclude" body={excludeBodyTemplate} />
                                        <Column header="Actions" body={actionAccountTypeBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Form and data table Fields related */}

                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="fieldName" className={classNames({ 'p-error': isFormFieldValid('fieldName') }, 'Label__Text')}>
                                        Field Name
                                        {/* <span className="Label__Required">*</span> */}
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="fieldName"
                                        placeholder="Enter Field Name"
                                        name="fieldName"
                                        value={fieldTypeData.fieldName || ''}
                                        onChange={handledFieldInputData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('fieldName') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{fieldTypeError.fieldName}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="fieldValue" className={classNames({ 'p-error': isFormFieldValid('fieldValue') }, 'Label__Text')}>
                                        Field Value
                                        {/* <span className="Label__Required">*</span> */}
                                    </label>
                                    <InputText
                                        maxLength={100}
                                        id="fieldValue"
                                        placeholder="Enter Field Value"
                                        name="fieldValue"
                                        value={fieldTypeData.fieldValue || ''}
                                        onChange={handledFieldInputData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('fieldValue') }, 'Input__Round')}
                                    />
                                    <small className="p-error">{fieldTypeError.fieldValue}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-2 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" label="Add" onClick={(e) => handledFieldsDataAdd(e)} className="Btn__Add" />
                                </div>
                            </div>
                        </div>

                        <div className="grid">
                            <div className="col-12">
                                <div className="card">
                                    <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={fieldTableData}>
                                        <Column field="fieldName" header="Field Name" />
                                        <Column field="fieldValue" header="Field Value" />
                                        <Column header="Actions" body={actionFieldsBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                    </DataTable>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Down__Btn">
                        <Button type="submit" label="Update" className="Btn__Dark" onClick={() => ScrollMover()} />
                        <Button disabled={loading} onClick={() => navigate('/transactions')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}

export default EditTransactionConfiguration;
