import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useDispatch } from 'react-redux';
import { handlePostRequest } from '../../../services/PostTemplate';
import { useLocation, useNavigate } from 'react-router-dom';
import { InputTextarea } from 'primereact/inputtextarea';
import { handleGetRequest } from '../../../services/GetTemplate';
import AuthorizationHistoryTable from '../../../components/AuthorizationHistoryTable/AuthorizationHistoryTable';
import { handlePostRequestForSAF } from '../../../services/PostTemplateForSAF';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function SwitchConfigurationCheckerEdit() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [addData, setAddData] = useState([]);
    const [editData, setEditData] = useState([]);
    const [switchConnectioData, setSwitchConnectioData] = useState([]);

    const [switchMtiData, setSwitchMtiData] = useState([]);
    const [queueConfigTableData, setQueueConfigTableData] = useState([]);
    const [queueConfigData, setQueueConfigData] = useState({
        expiryTime: '',
        size: '',
        mtiId: ''
    });
    const [queueConfigError, setQueueConfigError] = useState({
        expiryTime: '',
        size: '',
        mtiId: ''
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let { search } = useLocation();
    const query = new URLSearchParams(search);
    const refTableId = query.get('refTableId');
    const requestType = query.get('requestType');
    const mcRequestId = query.get('mcRequestId');
    const mcPeindingRequestId = query.get('mcPendingRequest');
    const requestTypeDescr = query.get('requestTypeDescr');
    const tableName = query.get('tableName');

    const socketTypeOptions = [
        {
            name: 'Server',
            value: 'S'
        },
        {
            name: 'Client',
            value: 'C'
        }
    ];
    const statusOptions = [
        {
            name: 'Active',
            value: 'Y'
        },
        {
            name: 'Inactive',
            value: 'N'
        }
    ];

    const validationSchema = Yup.object().shape({
        checkerComments: Yup.string().required('This Field is required.'),
        action: Yup.string().required('This Field is required.')
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            clientCode: '',
            clientName: '',
            safRetries: '',
            safRetriesTime: '',
            socketName: '',
            socketIp: '',
            socketPort: '',
            socketType: '',
            socketUrl: '',
            socketStatus: '',
            queueExpiryTime: '',
            queueSize: '',
            queueStatus: '',
            action: '',
            checkerComments: ''
        },

        onSubmit: async (data) => {
            if (requestType === 'I') {
                const addPayload = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            switchConfigDTO: {
                                client: {
                                    id: refTableId,
                                    clientCode: addData?.switchClientCode,
                                    clientName: addData?.switchClientName,
                                    switchConnectionId: addData?.lkpSwitchConnection?.switchConnectionId,
                                    active: data?.action === '2' ? 'Y' : 'N'
                                },
                                safConfig: [
                                    {
                                        id: addData?.tblSwitchSafConfigs[0]?.switchSafConfigId,
                                        safEntries: addData?.tblSwitchSafConfigs[0]?.safRetries,
                                        safEntriesTime: addData?.tblSwitchSafConfigs[0]?.safRetriesTime
                                    }
                                ],
                                socket: [
                                    {
                                        id: addData?.tblSwitchSockets[0]?.switchSocketId,
                                        name: addData?.tblSwitchSockets[0]?.socketName,
                                        ip: addData?.tblSwitchSockets[0]?.socketIp,
                                        port: addData?.tblSwitchSockets[0]?.socketPort,
                                        type: addData?.tblSwitchSockets[0]?.socketType,
                                        url: addData?.tblSwitchSockets[0]?.socketUrl,
                                        status: addData?.tblSwitchSockets[0]?.isActive
                                    }
                                ],
                                queue: queueConfigTableData
                            },
                            mcActionRequest: {
                                mcRequestId: mcRequestId,
                                mcPeindingRequestId: mcPeindingRequestId,
                                checkerId: '18',
                                checkerComments: data?.checkerComments,
                                action: data?.action,
                                updatedIndex: null
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                await dispatch(handlePostRequestForSAF(addPayload, '/communication-service/client/clientCheckerAction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
                const updatePayload = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            switchConfigDTO: {
                                client: {
                                    id: refTableId,
                                    clientCode: editData?.client?.clientCode,
                                    clientName: editData?.client?.clientName,
                                    switchConnectionId: editData?.client?.switchConnectionId,
                                    active: data?.action === '2' ? 'Y' : 'N'
                                },
                                safConfig: [
                                    {
                                        // id: editData?.tblSwitchSafConfigs[0]?.switchSafConfigId,
                                        safEntries: editData?.safConfig[0]?.safEntries?.toString(),
                                        safEntriesTime: editData?.safConfig[0]?.safEntriesTime?.toString()
                                    }
                                ],
                                socket: [
                                    {
                                        // id: editData?.tblSwitchSockets[0]?.switchSocketId,
                                        name: editData?.socket[0]?.name,
                                        ip: editData?.socket[0]?.ip,
                                        port: editData?.socket[0]?.port,
                                        type: editData?.socket[0]?.type,
                                        url: editData?.socket[0]?.url,
                                        status: editData?.socket[0]?.status
                                    }
                                ],
                                queue: queueConfigTableData
                            },
                            mcActionRequest: {
                                mcRequestId: mcRequestId,
                                mcPeindingRequestId: mcPeindingRequestId,
                                checkerId: '18',
                                checkerComments: data?.checkerComments,
                                action: data?.action,
                                updatedIndex: null
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                await dispatch(handlePostRequestForSAF(updatePayload, '/communication-service/client/clientCheckerAction', true, false, 'requestchecker'));
            } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
                const enableDisablePayload = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            switchConfigDTO: {
                                client: {
                                    id: refTableId,
                                    clientCode: addData?.switchClientCode,
                                    clientName: addData?.switchClientName,
                                    switchConnectionId: addData?.lkpSwitchConnection?.switchConnectionId,
                                    active: editData?.client?.active === 'N' ? 'N' : 'Y'
                                },
                                safConfig: [
                                    {
                                        id: addData?.tblSwitchSafConfigs[0]?.switchSafConfigId,
                                        safEntries: addData?.tblSwitchSafConfigs[0]?.safRetries,
                                        safEntriesTime: addData?.tblSwitchSafConfigs[0]?.safRetriesTime
                                    }
                                ],
                                socket: [
                                    {
                                        id: addData?.tblSwitchSockets[0]?.switchSocketId,
                                        name: addData?.tblSwitchSockets[0]?.socketName,
                                        ip: addData?.tblSwitchSockets[0]?.socketIp,
                                        port: addData?.tblSwitchSockets[0]?.socketPort,
                                        type: addData?.tblSwitchSockets[0]?.socketType,
                                        url: addData?.tblSwitchSockets[0]?.socketUrl,
                                        status: addData?.tblSwitchSockets[0]?.isActive
                                    }
                                ],
                                queue: queueConfigTableData
                            },
                            mcActionRequest: {
                                mcRequestId: mcRequestId,
                                mcPeindingRequestId: mcPeindingRequestId,
                                checkerId: '18',
                                checkerComments: data?.checkerComments,
                                action: data?.action,
                                updatedIndex: null
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                await dispatch(handlePostRequestForSAF(enableDisablePayload, '/communication-service/client/clientCheckerAction', true, false, 'requestchecker'));
            }
        }
    });

    //get Data by table Id
    const getDatabyId = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    client: { id: refTableId }
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequestForSAF(newData, '/communication-service/client/'));
        setAddData(res?.payLoad);

        formik.setFieldValue('clientCode', res?.payLoad?.switchClientCode);
        formik.setFieldValue('clientName', res?.payLoad?.switchClientName);

        formik.setFieldValue('safRetries', res?.payLoad?.tblSwitchSafConfigs[0]?.safRetries);
        formik.setFieldValue('safRetriesTime', res?.payLoad?.tblSwitchSafConfigs[0]?.safRetriesTime);

        formik.setFieldValue('socketName', res?.payLoad?.tblSwitchSockets[0]?.socketName);
        formik.setFieldValue('socketIp', res?.payLoad?.tblSwitchSockets[0]?.socketIp);
        formik.setFieldValue('socketPort', res?.payLoad?.tblSwitchSockets[0]?.socketPort);
        formik.setFieldValue('socketType', res?.payLoad?.tblSwitchSockets[0]?.socketType);
        formik.setFieldValue('socketUrl', res?.payLoad?.tblSwitchSockets[0]?.socketUrl);
        formik.setFieldValue('socketStatus', res?.payLoad?.tblSwitchSockets[0]?.isActive);

        // formik.setFieldValue('queueExpiryTime', res?.payLoad?.tblSwitchMessageBufferQueues[0]?.queueExpiryTime);
        // formik.setFieldValue('queueSize', res?.payLoad?.tblSwitchMessageBufferQueues[0]?.queueLimitSize);
        // formik.setFieldValue('queueStatus', res?.payLoad?.tblSwitchMessageBufferQueues[0]?.isActive);

        const queueData = res?.payLoad?.tblSwitchMessageBufferQueues?.map((item) => {
            return {
                expiryTime: item?.queueExpiryTime,
                size: item?.queueLimitSize,
                mtiId: item?.tblSwitchMti?.switchMtiId,
                status: item?.isActive,
                id: item?.switchMessageBufferQueueId
            };
        });

        setQueueConfigTableData(queueData);

        formik.setFieldValue('switchConnectionId', res?.payLoad?.lkpSwitchConnection?.switchConnectionId?.toString());
    };

    //Get data by mc request Id
    const getMcRequestDataById = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    id: mcRequestId
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };

        const res = await dispatch(handlePostRequestForSAF(newData, '/communication-service/client/checker'));
        setEditData(res?.payLoad);

        // formik.setFieldValue('clientCode', res?.payLoad?.client?.clientCode);
        // formik.setFieldValue('clientName', res?.payLoad?.client?.clientName);
        // formik.setFieldValue('switchConnectionId', res?.payLoad?.client?.switchConnectionId?.toString());

        // formik.setFieldValue('safRetries', res?.payLoad?.safConfig[0]?.safEntries?.toString());
        // formik.setFieldValue('safRetriesTime', res?.payLoad?.safConfig[0]?.safEntriesTime?.toString());

        // formik.setFieldValue('socketName', res?.payLoad?.socket[0]?.name);
        // formik.setFieldValue('socketIp', res?.payLoad?.socket[0]?.ip);
        // formik.setFieldValue('socketPort', res?.payLoad?.socket[0]?.port);
        // formik.setFieldValue('socketType', res?.payLoad?.socket[0]?.type);
        // formik.setFieldValue('socketUrl', res?.payLoad?.socket[0]?.url);
        // formik.setFieldValue('socketStatus', res?.payLoad?.socket[0]?.status);

        // formik.setFieldValue('queueExpiryTime', res?.payLoad?.queue[0]?.expiryTime);
        // formik.setFieldValue('queueSize', res?.payLoad?.queue[0]?.size);
        // formik.setFieldValue('queueStatus', res?.payLoad?.queue[0]?.status);

        if (requestTypeDescr !== 'Enable/Disable Request') {
            formik.setFieldValue('clientCode', res?.payLoad?.client?.clientCode);
            formik.setFieldValue('clientName', res?.payLoad?.client?.clientName);
            formik.setFieldValue('switchConnectionId', res?.payLoad?.client?.switchConnectionId?.toString());

            formik.setFieldValue('safRetries', res?.payLoad?.safConfig[0]?.safEntries?.toString());
            formik.setFieldValue('safRetriesTime', res?.payLoad?.safConfig[0]?.safEntriesTime?.toString());

            formik.setFieldValue('socketName', res?.payLoad?.socket[0]?.name);
            formik.setFieldValue('socketIp', res?.payLoad?.socket[0]?.ip);
            formik.setFieldValue('socketPort', res?.payLoad?.socket[0]?.port);
            formik.setFieldValue('socketType', res?.payLoad?.socket[0]?.type);
            formik.setFieldValue('socketUrl', res?.payLoad?.socket[0]?.url);
            formik.setFieldValue('socketStatus', res?.payLoad?.socket[0]?.status);

            // formik.setFieldValue('queueExpiryTime', res?.payLoad?.queue[0]?.expiryTime);
            // formik.setFieldValue('queueSize', res?.payLoad?.queue[0]?.size);
            // formik.setFieldValue('queueStatus', res?.payLoad?.queue[0]?.status);

            const queueData = res?.payLoad?.queue?.map((item) => {
                return {
                    expiryTime: item?.expiryTime,
                    size: item?.size,
                    mtiId: item?.mtiId,
                    status: item?.status,
                    id: item?.id
                };
            });

            setQueueConfigTableData(queueData);
        }
    };

    //get status list
    const getStatusListData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        const updatedArrayWithoutPending = res?.payLoad.filter((item) => item.lovId !== '1');
        setStatusLovData(updatedArrayWithoutPending);
    };

    const getSwitchConnectionData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getswitchconnections');
        setSwitchConnectioData(res?.payLoad);
    };

    const getSwitchMtiData = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getswitchmti');
        setSwitchMtiData(res?.payLoad);
    };

    useEffect(() => {
        getSwitchMtiData();
        if (requestType === 'I') {
            getDatabyId();
        } else if (requestType === 'U' && requestTypeDescr !== 'Enable/Disable Request') {
            getMcRequestDataById();
        } else if (requestType === 'U' && requestTypeDescr === 'Enable/Disable Request') {
            getDatabyId();
            getMcRequestDataById();
        }
        getStatusListData();
        getSwitchConnectionData();
    }, []); // eslint-disable-line

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const handledQueueConfigAdd = (e) => {
        e.preventDefault();

        const errors = {
            expiryTime: '',
            size: '',
            mtiId: ''
        };

        // Check for duplicate Id
        if (queueConfigTableData?.some((item) => item?.mtiId === queueConfigData?.mtiId)) {
            errors.mtiId = 'This MTI is already in use.';
        }

        setQueueConfigError(errors);

        // If there are no errors, you can submit the form or perform further actions.
        if (Object.values(errors).every((error) => error === '')) {
            const newQueueConfigTableData = {
                expiryTime: queueConfigData.expiryTime,
                size: queueConfigData.size,
                mtiId: queueConfigData.mtiId,
                status: 'Y'
            };
            setQueueConfigTableData([...queueConfigTableData, newQueueConfigTableData]);

            setQueueConfigData({ expiryTime: '', size: '', mtiId: '' });
        }
    };

    const handledFieldInputQueueConfigData = (e) => {
        e.preventDefault();
        const name = e.target.name;
        const value = e.target.value;

        setQueueConfigData({ ...queueConfigData, [name]: value });

        // Clear the error message for the field being edited
        setQueueConfigError({
            ...queueConfigError,
            [name]: ''
        });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button tooltip="Delete" disabled tooltipOptions={{ position: 'top' }} icon="pi pi-trash" onClick={(e) => handledDeletequeueConfigData(e, rowData)} className="p-button-rounded p-button-danger" style={{ border: 'none' }} />
                </div>
            </>
        );
    };

    const mtiBodyTemplate = (rowData) => {
        const filteredMti = switchMtiData?.filter((item) => item.lovId == rowData?.mtiId);

        return (
            <p>
                <b>{filteredMti[0]?.name}</b>
            </p>
        );
    };

    const handledDeletequeueConfigData = (e, rowData) => {
        e.preventDefault();
        const newFilterData = queueConfigTableData?.filter((value) => value.mtiId !== rowData.mtiId);
        setQueueConfigTableData(newFilterData);
    };

    return (
        <>
            <div className="card Card__Round riskDashboardPage">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">ADD CLIENT</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientCode" className={classNames({ 'p-error': isFormFieldValid('clientCode') }, 'Label__Text')}>
                                    Client Code
                                </label>
                                <InputText
                                    id="clientCode"
                                    name="clientCode"
                                    placeholder="Enter Client Code"
                                    value={formik?.values?.clientCode || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientCode') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('clientCode')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="clientName" className={classNames({ 'p-error': isFormFieldValid('clientName') }, 'Label__Text')}>
                                    Client Name
                                </label>
                                <InputText
                                    id="clientName"
                                    name="clientName"
                                    placeholder="Client Name"
                                    value={formik?.values?.clientName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('clientName') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('clientName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="switchConnectionId" className={classNames({ 'p-error': isFormFieldValid('switchConnectionId') }, 'Label__Text')}>
                                    Switch Connection<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="switchConnectionId"
                                    placeholder="Select Switch Connection"
                                    options={switchConnectioData}
                                    optionLabel="name"
                                    optionValue="lovId"
                                    disabled
                                    name="switchConnectionId"
                                    value={formik.values.switchConnectionId}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('switchConnectionId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('switchConnectionId')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SAF RETRIES</div>
                    </div>

                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetries" className={classNames({ 'p-error': isFormFieldValid('safRetries') }, 'Label__Text')}>
                                    SAF Retries
                                </label>
                                <InputText
                                    id="safRetries"
                                    name="safRetries"
                                    placeholder="Enter SAF Retries"
                                    value={formik?.values?.safRetries || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetries') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('safRetries')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="safRetriesTime" className={classNames({ 'p-error': isFormFieldValid('safRetriesTime') }, 'Label__Text')}>
                                    SAF Retries Time (in milli's)
                                </label>
                                <InputText
                                    id="safRetriesTime"
                                    name="safRetriesTime"
                                    placeholder="Enter SAF Retries Time"
                                    value={formik?.values?.safRetriesTime || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('safRetriesTime') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('safRetriesTime')}
                            </div>
                        </div>
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">SOCKET CONFIGURATION</div>
                    </div>
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketName" className={classNames({ 'p-error': isFormFieldValid('socketName') }, 'Label__Text')}>
                                    Socket Name
                                </label>
                                <InputText
                                    id="socketName"
                                    name="socketName"
                                    placeholder="Enter Socket Name"
                                    value={formik?.values?.socketName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketName') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketIp" className={classNames({ 'p-error': isFormFieldValid('socketIp') }, 'Label__Text')}>
                                    Socket IP
                                </label>
                                <InputText
                                    id="socketIp"
                                    name="socketIp"
                                    placeholder="Enter Socket IP"
                                    value={formik?.values?.socketIp || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketIp') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketIp')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketPort" className={classNames({ 'p-error': isFormFieldValid('socketPort') }, 'Label__Text')}>
                                    Port
                                </label>
                                <InputText
                                    id="socketPort"
                                    placeholder="Enter Port Number"
                                    name="socketPort"
                                    value={formik.values.socketPort}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketPort') }, 'Input__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketPort')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketType" className={classNames({ 'p-error': isFormFieldValid('socketType') }, 'Label__Text')}>
                                    Socket Type
                                </label>
                                <Dropdown
                                    id="socketType"
                                    placeholder="Select Socket Type"
                                    options={socketTypeOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketType"
                                    value={formik.values.socketType}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketType') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-8">
                            <div className="p-field">
                                <label htmlFor="socketUrl" className={classNames({ 'p-error': isFormFieldValid('socketUrl') }, 'Label__Text')}>
                                    Socket URL
                                </label>
                                <InputText
                                    id="socketUrl"
                                    name="socketUrl"
                                    placeholder="Enter Socket URL"
                                    value={formik?.values?.socketUrl || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketUrl') }, 'Input__Round')}
                                    aria-required
                                    disabled
                                />
                                {getFormErrorMessage('socketUrl')}
                            </div>
                        </div>

                        {/* <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="socketStatus" className={classNames({ 'p-error': isFormFieldValid('socketStatus') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="socketStatus"
                                    placeholder="Select Status"
                                    options={statusOptions}
                                    optionLabel="name"
                                    optionValue="value"
                                    name="socketStatus"
                                    value={formik.values.socketStatus}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('socketStatus') }, 'Dropdown__Round')}
                                    disabled
                                />
                                {getFormErrorMessage('socketStatus')}
                            </div>
                        </div> */}
                    </div>

                    <div className="flex mb-3">
                        <div className="title__header riskDashboardHeader ">QUEUE CONFIGURATION</div>
                    </div>
                    <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                        <div className="p-fluid p-formgrid grid mb-5">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="expiryTime" className={classNames({ 'p-error': isFormFieldValid('expiryTime') }, 'Label__Text')}>
                                        Expiry Time In (in milli's)
                                    </label>
                                    <InputText
                                        maxLength={10}
                                        id="expiryTime"
                                        name="expiryTime"
                                        // keyfilter="int"
                                        disabled
                                        placeholder="Enter Expiry Time"
                                        value={queueConfigData?.expiryTime || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('expiryTime') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('expiryTime')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="size" className={classNames({ 'p-error': isFormFieldValid('size') }, 'Label__Text')}>
                                        Queue Size
                                    </label>
                                    <InputText
                                        maxLength={2}
                                        id="size"
                                        name="size"
                                        keyfilter="int"
                                        disabled
                                        placeholder="Enter Queue Size"
                                        value={queueConfigData?.size || ''}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('size') }, 'Input__Round')}
                                        aria-required
                                    />
                                    {/* {getFormErrorMessage('size')} */}
                                    {/* <small className="p-error">{transactionClassificationError.id}</small> */}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="mtiId" className={classNames({ 'p-error': isFormFieldValid('mtiId') }, 'Label__Text')}>
                                        MTI
                                    </label>
                                    <Dropdown
                                        id="mtiId"
                                        placeholder="Select MTI"
                                        options={switchMtiData}
                                        optionLabel="name"
                                        optionValue="lovId"
                                        name="mtiId"
                                        disabled
                                        value={queueConfigData.mtiId}
                                        onChange={handledFieldInputQueueConfigData}
                                        className={classNames({ 'p-invalid': isFormFieldValid('mtiId') }, 'Dropdown__Round')}
                                    />

                                    {/* {getFormErrorMessage('mtiId')} */}
                                    <small className="p-error">{queueConfigError.mtiId}</small>
                                </div>
                            </div>

                            <div className="p-field col-12 md:col-4 mr-5 mt-4">
                                <div className="Down__Btn__Configuration">
                                    <Button type="submit" loadingIcon={loadingIcon} disabled label="Add" onClick={handledQueueConfigAdd} className="Btn__Add" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid">
                        <div className="col-12">
                            <div className="card">
                                <DataTable className="datatable-responsive" emptyMessage="No List found." responsiveLayout="scroll" value={queueConfigTableData}>
                                    <Column field="expiryTime" header="Queue Expiry Time" />
                                    <Column field="size" header="Queue Size" />
                                    <Column header="MTI" body={mtiBodyTemplate} />
                                    <Column header="Actions" body={actionBodyTemplate} className="Table__ActionHeading__CentredAlign" />
                                </DataTable>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="p-fluid p-formgrid grid ">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="action" className="Label__Text">
                                        Action <span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="action"
                                        placeholder="Select Action"
                                        options={statusLovData}
                                        optionLabel="name"
                                        name="action"
                                        optionValue="lovId"
                                        value={formik.values.action || ''}
                                        onChange={formik.handleChange}
                                        className="Dropdown__Round"
                                    />
                                    {getFormErrorMessage('action')}
                                </div>
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid grid">
                            <div className="p-field col-12 md:col-4">
                                <div className="p-field">
                                    <label htmlFor="checkerComments" className="Label__Text">
                                        Comments
                                        <span className="Label__Required">*</span>
                                    </label>
                                    <InputTextarea
                                        autoResize="false"
                                        rows={5}
                                        cols={30}
                                        maxLength={500}
                                        id="checkerComments"
                                        name="checkerComments"
                                        value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ')}
                                        onChange={formik.handleChange}
                                        className="TextArea__Round"
                                    />
                                    {getFormErrorMessage('checkerComments')}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Usergroup__SearchBtn p-field col-12  flex justify-content-center">
                        <Button label="Submit" type="submit" className="Btn__Dark__Search" />
                        <Button type="button" label="Cancel" onClick={() => navigate('/requestchecker')} className="Btn__Transparent__Reset" />
                    </div>
                </form>
            </div>
            {/* Action Authorization History */}

            <AuthorizationHistoryTable refTableId={refTableId} tableName={tableName} />
        </>
    );
}
export default SwitchConfigurationCheckerEdit;
