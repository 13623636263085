import React, { useEffect, useState } from 'react';
import { TabPanel, TabView } from 'primereact/tabview';
import AccountDetailsView from './AccountDetailsView';
import ZindagiCardDetails from '../../zindagiCards/Maker/ZindagiCardDetails';
import Campaigns from '../Campaigns/Campaigns';
import ManageTransactions from '../Transactions/ManageTransactions';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Lien from '../Lien/Lien';

const AccountDetails = () => {

    const [userTabsFieldData, setUserTabsFieldData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const dispatch = useDispatch();

    const location = useLocation()

    const cxData = location?.state?.rowData

    const accountDetails = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Account Details</p>
                </div>
            </>
        );
    };
    const zindigiCards = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Zindigi Cards</p>
                </div>
            </>
        );
    };
    const acTransDetailBankTransfer = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Account Transaction Detail & Bank Transfer</p>
                </div>
            </>
        );
    };
    const campaigns = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Campaigns</p>
                </div>
            </>
        );
    };
    const transactions = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Transactions</p>
                </div>
            </>
        );
    };
    const lien = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>Lien</p>
                </div>
            </>
        );
    };


    useEffect(() => {
        const loggedInUserDetail = JSON.parse(sessionStorage.getItem('userDetails'));
        const userId = loggedInUserDetail?.userId;

        if (userId) {
            const getUserRights = async () => {
                const jsonData = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            userId: userId
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                };
                try {
                    const res = await dispatch(handlePostRequest(jsonData, `/account/v1/customeraccounts/getCxUserTabFieldsByUserId`, true));
                    if (res?.responseCode == '010000') setUserTabsFieldData(res?.payLoad);
                } catch (error) { }
            };
            getUserRights();
        }
    }, []);

    const isAccDetailsViewable = userTabsFieldData.find((f) => f.fieldName === 'ACCOUNT DETAILS')?.viewAllowed === 'Y' ? true : false;
    const isZinCardsViewable = userTabsFieldData.find((f) => f.fieldName === 'ZINDIGI CARDS')?.viewAllowed === 'Y' ? true : false;
    const isAccTransDetailsViewable = userTabsFieldData.find((f) => f.fieldName === 'ACCOUNT TRANSACTION DETAIL')?.viewAllowed === 'Y' ? true : false;
    const isCampaignsViewable = userTabsFieldData.find((f) => f.fieldName === 'CAMPAIGNS')?.viewAllowed === 'Y' ? true : false;
    const isTransactionsViewable = userTabsFieldData.find((f) => f.fieldName === 'TRANSACTIONS')?.viewAllowed === 'Y' ? true : false;

    useEffect(() => {
        const handleHashChange = (event) => {
            const oldUrl = event.oldURL.split('/').pop();
            if (oldUrl === "viewtransactions") {
                setActiveIndex(4);
            }
        };

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <>
            <div className="card Card__Round">
                <TabView activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                    {isAccDetailsViewable && (
                        <TabPanel header="Header I" headerTemplate={accountDetails}>
                            <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                                <AccountDetailsView userTabsFieldData={userTabsFieldData} />
                            </div>
                        </TabPanel>
                    )}
                    {isZinCardsViewable && (
                        <TabPanel headerTemplate={zindigiCards} headerClassName="flex align-items-center">
                            <ZindagiCardDetails />
                        </TabPanel>
                    )}

                    {isAccTransDetailsViewable && (
                        <TabPanel headerTemplate={acTransDetailBankTransfer} headerClassName="flex align-items-center">
                            <div className="card">
                                <p className="m-0">
                                    consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id
                                    est laborum.
                                </p>
                            </div>
                        </TabPanel>
                    )}

                    {isCampaignsViewable && (
                        <TabPanel headerTemplate={campaigns} headerClassName="flex align-items-center">
                            <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                                <Campaigns />
                            </div>
                        </TabPanel>
                    )}

                    {/* {isTransactionsViewable && ( */}
                    <TabPanel headerTemplate={transactions} headerClassName="flex align-items-center">
                        <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                            <ManageTransactions customerData={cxData} />
                        </div>
                    </TabPanel>
                    {/* )} */}
                    <TabPanel headerTemplate={lien} headerClassName="flex align-items-center">
                        <div className="card pt-3" style={{ background: '#f8f8f8' }}>
                            <Lien customerData={cxData} />
                        </div>
                    </TabPanel>
                </TabView>
            </div>
        </>
    );
};

export default AccountDetails;
