import React, { useState, useEffect } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import CardLimits from '../Components/VirtualCard/CardLimits';
import ChannelDetails from '../Components/VirtualCard/ChannelDetails';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog } from 'primereact/dialog';
import EditCardStatus from '../Dialogs/EditCardStatus';
import { Divider } from 'primereact/divider';
import EditCardLimits from '../Dialogs/EditCardLimits';
import EditChannelDetails from '../Dialogs/EditChannelDetails';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { useDispatch } from 'react-redux';
import { Button } from 'primereact/button';
import { handleGetRequest } from '../../../../services/GetTemplate';
import { InputTextarea } from 'primereact/inputtextarea';
import { TabPanel, TabView } from 'primereact/tabview';

const maskCardNumber = (cardNumber) => {
    if (!cardNumber) return '';
    const length = cardNumber.length;
    if (length <= 6) return cardNumber; // If card number is too short, don't mask it
    const first4 = cardNumber.slice(0, 4);
    const last2 = cardNumber.slice(length - 2, length);
    const maskedSection = '*'.repeat(length - 6);
    return `${first4}${maskedSection}${last2}`;
};

const maskCnicNumber = (CnicNumber) => {
    if (!CnicNumber) return '';
    const length = CnicNumber.length;
    if (length <= 6) return CnicNumber;
    const first4 = CnicNumber.slice(0, 5);
    const last = CnicNumber.slice(length - 1, length);
    const maskedSection = 'x'.repeat(length - 6);
    return `${first4}-${maskedSection}-${last}`;
};

function VirtualCardEditDetails({ type, virtualCardData, statusLovData, mcReqData, mcRequestId, mcPeindingRequestId }) {

    const [displayCardStatus, setDisplayCardStatus] = useState(false);
    const [displayCardLimits, setDisplayCardLimits] = useState(false);
    const [displayChannelDetails, setDisplayChannelDetails] = useState(false);
    const [cardStatus, setCardStatus] = useState('');
    const [cardLimitValues, setCardLimitValues] = useState(null);
    const [channelDetailsValues, setChannelDetailsValues] = useState(null);
    const [isCardLimitsUpdateReq, setIsCardLimitsUpdateReq] = useState(false);
    const [isChannelDetailsUpdateReq, setIsChannelDetailsUpdateReq] = useState(false);
    const [isCardStatusUpdateReq, setIsCardStatusUpdateReq] = useState(false);
    const [cardStatusLov, setCardStatusLov] = useState([]);
    const [maskedCardNumber, setMaskedCardNumber] = useState('');
    const [maskedCnicNumber, setMaskedCnicNumber] = useState('');
    const [customErrors, setCustomErrors] = useState([]);

    const [statusAction, setStatusAction] = useState('');
    const [comments, setComments] = useState('');

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const { participantDTO, indLimits, channelResponse, virtualCardInfo } = type === 'checkerEdit' || type === 'checkerView' ? virtualCardData : location?.state?.virtualCardData;
    const { mobileNo, cnic, mailingAddress, channelName, segmentName, email, acccountId } = type === 'checkerEdit' || type === 'checkerView' ? virtualCardData : location?.state;

    let rowData = location?.state?.rowData;
    if (type !== 'checkerEdit' || type !== 'checkerView') {
        rowData = location?.state?.rowData;
    }

    const handleCardStatusDialog = () => {
        setDisplayCardStatus(true);
    };
    const handleCardLimits = () => {
        setDisplayCardLimits(true);
    };
    const handleChannelDetails = () => {
        setDisplayChannelDetails(true);
    };
    const onHide = () => {
        setDisplayCardStatus(false);
        setDisplayCardLimits(false);
        setDisplayChannelDetails(false);
    };
    const handleCardStatusCallBack = (value) => {
        setCardStatus(value?.status);
        setIsCardStatusUpdateReq(value?.isCardStatusUpdateReq);
    };
    const handleCardLimitsCallBack = (values) => {
        const { isCardLimitsUpdateReq, ...rest } = values;
        setCardLimitValues(rest);
        setIsCardLimitsUpdateReq(isCardLimitsUpdateReq);
    };
    const handleChannelDetailsCallBack = (values) => {
        const { isChannelDetailsUpdateReq, ...rest } = values;
        setChannelDetailsValues(rest);
        setIsChannelDetailsUpdateReq(isChannelDetailsUpdateReq);
    };

    const handleSubmit = async () => {
        if (type === 'checkerEdit') {
            if (!statusAction && !comments) {
                setCustomErrors([
                    { field: 'statusAction', message: 'This field is required' },
                    { field: 'comments', message: 'This field is required' }
                ]);
                return;
            }
            if (!statusAction) {
                setCustomErrors([{ field: 'statusAction', message: 'This field is required' }]);
                return;
            }
            if (!comments) {
                setCustomErrors([{ field: 'comments', message: 'This field is required' }]);
                return;
            }
            const data = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        updateCxVirtualCard: {
                            isStatusUpdate: mcReqData?.isStatusUpdate,
                            status: mcReqData?.status,
                            isLimitUpdate: mcReqData?.isLimitUpdate,
                            indLimits: {
                                // limitCycle: mcReqData?.indLimits?.limitCycle,
                                // productCode: mcReqData?.indLimits?.productCode,
                                // limitClass: mcReqData?.indLimits?.limitClass,
                                // onTrxCount: mcReqData?.onTrxCount,
                                // onTrxAmount: mcReqData?.onTrxAmount,
                                // maxOnCount: mcReqData?.maxOnCount,
                                // onLowerLimit: mcReqData?.onLowerLimit,
                                // onUpperLimit: mcReqData?.onUpperLimit,
                                // maxOffCount: mcReqData?.maxOffCount,
                                // offLowerLimit: mcReqData?.offLowerLimit,
                                // offUpperLimit: mcReqData?.offUpperLimit,
                                // offTrxCount: mcReqData?.offTrxCount,
                                // offTrxAmount: mcReqData?.offTrxAmount
                                ...mcReqData?.indLimits
                            },
                            isChannelUpdate: mcReqData?.isChannelUpdate,
                            outAtmDom: mcReqData?.outAtmDom,
                            outAtmInt: mcReqData?.outAtmInt,
                            outPosDom: mcReqData?.outPosDom,
                            outPosInt: mcReqData?.outPosInt,
                            outEcomm: mcReqData?.outEcomm,
                            outEcommInt: mcReqData?.outEcommInt,
                            cardNumber: mcReqData?.cardNumber
                        },
                        mcActionRequest: {
                            mcRequestId: mcRequestId,
                            mcPeindingRequestId: mcPeindingRequestId,
                            checkerId: '1',
                            checkerComments: comments,
                            action: statusAction,
                            updatedIndex: null
                        }
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            // return
            await dispatch(handlePostRequest(data, '/debitcardmanagement/v1/vcards/vcardsrequestcheckeraction', true, false, 'requestchecker'));
        } else {
            if (channelResponse) {
                const { outResponseCode, messageNumber, messageText, outPartID, outCardNumber, outMemberNumber, outExternalCIF, ...restChannelDetails } = channelResponse?.channelBlockingResponse;
                const data = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            isStatusUpdate: isCardStatusUpdateReq,
                            status: cardStatus ? cardStatus : participantDTO?.activeInactive,
                            isLimitUpdate: isCardLimitsUpdateReq,
                            indLimits: {
                                limitCycle: indLimits?.[0]?.limitCycle,
                                productCode: indLimits?.[0]?.productCode,
                                limitClass: indLimits?.[0]?.limitClass,
                                ...(cardLimitValues ? cardLimitValues : indLimits?.[0])
                            },
                            isChannelUpdate: isChannelDetailsUpdateReq,
                            ...(channelDetailsValues ? channelDetailsValues : restChannelDetails),
                            cardNumber: participantDTO?.outPRICard,
                            additionalParams: {
                                fullName: participantDTO?.embossName1,
                                mobileNo: mobileNo,
                                cnic: cnic,
                                permanentAddress: rowData?.fullAddress,
                                mailingAddress: virtualCardInfo?.address,
                                channelName: channelName,
                                segmentName: segmentName,
                                acccountId: acccountId,
                                email: email
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                // return
                await dispatch(handlePostRequest(data, '/debitcardmanagement/v1/vcards/updatecustomervirtualcarddetails', true, false, 'accountsmanagement'));
            } else {
                // const { outResponseCode, messageNumber, messageText, outPartID, outCardNumber, outMemberNumber, outExternalCIF } = channelResponse?.channelBlockingResponse;
                const data = {
                    data: {
                        security: {
                            userName: '',
                            password: '',
                            securityToken: ''
                        },
                        account: {
                            msidn: '',
                            iban: '',
                            bban: '',
                            pan: '',
                            currency: ''
                        },
                        channel: '',
                        terminal: '',
                        reterivalReferenceNumber: '',
                        payLoad: {
                            isStatusUpdate: isCardStatusUpdateReq,
                            status: cardStatus ? cardStatus : participantDTO?.activeInactive,
                            isLimitUpdate: isCardLimitsUpdateReq,
                            indLimits: {
                                limitCycle: indLimits?.[0]?.limitCycle,
                                productCode: indLimits?.[0]?.productCode,
                                limitClass: indLimits?.[0]?.limitClass,
                                ...(cardLimitValues ? cardLimitValues : indLimits?.[0])
                            },
                            cardNumber: participantDTO?.outPRICard,
                            additionalParams: {
                                fullName: participantDTO?.embossName1,
                                mobileNo: mobileNo,
                                cnic: cnic,
                                permanentAddress: rowData?.fullAddress,
                                mailingAddress: virtualCardInfo?.address,
                                channelName: channelName,
                                segmentName: segmentName,
                                acccountId: acccountId,
                                email: email
                            }
                        },
                        additionalInformation: [
                            {
                                infoKey: '',
                                infoValue: ''
                            }
                        ],
                        checkSum: ''
                    }
                };
                // return
                await dispatch(handlePostRequest(data, '/debitcardmanagement/v1/vcards/updatecustomervirtualcarddetails', true, false, 'accountsmanagement'));
            }
        }
    };

    const getCardStatusLov = async () => {
        try {
            const resp = await handleGetRequest(`/configuration/v1/lookups/getcardstatus/3`);
            if (resp?.responseCode === '050000') {
                setCardStatusLov(resp?.payLoad);
            }
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(() => {
        if (isCardLimitsUpdateReq || isChannelDetailsUpdateReq || isCardStatusUpdateReq) {
            handleSubmit();
        }
    }, [isCardLimitsUpdateReq, isChannelDetailsUpdateReq, isCardStatusUpdateReq]);

    useEffect(() => {
        getCardStatusLov();
    }, []);

    useEffect(() => {
        if (participantDTO) setMaskedCardNumber(maskCardNumber(participantDTO?.outPRICard));
        if (mcReqData) setMaskedCnicNumber(maskCnicNumber(mcReqData?.additionalParams?.cnic));
        if (cnic) setMaskedCnicNumber(maskCnicNumber(cnic));
    }, [participantDTO, cnic, mcReqData]);

    const accountDetails = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>
                        <b>Account Details</b>
                    </p>
                </div>
            </>
        );
    };

    const zindigiCards = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>
                        <b>Zindigi Cards</b>
                    </p>
                </div>
            </>
        );
    };

    const acTransDetailBankTransfer = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>
                        <b>Account Transaction Detail & Bank Transfer</b>
                    </p>
                </div>
            </>
        );
    };

    const campaigns = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>
                        <b>Campaigns</b>
                    </p>
                </div>
            </>
        );
    };

    const transactions = (options) => {
        return (
            <>
                <div className="custom-tabs" onClick={options.onClick}>
                    <p>
                        <b>Transactions</b>
                    </p>
                </div>
            </>
        );
    };

    return (
        <>
            <Dialog header="CARD STATUS EDIT" visible={displayCardStatus} style={{ width: '30vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditCardStatus onHide={onHide} participantDTO={participantDTO} handleCardStatusCallBack={handleCardStatusCallBack} cardStatusLov={cardStatusLov} />
            </Dialog>

            <Dialog header="CARD LIMITS EDIT" visible={displayCardLimits} style={{ width: '70vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditCardLimits onHide={onHide} indLimits={indLimits?.[0]} handleCardLimitsCallBack={handleCardLimitsCallBack} />
            </Dialog>

            <Dialog header="CHANNEL DETAILS EDIT" visible={displayChannelDetails} style={{ width: '60vw' }} onHide={onHide} resizable={false} draggable={false}>
                <EditChannelDetails onHide={onHide} channelBlockingResponse={channelResponse?.channelBlockingResponse} handleChannelDetailsCallBack={handleChannelDetailsCallBack} />
            </Dialog>
            <div className="card Card__Round">
                {/* {(type === 'checkerEdit' || type === 'checkerView') && ( */}
                <TabView activeIndex={1} className="mt-5">
                    <TabPanel disabled headerTemplate={accountDetails} headerClassName="flex align-items-center"></TabPanel>
                    <TabPanel headerTemplate={zindigiCards} headerClassName="flex align-items-center"></TabPanel>
                    <TabPanel disabled headerTemplate={acTransDetailBankTransfer} headerClassName="flex align-items-center"></TabPanel>
                    <TabPanel disabled headerTemplate={campaigns} headerClassName="flex align-items-center"></TabPanel>
                    <TabPanel disabled headerTemplate={transactions} headerClassName="flex align-items-center"></TabPanel>
                </TabView>
                {/* )} */}
                {/* <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2"> */}
                <div className="p-fluid p-formgrid grid mb-5">
                    <div className="Form__Header Full__Width">
                        <h2>Virtual Card</h2>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Name</label>
                            <InputText value={participantDTO ? `${participantDTO?.clientDirectoryFirstName} ${participantDTO?.clientDirectoryLastName}` : ''} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Mobile Number</label>
                            <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.mobileNo : mobileNo} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>CNIC </label>
                            {/* <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.cnic : cnic} className="Input__Round" readOnly /> */}
                            <InputText value={maskedCnicNumber ?? ''} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Email Address</label>
                            <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.email : rowData?.email} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-8 pt-3 pb-3">
                        <div className="p-field">
                            <label>Permanent Address</label>
                            <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.permanentAddress : rowData?.fullAddress} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-12 pt-3 pb-3">
                        <div className="p-field">
                            <label>Mailing Address</label>
                            <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.mailingAddress : virtualCardInfo?.address} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Channel</label>
                            <Dropdown
                                value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.channelName : channelName}
                                className="Dropdown__Round"
                                options={[{ label: channelName || mcReqData?.additionalParams?.channelName, value: channelName || mcReqData?.additionalParams?.channelName }]}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Product</label>
                            <Dropdown value={virtualCardInfo?.productName} options={[{ label: virtualCardInfo?.productName, value: virtualCardInfo?.productName }]} className="Dropdown__Round" disabled />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Segment</label>
                            <Dropdown
                                value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.segmentName : segmentName}
                                options={[{ label: segmentName || mcReqData?.additionalParams?.segmentName, value: segmentName || mcReqData?.additionalParams?.segmentName }]}
                                className="Dropdown__Round"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Embossing Name</label>
                            <InputText value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.additionalParams?.fullName : participantDTO?.embossName1} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Card Number</label>
                            <InputText value={maskedCardNumber ?? ''} className="Input__Round" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Card Registration Date</label>
                            <InputText value={virtualCardInfo?.createDate} className="Input__Round" type="date" readOnly />
                        </div>
                    </div>
                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                        <div className="p-field">
                            <label>Card Status</label>
                            <div className="flex align-items-center">
                                <Dropdown
                                    id="activeInactive"
                                    name="activeInactive"
                                    placeholder="--Select--"
                                    options={cardStatusLov}
                                    optionLabel="name"
                                    optionValue="code"
                                    value={type === 'checkerEdit' || type === 'checkerView' ? mcReqData?.status : cardStatus ? cardStatus : participantDTO?.statusReason}
                                    // value={
                                    //     type === 'checkerEdit' || type === 'checkerView'
                                    //         ? mcReqData?.status === 'A'
                                    //             ? '*ACTIVE'
                                    //             : 'Inactive'
                                    //         : mcReqData?.isStatusUpdate
                                    //         ? mcReqData?.status
                                    //         : cardStatus
                                    //         ? cardStatus
                                    //         : participantDTO?.activeInactive === 'A'
                                    //         ? '*ACTIVE'
                                    //         : 'Inactive'
                                    // }
                                    className="Dropdown__Round mr-2"
                                    appendTo="self"
                                    disabled
                                />
                                {type !== 'checkerEdit' && type !== 'checkerView' && (
                                    <i
                                        className={`field-edit-icon pi pi-pencil ${location?.state?.type === 'ViewPage' || (participantDTO?.statusReason !== '*ACTIVE' && participantDTO?.statusReason !== '*TMPBLOCK') ? 'p-disabled' : ''}`}
                                        onClick={() => {
                                            if (location?.state?.type === 'ViewPage' || (participantDTO?.statusReason !== '*ACTIVE' && participantDTO?.statusReason !== '*TMPBLOCK')) return;
                                            else handleCardStatusDialog();
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="custom-card">
                    <div className="flex justify-content-between">
                        <h5 className="secondary-color">
                            <b>Card Limits</b>
                        </h5>
                        {type !== 'checkerEdit' && type !== 'checkerView' && (
                            <i
                                className={`field-edit-icon pi pi-pencil ${location?.state?.type === 'ViewPage' || participantDTO?.statusReason !== '*ACTIVE' ? 'p-disabled' : ''}`}
                                onClick={() => {
                                    if (location?.state?.type === 'ViewPage' || participantDTO?.statusReason !== '*ACTIVE') return;
                                    else handleCardLimits();
                                }}
                            />
                        )}
                    </div>
                    <Divider />
                    <CardLimits indLimits={indLimits?.[0]} mcReqData={mcReqData} onHide={onHide} />
                </div>

                <div className="custom-card mt-1">
                    <div className="flex justify-content-between">
                        <h5 className="secondary-color">
                            <b>Channel Details</b>
                        </h5>
                        {type !== 'checkerEdit' && type !== 'checkerView' && (
                            <i
                                className={`field-edit-icon pi pi-pencil ${location?.state?.type === 'ViewPage' || participantDTO?.statusReason !== '*ACTIVE' ? 'p-disabled' : ''}`}
                                onClick={() => {
                                    if (location?.state?.type === 'ViewPage' || participantDTO?.statusReason !== '*ACTIVE') return;
                                    else handleChannelDetails();
                                }}
                            />
                        )}
                    </div>
                    <Divider />
                    <ChannelDetails channelBlockingResponse={channelResponse?.channelBlockingResponse} mcReqChannelDetailData={mcReqData} />
                </div>

                {/* </form> */}

                {type && type === 'checkerEdit' ? (
                    <>
                        <div className="custom-card mt-6">
                            <div className="p-fluid p-formgrid grid mb-2">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label className="Label__Text">Status</label>
                                        <span className="steric">*</span>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={statusLovData}
                                            optionLabel="name"
                                            optionValue="lovId"
                                            value={statusAction}
                                            onChange={(e) => {
                                                setStatusAction(e.target.value);
                                                setCustomErrors([{ field: 'statusAction', message: '' }]);
                                            }}
                                            className="Dropdown__Round"
                                        />
                                        {customErrors?.map(
                                            (error, key) =>
                                                error.field === 'statusAction' && (
                                                    <small key={key} className="p-error mt-1">
                                                        {error.message}
                                                    </small>
                                                )
                                        )}
                                    </div>
                                </div>

                                <div className="p-field col-12 md:col-8">
                                    <div className="p-field">
                                        <label className="Label__Text">Comments</label>
                                        <span className="steric">*</span>
                                        <InputTextarea
                                            rows={3}
                                            id="checkerComments"
                                            placeholder="-"
                                            value={comments}
                                            onChange={(e) => {
                                                setComments(e.target.value);
                                                setCustomErrors([{ field: 'comments', message: '' }]);
                                            }}
                                            className="Input__Round resizeNone"
                                            min={10}
                                        />
                                        {customErrors?.map(
                                            (error, key) =>
                                                error.field === 'comments' && (
                                                    <small key={key} className="p-error mt-1">
                                                        {error.message}
                                                    </small>
                                                )
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="Down__Btn mt-6 pt-6">
                            <Button label="Update" iconPos="right" className="Btn__Dark" onClick={handleSubmit} />
                            <Button label="Cancel" iconPos="right" className="Btn__cancel" onClick={() => navigate('/requestchecker')} />
                        </div>
                    </>
                ) : type === 'checkerView' || location?.state?.type === 'ViewPage' ? (
                    <div className="Down__Btn mt-6 pt-6">
                        <Button
                            label="Okay"
                            iconPos="right"
                            className="Btn__Dark"
                            onClick={() => {
                                if (type === 'checkerView') {
                                    navigate('/requestchecker');
                                } else {
                                    navigate('/viewaccountdetails');
                                }
                            }}
                        />
                    </div>
                ) : (
                    <div className="Down__Btn mt-6 pt-6">
                        {/* <Button label="Update" iconPos="right" className="Btn__Dark" onClick={handleSubmit} /> */}
                        <Button label="Cancel" iconPos="right" className="Btn__cancel" onClick={() => navigate('/viewaccountdetails')} />
                    </div>
                )}
            </div>
        </>
    );
}

export default VirtualCardEditDetails;
