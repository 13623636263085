import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useNavigate } from 'react-router-dom';
import BulkDiscardDialog from '../../../components/BulkDialog/BulkDiscardDialog';
import { PaginationCall, PaginationTemplate } from '../../../components/Pagination';
import { Paginator } from 'primereact/paginator';

function DisbursementFileSummary() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [statusLovData, setStatusLovData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [customFirst1, setCustomFirst1] = useState(0);
    const [customRows1, setCustomRows1] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);
    const [render, setRender] = useState(false);
    const [apiHit, setApiHit] = useState([]);
    const [rowDataProp, setRowDataProp] = useState(null);
    const [disbrusementData, setDisbrusementData] = useState([]);
    const [userLov, setUserLov] = useState([]);
    const [products, setProduct] = useState([]);
    const [service, setService] = useState([
        {
            label: 'Bulk Disbursement to A/c Holder',
            lovId: 'BDA'
        },
        {
            label: 'Bulk Disbursement to N/c Holder',
            lovId: 'BDN'
        }
    ]);

  

  
    const validationSchema = Yup.object().shape({
        dateFrom: Yup.date().nullable(),
        dateTo: Yup.date().when('dateFrom', {
            is: (dateFrom) => !!dateFrom,
            then: Yup.date().required('Date To is required').min(Yup.ref('dateFrom'), 'Date To has to be more than Date From')
        })
    });

    const dataTableRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const formik = useFormik({
        validationSchema,
        initialValues: {
            bulkOperationName: 'BD',
            services: '',
            product: '',
            dateTo: '',
            batchNumber: '',
            dateFrom: '',
            updatedBy: '',
            createdBy: '',
            statusId: '2',
            page: '1',
            size: customRows1
        },
        validate: (data) => {
            let errors = {};
            if (data.dateTo && !data.dateFrom) {
                errors.dateFrom = 'Date From is required when Date To is provided';
            }

            if (data.dateFrom && data.dateTo && data.dateFrom > data.dateTo) {
                errors.dateTo = 'Date To must be greater than or equal to Date From';
            }
            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const payloadDataNew = {
                bulkOperationName: data.bulkOperationName,
                page:data.page,
                size:data.size,
                bulkDisbursmentDTO: {
                    service: data.services,
                    productID: data.product,

                    batchID: data.batchNumber,
                    createdBy: data.createuser,
                    statusID: data.statusId,
                    dateTo: data.dateTo,
                    dateFrom: data.dateFrom,
                    updatedBy: data.lastupdateuser
                }
            };
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: payloadDataNew,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ]
                }
            };

            dataTableRef.current.reset();

            setApiHit(newData?.data?.payLoad);

            const res = await dispatch(handlePostRequest(newData, '/batchprocessing/v1/batchprocessing/getallrecords', false, true));

            if (res?.responseCode === '170000') {
                setDisbrusementData(res?.payLoad);
            } else {
                setDisbrusementData('');
            }
            setloading(false);
            setloadingIcon('pi pi-search');
        }
    });

    const viewNewUseCaseManagement = (rowData) => {
        setRowDataProp(rowData);
        setShowModal(true);
    };



    const renderHeader = () => {
        return (
            <div className="Global__Header">
                <h5 className="m-0">Disbursement File Summary Details</h5>
            </div>
        );
    };

    const header = renderHeader();

    const actionBodyTemplate = (rowData) => {
        return (
            <>
                <div className="actions">
                    <Button label="View Detail" tooltipOptions={{ position: 'top' }} tooltip="Withdraw" disabled={rowData.fileStatusName === 'Discarded'} onClick={() => handleBatchNumberClick(rowData)} className="Btn__Withdraw" iconPos="left" />
                    <Button
                        tooltip="Delete"
                        tooltipOptions={{ position: 'top' }}
                        icon="pi pi-trash"
                        onClick={() => viewNewUseCaseManagement(rowData)}
                        className="p-button-rounded p-button-danger"
                        disabled={
                            rowData?.fileStatusName === 'Executed' || rowData?.fileStatusName === 'Batch in Progress' || rowData?.fileStatusName === 'Approved' || rowData?.fileStatusName === 'Completed' || rowData?.fileStatusName === 'Discarded'
                        }
                    />
                </div>
            </>
        );
    };

  

    const handleBatchNumberClick = (rowData) => {
        const batchProcessingStatus = getBatchProcessingStatus(rowData?.batchId);

        navigate(`/bulkDisbursementView`, {
            state: {
                batchNo: rowData?.batchId,
                batchProcessingStatus: batchProcessingStatus,
                bulkOprFileId: rowData?.bulkOprFileId
                // Add other parameters as needed
            }
        });
    };

    const createdDateAndTimeBodyTemplate = (rowData) => {
        const isoDate = rowData?.createdate;
        const date = new Date(isoDate);

        const mm = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const yyyy = date.getFullYear();
        let hours = date.getHours();
        const minutes = String(date.getMinutes()).padStart(2, '0');

        let ampm = 'AM';
        if (hours >= 12) {
            ampm = 'PM';
            hours -= 12;
        }
        if (hours === 0) {
            hours = 12;
        }

        const formattedDateTime = `${dd}/${mm}/${yyyy} ${hours}:${minutes} ${ampm}`;
        return <p>{formattedDateTime}</p>;
    };
    const getBatchProcessingStatus = (batchNo) => {
        const batch = data.find((item) => item.batchNo === batchNo);
        return batch ? batch.batchProcessingStatus : 'Unknown';
    };

    const resetHandle = (e) => {
        e.preventDefault();
        formik.resetForm();
    };
    const data = [
        {
            batchNo: '1',
            batchProcessingStatus: 'Ready for Submit'
        },
        {
            batchNo: '2',
            batchProcessingStatus: 'Submitted'
        }
    ];

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];
    const handleShowModal = () => {
        setShowModal(false);
    };
    const esbProps = {
        showModal,
        handleShowModal
    };

    const product = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/gettransactiontypes/TBL_PRODUCT:*');
        setProduct(res?.payLoad);
    };

    const getStatusLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getStatus/LKP_STATUS:*');
        let data = resp?.payLoad;
        data = data.filter((item) => item.lovId !== '4');

        setStatusLovData(data);
    };
    const getUserLov = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getusers/TBL_USER:*');
        setUserLov(resp?.payLoad);
    };

    useEffect(() => {
        product();
        getStatusLov();
        getUserLov();
    }, []);// eslint-disable-line
    const statusBodyTemplate = (rowData) => {
        const statusColor = rowData?.statusId?.statusName === 'Approved' ? '#14A38B' : rowData?.statusId?.statusName === 'Pending' ? '#F2AC57' : '#FF7171';
        return (
            <p style={{ color: statusColor }}>
                <b>{rowData?.statusId?.statusName}</b>
            </p>
        );
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const onCustomPageChange1 = (event) => {
        setCustomFirst1(event.first);
        setCustomRows1(event.rows);
        setCurrentPage(event.page + 1);
        setRender(true);
    };

    const RequestData = {
        ...apiHit,
        page: currentPage,
        size: customRows1
    };
    return (
        <>
            <BulkDiscardDialog {...esbProps} rowDataProp={rowDataProp} />

            <div className="card Card__Round">
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="service" className={classNames({ 'p-error': isFormFieldValid('service') }, 'Label__Text')}>
                                    Service
                                </label>
                                <Dropdown
                                    id="service"
                                    placeholder="Select service"
                                    options={service}
                                    optionLabel="label"
                                    name="service"
                                    optionValue="lovId"
                                    value={formik.values.service || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('service') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('service')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="product" className={classNames({ 'p-error': isFormFieldValid('product') }, 'Label__Text')}>
                                    Product
                                </label>
                                <Dropdown
                                    id="product"
                                    placeholder="Select product"
                                    options={products}
                                    optionLabel="name"
                                    name="product"
                                    optionValue="lovId"
                                    value={formik.values.product || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('product') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('product')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="batchNumber" className={classNames({ 'p-error': isFormFieldValid('batchNumber') }, 'Label__Text')}>
                                    Batch Number
                                </label>
                                <InputText
                                    id="batchNumber"
                                    placeholder="Enter Source Batch "
                                    name="batchNumber"
                                    value={formik?.values?.batchNumber || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('batchNumber') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('batchNumber')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateFrom" className={classNames({ 'p-error': isFormFieldValid('dateFrom') }, 'Label__Text')}>
                                    File Uploaded From Date
                                </label>
                                <InputText
                                    id="dateFrom"
                                    type="date"
                                    max={previousDate}
                                    name="dateFrom"
                                    value={formik?.values?.dateFrom}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('dateFrom') }, 'Input__Round')}
                                />

                                {getFormErrorMessage('dateFrom')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="dateTo" className={classNames({ 'p-error': isFormFieldValid('dateTo') }, 'Label__Text')}>
                                    File Uploaded To Date
                                </label>
                                <InputText id="dateTo" type="date" max={previousDate} name="dateTo" value={formik?.values?.dateTo} onChange={formik.handleChange} className={classNames({ 'p-invalid': isFormFieldValid('dateTo') }, 'Input__Round')} />

                                {getFormErrorMessage('dateTo')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="createuser" className={classNames({ 'p-error': isFormFieldValid('createuser') }, 'Label__Text')}>
                                    Created By
                                </label>
                                <Dropdown
                                    id="createuser"
                                    placeholder="Select Created By"
                                    options={userLov}
                                    optionLabel="name"
                                    name="createuser"
                                    optionValue="lovId"
                                    value={formik.values.createuser || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('createuser') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('createuser')}
                            </div>
                        </div>

                        <div className="p-field col-12 md:col-4">
                            <div className="p-field">
                                <label htmlFor="status" className={classNames({ 'p-error': isFormFieldValid('status') }, 'Label__Text')}>
                                    Status
                                </label>
                                <Dropdown
                                    id="statusId"
                                    placeholder="Select status"
                                    options={statusLovData}
                                    optionLabel="name"
                                    name="statusId"
                                    optionValue="lovId"
                                    value={formik.values.statusId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('statusId') }, 'Dropdown__Round')}
                                />
                                {getFormErrorMessage('status')}
                            </div>
                        </div>
                        <div className="Down__Btn">
                        <Button disabled={loading} loadingIcon={loadingIcon} label="Search" className="Btn__Dark" />
                        <Button disabled={loading} label="Reset" onClick={(e) => resetHandle(e)} className="Btn__Transparent" />
                    </div>
                    </div>
                </form>
            </div>
            <PaginationCall
                render={render}
                customFirst1={customFirst1}
                customRows1={customRows1}
                currentPage={currentPage}
                dataTableRef={dataTableRef}
                setData={setDisbrusementData}
               
                RequestData={RequestData}
                url={'/batchprocessing/v1/batchprocessing/getallrecords'}
                responseCode={'170000'}
            />
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            ref={dataTableRef}
                            rowsPerPageOptions={[5, 10, 25]}
                            className="datatable-responsive"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={disbrusementData}
                        >
                            <Column
                                field="batchId"
                                header="Batch Number"
                                body={(rowData) => (
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault(); // Prevent default link behavior
                                            handleBatchNumberClick(rowData);
                                        }}
                                    >
                                        {rowData.batchId}
                                    </a>
                                )}
                            />
                            <Column field="service" header="Service" />
                            <Column field="productName" header="Product" />
                            <Column field="sourceAccount" header="Source A/C #" />
                            <Column header="Uploaded Date" body={createdDateAndTimeBodyTemplate} />
                            <Column field="totalRecords" header="Total Records" />
                            <Column field="validRecords" header="Valid Records" />
                            <Column field="invalidRecords" header="Invalid Records" />
                            <Column field="totalAmount" header="Total Amount" />
                            <Column field="totalCharge" header="Total Charge" />
                            <Column field="createdBy" header="Uploaded By" />
                            <Column field="totalFed" header="Total FED" />
                            <Column field="fileStatusName" header="Batch Processing Status" body={(rowData) => <span style={{ color: rowData.fileStatusName === 'Submitted' ? 'red' : 'green' }}>{rowData?.fileStatusName}</span>} />
                            <Column header="Status" body={statusBodyTemplate} />
                            <Column header="Action" body={actionBodyTemplate} />
                        </DataTable> 
                        <Paginator template={PaginationTemplate(false)} first={customFirst1} rows={customRows1} totalRecords={disbrusementData?.[1]?.totalRecordCount} onPageChange={onCustomPageChange1}></Paginator>

                    </div>
                </div>
            </div>
        </>
    );
}
export default DisbursementFileSummary;
