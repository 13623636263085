import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HistoryTable from './HistoryTable';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { handlePostRequest } from '../../../../services/PostTemplate';
import { handleGetRequest } from '../../../../services/GetTemplate';

function T24AccountLink({ type, dataForTable, historyTableView }) {
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [checkerEditPayLoad, setCheckerEditPayLoad] = useState([]);
    const [accountFetched, setAccountFetched] = useState(false);
    const [fetchedAccountData, setFetchedAccountData] = useState([]);
    const [accountTypeValues, setAccountTypeValues] = useState([]);
    const [customErrors, setCustomErrors] = useState({
        accountClassificationId: '',
        mobileNo: '',
        cnic: '',
        coreAccountNo: '',
        newAccountIban: ''
    });

    let { search } = useLocation();
    const location = useLocation();
    const getClassificationId = location?.state?.getClassificationId;
    const getMobileNo = location?.state?.getMobileNo;
    const getCnic = location?.state?.getCnic;
    const getCoreAccountNo = location?.state?.getCoreAccountNo;

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const checkerValidationSchema = Yup.object().shape({
        action: Yup.string().required('This field is required'),
        checkerComments: Yup.string().required('This field is required')
    });

    const addEditInitialValues = {
        accountClassificationId: '',
        mobileNo: '',
        cnic: '',
        coreAccountNo: '',
        newAccountIban: '',
        accountId: '',
        firstName: '',
        lastName: '',
        paymentModeName: '',
        coreAccountNick: '',
        coreAccountCnic: ''
    };

    const formik = useFormik({
        validationSchema: type === 'checkerEdit' ? checkerValidationSchema : null,
        initialValues: type === 'add' || type === 'edit' || type === 'view' ? addEditInitialValues : type === 'checkerEdit' || type === 'checkerView' ? { ...addEditInitialValues, action: '', checkerComments: '' } : null,

        onSubmit: async () => {
            const addData = {
                coreAccountNick: formik?.values?.coreAccountNick,
                coreAccountNo: formik?.values?.coreAccountNo,
                coreAccountCnic: formik?.values?.coreAccountCnic,
                accountId: formik?.values?.accountId,
                cnicHash: fetchedAccountData?.cnicHash
            };
            const editData = {
                t24AccountLinkId: dataForTable?.t24AccountLinkId,
                coreAccountNick: formik?.values?.coreAccountNick,
                coreAccountNo: formik?.values?.newAccountIban,
                coreAccountCnic: formik?.values?.coreAccountCnic,
                isActive: dataForTable?.isActive,
                accountId: formik?.values?.accountId,
                cnicHash: fetchedAccountData?.cnicHash
            };
            setloading(true);
            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: type === 'add' ? addData : type === 'edit' ? editData : type === 'checkerEdit' ? checkerEditPayLoad : null,
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            if (type === 'add') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/linkcoreaccount', true, false, 't24accountlink'));
            }
            if (type === 'edit') {
                await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/updatelinkedcoreaccount', true, false, 't24accountlink'));
            }
            if (type === 'checkerEdit') {
                await dispatch(handlePostRequest(newData, '/account/v1/t24/t24accountlinkcheckeraction', true, false, 'requestchecker'));
            }
            setloading(false);
        }
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const fetchAccountData = async () => {
        const newData = {
            data: {
                security: {
                    userName: '',
                    password: '',
                    securityToken: ''
                },
                account: {
                    msidn: '',
                    iban: '',
                    bban: '',
                    pan: '',
                    currency: ''
                },
                channel: '',
                terminal: '',
                reterivalReferenceNumber: '',
                payLoad: {
                    accountClassificationId: formik?.values?.accountClassificationId,
                    mobileNo: formik?.values?.mobileNo,
                    coreAccountNo: type === 'add' ? formik?.values?.coreAccountNo : formik?.values?.newAccountIban,
                    cnic: formik?.values?.cnic
                },
                additionalInformation: [
                    {
                        infoKey: '',
                        infoValue: ''
                    }
                ],
                checkSum: ''
            }
        };
        if (type === 'add') {
            if (!formik?.values?.accountClassificationId) {
                return setCustomErrors({ ...customErrors, accountClassificationId: 'This field is required' });
            } else if (!formik?.values?.mobileNo && !formik?.values?.cnic) {
                return setCustomErrors({
                    ...customErrors,
                    mobileNo: 'Mobile Number or CNIC is required',
                    cnic: 'Mobile Number or CNIC is required'
                });
            } else if (!formik?.values?.coreAccountNo) {
                return setCustomErrors({ ...customErrors, coreAccountNo: 'This field is required' });
            } else if (formik?.values?.mobileNo && !/^[\d]{11}$/.test(formik?.values?.mobileNo)) {
                return setCustomErrors({ ...customErrors, mobileNo: 'It must be exactly 11 digits' });
                // formik.setFieldError('mobileNo', 'It must be exactly 11 digits');
                // return (formik.errors.mobileNo = 'It must be exactly 11 digits');
            } else if (formik?.values?.cnic && !/^[\d]{13}$/.test(formik?.values?.cnic)) {
                return setCustomErrors({ ...customErrors, cnic: 'It must be exactly 13 digits' });
                // formik.setFieldError('cnic', 'It must be exactly 13 digits');
                // return (formik.errors.cnic = 'It must be exactly 13 digits');
            } else if (!/^[A-Za-z]{2}[A-Za-z0-9]{20}[0-9]{2}$/.test(formik?.values?.coreAccountNo)) {
                return setCustomErrors({ ...customErrors, coreAccountNo: 'Invalid IBAN' });
                // formik.setFieldError('coreAccountNo', 'Invalid IBAN');
                // return (formik.errors.coreAccountNo = 'Invalid IBAN');
            } else {
                await fetchAccountResponse(newData);
            }
        } else if (type === 'edit') {
            if (!formik?.values?.newAccountIban) {
                return setCustomErrors({ ...customErrors, newAccountIban: 'This field is required' });
            } else if (!/^[A-Za-z]{2}[A-Za-z0-9]{20}[0-9]{2}$/.test(formik?.values?.newAccountIban)) {
                return setCustomErrors({ ...customErrors, newAccountIban: 'Invalid IBAN' });
                // formik.setFieldError('newAccountIban', 'Invalid IBAN');
                // return (formik.errors.newAccountIban = 'Invalid IBAN');
            } else {
                await fetchAccountResponse(newData);
            }
        }
    };

    const fetchAccountResponse = async (newData) => {
        const res = await dispatch(handlePostRequest(newData, '/account/v1/customeraccounts/getlinkedaccountdetails', true, true));
        if (res?.responseCode === '010000') {
            formik.setFieldValue('mobileNo', res?.payLoad?.mobileNo);
            formik.setFieldValue('cnic', res?.payLoad?.cnic);
            formik.setFieldValue('accountId', res?.payLoad?.accountId);
            formik.setFieldValue('coreAccountNick', res?.payLoad?.coreAccountNick);
            formik.setFieldValue('coreAccountCnic', res?.payLoad?.coreAccountCnic);
            // formik.setFieldValue('paymentModeName', res?.payLoad?.paymentModeName);
            formik.setFieldValue('paymentModeName', 'CORE');
            nameSplitter(res?.payLoad?.fullName);
            setAccountFetched(true);
            setFetchedAccountData(res?.payLoad);
        } else {
            formik.setFieldValue('mobileNo', '');
            formik.setFieldValue('cnic', '');
            formik.setFieldValue('accountId', '');
            formik.setFieldValue('coreAccountNick', '');
            formik.setFieldValue('coreAccountCnic', '');
            // formik.setFieldValue('paymentModeName', res?.payLoad?.paymentModeName);
            formik.setFieldValue('paymentModeName', '');
            nameSplitter('');
            setAccountFetched(false);
            setFetchedAccountData('');
        }
    };

    useEffect(() => {
        if ((type === 'checkerEdit' || type === 'checkerView') && (dataForTable?.dataFromId || dataForTable?.dataFromApi)) {
            formik.setFieldValue('accountClassificationId', dataForTable?.dataFromId?.accountClassificationId || dataForTable?.dataFromApi?.accountClassificationId);
            formik.setFieldValue('mobileNo', dataForTable?.dataFromId?.mobileNo || dataForTable?.dataFromApi?.mobileNo);
            formik.setFieldValue('cnic', dataForTable?.dataFromId?.cnic || dataForTable?.dataFromApi?.cnic);
            // formik.setFieldValue('paymentModeName', dataForTable?.dataFromId?.paymentModeName || dataForTable?.dataFromApi?.paymentModeName);
            formik.setFieldValue('paymentModeName', 'CORE');
            formik.setFieldValue('coreAccountNo', dataForTable?.dataFromId?.coreAccountNo || dataForTable?.dataFromApi?.coreAccountNo);
            if (dataForTable?.urlData?.requestTypeDescr !== 'Update Request') {
                formik.setFieldValue('accountId', dataForTable?.dataFromId?.accountId || dataForTable?.dataFromApi?.accountId);
                formik.setFieldValue('newAccountIban', dataForTable?.dataFromId?.newAccountIban || dataForTable?.dataFromApi?.newAccountIban);
                formik.setFieldValue('coreAccountNick', dataForTable?.dataFromId?.coreAccountNick || dataForTable?.dataFromApi?.coreAccountNick);
                formik.setFieldValue('coreAccountCnic', dataForTable?.dataFromId?.coreAccountCnic || dataForTable?.dataFromApi?.coreAccountCnic);
            } else {
                formik.setFieldValue('accountId', dataForTable?.dataFromApi?.accountToLink?.accountId);
                formik.setFieldValue('newAccountIban', dataForTable?.dataFromApi?.accountToLink?.coreAccountNo);
                formik.setFieldValue('coreAccountNick', dataForTable?.dataFromApi?.accountToLink?.coreAccountNick);
                formik.setFieldValue('coreAccountCnic', dataForTable?.dataFromApi?.accountToLink?.coreAccountCnic);
            }
            nameSplitter(dataForTable?.dataFromId?.fullName || dataForTable?.dataFromApi?.fullName);

            if (dataForTable?.urlData?.requestType === 'I') {
                setCheckerEditPayLoad({
                    t24AccountLinkDTO: {
                        requestType: dataForTable?.urlData?.requestType,
                        t24AccountLinkId: dataForTable?.urlData?.refTableId
                    },
                    mcActionRequest: {
                        mcRequestId: dataForTable?.urlData?.mcRequestId,
                        mcPeindingRequestId: dataForTable?.urlData?.mcPeindingRequestId,
                        checkerId: '1',
                        checkerComments: formik?.values?.checkerComments,
                        action: formik?.values?.action,
                        updatedIndex: ''
                    }
                });
            } else {
                setCheckerEditPayLoad({
                    t24AccountLinkDTO: {
                        ...dataForTable?.dataFromApi
                    },
                    mcActionRequest: {
                        mcRequestId: dataForTable?.urlData?.mcRequestId,
                        mcPeindingRequestId: dataForTable?.urlData?.mcPeindingRequestId,
                        checkerId: '1',
                        checkerComments: formik?.values?.checkerComments,
                        action: formik?.values?.action,
                        updatedIndex: ''
                    }
                });
            }
        }
        if ((type === 'edit' || type === 'view') && dataForTable) {
            formik.setFieldValue('accountClassificationId', dataForTable?.accountClassificationId);
            formik.setFieldValue('accountId', dataForTable?.accountId);
            formik.setFieldValue('mobileNo', dataForTable?.mobileNo);
            formik.setFieldValue('cnic', dataForTable?.cnic);
            // formik.setFieldValue('paymentModeName', dataForTable?.paymentModeName);
            formik.setFieldValue('paymentModeName', 'CORE');
            formik.setFieldValue('coreAccountNo', dataForTable?.coreAccountNo);
            formik.setFieldValue('newAccountIban', dataForTable?.newAccountIban);
            formik.setFieldValue('coreAccountNick', dataForTable?.coreAccountNick);
            formik.setFieldValue('coreAccountCnic', dataForTable?.coreAccountCnic);
            nameSplitter(dataForTable?.fullName);
        }
    }, [dataForTable]);

    const getaccountTypeValues = async () => {
        const res = await handleGetRequest('/configuration/v1/lookups/getAccountClassification/LKP_ACCOUNT_CLASSIFICATION:*');
        const updatedRes = res?.payLoad?.filter((item) => item?.code !== 'HND');
        setAccountTypeValues(updatedRes);
    };

    useEffect(() => {
        getaccountTypeValues();
    }, []);

    const nameSplitter = (name) => {
        if (name) {
            const fullNameParts = name.split(' ');
            if (fullNameParts.length > 1) {
                formik.setFieldValue('firstName', fullNameParts.slice(0, -1).join(' '));
                formik.setFieldValue('lastName', fullNameParts[fullNameParts.length - 1]);
            } else {
                // Handle cases where there's only one part (e.g., only a first name)
                formik.setFieldValue('firstName', fullNameParts[0]);
                formik.setFieldValue('lastName', '');
            }
        } else {
            formik.setFieldValue('firstName', '');
            formik.setFieldValue('lastName', '');
        }
    };

    useEffect(() => {
        if (type === 'add' && getClassificationId && getMobileNo && getCnic && getCoreAccountNo) {
            formik.setFieldValue('accountClassificationId', getClassificationId);
            formik.setFieldValue('mobileNo', getMobileNo);
            formik.setFieldValue('cnic', getCnic);
            formik.setFieldValue('coreAccountNo', getCoreAccountNo);
            if (formik?.values?.accountClassificationId && formik?.values?.mobileNo && formik?.values?.cnic && formik?.values?.coreAccountNo) {
                fetchAccountData();
            }
        }
    }, [type, getClassificationId, getMobileNo, getCnic, getCoreAccountNo, formik?.values?.accountClassificationId, formik?.values?.mobileNo, formik?.values?.cnic, formik?.values?.coreAccountNo]);

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h5 className="uppercase">{type === 'add' ? 'Link Account' : type === 'edit' || type === 'checkerEdit' ? 'Edit Detail' : type === 'view' || type === 'checkerView' ? 'View Detail' : null}</h5>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid ">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Customer Account<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Customer Account"
                                    options={accountTypeValues}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        formik.setFieldValue('accountClassificationId', e.target.value);
                                        setCustomErrors({ ...customErrors, accountClassificationId: '' });
                                    }}
                                    disabled={type !== 'add' || getClassificationId || getMobileNo || getCnic || getCoreAccountNo}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {customErrors?.accountClassificationId ? <small className="p-error">{customErrors?.accountClassificationId}</small> : getFormErrorMessage('accountClassificationId')}
                                {/* {getFormErrorMessage('accountClassificationId')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="mobileNo" className={classNames({ 'p-error': isFormFieldValid('mobileNo') }, 'Label__Text')}>
                                    Mobile Number
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={11}
                                    placeholder="Enter Mobile Number"
                                    id="mobileNo"
                                    name="mobileNo"
                                    value={formik?.values?.mobileNo || ''}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        formik.setFieldValue('mobileNo', e.target.value);
                                        setCustomErrors({ ...customErrors, mobileNo: '', cnic: '' });
                                    }}
                                    disabled={type !== 'add' || getClassificationId || getMobileNo || getCnic || getCoreAccountNo}
                                    className={classNames({ 'p-invalid': isFormFieldValid('mobileNo') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {customErrors?.mobileNo ? <small className="p-error">{customErrors?.mobileNo}</small> : getFormErrorMessage('mobileNo')}
                                {/* {formik?.errors?.mobileNo ? <small className="p-error">{formik?.errors?.mobileNo}</small> : getFormErrorMessage('mobileNo')} */}
                                {/* {getFormErrorMessage('mobileNo')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="cnic" className={classNames({ 'p-error': isFormFieldValid('cnic') }, 'Label__Text')}>
                                    CNIC
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <InputText
                                    keyfilter="int"
                                    maxLength={13}
                                    placeholder="Enter CNIC"
                                    id="cnic"
                                    name="cnic"
                                    value={formik?.values?.cnic || ''}
                                    // onChange={formik.handleChange}
                                    onChange={(e) => {
                                        formik.setFieldValue('cnic', e.target.value);
                                        setCustomErrors({ ...customErrors, mobileNo: '', cnic: '' });
                                    }}
                                    disabled={type !== 'add' || getClassificationId || getMobileNo || getCnic || getCoreAccountNo}
                                    className={classNames({ 'p-invalid': isFormFieldValid('cnic') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {customErrors?.cnic ? <small className="p-error">{customErrors?.cnic}</small> : getFormErrorMessage('cnic')}
                                {/* {formik?.errors?.cnic ? <small className="p-error">{formik?.errors?.cnic}</small> : getFormErrorMessage('cnic')} */}
                                {/* {getFormErrorMessage('cnic')} */}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className={classNames(isFormFieldValid('coreAccountNo') || customErrors?.coreAccountNo ? 'align-items-center' : 'align-items-end', 'flex')}>
                                <div className="p-field w-100">
                                    <label htmlFor="coreAccountNo" className={classNames({ 'p-error': isFormFieldValid('coreAccountNo') }, 'Label__Text')}>
                                        Core Account IBAN<span className="Label__Required">*</span>
                                    </label>
                                    <InputText
                                        maxLength={24}
                                        placeholder="Enter Core Account IBAN"
                                        id="coreAccountNo"
                                        name="coreAccountNo"
                                        value={formik?.values?.coreAccountNo || ''}
                                        onChange={(e) => {
                                            formik.setFieldValue('coreAccountNo', e.target.value.toUpperCase());
                                            setAccountFetched(false);
                                            setCustomErrors({ ...customErrors, coreAccountNo: '' });
                                        }}
                                        disabled={type !== 'add' || (type === 'add' && getClassificationId && getMobileNo && getCnic && getCoreAccountNo)}
                                        className={classNames({ 'p-invalid': isFormFieldValid('coreAccountNo') }, 'Input__Round')}
                                        onKeyDown={(e) => handleKeyPress(e)}
                                    />
                                    {customErrors?.coreAccountNo ? <small className="p-error">{customErrors?.coreAccountNo}</small> : getFormErrorMessage('coreAccountNo')}
                                    {/* {formik?.errors?.coreAccountNo ? <small className="p-error">{formik?.errors?.coreAccountNo}</small> : getFormErrorMessage('coreAccountNo')} */}
                                    {/* {getFormErrorMessage('coreAccountNo')} */}
                                </div>
                                <div className={classNames({ 'mt-1': isFormFieldValid('coreAccountNo') }, 'Down__Btn w-auto justify-content-end ml-3')}>
                                    <Button
                                        className="Btn__Dark mx-0 w-7rem"
                                        type="button"
                                        onClick={() => fetchAccountData()}
                                        disabled={type !== 'add' || (type === 'add' && getClassificationId && getMobileNo && getCnic && getCoreAccountNo)}
                                        label="Fetch"
                                    />
                                </div>
                            </div>
                        </div>
                        {/* {(type === 'edit' && formik?.values?.coreAccountNo) ||
                            (formik?.values?.newAccountIban && (type === 'checkerEdit' || type === 'checkerView') && ( */}
                        {((type === 'edit' && formik?.values?.coreAccountNo) || (formik?.values?.newAccountIban && (type === 'checkerEdit' || type === 'checkerView'))) && (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className={classNames(isFormFieldValid('newAccountIban') || customErrors?.newAccountIban ? 'align-items-center' : 'align-items-end', 'flex')}>
                                    <div className="p-field w-100">
                                        <label htmlFor="newAccountIban" className={classNames({ 'p-error': isFormFieldValid('newAccountIban') }, 'Label__Text')}>
                                            New Account IBAN
                                        </label>
                                        <InputText
                                            maxLength={24}
                                            placeholder="Enter New Account IBAN"
                                            id="newAccountIban"
                                            name="newAccountIban"
                                            value={formik?.values?.newAccountIban || ''}
                                            // onChange={formik.handleChange}
                                            disabled={type === 'checkerEdit' || type === 'checkerView'}
                                            onChange={(e) => {
                                                formik.setFieldValue('newAccountIban', e.target.value.toUpperCase());
                                                setAccountFetched(false);
                                                setCustomErrors({ ...customErrors, newAccountIban: '' });
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('newAccountIban') }, 'Input__Round')}
                                            onKeyDown={(e) => handleKeyPress(e)}
                                        />
                                        {customErrors?.newAccountIban ? <small className="p-error">{customErrors?.newAccountIban}</small> : getFormErrorMessage('newAccountIban')}
                                        {/* {formik?.errors?.newAccountIban ? <small className="p-error">{formik?.errors?.newAccountIban}</small> : getFormErrorMessage('newAccountIban')} */}
                                        {/* {getFormErrorMessage('newAccountIban')} */}
                                    </div>
                                    <div className={classNames({ 'mt-1': isFormFieldValid('newAccountIban') }, 'Down__Btn w-auto justify-content-end ml-3')}>
                                        <Button
                                            className="Btn__Dark mx-0 w-7rem"
                                            type="button"
                                            onClick={() => {
                                                fetchAccountData();
                                            }}
                                            disabled={type === 'checkerEdit' || type === 'checkerView'}
                                            label="Fetch"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountId" className={classNames({ 'p-error': isFormFieldValid('accountId') }, 'Label__Text')}>
                                    Account ID
                                </label>
                                <InputText
                                    // maxLength={50}
                                    // minLength={3}
                                    // placeholder="Enter Account ID"
                                    id="accountId"
                                    name="accountId"
                                    value={formik?.values?.accountId || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountId') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('accountId')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="firstName" className={classNames({ 'p-error': isFormFieldValid('firstName') }, 'Label__Text')}>
                                    First Name
                                </label>
                                <InputText
                                    // maxLength={50}
                                    // minLength={3}
                                    // placeholder="Enter First Name"
                                    id="firstName"
                                    name="firstName"
                                    value={formik?.values?.firstName || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('firstName') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('firstName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="lastName" className={classNames({ 'p-error': isFormFieldValid('lastName') }, 'Label__Text')}>
                                    Last Name
                                </label>
                                <InputText
                                    // maxLength={50}
                                    // minLength={3}
                                    // placeholder="Enter Last Name"
                                    id="lastName"
                                    name="lastName"
                                    value={formik?.values?.lastName || ''}
                                    onChange={formik.handleChange}
                                    disabled
                                    className={classNames({ 'p-invalid': isFormFieldValid('lastName') }, 'Input__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('lastName')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="paymentModeName" className={classNames({ 'p-error': isFormFieldValid('paymentModeName') }, 'Label__Text')}>
                                    Payment Mode
                                    {/* <span className="Label__Required">*</span> */}
                                </label>
                                <Dropdown
                                    id="paymentModeName"
                                    // placeholder="Select Payment Mode"
                                    options={[{ name: formik?.values?.paymentModeName || 'N/A' }]}
                                    optionLabel="name"
                                    name="paymentModeName"
                                    optionValue="name"
                                    value={formik?.values?.paymentModeName || ''}
                                    disabled
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('paymentModeName') }, 'Dropdown__Round')}
                                    onKeyDown={(e) => handleKeyPress(e)}
                                />
                                {getFormErrorMessage('paymentModeName')}
                            </div>
                        </div>
                    </div>
                    <div className="custom-card p-3 my-3">
                        <div className="p-fluid p-formgrid grid mb-2 mx-3">
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="coreAccountNick" className={classNames({ 'p-error': isFormFieldValid('coreAccountNick') }, 'Label__Text')}>
                                        Account Nick (Core Account)
                                    </label>
                                    <InputText
                                        id="coreAccountNick"
                                        name="coreAccountNick"
                                        value={formik.values.coreAccountNick}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('coreAccountNick') }, 'Input__Round')}
                                        disabled
                                    />
                                    {getFormErrorMessage('coreAccountCnic')}
                                </div>
                            </div>
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="coreAccountCnic" className={classNames({ 'p-error': isFormFieldValid('coreAccountCnic') }, 'Label__Text')}>
                                        CNIC (Core Account)
                                    </label>
                                    <InputText
                                        id="coreAccountCnic"
                                        name="coreAccountCnic"
                                        value={formik.values.coreAccountCnic}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('coreAccountCnic') }, 'Input__Round')}
                                        disabled
                                    />
                                    {getFormErrorMessage('coreAccountCnic')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn py-3">
                        {(type === 'edit' || type === 'add') && (
                            <React.Fragment>
                                <Button className="Btn__Dark" disabled={!accountFetched} type="submit" label={type === 'add' ? 'Link Account' : 'Update'} />
                                <Button type="button" onClick={() => navigate(-1)} label="Cancel" className="Btn__Transparent" />
                            </React.Fragment>
                        )}
                    </div>
                    {historyTableView && historyTableView?.length > 0 && (type == 'view' || type == 'checkerView') && <HistoryTable tableData={historyTableView} />}
                    <div className="Down__Btn mt-4">{type === 'view' ? <Button type="button" onClick={() => navigate(-1)} label="Okay" className="Btn__Dark" /> : null}</div>
                    {type == 'checkerEdit' && (
                        <React.Fragment>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="action" className={classNames({ 'p-error': isFormFieldValid('action') }, 'Label__Text')}>
                                            Action
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <Dropdown
                                            id="action"
                                            placeholder="Select Action"
                                            options={dataForTable?.statusLovData || []}
                                            optionLabel="name"
                                            name="action"
                                            optionValue="lovId"
                                            value={formik.values.action || ''}
                                            // onChange={formik.handleChange}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setCheckerEditPayLoad({
                                                    ...checkerEditPayLoad,
                                                    mcActionRequest: {
                                                        ...checkerEditPayLoad?.mcActionRequest,
                                                        action: e.target.value
                                                    }
                                                });
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('action') }, 'Dropdown__Round')}
                                        />

                                        {getFormErrorMessage('action')}
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid grid mb-5">
                                <div className="p-field col-12 md:col-4">
                                    <div className="p-field">
                                        <label htmlFor="checkerComments" className={classNames({ 'p-error': isFormFieldValid('checkerComments') }, 'Label__Text')}>
                                            Comments
                                            <span className="Label__Required">*</span>
                                        </label>
                                        <InputTextarea
                                            maxLength={500}
                                            rows={5}
                                            cols={30}
                                            id="checkerComments"
                                            name="checkerComments"
                                            value={formik?.values?.checkerComments?.replace(/\s\s+/g, ' ') || ''}
                                            onChange={(e) => {
                                                formik.handleChange(e);
                                                setCheckerEditPayLoad({
                                                    ...checkerEditPayLoad,
                                                    mcActionRequest: {
                                                        ...checkerEditPayLoad?.mcActionRequest,
                                                        checkerComments: e.target.value
                                                    }
                                                });
                                            }}
                                            className={classNames({ 'p-invalid': isFormFieldValid('checkerComments') }, 'TextArea__Round')}
                                        />

                                        {getFormErrorMessage('checkerComments')}
                                    </div>
                                </div>
                            </div>
                            <div className="Down__Btn py-3">
                                <Button disabled={loading} label="Update" className="Btn__Dark" />
                                <Button type="button" onClick={() => navigate('/requestchecker')} label="Cancel" className="Btn__Transparent" />
                            </div>
                        </React.Fragment>
                    )}
                    {type == 'checkerView' && (
                        <div className="Down__Btn">
                            <Button type="button" onClick={() => navigate('/requestchecker')} label="Okay" className="Btn__Dark" />
                        </div>
                    )}
                </form>
            </div>
        </>
    );
}
export default T24AccountLink;
