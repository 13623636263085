import React, { useState, useEffect } from 'react';
import { Button } from 'primereact/button';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { handlePostRequest } from '../../../services/PostTemplate';
import { handleGetRequest } from '../../../services/GetTemplate';
import * as Yup from 'yup';
import { InputText } from 'primereact/inputtext';
import { useDispatch } from 'react-redux';
import { Dropdown } from 'primereact/dropdown';
import { useLocation, useNavigate } from 'react-router-dom';
import { RadioButton } from 'primereact/radiobutton';
import { MultiSelect } from 'primereact/multiselect';

function EditPricingConditionalFee() {
    const [loading, setloading] = useState(false);
    const [loadingIcon, setloadingIcon] = useState('pi pi-search');
    const [allLovs, setAllLovs] = useState([]);
    const [sharingAccount, setSharingAccount] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const requestedID = query.get('profileId');

    const getPricingFixedbyId = async () => {
        const response = await handleGetRequest(`/pricingprofile/v1/pricingprofile/getfeeprofilebyid/${requestedID}`);
        const keyData = response?.payLoad;
        console.log('keyData', keyData);

        const productsValues = keyData?.tblPricingProducts?.map((values) => {
            return values?.tblProduct?.productId?.toString();
        });

        const partnerData = keyData?.incomeSharingRequests?.map((value) => {
            return {
                pricingIncomeSharingId: value?.pricingIncomeSharingId,
                sharingType: value?.sharingType,
                incomeGlAccountId: value?.incomeGlAccountId?.toString(),
                sharingDetails: value?.sharingDetails,
                sharingPercentage: value?.sharingPercentage?.toString(),
                taxStatus: value?.taxStatus,
                whtGlAccountId: value?.whtGlAccountId?.toString()
            };
        });

        formik.setFieldValue('accountClassificationId', keyData?.lkpAccountClassification?.accountClassificationId?.toString());
        const segmentValues = keyData?.tblPricingSegments?.map((values) => {
            return values?.segmentId?.toString();
        });
        formik.setFieldValue('segmentIds', segmentValues || '');


        const channelValues = keyData?.tblPricingChannels?.map((values) => {
            return values?.channelId?.toString();
        });
        formik.setFieldValue('channelIds', channelValues || '');
        // formik.setFieldValue('segmentId', keyData?.lkpSegment?.segmentId?.toString());
        formik.setFieldValue('pricingProfileName', keyData?.pricingProfileName);
        // formik.setFieldValue('channelId', keyData?.lkpChannel?.channelId?.toString());
        formik.setFieldValue('chargingParty', keyData?.lkpPricingChargingParty?.pricingChargingPartyCode);
        formik.setFieldValue('startDate', keyData?.startDate?.slice(0, 10));
        formik.setFieldValue('endDate', keyData?.endDate?.slice(0, 10));
        formik.setFieldValue('feeCalculationType', keyData?.feeCalculationType);
        formik.setFieldValue('taxCalculationType', keyData?.taxCalculationType);
        formik.setFieldValue('incomeGlAccountId', keyData?.tblAccount1?.accountId?.toString());
        formik.setFieldValue('noOfPartners', keyData?.noOfPartners);
        formik.setFieldValue('payeeGlAccountId', keyData?.tblAccount2?.accountId?.toString());
        // formik.setFieldValue('pricingIncomeSharingId', keyData?.incomeSharingRequests[0]?.pricingIncomeSharingId);
        formik.setFieldValue('feePercentage', keyData?.feePercentage);
        formik.setFieldValue('feeAmount', keyData?.feeAmount);
        formik.setFieldValue('feeCondition', keyData?.feeCondition);
        formik.setFieldValue('pricingSlabId', keyData?.tblPricingSlabs[0]?.pricingSlabId);

        if (keyData?.velocity === 'Y') {
            formik.setFieldValue('tblPricingVelocities', keyData?.tblPricingVelocities);
        }
        if (keyData?.incomeSharing === 'Y') {
            formik.setFieldValue('tblPricingIncomeSharings', partnerData);
        }
        if (productsValues && productsValues.length > 0) {
            formik.setFieldValue('tblProduct', productsValues);
        }
        if (keyData?.velocity === 'N') {
            formik.setFieldValue('velocity', 'N');
        } else {
            formik.setFieldValue('velocity', 'Y');
        }
        if (keyData?.incomeSharing === 'N') {
            formik.setFieldValue('incomeSharing', 'N');
        } else {
            formik.setFieldValue('incomeSharing', 'Y');
        }
    };

    useEffect(() => {
        getPricingFixedbyId();
    }, []); // eslint-disable-line

    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate());
    const previousDate = currentDate.toISOString().split('T')[0];

    const validationSchema = Yup.object().shape({
        // noOfPartners: Yup.string().matches(/^[0-9]*$/, 'Please enter numeric data'),
        tblPricingIncomeSharings: Yup.array().when('incomeSharing', (incomeSharing, schema) => {
            return incomeSharing === 'Y'
                ? Yup.array().of(
                      Yup.object().shape({
                          sharingType: Yup.mixed().required('This field is required'),
                          incomeGlAccountId: Yup.mixed().required('This field is required'),
                          sharingDetails: Yup.string().required('This field is required'),
                          sharingPercentage: Yup.string().when(['sharingType'], {
                              is: 'F',
                              then: Yup.string()
                                  .required('This field is required')
                                  .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data')
                                  .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                                      if (!value) {
                                          return false;
                                      }
                                      const numericValue = parseFloat(value);
                                      return numericValue > 0;
                                  }),
                              otherwise: Yup.string()
                                  .required('This field is required')
                                  .when(['sharingType'], {
                                      is: 'P',
                                      then: Yup.string()
                                          .required('This field is required')
                                          .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data')
                                          .test('within-range', 'Value must be greater than 0 and equal to or lesser than 100', (value) => {
                                              const numericValue = parseFloat(value);
                                              return numericValue > 0 && numericValue <= 100;
                                          })
                                  })
                          }),
                          taxStatus: Yup.mixed().required('This field is required').nullable(),
                          whtGlAccountId: Yup.mixed().required('This field is required').nullable()
                      })
                  )
                : schema;
        }),
        tblPricingVelocities: Yup.array().when('velocity', (velocity, schema) => {
            return velocity === 'Y'
                ? Yup.array().of(
                      Yup.object().shape({
                          noOfFreeTrxn: Yup.string()
                              .required('This field is required')
                              .matches(/^[0-9]*$/, 'Please enter numeric data')
                              .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                                  if (!value) {
                                      return false;
                                  }
                                  const numericValue = parseFloat(value);
                                  return numericValue > 0;
                              }),
                          frequency: Yup.string().required('This field is required'),
                          velocityRule: Yup.string()
                              .trim()
                              //   .required('This field is required')
                              //   .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data')
                              .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                                  if (!value) {
                                      return false;
                                  }
                                  const numericValue = parseFloat(value);
                                  return numericValue > 0;
                              })
                              .nullable(),
                          velocityAmount: Yup.string()
                              .required('This field is required')
                              .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data')
                              .test('greater-than-zero', 'Value must be greater than 0', (value) => {
                                  if (!value) {
                                      return false;
                                  }
                                  const numericValue = parseFloat(value);
                                  return numericValue > 0;
                              })
                              .nullable()
                      })
                  )
                : schema;
        }),
        accountClassificationId: Yup.string().required('This field is required'),
        segmentIds: Yup.array().required('This field is required'),
        channelIds: Yup.array().required('This field is required'),
        chargingParty: Yup.string().required('This field is required'),
        taxCalculationType: Yup.string().required('This field is required'),
        feeCalculationType: Yup.string().required('This field is required'),
        feeAmount: Yup.string()
            .required('This field is required')
            .matches(/^-?\d+(\.\d+)?$/, 'Please enter valid value'),
        startDate: Yup.date().required('This field is required').nullable(),
        endDate: Yup.date()
            .required('This field is required')
            .when('startDate', {
                is: (startDate) => !!startDate,
                then: Yup.date().required('End Date is required').min(Yup.ref('startDate'), 'End Date has to be more than Start Date')
            }),

        feePercentage: Yup.string()
            .required('This field is required')
            .matches(/^-?\d+(\.\d+)?$/, 'Please enter numeric data'),
        feeCondition: Yup.string().required('This field is required')
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            accountClassificationId: '',
            segmentIds: [],
            pricingProfileName: '',
            channelIds: [],
            chargingParty: '',
            taxCalculationType: '',
            feeCalculationType: '',
            startDate: '',
            endDate: '',
            feeTypeCode: 'CF',
            incomeGlAccountId: '',
            velocity: '',
            incomeSharing: 'N',
            tblProduct: [],
            payeeGlAccountId: '',
            feeAmount: '',
            feePercentage: '',
            feeCondition: '',
            noOfPartners: '',
            pricingProfileId: requestedID,
            tblPricingVelocities: [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: 'N'
                }
            ],
            tblPricingIncomeSharings: [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: '',
                    pricingIncomeSharingId: ''
                }
            ]
        },
        validate: (data) => {
            let errors = {};
            if (data.chargingParty === 'P') {
                if (data?.payeeGlAccountId === '' || data.payeeGlAccountId === null) {
                    errors.payeeGlAccountId = 'This field is required';
                }
            }
            if (data?.incomeSharing === 'N') {
                if (data?.incomeGlAccountId === '' || data?.incomeGlAccountId === null || data?.incomeGlAccountId === undefined) {
                    errors.incomeGlAccountId = 'This field is required';
                }
            }
            if (data?.tblProduct.length === 0) {
                errors.tblProduct = 'This field is required';
            }
            // if (data.incomeSharing === 'Y') {
            //     if (!data.noOfPartners) {
            //         errors.noOfPartners = 'This field is required';
            //     }
            // }
            if (data.feePercentage === '0' || data.feePercentage > 100) {
                errors.feePercentage = 'Fee percentage must be between 1 and 100';
            }

            if (/^0+$/.test(data?.feePercentage)) {
                errors.feePercentage = 'Fee Percentage should be greater than 0';
            }
            if (parseFloat(data?.feeAmount) <= 0) {
                errors.feeAmount = 'Fee Amount should be greater than 0';
            }
            if (!data?.pricingProfileName) {
                errors.pricingProfileName = 'This field is required';
            } else if (data?.pricingProfileName === '') {
                errors.pricingProfileName = 'This Field should not be empty';
            } else if (!/^[^\s]+(\s[^\s]+)*$/.test(data?.pricingProfileName)) {
                errors.pricingProfileName = 'Leading and trailing space is not allowed';
            } else if (!/^[a-zA-Z](?:[a-zA-Z0-9\s]*-?[a-zA-Z0-9\s]*)?$/.test(data?.pricingProfileName)) {
                errors.pricingProfileName = 'Please enter alpha numeric data';
            }

            return errors;
        },
        onSubmit: async (data) => {
            setloading(true);
            setloadingIcon('pi pi-spin pi-spinner');

            const productData = data?.tblProduct?.map((value) => value?.productId);

            formik.setFieldValue('tblProduct', productData);

            // console.log('datadsf', data?.incomeGlAccountId);
            // const segmentData = data.tblPricingSegments.map((value) => value.segmentId);
            // formik.setFieldValue('tblPricingSegments', segmentData);

            // const channelData = data.tblPricingChannels.map((value) => value.channelId);
            // formik.setFieldValue('tblPricingChannels', channelData);

            if (data.velocity === 'N') {
                delete data['tblPricingVelocities'];
            }
            if (data.incomeSharing === 'N') {
                delete data['tblPricingIncomeSharings'];
            }

            const productArray = data?.tblProduct.map((prdctData) => {
                return {
                    productId: prdctData
                };
            });
            // const segmentArray = data.map((segmenData) => {
            //     return {
            //         segmentid: segmenData
            //     };
            // });
            // const channelArray = data.map((chanelData) => {
            //     return {
            //         channelId: chanelData
            //     };
            // });
            if (data?.velocity === 'Y') {
                data?.tblPricingVelocities?.forEach((item) => {
                    delete item.lastupdatedate;
                    delete item.lastupdateuser;
                    delete item.updateindex;
                    delete item.createdate;
                    delete item.createuser;
                    delete item.isActive;
                });
            }
            if (data?.incomeSharing === 'Y') {
                data['tblPricingIncomeSharings'] = data?.tblPricingIncomeSharings?.map((items) => {
                    return {
                        sharingType: items?.sharingType,
                        incomeGlAccountId: items?.incomeGlAccountId,
                        sharingDetails: items?.sharingDetails,
                        sharingPercentage: items?.sharingPercentage,
                        taxStatus: items?.taxStatus,
                        whtGlAccountId: items?.whtGlAccountId,
                        pricingIncomeSharingId: items?.pricingIncomeSharingId || 0
                    };
                });
            }
            data['payeeGlAccountId'] = data?.payeeGlAccountId;
            data['tblProduct'] = productArray;
            // data['segmentIds'] = segmentArray;
            // data['channelIds'] = channelArray;

            delete data['startSlab'];
            delete data['endSlab'];
            delete data['pricingIncomeSharingId'];

            const newData = {
                data: {
                    security: {
                        userName: '',
                        password: '',
                        securityToken: ''
                    },
                    account: {
                        msidn: '',
                        iban: '',
                        bban: '',
                        pan: '',
                        currency: ''
                    },
                    channel: '',
                    terminal: '',
                    reterivalReferenceNumber: '',
                    payLoad: {
                        ...data
                    },
                    additionalInformation: [
                        {
                            infoKey: '',
                            infoValue: ''
                        }
                    ],
                    checkSum: ''
                }
            };
            const response = await dispatch(handlePostRequest(newData, '/pricingprofile/v1/pricingprofile/updatepricingprofile', true, false, 'pricing'));
            console.log(response);
        }
    });
    if (formik.values.noOfPartners === '0') {
        formik.setFieldValue('incomeSharing', 'N');
        formik.setFieldValue('noOfPartners', '');
    }
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);

    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const addVelocity = () => {
        formik.setFieldValue('tblPricingVelocities', [
            ...formik.values.tblPricingVelocities,
            {
                noOfFreeTrxn: '',
                frequency: '',
                velocityRule: '',
                velocityAmount: '',
                chargeOnDifferential: ''
            }
        ]);
    };
    const removeCard = (index) => {
        if (index > 0) {
            const updatedData = [...formik.values.tblPricingVelocities];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
        if (index === 0) {
            const updatedData = [...formik.values.tblPricingVelocities];
            updatedData.splice(index, 1);
            formik.setFieldValue('tblPricingVelocities', updatedData);
        }
        if (formik.values.tblPricingVelocities.length === 1) {
            formik.setFieldValue('velocity', 'No');
        }
    };
    const addPartner = () => {
        formik.setFieldValue('tblPricingIncomeSharings', [
            ...formik.values.tblPricingIncomeSharings,
            {
                sharingType: '',
                incomeGlAccountId: '',
                sharingDetails: '',
                sharingPercentage: '',
                taxStatus: '',
                whtGlAccountId: ''
            }
        ]);
    };
    const removePartnerCard = (index) => {
        if (index > 1) {
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            updatedData.splice(index - 1, 1);
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
        if (index === 1) {
            const updatedData = [...formik.values.tblPricingIncomeSharings];
            updatedData.splice(index - 1, 1);
            formik.setFieldValue('tblPricingIncomeSharings', updatedData);
        }
        if (formik.values.tblPricingIncomeSharings.length === 1) {
            formik.setFieldValue('incomeSharing', 'No');
        }
    };

    const getAllLovsForPricing = async () => {
        const resp = await handleGetRequest('/configuration/v1/lookups/getfeeprofiledata');
        setAllLovs(resp?.payLoad);
    };

    useEffect(() => {
        getAllLovsForPricing();
    }, []);
    const accountTypeLov = allLovs?.accountype?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const segmentLov = allLovs?.segment?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const channelLov = allLovs?.channel?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });
    const transactionsLov = allLovs?.transactions?.map((data) => {
        return {
            lovId: data?.lovId,
            name: data?.name
        };
    });

    const chargingPartyLov = allLovs?.chargingParty?.map((data) => {
        return {
            lovId: data?.code,
            name: data?.name
        };
    });

    const glJsonLov = allLovs?.glAccounts?.map((data) => {
        return {
            lovId: data?.accountId,
            name: `${data?.accountNo}-${data?.accountTitle}`,
            accountTitle: data?.accountTitle
        };
    });

    const feeTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];
    const taxTypeJson = [
        {
            value: 'I',
            label: 'Inclusive'
        },
        {
            value: 'E',
            label: 'Exclusive'
        }
    ];

    const frequencyJson = [
        {
            value: 'D',
            label: 'Daily'
        },
        {
            value: 'M',
            label: 'Monthly'
        },
        {
            value: 'Y',
            label: 'Yearly'
        }
    ];

    const sharingTypeJson = [
        {
            value: 'F',
            label: 'Fixed'
        },
        {
            value: 'P',
            label: 'Percentage'
        }
    ];
    const taxStatusJson = [
        {
            value: 'Y',
            label: 'Filer'
        },
        {
            value: 'N',
            label: 'Non-Filer'
        }
    ];
    const conditionLov = [
        {
            value: 'H',
            label: 'Higher'
        },
        {
            value: 'L',
            label: 'Lower'
        }
    ];
    const handleVelocityChange = (value) => {
        if (value === 'N') {
            formik.setFieldValue('tblPricingVelocities', []);
        }
        if (value === 'Y') {
            formik.setFieldValue('tblPricingVelocities', [
                {
                    noOfFreeTrxn: '',
                    frequency: '',
                    velocityRule: '',
                    velocityAmount: '',
                    chargeOnDifferential: 'N'
                }
            ]);
        }
        formik.handleChange('velocity', value);
    };

    const handleIncomeSharingChange = (value) => {
        if (value === 'N') {
            formik.setFieldValue('tblPricingIncomeSharings', []);
        }
        if (value === 'Y') {
            formik.setFieldValue('tblPricingIncomeSharings', [
                {
                    sharingType: '',
                    incomeGlAccountId: '',
                    sharingDetails: '',
                    sharingPercentage: '',
                    taxStatus: '',
                    whtGlAccountId: '',
                    pricingIncomeSharingId: ''
                }
            ]);
        }
        formik.handleChange('incomeSharing', value);
    };

    useEffect(() => {
        if (formik.values.incomeSharing === 'Y') {
            formik.setFieldValue('noOfPartners', formik.values.tblPricingIncomeSharings.length);
        } else {
            formik.setFieldValue('noOfPartners', '');
        }
    }, [formik.values.tblPricingIncomeSharings]); // eslint-disable-line

    return (
        <>
            <div className="card Card__Round">
                <div className="Form__Header">
                    <h1>EDIT DETAILS</h1>
                </div>
                <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                    <div className="p-fluid p-formgrid grid mb-5">
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="accountClassificationId" className={classNames({ 'p-error': isFormFieldValid('accountClassificationId') }, 'Label__Text')}>
                                    Account Type<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="accountClassificationId"
                                    placeholder="Select Account Type"
                                    options={accountTypeLov}
                                    optionLabel="name"
                                    name="accountClassificationId"
                                    optionValue="lovId"
                                    value={formik.values.accountClassificationId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('accountClassificationId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('accountClassificationId')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="segmentId" className={classNames({ 'p-error': isFormFieldValid('segmentId') }, 'Label__Text')}>
                                    Segment<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="segmentId"
                                    placeholder="Select User Type"
                                    options={segmentLov}
                                    optionLabel="name"
                                    name="segmentId"
                                    optionValue="lovId"
                                    value={formik.values.segmentId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('segmentId')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3 ">
                            <div className="p-field customMultiselect">
                                <label htmlFor="segmentIds" className={classNames({ 'p-error': isFormFieldValid('segmentIds') }, 'Label__Text')}>
                                    Segments<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                
                                    id="segmentIds"
                                    placeholder="Select Segments"
                                    options={segmentLov}
                                    optionLabel="name"
                                    name="segmentIds"
                                    optionValue="lovId"
                                    value={formik?.values?.segmentIds}
                                    className={classNames({ 'p-invalid': isFormFieldValid('segmentIds') }, 'Dropdown__Round')}

                                    onChange={formik.handleChange}
                                    
                                />
                                {getFormErrorMessage('segmentIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="pricingProfileName" className={classNames({ 'p-error': isFormFieldValid('pricingProfileName') }, 'Label__Text')}>
                                    Pricing Profile Name<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    disabled={true}
                                    maxLength={30}
                                    id="pricingProfileName"
                                    placeholder="Enter Pricing Profile Name"
                                    name="pricingProfileName"
                                    value={formik?.values?.pricingProfileName || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('pricingProfileName') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('pricingProfileName')}
                            </div>
                        </div>
                        {/* <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="channelId" className={classNames({ 'p-error': isFormFieldValid('channelId') }, 'Label__Text')}>
                                    Channel<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="channelId"
                                    placeholder="Select Partner Group"
                                    options={channelLov}
                                    optionLabel="name"
                                    name="channelId"
                                    optionValue="lovId"
                                    value={formik.values.channelId || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelId') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('channelId')}
                            </div>
                        </div> */}
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field customMultiselect">
                                <label htmlFor="channelIds" className={classNames({ 'p-error': isFormFieldValid('channelIds') }, 'Label__Text')}>
                                    Channel<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                  
                                    id="channelIds"
                                    placeholder="Select Partner Group"
                                    options={channelLov}
                                    optionLabel="name"
                                    name="channelIds"
                                    optionValue="lovId"
                                    value={formik.values.channelIds || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('channelIds') }, 'Dropdown__Round')}

                                />
                            
                                {getFormErrorMessage('channelIds')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="transaction" className={classNames({ 'p-error': isFormFieldValid('transaction') }, 'Label__Text')}>
                                    Transaction<span className="Label__Required">*</span>
                                </label>
                                <MultiSelect
                                    id="tblProduct"
                                    placeholder="Select Transaction"
                                    options={transactionsLov}
                                    optionLabel="name"
                                    filter
                                    name="tblProduct"
                                    optionValue="lovId"
                                    value={formik.values.tblProduct || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('tblProduct') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('tblProduct')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="chargingParty" className={classNames({ 'p-error': isFormFieldValid('chargingParty') }, 'Label__Text')}>
                                    Charging Party<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="chargingParty"
                                    placeholder="Select Charging Party"
                                    options={chargingPartyLov}
                                    optionLabel="name"
                                    name="chargingParty"
                                    optionValue="lovId"
                                    value={formik.values.chargingParty || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('chargingParty') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('chargingParty')}
                            </div>
                        </div>
                        {formik.values.chargingParty === 'P' ? (
                            <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                <div className="p-field">
                                    <label htmlFor="payeeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('payeeGlAccountId') }, 'Label__Text')}>
                                        Payee GL Account<span className="Label__Required">*</span>
                                    </label>
                                    <Dropdown
                                        id="payeeGlAccountId"
                                        placeholder="Select Charging Party"
                                        options={glJsonLov}
                                        optionLabel="name"
                                        filter
                                        name="payeeGlAccountId"
                                        optionValue="lovId"
                                        value={formik.values.payeeGlAccountId || ''}
                                        onChange={formik.handleChange}
                                        className={classNames({ 'p-invalid': isFormFieldValid('payeeGlAccountId') }, 'Dropdown__Round')}
                                    />

                                    {getFormErrorMessage('payeeGlAccountId')}
                                </div>
                            </div>
                        ) : null}
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="startDate" className={classNames({ 'p-error': isFormFieldValid('startDate') }, 'Label__Text')}>
                                    Start Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    max="9999-12-31"
                                    // min={previousDate}
                                    disabled={true}
                                    id="startDate"
                                    placeholder="Enter startDate"
                                    name="startDate"
                                    type="date"
                                    value={formik?.values?.startDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('startDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('startDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="endDate" className={classNames({ 'p-error': isFormFieldValid('endDate') }, 'Label__Text')}>
                                    End Date<span className="Label__Required">*</span>
                                </label>
                                <InputText
                                    max="9999-12-31"
                                    min={previousDate}
                                    id="endDate"
                                    placeholder="Enter endDate"
                                    name="endDate"
                                    type="date"
                                    value={formik?.values?.endDate || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('endDate') }, 'Input__Round')}
                                />
                                {getFormErrorMessage('endDate')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="feeCalculationType" className={classNames({ 'p-error': isFormFieldValid('feeCalculationType') }, 'Label__Text')}>
                                    Type of Fee Calculation<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="feeCalculationType"
                                    placeholder="Select Type of Fee Calculation"
                                    options={feeTypeJson}
                                    optionLabel="label"
                                    name="feeCalculationType"
                                    optionValue="value"
                                    value={formik.values.feeCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('feeCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('feeCalculationType')}
                            </div>
                        </div>
                        <div className="p-field col-12 md:col-4 pt-3 pb-3">
                            <div className="p-field">
                                <label htmlFor="taxCalculationType" className={classNames({ 'p-error': isFormFieldValid('taxCalculationType') }, 'Label__Text')}>
                                    Type of Tax Calculation<span className="Label__Required">*</span>
                                </label>
                                <Dropdown
                                    id="taxCalculationType"
                                    placeholder="Select Type of Tax Calculation"
                                    options={taxTypeJson}
                                    optionLabel="label"
                                    name="taxCalculationType"
                                    optionValue="value"
                                    value={formik.values.taxCalculationType || ''}
                                    onChange={formik.handleChange}
                                    className={classNames({ 'p-invalid': isFormFieldValid('taxCalculationType') }, 'Dropdown__Round')}
                                />

                                {getFormErrorMessage('taxCalculationType')}
                            </div>
                        </div>

                        <div style={{ backgroundColor: '#edf0f5', padding: '2rem' }} className=" col-12 card">
                            <div>
                                <div className="Form__Header">
                                    <h1>Conditional Fee</h1>
                                </div>
                                <div className="grid">
                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="feePercentage" className={classNames({ 'p-error': isFormFieldValid('feePercentage') }, 'Label__Text')}>
                                                Enter Percentage<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                type="text"
                                                maxLength={15}
                                                keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                id="feePercentage"
                                                placeholder="Enter feePercentage"
                                                name="feePercentage"
                                                value={formik?.values?.feePercentage || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('feePercentage') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('feePercentage')}
                                        </div>
                                    </div>
                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="feeAmount" className={classNames({ 'p-error': isFormFieldValid('feeAmount') }, 'Label__Text')}>
                                                Enter Fixed Amount<span className="Label__Required">*</span>
                                            </label>
                                            <InputText
                                                type="text"
                                                maxLength={15}
                                                keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                id="feeAmount"
                                                placeholder="Enter Fixed Amount"
                                                name="feeAmount"
                                                value={formik?.values?.feeAmount || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('feeAmount') }, 'Input__Round')}
                                            />
                                            {getFormErrorMessage('feeAmount')}
                                        </div>
                                    </div>

                                    <div className="p-field col-12 md:col-4 pt-3 pb-3">
                                        <div className="p-field">
                                            <label htmlFor="feeCondition" className={classNames({ 'p-error': isFormFieldValid('feeCondition') }, 'Label__Text')}>
                                                Select Condition<span className="Label__Required">*</span>
                                            </label>
                                            <Dropdown
                                                id="feeCondition"
                                                placeholder="Select Condition"
                                                options={conditionLov}
                                                optionLabel="label"
                                                name="feeCondition"
                                                optionValue="value"
                                                value={formik.values.feeCondition || ''}
                                                onChange={formik.handleChange}
                                                className={classNames({ 'p-invalid': isFormFieldValid('feeCondition') }, 'Dropdown__Round')}
                                            />

                                            {getFormErrorMessage('feeCondition')}
                                        </div>
                                    </div>
                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div className="">
                                                    <label htmlFor="velocity" className={classNames({ 'p-error': isFormFieldValid('velocity') }, 'Label__Text')}>
                                                        Add Velocity <span className="Label__Required">*</span>
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton
                                                            inputId="velocity"
                                                            name="velocity"
                                                            value="N"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handleVelocityChange('N');
                                                            }}
                                                            checked={formik.values.velocity === 'N'}
                                                        />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton
                                                            inputId="velocity"
                                                            name="velocity"
                                                            value="Y"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handleVelocityChange('Y');
                                                            }}
                                                            checked={formik.values.velocity === 'Y'}
                                                        />
                                                        <label htmlFor="velocity" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>
                                    {formik?.values?.velocity === 'Y' && (
                                        <>
                                            {formik?.values?.tblPricingVelocities?.map((velocityData, index) => {
                                                return (
                                                    <div key={`id-${index}`} className="col-12 card">
                                                        <div className="grid">
                                                            <div className=" grid col-11">
                                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                    <div className="p-field">
                                                                        <label htmlFor={`velocityRule${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Label__Text')}>
                                                                            Add Velocity Rule
                                                                        </label>
                                                                        <InputText
                                                                            type="number"
                                                                            maxLength={15}
                                                                            id={`velocityRule${index}`}
                                                                            placeholder="Enter Velocity Rule"
                                                                            name={`tblPricingVelocities[${index}].velocityRule`}
                                                                            value={velocityData.velocityRule?.replace(/\s\s+/g, ' ') || ''}
                                                                            onChange={formik.handleChange}
                                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityRule`) }, 'Input__Round')}
                                                                        />
                                                                        <small className="p-error">{formik.touched.tblPricingVelocities?.[index]?.velocityRule && formik.errors.tblPricingVelocities?.[index]?.velocityRule}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                    <div className="p-field">
                                                                        <label htmlFor={`frequency${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Label__Text')}>
                                                                            Enter Frequency<span className="Label__Required">*</span>
                                                                        </label>
                                                                        <Dropdown
                                                                            id={`frequency${index}`}
                                                                            placeholder="Select Type of Fee "
                                                                            options={frequencyJson}
                                                                            optionLabel="label"
                                                                            name={`tblPricingVelocities[${index}].frequency`}
                                                                            optionValue="value"
                                                                            value={velocityData.frequency || ''}
                                                                            onChange={formik.handleChange}
                                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].frequency`) }, 'Dropdown__Round')}
                                                                        />

                                                                        <small className="p-error">{formik.touched.tblPricingVelocities?.[index]?.frequency && formik.errors.tblPricingVelocities?.[index]?.frequency}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                    <div className="p-field">
                                                                        <label htmlFor={`velocityAmount${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Label__Text')}>
                                                                            Velocity Amount<span className="Label__Required">*</span>
                                                                        </label>
                                                                        <InputText
                                                                            maxLength={15}
                                                                            keyfilter={'int'}
                                                                            id={`velocityAmount${index}`}
                                                                            placeholder="Enter velocity amount"
                                                                            name={`tblPricingVelocities[${index}].velocityAmount`}
                                                                            value={velocityData.velocityAmount || ''}
                                                                            onChange={formik.handleChange}
                                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].velocityAmount`) }, 'Input__Round')}
                                                                        />

                                                                        <small className="p-error">{formik.touched.tblPricingVelocities?.[index]?.velocityAmount && formik.errors.tblPricingVelocities?.[index]?.velocityAmount}</small>
                                                                    </div>
                                                                </div>
                                                                <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                    <div className="p-field">
                                                                        <label htmlFor={`noOfFreeTrxn${index}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Label__Text')}>
                                                                            Enter Number of Free Trx<span className="Label__Required">*</span>
                                                                        </label>
                                                                        <InputText
                                                                            type="text"
                                                                            keyfilter={'int'}
                                                                            maxLength={15}
                                                                            id={`noOfFreeTrxn${index}`}
                                                                            placeholder="Enter Number of Free Trx"
                                                                            name={`tblPricingVelocities[${index}].noOfFreeTrxn`}
                                                                            value={velocityData.noOfFreeTrxn || ''}
                                                                            onChange={formik.handleChange}
                                                                            className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingVelocities[${index}].noOfFreeTrxn`) }, 'Input__Round')}
                                                                        />
                                                                        <small className="p-error">{formik.touched.tblPricingVelocities?.[index]?.noOfFreeTrxn && formik.errors.tblPricingVelocities?.[index]?.noOfFreeTrxn}</small>
                                                                    </div>
                                                                </div>

                                                                <div className="col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3 mt-5">
                                                                    <div className="flex flex-column gap-2">
                                                                        <div className="flex align-items-center">
                                                                            <label htmlFor="chargeOnDifferential" className={classNames({ 'p-error': isFormFieldValid('chargeOnDifferential') }, 'Label__Text')}>
                                                                                Charge on Differential
                                                                            </label>
                                                                            <div className="flex align-items-center mr-3">
                                                                                <RadioButton
                                                                                    style={{ marginLeft: '10px' }}
                                                                                    inputId={`tblPricingVelocities${index}_n`}
                                                                                    name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                    value="N"
                                                                                    onChange={formik.handleChange}
                                                                                    checked={velocityData.chargeOnDifferential === 'N'}
                                                                                />
                                                                                <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                    No
                                                                                </label>
                                                                            </div>
                                                                            <div className="flex align-items-center mr-3">
                                                                                <RadioButton
                                                                                    inputId={`tblPricingVelocities[${index}]_y`}
                                                                                    name={`tblPricingVelocities[${index}].chargeOnDifferential`}
                                                                                    value="Y"
                                                                                    onChange={formik.handleChange}
                                                                                    checked={velocityData.chargeOnDifferential === 'Y'}
                                                                                />
                                                                                <label htmlFor="chargeOnDifferential" className="ml-2">
                                                                                    Yes
                                                                                </label>
                                                                            </div>

                                                                            {getFormErrorMessage('chargeOnDifferential')}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                <Button
                                                                    icon="pi pi-plus"
                                                                    className="p-button-rounded"
                                                                    aria-label="Filter"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        addVelocity();
                                                                    }}
                                                                />
                                                                <Button
                                                                    icon="pi pi-minus"
                                                                    className="p-button-rounded mt-2 p-button-danger"
                                                                    aria-label="Filter"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        removeCard(index);
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                    <div className="col-12 lg:col-6 xl:col-4 md:col-4 flex">
                                        <div className="flex flex-column justify-content-center gap-2">
                                            <div className="flex flex-column">
                                                <div>
                                                    <label htmlFor="incomeSharing" className={classNames({ 'p-error': isFormFieldValid('incomeSharing') }, 'Label__Text')}>
                                                        Income Sharing <span className="Label__Required">*</span>
                                                    </label>
                                                </div>
                                                <div className="flex">
                                                    <div className="flex mr-3">
                                                        <RadioButton
                                                            inputId="incomeSharing"
                                                            name="incomeSharing"
                                                            value="N"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handleIncomeSharingChange('N');
                                                            }}
                                                            checked={formik.values.incomeSharing === 'N'}
                                                        />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            No
                                                        </label>
                                                    </div>
                                                    <div style={{ marginLeft: '10rem' }} className="flex mr-3">
                                                        <RadioButton
                                                            inputId="incomeSharing"
                                                            name="incomeSharing"
                                                            value="Y"
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                                handleIncomeSharingChange('Y');
                                                            }}
                                                            checked={formik.values.incomeSharing === 'Y'}
                                                        />
                                                        <label htmlFor="incomeSharing" className="ml-2">
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>

                                                {getFormErrorMessage('isSearchable')}
                                            </div>
                                        </div>
                                    </div>

                                    {formik.values.incomeSharing === 'Y' && (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="noOfPartners" className={classNames({ 'p-error': isFormFieldValid('noOfPartners') }, 'Label__Text')}>
                                                        Number of Partners<span className="Label__Required">*</span>
                                                    </label>
                                                    <InputText
                                                        disabled={true}
                                                        type="text"
                                                        keyfilter="int"
                                                        maxLength={10}
                                                        id="noOfPartners"
                                                        placeholder="Select Partners "
                                                        name="noOfPartners"
                                                        value={formik.values.noOfPartners || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('noOfPartners') }, 'Input__Round')}
                                                    />

                                                    {getFormErrorMessage('noOfPartners')}
                                                </div>
                                            </div>
                                            {formik?.values?.tblPricingIncomeSharings?.map((partnerData, index) => {
                                                index = index + 1;
                                                return (
                                                    <div key={`id-${index - 1}`} className="col-12">
                                                        <div style={{ padding: '2rem' }} className="col-12 card">
                                                            <div className="Form__Header">
                                                                <h1>Sharing {index}</h1>
                                                            </div>

                                                            <div className="grid">
                                                                <div className="grid col-11">
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingType${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Label__Text')}>
                                                                                Sharing Type<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <Dropdown
                                                                                id={`sharingType${index - 1}`}
                                                                                placeholder="Select Type of Fee "
                                                                                options={sharingTypeJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingType`}
                                                                                optionValue="value"
                                                                                value={partnerData.sharingType || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingType`) }, 'Dropdown__Round')}
                                                                            />

                                                                            <small className="p-error">{formik.touched.tblPricingIncomeSharings?.[index - 1]?.sharingType && formik.errors.tblPricingIncomeSharings?.[index - 1]?.sharingType}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`incomeGlAccountId${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Label__Text')}
                                                                            >
                                                                                Income GL<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <Dropdown
                                                                                id={`incomeGlAccountId${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.incomeGlAccountId || ''}
                                                                                onChange={(e) => {
                                                                                    formik.handleChange(e);
                                                                                    const selectedValue = e.value;
                                                                                    const selectedAccount = glJsonLov.find((item) => item.lovId === selectedValue);
                                                                                    const updatedSharingAccount = [...sharingAccount];
                                                                                    updatedSharingAccount[index - 1] = selectedAccount ? selectedAccount.accountTitle : '';
                                                                                    setSharingAccount(updatedSharingAccount);
                                                                                }}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].incomeGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            <small className="p-error">
                                                                                {formik.touched.tblPricingIncomeSharings?.[index - 1]?.incomeGlAccountId && formik.errors.tblPricingIncomeSharings?.[index - 1]?.incomeGlAccountId}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`sharingDetails${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Label__Text')}>
                                                                                Sharing Details<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <InputText
                                                                                disabled={true}
                                                                                id={`sharingDetails${index - 1}`}
                                                                                placeholder="Select Income GL"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingDetails`}
                                                                                value={(partnerData.sharingDetails = sharingAccount[index - 1] || partnerData.sharingDetails || '')}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingDetails`) }, 'Input__Round')}
                                                                            />
                                                                            <small className="p-error">
                                                                                {formik.touched.tblPricingIncomeSharings?.[index - 1]?.sharingDetails && formik.errors.tblPricingIncomeSharings?.[index - 1]?.sharingDetails}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label
                                                                                htmlFor={`sharingPercentage${index - 1}`}
                                                                                className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Label__Text')}
                                                                            >
                                                                                Fee<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <InputText
                                                                                type="text"
                                                                                keyfilter={/^[0-9]*(\.[0-9]*)?$/}
                                                                                maxLength={15}
                                                                                id={`sharingPercentage${index - 1}`}
                                                                                placeholder="Enter Fee"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].sharingPercentage`}
                                                                                value={partnerData.sharingPercentage || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].sharingPercentage`) }, 'Input__Round')}
                                                                            />
                                                                            <small className="p-error">
                                                                                {formik.touched.tblPricingIncomeSharings?.[index - 1]?.sharingPercentage && formik.errors.tblPricingIncomeSharings?.[index - 1]?.sharingPercentage}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`taxStatus${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Label__Text')}>
                                                                                Tax Status<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <Dropdown
                                                                                id={`taxStatus${index - 1}`}
                                                                                placeholder="Select Type of Tax Status "
                                                                                options={taxStatusJson}
                                                                                optionLabel="label"
                                                                                name={`tblPricingIncomeSharings[${index - 1}].taxStatus`}
                                                                                optionValue="value"
                                                                                value={partnerData.taxStatus || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].taxStatus`) }, 'Dropdown__Round')}
                                                                            />

                                                                            <small className="p-error">{formik.touched.tblPricingIncomeSharings?.[index - 1]?.taxStatus && formik.errors.tblPricingIncomeSharings?.[index - 1]?.taxStatus}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                                        <div className="p-field">
                                                                            <label htmlFor={`whtGlAccountId${index - 1}`} className={classNames({ 'p-error': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Label__Text')}>
                                                                                WHT GL<span className="Label__Required">*</span>
                                                                            </label>
                                                                            <Dropdown
                                                                                id={`whtGlAccountId${index - 1}`}
                                                                                placeholder="Select Type of WHT GL"
                                                                                options={glJsonLov}
                                                                                optionLabel="name"
                                                                                filter
                                                                                name={`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`}
                                                                                optionValue="lovId"
                                                                                value={partnerData.whtGlAccountId || ''}
                                                                                onChange={formik.handleChange}
                                                                                className={classNames({ 'p-invalid': isFormFieldValid(`tblPricingIncomeSharings[${index - 1}].whtGlAccountId`) }, 'Dropdown__Round')}
                                                                            />

                                                                            <small className="p-error">
                                                                                {formik.touched.tblPricingIncomeSharings?.[index - 1]?.whtGlAccountId && formik.errors.tblPricingIncomeSharings?.[index - 1]?.whtGlAccountId}
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-column  align-items-center justify-content-center col-1">
                                                                    <Button
                                                                        // disabled={formik.values.tblPricingIncomeSharings.length == formik.values.noOfPartners ? true : false}
                                                                        icon="pi pi-plus"
                                                                        className="p-button-rounded"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            addPartner();
                                                                        }}
                                                                    />
                                                                    <Button
                                                                        icon="pi pi-minus"
                                                                        className="p-button-rounded mt-2 p-button-danger"
                                                                        aria-label="Filter"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removePartnerCard(index);
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}

                                    {formik.values.incomeSharing === 'Y' ? null : (
                                        <>
                                            <div className="p-field col-12 lg:col-6 xl:col-4 md:col-4 pt-3 pb-3">
                                                <div className="p-field">
                                                    <label htmlFor="incomeGlAccountId" className={classNames({ 'p-error': isFormFieldValid('incomeGlAccountId') }, 'Label__Text')}>
                                                        Income GL<span className="Label__Required">*</span>
                                                    </label>
                                                    <Dropdown
                                                        id="incomeGlAccountId"
                                                        placeholder="Select Income GL"
                                                        options={glJsonLov}
                                                        optionLabel="name"
                                                        filter
                                                        name="incomeGlAccountId"
                                                        optionValue="lovId"
                                                        value={formik.values.incomeGlAccountId || ''}
                                                        onChange={formik.handleChange}
                                                        className={classNames({ 'p-invalid': isFormFieldValid('incomeGlAccountId') }, 'Dropdown__Round')}
                                                    />
                                                </div>
                                                {getFormErrorMessage('incomeGlAccountId')}
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="Down__Btn">
                        <Button label="Submit" loadingIcon={loadingIcon} className="Btn__Dark" />
                        <Button disabled={loading} onClick={() => navigate('/pricing')} label="Cancel" className="Btn__Transparent" />
                    </div>
                </form>
            </div>
        </>
    );
}
export default EditPricingConditionalFee;
